export enum ParentPopulation {
  SOUTHERN_AND_WESTERN_ASIA = 'SAS',
  SUB_SAHARAN_AFRICA = 'AFR',
  AMERICAS = 'AMR',
  EUROPE = 'EUR',
  EAST_AND_NORTH_ASIA = 'EAS',
  OCEANIA = 'OCEANIAN',
  MIDDLE_EAST_AND_NORTH_AFRICA = 'MIDDLE_EASTERN_AND_NORTH_AFRICAN',
}

export enum Subpopulation {
  EAST_AFRICA = 'EAST_AFRICA',
  IBERIA = 'IBERIA',
  TUSCANY = 'TUSCANY',
  EAST_CHINA = 'EAST_CHINA',
  SOUTH_CHINA_AND_SOUTHEAST_ASIA = 'SOUTH_CHINA_AND_SOUTHEAST_ASIA',
  EAST_INDIA_AND_SRILANKA = 'EAST_INDIA_AND_SRILANKA',
  NORTH_INDIA_AND_WEST_ASIA = 'NORTH_INDIA_AND_WEST_ASIA',
  BALKANS_AND_MEDITERRANEAN = 'BALKANS_AND_MEDITERRANEAN',
  CENTRAL_EUROPE = 'CENTRAL_EUROPE',
  NORTH_ITALY = 'NORTH_ITALY',
  BANGLADESH_AND_BENGAL = 'BANGLADESH_AND_BENGAL',
  SOUTH_AND_EAST_INDIA = 'SOUTH_AND_EAST_INDIA',
  BALOCHISTAN = 'BALOCHISTAN',
  CENTRAL_AND_EAST_AFRICA = 'CENTRAL_AND_EAST_AFRICA',
  HORN_OF_AFRICA = 'HORN_OF_AFRICA',
  NORTHWEST_ASIA = 'Northwest_Asia',
  JAPAN = 'Japan',
  SOUTHEAST_ASIA = 'Southeast_Asia',
  NORTHEAST_ASIA = 'Northeast_Asia',
  CENTRAL_EAST_ASIA = 'Central_East_Asia',
  ASHKENAZI_JEWISH = 'Ashkenazi_Jewish',
  EAST_EUROPE = 'East_Europe',
  FINLAND = 'Finland',
  NORTH_EUROPE = 'North_Central_Europe',
  SOUTH_EUROPE = 'South_Europe_Med',
  WEST_EUROPE = 'West_Europe',
  MIDDLE_EAST = 'Middle_East',
  NORTH_AFRICA = 'North_Africa',
  WEST_ASIA = 'West_Asia',
  NORTH_INDIA = 'North_India',
  SOUTH_INDIA = 'South_India',
  EAST_INDIA_BENGAL = 'East_India_Bengal',
  WEST_AFRICA = 'West_Africa',
  CENTRAL_EAST_AFRICA = 'Central_East_Africa',
  MELANESIA = 'Melanesia',
  INDIGENOUS_AMERICAS = 'Indigenous_Americas',
}
export interface AncestryComposition {
  population: ParentPopulation;
  percentage: number;
  subcompositions: { population: Subpopulation; percentage: number }[];
}

export interface AncestryData {
  compositions: AncestryComposition[];
  version: number;
  hasViewedAncestry: boolean;
}

export interface AncestryPopulationsContent {
  populations: Record<
    ParentPopulation,
    {
      title: string;
      descriptions: React.ReactNode[];
      facts: React.ReactNode[];
      carousel: React.ReactNode[];
    }
  >;
  subpopulations: Record<Subpopulation, { title: React.ReactNode; examples?: React.ReactNode }>;
}
