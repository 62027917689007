import { Grid, Spacer, Text, useMediaQuery, useTheme } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

export interface AncestryTutorialHeaderProps {
  currentStep: number;
  maxStep: number;
}

export const AncestryTutorialHeader: React.FC<AncestryTutorialHeaderProps> = (
  props: AncestryTutorialHeaderProps
) => {
  const { currentStep, maxStep } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Grid
      container
      justifyContent="space-between"
      wrap="wrap-reverse"
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        paddingY: '12px',
        paddingX: '14px',
        [theme.breakpoints.down('xs')]: {
          // Consider using theme spacing value of 2 here:
          paddingX: '16px',
        },
      }}
    >
      <Grid item xs={12} sm={11}>
        <Text bold>
          <FormattedMessage
            defaultMessage="Tutorial: How is your genetic ancestry calculated?"
            description="Ancestry tutorial header text"
          />
        </Text>
      </Grid>
      {isMobile && (
        <Grid item xs={12}>
          <Spacer variant="xSmall" />
        </Grid>
      )}
      <Grid container item justifyContent={isMobile ? 'flex-start' : 'flex-end'} xs={12} sm={1}>
        <Text bold>
          {currentStep}/{maxStep}
        </Text>
      </Grid>
    </Grid>
  );
};
