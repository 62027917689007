// This file was moved back to GP from CDS, since it is a) not heavily shared with other projects, and
// b) the types and functionality should live with the component, not separately.
import { useRangedIndex } from '@color/continuum';
import { useState } from 'react';

import { isItemApplicable } from '../QuizItem/isItemApplicable';
import { IQuiz, TAnswerMap, TQuizAnswer } from '../types';

interface useQuizProps {
  quiz: IQuiz;
  initialAnswers: TAnswerMap;
}

export function useQuizState(props: useQuizProps) {
  const { quiz, initialAnswers } = props;
  // The answers for this quiz, stored in a map from question ID to answer.
  const [answers, setAnswers] = useState<Record<string, TQuizAnswer>>(initialAnswers);

  const quizItems = quiz.items.filter((quizItem) => {
    return isItemApplicable(quizItem, answers, quiz.items);
  });

  const initialQuizIndex = quizItems.findIndex(
    (quizItem) => quizItem.question && !(quizItem.question.id in answers)
  );

  // Stores whether or not the quiz is finished, e.g. we have completed the last QuizItem
  const [quizIsFinished, setQuizIsFinished] = useState<boolean>(Boolean(initialQuizIndex === -1));

  const { index, setIndex, hasNext, hasPrevious } = useRangedIndex({
    count: quizItems.length + 1,
    initialIndex: initialQuizIndex >= 0 ? initialQuizIndex : quizItems.length,
  });
  const quizItem = index === quizItems.length ? null : quizItems[index];

  // Save the answer to the current quizItem locally.
  const saveAnswerLocally = (quizAnswer?: TQuizAnswer) => {
    const newAnswers = { ...answers };
    if (quizItem && quizItem.question?.id) {
      if (quizAnswer) {
        newAnswers[quizItem.question?.id] = quizAnswer;
        setAnswers(newAnswers);
      }
    }
  };

  // Move the quiz to a new quiz item. Finish the quiz if we are moving past the last quiz item.
  // We allow the quizItemIndex to be 1 more than the quizItemCount to support the finish screen.
  const setQuizItemIndex = (newItemIndex: number) => {
    if (newItemIndex === quizItems.length) {
      setQuizIsFinished(true);
    }
    setIndex(newItemIndex);
  };
  return {
    quizItem,
    quizItemIndex: index,
    isLastQuizItem: index === quizItems.length - 1,
    answer: quizItem?.question && answers[quizItem.question.id],
    quizItemCount: quizItems.length,
    hasNextQuizItem: hasNext,
    hasPreviousQuizItem: hasPrevious,
    goToPreviousQuizItem: () => {
      setQuizItemIndex(index - 1);
    },
    saveAnswerLocallyAndGoToNextQuizItem: (quizAnswer?: TQuizAnswer) => {
      saveAnswerLocally(quizAnswer);
      setQuizItemIndex(index + 1);
    },
    quizIsFinished,
  };
}
