import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

const messages = defineMessages({
  gemILModule2PageTitle: {
    description: 'GEM informing loop - module 2 page title',
    defaultMessage: 'What You Can Learn',
  },
});

export const GemInformingLoopModule2: React.FC = () => {
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.gemILModule2PageTitle)}
      module={{
        analyticsInformingLoopName: 'GEM Informing Loop',
        analyticsPageName: '2. About Results',
        image: CircularImageType.TRAITS_CLIPBOARD,
        title: (
          <FormattedMessage
            description="GEM informing loop page 2 title"
            defaultMessage="What will my genetic ancestry and trait results tell me?"
          />
        ),
        content: (
          <FormattedMessage
            description="GEM informing loop page 2 content"
            defaultMessage="<p>Your trait results can tell you things about how your body works, such as:</p>
            <ul>
                <li>Whether your earwax is likely sticky or dry.</li>
                <li>Why you might love or hate cilantro.</li>
            </ul>
            <p>This is because some genes in your DNA make it more or less likely that you will have these traits.</p>
            <p>Your genetic ancestry results can show you where your ancestors might have lived hundreds of years ago. This is because people whose ancestors lived in the same part of the world have similar patterns in their DNA. By comparing your DNA to the DNA of people who know their ancestry, we can estimate the places your ancestors may have lived.</p>
            <p>Genetic ancestry results are estimates based on the information we have right now. That means your results may change as we learn more.</p>"
          />
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
