import { useState } from 'react';

import { ResultsFilterType } from './types';

export const useResultsFilterState = () => {
  const [resultsFilterSelection, onChangeResultsFilterSelection] = useState<ResultsFilterType>(
    ResultsFilterType.All
  );
  const shouldShowHealthRelatedResults =
    resultsFilterSelection === ResultsFilterType.All ||
    resultsFilterSelection === ResultsFilterType.HealthRelatedResults;

  const shouldShowAncestryAndTraitResults =
    resultsFilterSelection === ResultsFilterType.All ||
    resultsFilterSelection === ResultsFilterType.AncestryAndTraitResults;

  return {
    shouldShowHealthRelatedResults,
    shouldShowAncestryAndTraitResults,
    resultsFilterSelection,
    onChangeResultsFilterSelection,
  };
};
