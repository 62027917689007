import { ExternalLink, Text } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { config } from 'config';

import { PGX_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  pgxILModule4PageTitle: {
    description: 'PGx Informing Loop Page Title - Module 4',
    defaultMessage: 'Benefits',
  },
});

export const PgxInformingLoopModule4: React.FC = () => {
  const { locale } = usePreferredLocale();
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.pgxILModule4PageTitle)}
      module={{
        analyticsInformingLoopName: PGX_INFORMING_LOOP_NAME,
        analyticsPageName: '4. Benefits',
        image: CircularImageType.STAR_DOCUMENT_RED,
        title: <FormattedMessage id="PGX_IL_4__Title" />,
        content: (
          <>
            <Text paragraph>
              <FormattedMessage
                description="PGX informing loop page 4 paragraph 1"
                defaultMessage="Your results could show you have a version of a gene that may affect how your body responds to some medicines. If you do, your results will also contain a list of specific medicines that may be affected."
              />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="PGX informing loop page 4 paragraph 2"
                defaultMessage="Doctors and other health care providers may use this information to decide if you should get a clinical test. They can use the results of that clinical test when they consider what medicines to give you and how much."
              />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="PGX informing loop page 4 paragraph 3"
                defaultMessage="To learn more about this type of DNA result and others, visit the {learningCenterLink}. Learning more may help you decide if you would like to see this information."
                values={{
                  learningCenterLink: (
                    <ExternalLink to={config.LEARNING_CENTER_GENES_AND_MEDICINE_URL[locale].href}>
                      <FormattedMessage
                        description="Learning center link text"
                        defaultMessage="Learning Center"
                      />
                    </ExternalLink>
                  ),
                }}
              />
            </Text>
          </>
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
