import { Redirect, Route, Switch } from 'react-router-dom';

import {
  HdrPreResultsModule1,
  HdrPreResultsModule2,
  HdrPreResultsModule3,
  HdrPreResultsModule4,
} from './HdrPreResultsModules';
import { useHdrPreResultsLocationsByModule } from './locations';

export const HdrPreResultsRoutes = () => {
  const locations = useHdrPreResultsLocationsByModule();
  return (
    <Switch>
      {/* Redirect to the first module */}
      <Route exact path={locations.MODULE_1.pathname}>
        <HdrPreResultsModule1 />
      </Route>
      <Route exact path={locations.MODULE_2.pathname}>
        <HdrPreResultsModule2 />
      </Route>
      <Route exact path={locations.MODULE_3.pathname}>
        <HdrPreResultsModule3 />
      </Route>
      <Route exact path={locations.MODULE_4.pathname}>
        <HdrPreResultsModule4 />
      </Route>
      <Redirect to={locations.MODULE_1} />
    </Switch>
  );
};
