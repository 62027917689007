import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  getMuiClassSelector,
} from '@color/continuum';
import { find } from 'lodash';
import { useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { DecisionModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { useResultDecisionsQuery } from 'lib/analytics/result-decisions';
import { ResultDecisionValue } from 'lib/analytics/result-decisions/types';
import { ModuleType } from 'lib/analytics/types';

const messages = defineMessages({
  gemILDecisionModulePageTitle: {
    description: 'GEM informing loop - decision module page title',
    defaultMessage: 'Do You Want Results?',
  },
});
/**
 * This style should probably be incorporated into the theme, or extracted as a shared constant
 */
const RADIO_LABEL_SX = {
  alignItems: 'flex-start',
  [getMuiClassSelector('& .MuiFormControlLabel-label')]: {
    paddingTop: 1,
  },
};

export const GemInformingLoopDecisionModule: React.FC = () => {
  const [decision, setDecision] = useState<ResultDecisionValue>();
  const [loading, isLoading] = useState(true);
  const intl = useIntl();
  const moduleType = ModuleType.GEM;
  useResultDecisionsQuery(
    { moduleType },
    {
      onSuccess: (data) => {
        const resultsDecision = find(data?.resultDecisions || [], {
          moduleType,
        });
        if (resultsDecision) {
          setDecision(resultsDecision.decisionValue);
        }
        isLoading(false);
      },
      onError: () => {
        isLoading(false);
      },
    }
  );
  if (loading) {
    return null;
  }
  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.gemILDecisionModulePageTitle)}
      module={{
        analyticsInformingLoopName: 'GEM Informing Loop',
        analyticsPageName: '7. Decision',
        image: CircularImageType.CHECK_MARK_DOCUMENT_BLUE,
        title: (
          <FormattedMessage
            description="GEM informing loop decision page title"
            defaultMessage="Would you like to get your genetic ancestry and trait results?"
          />
        ),
        content: (
          <>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="informing-loop-decision"
                // The DOM API converts the InformingLoopDecision enum value to a string here, so we need to convert back.
                onChange={(_event, value) => setDecision(value as ResultDecisionValue)}
                defaultValue={decision}
              >
                <FormControlLabel
                  sx={RADIO_LABEL_SX}
                  value={ResultDecisionValue.YES}
                  control={<Radio size="small" />}
                  label={
                    <>
                      <Text bold>
                        <FormattedMessage
                          description="GEM informing loop decision page yes option label"
                          defaultMessage="Yes, I want my genetic ancestry and trait results."
                        />
                      </Text>
                      <Spacer variant="xSmall" />
                      <Text>
                        <FormattedMessage
                          description="GEM informing loop decision page yes option description"
                          defaultMessage="You will be able to see your trait results right away, but you will need to review additional information before you can see your genetic ancestry results."
                        />
                      </Text>
                    </>
                  }
                />
                <Spacer variant="medium" />
                <FormControlLabel
                  sx={RADIO_LABEL_SX}
                  value={ResultDecisionValue.NO}
                  control={<Radio size="small" />}
                  label={
                    <>
                      <Text bold>
                        <FormattedMessage
                          description="GEM informing loop decision page no option label"
                          defaultMessage="No, I do not want my genetic ancestry and trait results."
                        />
                      </Text>
                      <Spacer variant="xSmall" />
                      <Text>
                        <FormattedMessage
                          description="GEM informing loop decision page no option description"
                          defaultMessage="You can always change your decision later."
                        />
                      </Text>
                    </>
                  }
                />
                <Spacer variant="medium" />
                <FormControlLabel
                  sx={RADIO_LABEL_SX}
                  value={ResultDecisionValue.MAYBE_LATER}
                  control={<Radio size="small" />}
                  label={
                    <>
                      <Text bold>
                        <FormattedMessage
                          description="GEM informing loop decision page maybe later option label"
                          defaultMessage="I’m not sure right now."
                        />
                      </Text>
                      <Spacer variant="xSmall" />
                      <Text>
                        <FormattedMessage
                          description="GEM informing loop decision page maybe later option description"
                          defaultMessage="If you are not ready to decide, you can choose to get these results later."
                        />
                      </Text>
                    </>
                  }
                />
              </RadioGroup>
            </FormControl>
            <Spacer variant="medium" />
            <Text>
              <FormattedMessage
                description="GEM informing loop decision page additional information"
                defaultMessage="This decision does not affect whether you can see other types of DNA results, such as health-related results. <i>All of Us</i> will send you messages when other DNA results are available."
              />
            </Text>
          </>
        ),
      }}
      footer={
        <DecisionModuleInformingLoopFooter
          decision={decision}
          moduleType={moduleType}
          queriesInvalidatedAfterDecisionSaved={['ancestry', 'trait_outcomes']}
        />
      }
    />
  );
};
