import { Box, InlineSpacer, Text } from '@color/continuum';
import { defineMessages, useIntl } from 'react-intl';

import { ResultsFilterChip } from './ResultFilterChip';
import { ResultsFilterType } from './types';

interface ResultsFilterProps {
  resultsFilterSelection: ResultsFilterType;
  onChangeResultsFilterSelection: (resultsFilterSelection: ResultsFilterType) => void;
}

const messages = defineMessages({
  filterBy: {
    description: 'GP dashboard results filter by label',
    defaultMessage: 'Filter by:',
  },
  allLabel: {
    description: 'GP dashboard results filter all label',
    defaultMessage: 'All',
  },
  healthRelatedLabel: {
    description: 'GP dashboard results filter health realted label',
    defaultMessage: 'Health-related',
  },
  ancestryAndTraitsLabel: {
    description: 'GP dashboard results filter genetic ancestry and traits label',
    defaultMessage: 'Genetic ancestry and traits',
  },
  resultsFilterGroupLabel: {
    description: 'GP dashboard results filter group aria label',
    defaultMessage: 'Results Filter',
  },
});
export const ResultsFilter: React.FC<ResultsFilterProps> = (props) => {
  const { resultsFilterSelection, onChangeResultsFilterSelection } = props;
  const intl = useIntl();
  return (
    <Box overflow="auto">
      <Box
        display="inline-flex"
        role="group"
        aria-label={intl.formatMessage(messages.resultsFilterGroupLabel)}
        alignItems="center"
      >
        <Text>{intl.formatMessage(messages.filterBy)} </Text>
        <InlineSpacer variant="xSmall" />
        <ResultsFilterChip
          label={intl.formatMessage(messages.allLabel)}
          onClick={() => onChangeResultsFilterSelection(ResultsFilterType.All)}
          selected={resultsFilterSelection === ResultsFilterType.All}
        />
        <InlineSpacer variant="xSmall" />
        <ResultsFilterChip
          label={intl.formatMessage(messages.healthRelatedLabel)}
          onClick={() => onChangeResultsFilterSelection(ResultsFilterType.HealthRelatedResults)}
          selected={resultsFilterSelection === ResultsFilterType.HealthRelatedResults}
        />
        <InlineSpacer variant="xSmall" />
        <ResultsFilterChip
          label={intl.formatMessage(messages.ancestryAndTraitsLabel)}
          onClick={() => onChangeResultsFilterSelection(ResultsFilterType.AncestryAndTraitResults)}
          selected={resultsFilterSelection === ResultsFilterType.AncestryAndTraitResults}
        />
      </Box>
    </Box>
  );
};
