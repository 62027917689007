import { ConfirmCancelDialog, Text } from '@color/continuum';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

interface Props {
  dialogIsOpen: boolean;
  onClose: () => void;
  pathToInformingLoop: string;
}

export const ReviewInformingLoopDialog: React.FC<Props> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const { dialogIsOpen, pathToInformingLoop, onClose } = props;

  return (
    <ConfirmCancelDialog
      dialogIsOpen={dialogIsOpen}
      isFullScreenOnMobile={false}
      title={intl.formatMessage({
        defaultMessage:
          'First, you’ll need to review the benefits and risks of seeing your results.',
        description: 'Title of dialog on options page for reviewing informing loop',
      })}
      onAccept={() => {
        history.push({
          state: { backgroundLocation: location },
          pathname: pathToInformingLoop,
        });
        onClose();
      }}
      onCancel={() => onClose()}
      confirmButtonText={intl.formatMessage({
        defaultMessage: 'Continue',
        description: 'Continue button on dialog on options page for reviewing informing loop',
      })}
      cancelButtonText={intl.formatMessage({
        defaultMessage: 'Cancel',
        description: 'Cancel button on dialog on options page for reviewing informing loop',
      })}
    >
      <Text>
        <FormattedMessage
          defaultMessage="We’ll walk you through the benefits and risks of seeing these DNA results. This should only take a few minutes."
          description="Content on dialog on options page for reviewing informing loop"
        />
      </Text>
    </ConfirmCancelDialog>
  );
};
