import {
  CVL_TO_NAME,
  GENOME_CENTER_TO_NAME,
  HdrV1ReportPayload,
  revisionsAreAvailable,
} from '@color/aou-reports';
import { groupBy } from 'lodash';
import { FC, useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { HEALTH_RELATED_RESULTS_PATHS } from 'components/Dashboard/constants';
import { ModuleType } from 'lib/analytics/types';

import { HdrPositiveReport } from '../HdrPositiveReport';
import { PreScreen } from './PreScreen';

interface HdrPositiveReportPreScreenProps {
  report: HdrV1ReportPayload;
  token?: string;
}
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const HdrPositiveReportPreScreen: FC<HdrPositiveReportPreScreenProps> = (props) => {
  const { report, token } = props;
  const {
    reportInfo: { genomeCenter, clinicalValidationLab },
    components: { variantDetails },
  } = report;
  const geneToVariantDetails = groupBy(variantDetails, 'gene');
  const groupedVariantDetails = Object.values(geneToVariantDetails);
  const geneInVariantDetails = Object.keys(geneToVariantDetails);
  const query = useQuery();
  const reportIndex = query.get('reportIndex');
  const isRevision = revisionsAreAvailable(report);
  // If participant has one gene report we return the HDRPositiveReport
  if (groupedVariantDetails.length === 1) {
    return (
      <HdrPositiveReport
        cvlSiteName={CVL_TO_NAME[clinicalValidationLab]}
        geneVariantDetails={groupedVariantDetails[0]}
        genomeCenterSiteName={GENOME_CENTER_TO_NAME[genomeCenter]}
        token={token}
        isRevision={isRevision}
        revisions={report.reportInfo.revisions}
        revision={report.reportInfo.revision}
      />
    );
  }
  // If reportIndex is wrong (string or array out of bounds)
  if (
    !Number.isInteger(Number(reportIndex)) ||
    groupedVariantDetails.length <= Number(reportIndex)
  ) {
    return <Redirect to={HEALTH_RELATED_RESULTS_PATHS[ModuleType.HDR]} />;
  }
  /*
    If participant has more than one gene report and they haven't selected which screen to view
    we return the prescreen so that they can select which gene report to view
  */
  if (!reportIndex) {
    return <PreScreen geneInVariantDetails={geneInVariantDetails} token={token} />;
  }
  // If participant entered a correct reportIndex we display that report
  if (Number.isInteger(Number(reportIndex))) {
    return (
      <HdrPositiveReport
        cvlSiteName={CVL_TO_NAME[clinicalValidationLab]}
        geneVariantDetails={groupedVariantDetails[Number(reportIndex)]}
        genomeCenterSiteName={GENOME_CENTER_TO_NAME[genomeCenter]}
        token={token}
        isRevision={isRevision}
        revisions={report.reportInfo.revisions}
        revision={report.reportInfo.revision}
      />
    );
  }
  return <PreScreen geneInVariantDetails={geneInVariantDetails} token={token} />;
};
