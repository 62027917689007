import { addHours, addMinutes, millisecondsToHours } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';

import { TIME_SLOTS_TIMEZONE } from '../constants';
import { TimeSlot } from '../types';

interface ICreateDateFromTimeSlotProps {
  timeSlot: TimeSlot;
  startOfDay: Date;
}

/**
 * Create a Date for the start of an appointment time slot, given the `timeSlot` and
 * start of the day of the appointment (`startOfDay`).
 *
 * There is some timezone manipulation here since all the timeslots are in America/Los_Angeles,
 * but the date-fns functions all work in the browser local timezone.
 */
export const createDateFromTimeSlot = (props: ICreateDateFromTimeSlotProps): Date => {
  const { timeSlot, startOfDay } = props;
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const offsetFromPossibleTimeSlotsTimezone = millisecondsToHours(
    getTimezoneOffset(localTimezone) - getTimezoneOffset(TIME_SLOTS_TIMEZONE)
  );
  let appointmentDate = addHours(startOfDay, timeSlot.hour + offsetFromPossibleTimeSlotsTimezone);
  appointmentDate = addMinutes(appointmentDate, timeSlot.minute);
  return appointmentDate;
};
