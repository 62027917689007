import { Box, Spacer, Text, Title } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { useResultStatuses } from 'components/Dashboard/api';
import { ModuleType } from 'lib/analytics/types';

import { HealthRelatedResultsCard } from './HealthRelatedResultsCard';

export const HealthRelatedResults: React.FC = () => {
  const { pgxResultsAreReady, hdrResultsAreReady } = useResultStatuses();
  const someResultsAreReady = pgxResultsAreReady || hdrResultsAreReady;
  const numberOfPgxReports = pgxResultsAreReady ? 1 : 0;
  const numberOfHdrReports = hdrResultsAreReady ? 1 : 0;
  return (
    <>
      <Box display="flex" gap={1} justifyContent="space-between" alignItems="center">
        <Title variant="headline2">
          <FormattedMessage
            description="Title of HGM section of dashboard"
            defaultMessage="Health-related results"
            id="KJ1ned"
          />
        </Title>
        {someResultsAreReady && (
          <Text>
            <FormattedMessage
              defaultMessage="{reportNumber} reports"
              values={{ reportNumber: numberOfHdrReports + numberOfPgxReports }}
            />
          </Text>
        )}
      </Box>
      <Spacer variant="large" />
      <HealthRelatedResultsCard moduleType={ModuleType.HDR} />
      <Spacer variant="medium" />
      <HealthRelatedResultsCard moduleType={ModuleType.PGX} />
    </>
  );
};
