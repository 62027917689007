import { ExternalLink, Spacer } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { useLocation } from 'react-router-dom';

import { IDP_CE } from 'lib/constants';

import {
  FOOTER_FAQ_LINK,
  FOOTER_FAQ_LINK_CE,
  FOOTER_JOIN_ALL_OF_US_LINK,
  FOOTER_JOIN_ALL_OF_US_LINK_CE,
  FOOTER_RESEARCH_ALL_OF_US_LINK,
} from '../constants';
import { useFooterContent } from '../content';
import { FooterText } from './FooterText';

export const FooterLearnMoreLinks: React.FC = () => {
  const footerContent = useFooterContent();
  const { search } = useLocation();
  const idp = new URLSearchParams(search).get('idp') || '';
  const isCEUser = idp === IDP_CE;
  const { locale } = usePreferredLocale();

  const faqLink = isCEUser ? FOOTER_FAQ_LINK_CE : FOOTER_FAQ_LINK;
  const joinAllOfUsLink = isCEUser ? FOOTER_JOIN_ALL_OF_US_LINK_CE : FOOTER_JOIN_ALL_OF_US_LINK;

  return (
    <>
      <FooterText bold>
        <ExternalLink to={faqLink[locale as keyof typeof faqLink]}>
          {footerContent.faq}
        </ExternalLink>
      </FooterText>
      <Spacer variant="medium" />
      <FooterText bold>
        <ExternalLink to={joinAllOfUsLink[locale as keyof typeof joinAllOfUsLink]}>
          JoinAllofUs.org
        </ExternalLink>
      </FooterText>
      <Spacer variant="medium" />
      <FooterText bold>
        <ExternalLink to={FOOTER_RESEARCH_ALL_OF_US_LINK}>ResearchAllofUs.org</ExternalLink>
      </FooterText>
    </>
  );
};
