import { Box, ExternalLink, InlineSpacer, useMediaQuery, useTheme } from '@color/continuum';

import facebookIcon from 'assets/images/icons/facebook.svg';
import instagramIcon from 'assets/images/icons/instagram.svg';
import twitterIcon from 'assets/images/icons/twitter.svg';
import youtubeIcon from 'assets/images/icons/youtube.svg';

import {
  FOOTER_FACEBOOK_LINK,
  FOOTER_INSTAGRAM_LINK,
  FOOTER_TWITTER_LINK,
  FOOTER_YOUTUBE_LINK,
} from '../constants';

export const FooterFollowUsLinks: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const SOCIAL_ICON_SX = {
    objectFit: 'none',
    height: theme.spacing(4),
    width: theme.spacing(4),
  };
  return (
    <Box display={isMobile ? 'flex' : 'block'} alignItems="center">
      <ExternalLink to={FOOTER_FACEBOOK_LINK}>
        <Box component="img" src={facebookIcon} alt="Facebook icon" />
      </ExternalLink>
      <InlineSpacer variant="small" />
      <ExternalLink to={FOOTER_TWITTER_LINK}>
        <Box component="img" src={twitterIcon} alt="Twitter icon" sx={SOCIAL_ICON_SX} />
      </ExternalLink>
      <InlineSpacer variant="small" />
      <ExternalLink to={FOOTER_YOUTUBE_LINK}>
        <Box component="img" src={youtubeIcon} alt="Youtube icon" sx={SOCIAL_ICON_SX} />
      </ExternalLink>
      <InlineSpacer variant="small" />
      <ExternalLink to={FOOTER_INSTAGRAM_LINK}>
        <Box component="img" src={instagramIcon} alt="Instagram icon" sx={SOCIAL_ICON_SX} />
      </ExternalLink>
    </Box>
  );
};
