import { Location, LocationState } from 'history';

import { PGX_INFORMING_LOOP_PATH } from 'components/App/AppRoutes/constants';
import { LocationDictionary } from 'components/common/InformingLoop/types';
import { useLocationsForIdp } from 'hooks/useLocationsForIdp';

export const usePgxInformingLoopLocationsByModule: () => LocationDictionary = () => {
  return useLocationsForIdp({
    MODULE_1: {
      pathname: `${PGX_INFORMING_LOOP_PATH}/module-1`,
    },
    MODULE_2: {
      pathname: `${PGX_INFORMING_LOOP_PATH}/module-2`,
    },
    MODULE_3: {
      pathname: `${PGX_INFORMING_LOOP_PATH}/module-3`,
    },
    MODULE_4: {
      pathname: `${PGX_INFORMING_LOOP_PATH}/module-4`,
    },
    MODULE_5: {
      pathname: `${PGX_INFORMING_LOOP_PATH}/module-5`,
    },
    MODULE_6: {
      pathname: `${PGX_INFORMING_LOOP_PATH}/module-6`,
    },
    DECISION: {
      pathname: `${PGX_INFORMING_LOOP_PATH}/decision`,
    },
    RESULT: {
      pathname: `${PGX_INFORMING_LOOP_PATH}/result`,
    },
  });
};

export const usePgxInformingLoopLocations: () => Location<LocationState>[] = () =>
  Object.values(usePgxInformingLoopLocationsByModule());
