import { Environment, Locale } from '@color/lib';

import { IDP_CE } from 'lib/constants';

import { AppConfig } from './types';

const PARTICIPANT_PORTAL_BASE_URL = 'https://participant-stb.joinallofus.org';

export const config: AppConfig = {
  API_HOST_URL: new URL('http://localhost:8890'),
  AGREEMENTS_URL: new URL('/agreements', PARTICIPANT_PORTAL_BASE_URL),
  ENVIRONMENT: Environment.LOCAL,
  LEARNING_CENTER_URL: new URL('/dl/8ZuSCpy9', PARTICIPANT_PORTAL_BASE_URL),
  LEARNING_CENTER_GENES_AND_MEDICINE_URL: {
    [Locale.ENGLISH]: new URL('/dl/g3AfCgee', PARTICIPANT_PORTAL_BASE_URL),
    [Locale.SPANISH]: new URL('/dl/pI9h4Gk6', PARTICIPANT_PORTAL_BASE_URL),
  },
  LEARNING_CENTER_GENES_AND_HEALTH_URL: {
    [Locale.ENGLISH]: new URL('/dl/CVvcual4', PARTICIPANT_PORTAL_BASE_URL),
    [Locale.SPANISH]: new URL('/dl/Jk5Hdial', PARTICIPANT_PORTAL_BASE_URL),
  },
  LEARNING_CENTER_GENETIC_TESTING_URL: {
    [Locale.ENGLISH]: new URL('/dl/ywjeYF4R', PARTICIPANT_PORTAL_BASE_URL),
    [Locale.SPANISH]: new URL('/dl/vOPmOwWw', PARTICIPANT_PORTAL_BASE_URL),
  },
  LEARNING_CENTER_DNA_AND_GENOMICS_URL: {
    [Locale.ENGLISH]: new URL('/dl/8ZuSCpy9', PARTICIPANT_PORTAL_BASE_URL),
    [Locale.SPANISH]: new URL('/dl/6889IjDP', PARTICIPANT_PORTAL_BASE_URL),
  },
  MY_DATA_URL: new URL('/mydata', PARTICIPANT_PORTAL_BASE_URL),
  PARTICIPANT_PORTAL_URL: new URL(PARTICIPANT_PORTAL_BASE_URL),
  SEGMENT_WRITE_KEY: 'cMrsaV4ugMobh4WT2N28fWkWr7OpEvwE',
  SENTRY_DSN: undefined,
  SSO_LOGOUT_URL: new URL(
    `https://accounts-stb.joinallofus.org/auth/realms/stb_participant_realm/protocol/openid-connect/logout?redirect_uri=${PARTICIPANT_PORTAL_BASE_URL}`
  ),
  SUPPORT_URL: new URL('/support', PARTICIPANT_PORTAL_BASE_URL),
};

export function getIdpOverrides(idp: string) {
  if (idp === IDP_CE) {
    const BASE_URL = 'https://platformaou.ce.dev';
    return {
      AGREEMENTS_URL: new URL(
        '/dryrun/MyDataHelps/MyDataHelps#/eb0987a7-2794-4f79-836a-c2bd1cb64eab/Dashboard/eyJ0eXBlIjoiRGFzaGJvYXJkIiwiZGFzaGJvYXJkS2V5IjoiQWdyZWVtZW50cyJ9Cg==',
        BASE_URL
      ),
      LEARNING_CENTER_URL: new URL(
        '/dryrun/MyDataHelps/MyDataHelps#/eb0987a7-2794-4f79-836a-c2bd1cb64eab/Dashboard/eyJ0eXBlIjoiRGFzaGJvYXJkIiwiZGFzaGJvYXJkS2V5IjoiTGVhcm5pbmdDZW50ZXIifQ',
        BASE_URL
      ),
      LEARNING_CENTER_DNA_AND_GENOMICS_URL: {
        [Locale.ENGLISH]: new URL(
          '/dryrun/MyDataHelps/MyDataHelps#/eb0987a7-2794-4f79-836a-c2bd1cb64eab/Dashboard/eyJ0eXBlIjoiRGFzaGJvYXJkIiwiZGFzaGJvYXJkS2V5IjoiTEMtRE5BIn0=',
          BASE_URL
        ),
        [Locale.SPANISH]: new URL(
          '/dryrun/MyDataHelps/MyDataHelps#/eb0987a7-2794-4f79-836a-c2bd1cb64eab/Dashboard/eyJ0eXBlIjoiRGFzaGJvYXJkIiwiZGFzaGJvYXJkS2V5IjoiTEMtRE5BIn0=',
          BASE_URL
        ),
      },
      MY_DATA_URL: new URL(
        '/dryrun/MyDataHelps/MyDataHelps#/eb0987a7-2794-4f79-836a-c2bd1cb64eab/Dashboard/eyJ0eXBlIjoiRGFzaGJvYXJkIiwiZGFzaGJvYXJkS2V5IjoiTXlEYXRhIn0=',
        BASE_URL
      ),
      PARTICIPANT_PORTAL_URL: new URL(
        '/dryrun/MyDataHelps/MyDataHelps#/eb0987a7-2794-4f79-836a-c2bd1cb64eab/Dashboard/eyJ0eXBlIjoiRGFzaGJvYXJkIiwiZGFzaGJvYXJkS2V5IjoiQWxsT2ZVc0Rhc2hib2FyZCJ9Cg==',
        BASE_URL
      ),
      SSO_LOGOUT_URL: new URL(
        `/ppt/identityserver/connect/endsession?post_logout_redirect_uri=${BASE_URL}/ppt/mydatahelps/p/s/Dashboard`,
        BASE_URL
      ),
    };
  }
  return {};
}
