import { Box, ExternalLink, InlineSpacer } from '@color/continuum';

import { FooterText } from './FooterText';

export interface FooterContactRowProps {
  icon: string;
  iconAlt: string;
  linkTo: string;
  linkText: string;
}

export const FooterContactRow: React.FC<FooterContactRowProps> = (props: FooterContactRowProps) => {
  const { icon, iconAlt, linkTo, linkText } = props;
  return (
    <Box display="flex" alignItems="center">
      <Box
        component="img"
        src={icon}
        alt={iconAlt}
        sx={{
          objectFit: 'none',
          height: '16px',
          width: '16px',
        }}
      />
      <InlineSpacer variant="medium" />
      <FooterText
        bold
        sx={{
          whiteSpace: 'nowrap',
        }}
      >
        <ExternalLink to={linkTo}>{linkText}</ExternalLink>
      </FooterText>
    </Box>
  );
};
