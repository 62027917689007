import { Box, Button, Container, FormikTextField, Spacer } from '@color/continuum';
import { Formik, Form as FormikForm } from 'formik';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { FormikSubmitButton } from 'components/SubmitButton';

import { QuestionFormProps } from './types';

export const TextForm: React.FC<QuestionFormProps> = (props) => {
  const {
    question: { isOptional, placeholder },
    originalAnswer,
    onSubmit,
    isLoading,
    isLastQuizItem,
  } = props;
  return (
    <Formik
      initialValues={{ text: originalAnswer ?? '' }}
      validationSchema={Yup.object({
        text: Yup.string().required('This question is required.'),
      })}
      onSubmit={({ text }) => {
        onSubmit(text);
      }}
      validateOnMount
    >
      <Box
        component={FormikForm}
        sx={{
          flexGrow: 1, // grows to fill the remaining height of the quiz container, allowing the buttons to stay at the bottom.
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        <Container
          sx={{
            // grows to fill the remaining height of the form, which makes the buttons stay at bottom.
            flexGrow: 1,
          }}
        >
          <FormikTextField
            name="text"
            required
            placeholder={placeholder}
            multiline
            InputProps={{ disableUnderline: true }}
            minRows={11}
            helperText=""
            sx={(theme) => ({
              '& textarea': {
                padding: '16px',
                backgroundColor: theme.palette.primary.contrastText,
                borderRadius: '4px',
              },
            })}
          />
        </Container>
        <Spacer variant="xSmall" />
        <Box display="flex" gap={2} justifyContent="flex-end">
          {isOptional && (
            <Button size="large" variant="color-secondary" onClick={() => onSubmit('')}>
              <FormattedMessage
                description="Button text for skipping questions within GP survey component"
                defaultMessage="Skip"
              />
            </Button>
          )}
          <FormikSubmitButton isLoading={isLoading}>
            {isLastQuizItem ? (
              <FormattedMessage
                description="Button text for submitting answers within GP survey component"
                defaultMessage="Submit"
              />
            ) : (
              <FormattedMessage
                description="Button text for going to next question within GP survey component"
                defaultMessage="Next"
              />
            )}
          </FormikSubmitButton>
        </Box>
      </Box>
    </Formik>
  );
};
