import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { StrictMode } from 'react';
import { isIE } from 'react-device-detect';
import ReactDOM from 'react-dom';

import { App } from 'components/App/App';
import { initializeSegment } from 'lib/analytics';
import { initializeSentry } from 'lib/monitoring/monitoring';

initializeSegment();
initializeSentry();

if (isIE) {
  // eslint-disable-next-line no-alert
  window.alert(`
This browser is no longer supported.
Please use Chrome, Firefox, Safari, Opera, or Edge.

Este navegador ya no es compatible.
Por favor, use Chrome, Firefox, Safari, Opera o Edge.

Chrome: https://www.google.com/chrome/
Firefox: https://www.mozilla.org/en-US/firefox/
Safari: https://support.apple.com/downloads/safari
Opera: https://www.opera.com/download
Edge: https://www.microsoft.com/en-us/edge
  `);
} else {
  ReactDOM.render(
    <StrictMode>
      <App />
    </StrictMode>,
    document.getElementById('root')
  );
}
