const HDR_PATH_SUFFIX = 'hereditary-disease-risk';
const PGX_PATH_SUFFIX = 'pharmacogenetics';
const GEM_PATH_SUFFIX = 'ancestry-and-traits';

export const HDR_REPORT_PATH = `/${HDR_PATH_SUFFIX}`;
export const PGX_REPORT_PATH = `/${PGX_PATH_SUFFIX}`;

const APPOINTMENT_SCHEDULING_ROOT_PATH = '/schedule';
export const HDR_APPOINTMENT_SCHEDULING_PATH = `${APPOINTMENT_SCHEDULING_ROOT_PATH}/${HDR_PATH_SUFFIX}`;
export const PGX_APPOINTMENT_SCHEDULING_PATH = `${APPOINTMENT_SCHEDULING_ROOT_PATH}/${PGX_PATH_SUFFIX}`;

const PRE_RESULTS_SCREEN_ROOT_PATH = '/review';
export const HDR_PRE_RESULTS_SCREEN_PATH = `${PRE_RESULTS_SCREEN_ROOT_PATH}/${HDR_PATH_SUFFIX}`;
export const PGX_PRE_RESULTS_SCREEN_PATH = `${PRE_RESULTS_SCREEN_ROOT_PATH}/${PGX_PATH_SUFFIX}`;

const INFORMING_LOOP_ROOT_PATH = '/welcome';
export const HDR_INFORMING_LOOP_PATH = `${INFORMING_LOOP_ROOT_PATH}/${HDR_PATH_SUFFIX}`;
export const PGX_INFORMING_LOOP_PATH = `${INFORMING_LOOP_ROOT_PATH}/${PGX_PATH_SUFFIX}`;
export const GEM_INFORMING_LOOP_PATH = `${INFORMING_LOOP_ROOT_PATH}/${GEM_PATH_SUFFIX}`;

export const DOWNLOAD_PDF_PAGE_PATH = '/download-pdf';

export const SHARE_RESULTS_WITH_PROVIDER_PATH = '/share-results-with-provider';
