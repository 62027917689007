export enum AuthStrategy {
  PASSWORD,
  SSO,
}

export type User = {
  id: number;
  email: string;
  firstName: string;
  isProxy: boolean;
  patientId: string;
  permissions: Record<string, boolean>;
};
