import { Redirect, Route, Switch } from 'react-router-dom';

import {
  AncestryInformingLoopModule1,
  AncestryInformingLoopModule2,
  AncestryInformingLoopModule3,
} from './AncestryInformingLoopModules';
import { useAncestryInformingLoopLocationsByModule } from './locations';

export const AncestryInformingLoopRoutes = () => {
  const locations = useAncestryInformingLoopLocationsByModule();
  return (
    <Switch>
      {/* Redirect to the first module */}
      <Route exact path={locations.MODULE_1.pathname}>
        <AncestryInformingLoopModule1 />
      </Route>
      <Route exact path={locations.MODULE_2.pathname}>
        <AncestryInformingLoopModule2 />
      </Route>
      <Route exact path={locations.MODULE_3.pathname}>
        <AncestryInformingLoopModule3 />
      </Route>
      <Redirect to={locations.MODULE_1} />
    </Switch>
  );
};
