import { Container, ListItem, Spacer, Text, Title, UnorderedList } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { AccordionIconType } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionIcon/types';
import { AccordionWithIcon } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionWithIcon';

export const NextSteps: React.FC = () => (
  <Container>
    <AccordionWithIcon
      icon={AccordionIconType.STEPS}
      title={<FormattedMessage id="PGX__Next_Steps_Section_Title" />}
    >
      <Container>
        <Title variant="headline3">
          <FormattedMessage id="PGX__Whats_Next_Subsection_Title" />
        </Title>
        <Spacer variant="medium" />
        <UnorderedList>
          <ListItem>
            <Text>
              <FormattedMessage id="PGX__Whats_Next_Bullet_1" />
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              <FormattedMessage id="PGX__Whats_Next_Bullet_2" />
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              <FormattedMessage id="PGX__Whats_Next_Bullet_3" />
            </Text>
          </ListItem>
        </UnorderedList>
        <Spacer variant="xxLarge" />
        <Title variant="headline3">
          <FormattedMessage id="PGX__What_If_I_Have_Questions_Subsection_Title" />
        </Title>
        <Spacer variant="medium" />
        <Text paragraph>
          <FormattedMessage id="PGX__What_If_I_Have_Questions_Paragraph_1" />
        </Text>
        <Text paragraph>
          <FormattedMessage id="PGX__What_If_I_Have_Questions_Paragraph_2" />
        </Text>
      </Container>
    </AccordionWithIcon>
  </Container>
);
