import { Box, Grid, Spacer, Text, Title, useMediaQuery, useTheme } from '@color/continuum';
import { FC, ReactNode } from 'react';

import { CircularImageType } from 'components/common/CircularImage/types';
import { getCircularImageSrc } from 'components/common/CircularImage/utils';

export interface ImageWithContentSectionProps {
  title: ReactNode;
  description: ReactNode;
  imageRight?: boolean;
  image: CircularImageType;
}

// FIXME split this into two files. There should be one component per file
interface ImageBannerProps {
  imageUrl: string;
  imageAlt: string;
}
const ImageBanner: FC<ImageBannerProps> = (props) => {
  const { imageAlt, imageUrl } = props;
  return (
    <Grid item xs={12} md={3} display="flex" justifyContent="center" sx={{ marginBottom: 2 }}>
      <Box component="img" src={imageUrl} alt={imageAlt} width="200px" />
    </Grid>
  );
};
interface ContentSectionProps {
  title: ReactNode;
  description: ReactNode;
}
const ContentSection: FC<ContentSectionProps> = (props) => {
  const { title, description } = props;
  return (
    <Grid item xs={12} md={9}>
      <Title variant="headline2">{title}</Title>
      <Spacer variant="small" />
      <Text>{description}</Text>
    </Grid>
  );
};
export const ImageWithContentSection: React.FC<ImageWithContentSectionProps> = (props) => {
  const { title, description, imageRight = false, image } = props;
  const imageUrl = getCircularImageSrc(image);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container flexDirection={imageRight && !isMobile ? 'row-reverse' : 'row'}>
      <ImageBanner imageUrl={imageUrl} imageAlt={image.replace(/-/g, ' ')} />
      <ContentSection title={title} description={description} />
    </Grid>
  );
};
