import { HdrV1Gene } from '@color/aou-reports';
import { ReactNode } from 'react';

import HdrV1RiskChartsData from './HdrV1RiskCharts.json';

export interface RiskChart {
  gene: HdrV1Gene;
  sexAtBirth: 'Male' | 'Female';
  disease: string;
  age: number;
  resultRange: number[] | null;
  resultLabel: string;
  resultReference: string;
  averageRisk: number[] | null;
  averageRiskLabel: string;
  averageRiskReference: number;
}

export const HdrV1RiskCharts: Record<any, any[]> = HdrV1RiskChartsData;

export interface Reference {
  gene: string;
  referenceNumber: number;
  reference: ReactNode;
}

/**
 * These are genes that have sex specific content on the "risk with {gene}" page.
 */
export type SexSpecificContentHdrV1GeneType =
  | HdrV1Gene.BRCA1
  | HdrV1Gene.BRCA2
  | HdrV1Gene.MSH2
  | HdrV1Gene.MLH1
  | HdrV1Gene.APC
  | HdrV1Gene.MSH6
  | HdrV1Gene.TP53
  | HdrV1Gene.PTEN
  | HdrV1Gene.PMS2
  | HdrV1Gene.MUTYH
  | HdrV1Gene.STK11
  | HdrV1Gene.SMAD4
  | HdrV1Gene.BMPR1A;

export type SexAgnosticRiskContent = {
  riskAll: ReactNode;
  screenAll: ReactNode;
};

export type SexSpecificRiskContent = {
  riskFemale: ReactNode;
  riskMale: ReactNode;
  femaleDisorders: ReactNode[];
  maleDisorders: ReactNode[];
  riskAge: ReactNode;
  screenMale: ReactNode;
  screenFemale: ReactNode;
};

export type MultipleDisorderSexAgnosticRiskContent = Record<string, SexAgnosticRiskContent>;
