import { InternalButtonLink, InternalLink, Spacer } from '@color/continuum';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

const messages = defineMessages({
  finalModulePageTitleNo: {
    description: 'GEM informing loop final module page title - no decision',
    defaultMessage: 'No',
  },
  finalModuleTitle: {
    description: 'GEM informing loop final page title',
    defaultMessage:
      'Thanks for taking the time to consider the benefits and risks of receiving genetic ancestry and trait results.',
  },
});

export const GemInformingLoopDecisionNo: React.FC = () => {
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.finalModulePageTitleNo)}
      module={{
        analyticsInformingLoopName: 'GEM Informing Loop',
        analyticsPageName: '8. No',
        image: CircularImageType.GENES_PEOPLE,
        title: intl.formatMessage(messages.finalModuleTitle),
        content: (
          <>
            <FormattedMessage
              description="GEM informing loop final page no decision content"
              defaultMessage="<p><b>Your decision: No, I do not want my genetic ancestry and trait results.</b></p>
      <p>If you change your mind and would like to get your genetic ancestry and trait results, you can update your decision on your {optionsLink} page.</p>"
              values={{
                optionsLink: (
                  <InternalLink to="/options">
                    <FormattedMessage
                      description="Options page link name"
                      defaultMessage="DNA Results"
                    />
                  </InternalLink>
                ),
              }}
            />
            <Spacer variant="medium" />
            <InternalButtonLink to="/">
              <FormattedMessage
                defaultMessage="Back to DNA Results"
                description="GEM informing loop final page no decision content back to results button"
              />
            </InternalButtonLink>
          </>
        ),
      }}
    />
  );
};
