import { SingleButtonDialog, Spacer, Text } from '@color/continuum';
import { useIntl } from 'react-intl';

import { ModuleType } from 'lib/analytics/types';

interface Props {
  appointmentType: string;
  handleCancelDialogConfirm: () => void;
  setShowCancelDialog: (flag: boolean) => void;
}

export const CancelConfirmationDialog: React.FC<Props> = (props) => {
  const { appointmentType, handleCancelDialogConfirm, setShowCancelDialog } = props;
  const intl = useIntl();
  const messages = {
    title: intl.formatMessage({ id: 'Scheduling_Cancel__Dialog__Title' }),
    text1Pgx: intl.formatMessage({ id: 'Scheduling_Cancel__Dialog__Text1_PGX' }),
    text1Hdr: intl.formatMessage({ id: 'Scheduling_Cancel__Dialog__Text1_HDR' }),
    text2: intl.formatMessage({ id: 'Scheduling_Cancel__Dialog__Text2' }),
  };

  const text1 = appointmentType === ModuleType.HDR ? messages.text1Hdr : messages.text1Pgx;

  return (
    <SingleButtonDialog
      title={messages.title}
      buttonText="Confirm"
      onButtonClick={() => handleCancelDialogConfirm()}
      onClose={() => setShowCancelDialog(false)}
      isFullScreenOnMobile={false}
    >
      <Text>{text1}</Text>
      <Spacer variant="medium" />
      <Text>{messages.text2}</Text>
    </SingleButtonDialog>
  );
};
