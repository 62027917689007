import { Box, Grid, Spacer, Text, Title } from '@color/continuum';
import { FC, ReactNode } from 'react';

interface Props {
  title: ReactNode;
  description: ReactNode;
  value: number;
}

export const StatsGeneGridItem: FC<Props> = (props) => {
  const { title, description, value } = props;
  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        '& > *': {
          borderTop: '1px solid #212B36',
          borderBottom: {
            md: '1px solid #212B36',
          },
        },
        '&:last-child': {
          '& > *': {
            borderBottom: '1px solid #212B36',
          },
        },
      }}
    >
      <Box sx={{ height: '100%', paddingY: { xs: 5, md: 3 } }}>
        <Box
          sx={(theme) => ({
            fontSize: 32,
            fontWeight: 'bold',
            color: theme.palette.secondary.main,
          })}
        >
          {value.toLocaleString()}
        </Box>
        <Spacer variant="paragraph" />
        <Title variant="headline3" color="textPrimary">
          {title}
        </Title>
        <Spacer variant="paragraph" />
        <Text>{description}</Text>
      </Box>
    </Grid>
  );
};
