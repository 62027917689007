// eslint-disable-next-line no-restricted-imports
import { Chip, Theme } from '@mui/material';

interface ResultsFilterChipProps {
  onClick: () => void;
  selected: boolean;
  label: string;
}

const DEFAULT_CHIP_SX = (theme: Theme) => ({
  color: theme.palette.text.primary,
  borderColor: theme.palette.grey[300],
  '&:hover, &:focus': {
    backgroundColor: `${theme.palette.grey[200]} !important`,
  },
});

const SELECTED_CHIP_SX = (theme: Theme) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.secondary[100],
  borderColor: theme.palette.secondary.main,
  fontWeight: 'bold',
  '&:hover, &:focus': {
    color: theme.palette.primary.contrastText,
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
});

export const ResultsFilterChip: React.FC<ResultsFilterChipProps> = (props) => {
  const { label, selected, onClick } = props;
  return (
    <Chip
      label={label}
      variant="outlined"
      onClick={onClick}
      aria-pressed={selected}
      sx={selected ? SELECTED_CHIP_SX : DEFAULT_CHIP_SX}
    />
  );
};
