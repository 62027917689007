import { Container, Spacer, Title } from '@color/continuum';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';

import { BackButtonBar } from 'components/common/BackButtonBar';
import { SimplePage } from 'components/common/SimplePage/SimplePage';

import { useAncestryQuery } from './api';
import { PopulationBreakdown } from './components/PopulationBreakdown/PopulationBreakdown';
import { useAncestryPopulationsContent } from './content';
import { AncestryComposition } from './types';

const messages = defineMessages({
  ancestryPopulationsPageTitle: {
    description: 'Ancestry Populations Page Title',
    defaultMessage: 'Ancestry Populations',
  },
});

export const AncestryPopulations: React.FC = () => {
  const { data: ancestryData, error, isLoading } = useAncestryQuery();
  const ancestryContent = useAncestryPopulationsContent();
  const intl = useIntl();

  if (isLoading) {
    return null;
  }

  if (error) {
    return <Redirect to="/ancestry" />;
  }
  return (
    <SimplePage helmetTitle={intl.formatMessage(messages.ancestryPopulationsPageTitle)}>
      <BackButtonBar to="/ancestry" />
      <Spacer variant="xLarge" />
      <Title align="center">
        <FormattedMessage
          description="Ancestry population page title"
          defaultMessage="All tested populations"
        />
      </Title>
      <Spacer variant="xxLarge" />
      <Container>
        <>
          {ancestryData && (
            <PopulationBreakdown
              isOverviewPageBreakdown={false}
              data={ancestryData.compositions.map((composition: AncestryComposition) => ({
                title: ancestryContent.populations[composition.population].title,
                subpopulations: composition.subcompositions.map((subcomposition) => ({
                  title: ancestryContent.subpopulations[subcomposition.population].title,
                  examples: ancestryContent.subpopulations[subcomposition.population].examples,
                })),
              }))}
            />
          )}
        </>
      </Container>
      <Spacer variant="xLarge" />
      <Spacer variant="xLarge" />
    </SimplePage>
  );
};
