import { Container } from '@color/continuum';

import { ExpansionPanel } from 'components/common/Reports/ExpansionPanel';

import { Trait } from '../../types';
import { useTraitResultsExpansionPanelContent } from './content';

interface Props {
  panel: number;
  trait: Trait;
}

export const TraitResultsExpansionPanel: React.FC<Props> = (props) => {
  const { panel, trait } = props;
  const { title, text } = useTraitResultsExpansionPanelContent(trait, panel);

  return (
    <Container>
      <ExpansionPanel title={title} text={text} />
    </Container>
  );
};
