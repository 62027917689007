import { UseQueryOptions, useQuery } from 'react-query';

import { api } from 'lib/api';
import { ApiError, PaginatedApiResponsePayload } from 'lib/api/types';

import { EventLog, EventType, NewEventPayload } from './types';

interface EventLogQueryParams {
  eventType: EventType;
}

interface PaginatedEventLogs extends PaginatedApiResponsePayload {
  results: EventLog[];
}

export function useEventLogsQuery(
  params?: EventLogQueryParams,
  options?: UseQueryOptions<PaginatedEventLogs, ApiError>
) {
  return useQuery<PaginatedEventLogs, ApiError>(['event_log', { searchParams: params }], options);
}

export function createEventLog(event: NewEventPayload) {
  return api('event_log', { method: 'POST', json: event });
}
