import {
  CONTAINS_NAMELIKE_CHARACTER_REGEX,
  GpDialog,
  getAddressCityValidator,
} from '@color/continuum';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { useGpToast } from 'components/common/Toast';
import { trackAnalyticsEvent } from 'lib/analytics';
import { AnalyticsPlatform, SegmentEventType } from 'lib/analytics/types';

import { useCreateProviderInformationMutation } from './api';
import { FormFields } from './FormFields';
import { IProviderInformation } from './types';

const MAX_LENGTH = 50;

export const ShareResultsWithProviderForm: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const { showSuccessToast } = useGpToast();
  const createProviderInformationMutation = useCreateProviderInformationMutation();
  const messages = {
    firstNameRequired: intl.formatMessage({
      id: 'Share_With_Provider__Provider_First_Name_Required',
    }),
    firstNameMaxLength: intl.formatMessage(
      {
        id: 'Share_With_Provider__Provider_First_Name_Max_Length',
      },
      { maxLength: MAX_LENGTH }
    ),
    firstNameInvalidFormat: intl.formatMessage({
      id: 'Share_With_Provider__Provider_First_Name_Invalid_Format',
    }),
    lastNameRequired: intl.formatMessage({
      id: 'Share_With_Provider__Provider_Last_Name_Required',
    }),
    lastNameMaxLength: intl.formatMessage(
      {
        id: 'Share_With_Provider__Provider_Last_Name_Max_Length',
      },
      { maxLength: MAX_LENGTH }
    ),
    lastNameInvalidFormat: intl.formatMessage({
      id: 'Share_With_Provider__Provider_Last_Name_Invalid_Format',
    }),
    stateRequired: intl.formatMessage({ id: 'Share_With_Provider__Provider_State_Required' }),
    state_max_length: intl.formatMessage(
      {
        id: 'Share_With_Provider__Provider_State_Max_Length',
      },
      { maxLength: MAX_LENGTH }
    ),
    phoneNumberRequired: intl.formatMessage({
      id: 'Share_With_Provider__Provider_Phone_Number_Required',
    }),
    phoneNumberMinLength: intl.formatMessage({
      id: 'Share_With_Provider__Provider_Phone_Number_Min_Length',
    }),
    phoneNumberMaxLength: intl.formatMessage({
      id: 'Share_With_Provider__Provider_Phone_Number_Max_Length',
    }),
    faxNumberRequired: intl.formatMessage({
      id: 'Share_With_Provider__Provider_Fax_Number_Required',
    }),
    faxNumberMinLength: intl.formatMessage({
      id: 'Share_With_Provider__Provider_Fax_Number_Min_Length',
    }),
    faxNumberMaxLength: intl.formatMessage({
      id: 'Share_With_Provider__Provider_Fax_Number_Max_Length',
    }),
    toastMessage: intl.formatMessage({ id: 'Scheduling_Error_Toast_Message' }),
  };
  const schema = Yup.object({
    firstName: Yup.string()
      .max(MAX_LENGTH, messages.firstNameMaxLength)
      .matches(CONTAINS_NAMELIKE_CHARACTER_REGEX, {
        message: messages.firstNameInvalidFormat,
        excludeEmptyString: true,
      })
      .required(messages.firstNameRequired),
    lastName: Yup.string()
      .max(MAX_LENGTH, messages.lastNameMaxLength)
      .matches(CONTAINS_NAMELIKE_CHARACTER_REGEX, {
        message: messages.lastNameInvalidFormat,
        excludeEmptyString: true,
      })
      .required(messages.lastNameRequired),
    phoneNumber: Yup.string()
      .min(10, messages.phoneNumberMinLength)
      .max(10, messages.phoneNumberMaxLength)
      .required(messages.phoneNumberRequired),
    faxNumber: Yup.string()
      .min(10, messages.faxNumberMinLength)
      .max(10, messages.faxNumberMaxLength)
      .required(messages.faxNumberRequired),
    city: getAddressCityValidator(intl, { fieldIsRequired: true }),
    state: Yup.string().max(MAX_LENGTH, messages.state_max_length).required(messages.stateRequired),
  });

  return (
    <GpDialog fullScreen open>
      <Formik<IProviderInformation>
        initialValues={{
          firstName: '',
          lastName: '',
          state: '',
          city: '',
          faxNumber: '',
          phoneNumber: '',
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          createProviderInformationMutation.mutate(values, {
            onSuccess: () => {
              trackAnalyticsEvent(
                { eventType: SegmentEventType.HDR_RESULTS_SHARED_WITH_DOCTOR },
                AnalyticsPlatform.SEGMENT
              );
              history.push('/');
              showSuccessToast(
                intl.formatMessage({ id: 'Share_With_Provider__Result_Faxed_Toast' })
              );
            },
            // TODO(ALLOFUS-2452): Do something on error when we figure out the right UX.
          });
        }}
      >
        {() => <FormFields onCancel={() => history.goBack()} />}
      </Formik>
    </GpDialog>
  );
};
