import { Box, ExternalLink } from '@color/continuum';

import {
  FOOTER_PRIVACY_POLICY_LINK,
  FOOTER_SECURITY_POLICY_LINK,
  FOOTER_TERMS_LINK,
} from '../constants';
import { useFooterContent } from '../content';
import { FooterText } from './FooterText';

export const FooterPolicyLinks: React.FC = () => {
  const footerContent = useFooterContent();
  return (
    <Box
      sx={{
        background: 'rgba(0, 0, 0, 0.15)',
        justifyContent: 'end',
        display: 'flex',
        flexWrap: 'wrap-reverse',
        rowGap: 2,
        columnGap: 5,
        paddingRight: 5,
        paddingLeft: 5,
        paddingTop: 1.5,
        paddingBottom: 1,
      }}
    >
      <FooterText>{footerContent.copyright}</FooterText>
      <FooterText>
        <ExternalLink to={FOOTER_PRIVACY_POLICY_LINK}>{footerContent.privacyPolicy}</ExternalLink> |{' '}
        <ExternalLink to={FOOTER_SECURITY_POLICY_LINK}>{footerContent.securityPolicy}</ExternalLink>{' '}
        | <ExternalLink to={FOOTER_TERMS_LINK}>{footerContent.terms}</ExternalLink>
      </FooterText>
    </Box>
  );
};
