import {
  Box,
  Divider,
  Grid,
  InternalButtonLink,
  InternalLink,
  Spacer,
  Text,
} from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import WarningIcon from '@mui/icons-material/Warning';
import { FormattedMessage } from 'react-intl';

import {
  HDR_APPOINTMENT_SCHEDULING_PATH,
  PGX_APPOINTMENT_SCHEDULING_PATH,
} from 'components/App/AppRoutes/constants';
import { useExistingAppointment } from 'components/Appointments/Appointments';
import { useAppointmentsQuery } from 'components/Dashboard/api';
import { Appointment } from 'components/Dashboard/types';
import { trackSegmentEvent } from 'lib/analytics/segment';
import { ModuleType, SegmentEventType } from 'lib/analytics/types';
import { isGcAppointmentsDisabledForNegativeResultsWaffleEnabled } from 'lib/waffle/util';

import { ViewNotesLink } from './components/ViewNotesLink';

const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  day: 'numeric',
  month: 'short',
  hour: 'numeric',
  minute: '2-digit',
  timeZoneName: 'short',
};

interface IAppointmentsSummarySectionProps {
  moduleType: ModuleType.HDR | ModuleType.PGX;
  viewedResults: boolean;
  isPositive: boolean;
}

/**
 * This component displays a summary of a participants appointments.
 */
export const AppointmentsSummarySection: React.FC<IAppointmentsSummarySectionProps> = (props) => {
  const { moduleType, viewedResults, isPositive } = props;
  const {
    data: previousAppointments,
    isLoading: previousAppointmentsAreLoading,
  } = useAppointmentsQuery({
    type: moduleType,
    previous: true,
    exclude: 'participant_cancelled',
  });
  const {
    data: upcomingAppointment,
    isLoading: upcomingAppointmentIsLoading,
  } = useExistingAppointment(moduleType);
  const disableNegativeResultsScheduling = isGcAppointmentsDisabledForNegativeResultsWaffleEnabled();

  const { locale } = usePreferredLocale();
  const formatAppointmentTimestamp = (timestamp: string | Date, timeZone: string) => {
    let count: number = 0;
    return new Date(timestamp)
      .toLocaleString(locale, { ...DATE_OPTIONS, timeZone })
      .replace('PM', 'p.m.')
      .replace('AM', 'a.m.')
      .replace(/,/g, (match: string) => {
        count += 1;
        return count === 2 ? ' at' : match;
      });
  };
  if (previousAppointmentsAreLoading || upcomingAppointmentIsLoading) {
    return null;
  }

  const hadPreviousAppointment =
    previousAppointments?.results && previousAppointments.results.length > 0;

  const missedLastAppointment =
    hadPreviousAppointment && previousAppointments.results[0].missedAppointment;
  const hasNoAppointments = !upcomingAppointment?.appointment && !hadPreviousAppointment;

  const shouldDisableScheduling =
    disableNegativeResultsScheduling &&
    !isPositive &&
    !missedLastAppointment &&
    !upcomingAppointment?.appointment;

  if (hasNoAppointments && (!viewedResults || shouldDisableScheduling)) {
    return null;
  }
  return (
    <Grid>
      <Spacer variant="medium" />
      <Divider aria-hidden="true" />
      <Spacer variant="medium" />
      <Grid container item xs justifyContent="space-between">
        <Grid item component={Box} xs={12} sm>
          <Text variant="subtitle1">
            {!upcomingAppointment?.appointment &&
            !missedLastAppointment &&
            !hadPreviousAppointment ? (
              <FormattedMessage
                defaultMessage="Questions?"
                description="Informing loop footer contacts text"
              />
            ) : (
              <FormattedMessage id="Appt_Genetic_Counseling" />
            )}
          </Text>
          <Spacer variant="xSmall" />
          {!upcomingAppointment?.appointment && !missedLastAppointment && (
            <>
              {hadPreviousAppointment ? (
                <Text>
                  <FormattedMessage id="GP_Card__GC_Additional_Scheduling" />
                </Text>
              ) : (
                <Text>
                  <FormattedMessage id="GP_Card__GC_Scheduling" />
                </Text>
              )}

              <Spacer variant="xSmall" />
            </>
          )}
          {/** Upcoming Appointment (there can only be one). */}
          {upcomingAppointment?.appointment && (
            <>
              <Text>
                <FormattedMessage id="Appt_Upcoming" />:
                {` ${formatAppointmentTimestamp(
                  upcomingAppointment.appointment.timestamp,
                  upcomingAppointment.appointment.timezone
                )}. `}
                <InternalLink
                  underline="always"
                  to={
                    moduleType === ModuleType.HDR
                      ? HDR_APPOINTMENT_SCHEDULING_PATH
                      : PGX_APPOINTMENT_SCHEDULING_PATH
                  }
                >
                  <FormattedMessage id="Appt_View_Details" />
                </InternalLink>
              </Text>
              <Spacer variant="xSmall" />
            </>
          )}
          {/** Past Appointments (completed and missed, there can be many). */}
          {previousAppointments?.results?.map((appointment: Appointment) =>
            appointment.missedAppointment ? (
              <Box display="flex" gap={1} key={appointment.id}>
                <WarningIcon color="error" fontSize="small" />
                <Text>
                  <FormattedMessage id="Appt_Missed" />
                  {`: ${formatAppointmentTimestamp(appointment.startTime, appointment.timezone)}. `}
                </Text>
              </Box>
            ) : (
              <Text key={appointment.id}>
                <FormattedMessage id="Appt_Past" />
                {`: ${formatAppointmentTimestamp(appointment.startTime, appointment.timezone)}. `}
                {appointment.noteAvailable && (
                  <ViewNotesLink moduleType={moduleType} appointmentId={appointment.id} />
                )}
              </Text>
            )
          )}
        </Grid>
        {!upcomingAppointment?.appointment && viewedResults && !shouldDisableScheduling && (
          <Grid item component={Box}>
            <InternalButtonLink
              to={
                moduleType === ModuleType.HDR
                  ? HDR_APPOINTMENT_SCHEDULING_PATH
                  : PGX_APPOINTMENT_SCHEDULING_PATH
              }
              variant="color-tertiary"
              onClick={() => {
                trackSegmentEvent({
                  eventType:
                    moduleType === ModuleType.HDR
                      ? SegmentEventType.HDR_DASHBOARD_GC_SCHEDULING
                      : SegmentEventType.PGX_DASHBOARD_GC_SCHEDULING,
                });
              }}
            >
              {missedLastAppointment ? (
                <FormattedMessage id="Appt_Reschedule" />
              ) : (
                <FormattedMessage
                  description="HDR schedule appointment"
                  defaultMessage="Schedule Appointment"
                />
              )}
            </InternalButtonLink>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
