import { Card, CardContent, ExternalLink, Grid, Spacer, Text, Title } from '@color/continuum';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';

import learningCenterImage from 'assets/images/art-phones.svg';
import { useConfigForIdp } from 'hooks/useConfigForIdp';

const learningCenterMessage = defineMessage({
  defaultMessage: 'Learning Center',
});

export const ResourceCard: React.FC = () => {
  const intl = useIntl();
  const { LEARNING_CENTER_URL } = useConfigForIdp();

  return (
    <Card>
      <Grid container>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          sx={{
            background: 'linear-gradient(135deg, #CABFFF8A 0%, #FFC5A68A 99.93%)',
          }}
        >
          <img src={learningCenterImage} alt="" />
        </Grid>
        <Grid item xs={12}>
          <CardContent>
            <Title variant="headline3">{intl.formatMessage(learningCenterMessage)}</Title>
            <Spacer variant="xSmall" />
            <Text>
              <FormattedMessage
                defaultMessage="See more information on the science and methods you might come across in the <i>All of Us</i> Research Program."
                values={{
                  i: (chunks: React.ReactNode) => <i>{chunks}</i>,
                }}
              />
            </Text>
            <Spacer variant="small" />
            <Text>
              <ExternalLink to={LEARNING_CENTER_URL.href} linkShouldOpenInCurrentTab>
                <FormattedMessage defaultMessage="Explore the Learning Center" />
              </ExternalLink>
            </Text>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};
