import { Options as KyOptions } from 'ky';

export interface ApiErrorPayload {
  status: number;
  errorMessage: string;
}

export class ApiError extends Error {
  public status: number;

  constructor(errorPayload: ApiErrorPayload) {
    super(errorPayload.errorMessage);
    this.name = 'API Response Error';
    this.status = errorPayload.status;
  }
}

export interface ApiResponse<T = object | string> extends Response {
  payload?: T;
  error?: ApiError;
}

export interface PaginatedApiResponsePayload {
  count: number;
  next: string;
  previous: string;
  results: unknown[];
}

export interface ApiRequestOptions extends Omit<KyOptions, 'body'> {
  json?: object;
}
