import { Box, Divider, Grid, IMuiTheme, Spacer, useMediaQuery, useTheme } from '@color/continuum';

import allOfUsLogoLongLarge from 'assets/images/icons/allofus-logo-long-large.svg';
import allOfUsLogoLongSmall from 'assets/images/icons/allofus-logo-long-small.svg';
import nihLogoLong from 'assets/images/icons/nih-logo-long.svg';
import nihLogoShort from 'assets/images/icons/nih-logo-short.svg';

import { FooterContactLinks } from './common/FooterContactLinks';
import { FooterContainer } from './common/FooterContainer';
import { FooterDisclaimerText } from './common/FooterDisclaimerText';
import { FooterFollowUsLinks } from './common/FooterFollowUsLinks';
import { FooterLearnMoreLinks } from './common/FooterLearnMoreLinks';
import { FooterPolicyLinks } from './common/FooterPolicyLinks';
import { FooterText } from './common/FooterText';
import { useFooterContent } from './content';

// FIXME Convert to `@color/continuum` `Divider` and `sx`
const FOOTER_SECTION_DIVIDER_SX = (theme: IMuiTheme) => ({
  backgroundColor: theme.palette.primary.contrastText,
  marginTop: '5px',
});

export const DesktopFooter = () => {
  const footerContent = useFooterContent();
  const theme = useTheme();
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumDesktop = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <FooterContainer>
      <Box paddingX={3} paddingTop={5} paddingBottom={6}>
        <Grid container spacing={2}>
          <Grid item xs>
            <img
              src={isSmallDesktop ? allOfUsLogoLongSmall : allOfUsLogoLongLarge}
              alt="All of Us logo"
            />
            <Spacer variant="small" />
            <img src={isMediumDesktop ? nihLogoShort : nihLogoLong} alt="NIH logo" />
            <Spacer variant="small" />
            <FooterDisclaimerText>{footerContent.disclaimerText}</FooterDisclaimerText>
          </Grid>
          <Grid item xs>
            <FooterText>{footerContent.contactSectionTitle}</FooterText>
            <Divider aria-hidden="true" sx={FOOTER_SECTION_DIVIDER_SX} />
            <Spacer variant="small" />
            <FooterContactLinks />
          </Grid>
          <Grid item xs>
            <FooterText>{footerContent.learnMoreSectionTitle}</FooterText>
            <Divider aria-hidden="true" sx={FOOTER_SECTION_DIVIDER_SX} />
            <Spacer variant="small" />
            <FooterLearnMoreLinks />
          </Grid>
          <Grid item xs>
            <FooterText>{footerContent.followUsSectionTitle}</FooterText>
            <Divider aria-hidden="true" sx={FOOTER_SECTION_DIVIDER_SX} />
            <Spacer variant="small" />
            <FooterFollowUsLinks />
          </Grid>
        </Grid>
      </Box>
      <FooterPolicyLinks />
    </FooterContainer>
  );
};
