import { GpDialog, LocationFlowProvider } from '@color/continuum';

import { usePgxInformingLoopLocations } from './locations';
import { PgxInformingLoopRoutes } from './PgxInformingLoopRoutes';

export const PgxInformingLoop: React.FC = () => {
  return (
    <GpDialog open scroll="body" fullScreen aria-labelledby="dialogTitle">
      <LocationFlowProvider locations={usePgxInformingLoopLocations()}>
        <PgxInformingLoopRoutes />
      </LocationFlowProvider>
    </GpDialog>
  );
};
