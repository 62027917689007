import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

const messages = defineMessages({
  ancestryILModule2PageTitle: {
    description: 'Ancestry Informing Loop Page Title - Module 2',
    defaultMessage: 'Race, Ethnicity, or Family History',
  },
});

export const AncestryInformingLoopModule2: React.FC = () => {
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.ancestryILModule2PageTitle)}
      module={{
        analyticsInformingLoopName: 'Ancestry Informing Loop',
        analyticsPageName: '2. Race and Ethnicity',
        image: CircularImageType.ANCESTRY_FACTORS,
        title: intl.formatMessage({
          description: 'Ancestry informing loop page 2 title',
          defaultMessage: 'Genetic ancestry is not the same as race, ethnicity, or family history.',
        }),
        content: (
          <FormattedMessage
            description="Ancestry informing loop page 2 content"
            defaultMessage="<p>Race and ethnicity are categories created by humans and are not determined by your DNA. They are usually based on physical features such as skin color, or shared language and culture. Your genetic ancestry also may not match your family stories or religious and cultural beliefs.</p><p>There is more to your ancestry and sense of self than you can learn from your DNA.</p>"
          />
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
