import { PgxV1ReportPayload } from '@color/aou-reports';
import { Container, MoreInformationCard, Spacer, Text, Title } from '@color/continuum';
import { scrollIntoView, usePreferredLocale } from '@color/lib';
import { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';

import { BackButtonBar } from 'components/common/BackButtonBar';
import { QuizAndMoreInformationSection } from 'components/common/Reports/QuizAndMoreInformationSection';
import { ReportIconType } from 'components/common/Reports/ReportIcon/types';
import { getReportIconSrc } from 'components/common/Reports/ReportIcon/utils';
import { ResultViewed } from 'components/HealthRelatedResults/components/ResultViewed/ResultViewed';
import { trackSegmentEvent } from 'lib/analytics/segment';
import { ModuleType, SegmentEventType } from 'lib/analytics/types';
import { PGX_INFO_SHEET_URLS } from 'lib/constants';

import { useHealthResultsQuery, useReportPdfUrl } from '../api';
import { useLocationControlledTabs } from '../components/FullWidthTabsWithPanel';
import { FullWidthTabsWithPanel } from '../components/FullWidthTabsWithPanel/FullWidthTabsWithPanel';
import { MoreInformationSection } from '../components/MoreInformationSection/MoreInformationSection';
import { ResearchResultWarningBanner } from '../components/ResearchResultWarningBanner/ResearchResultWarningBanner';
import { PGX_LOCATION_CONTROLLED_TABS_PROPS } from './constants';
import { BeyondDnaTab } from './Tabs/BeyondDnaTab';
import { DiscussResultsTab } from './Tabs/DiscussResultsTab';
import { YourResultsTab } from './Tabs/YourResultsTab/YourResultsTab';
import { PgxTabIds } from './types';

/**
 * PGx reports can optionally be accessed with token that is generated by
 * clinical and allows GCs to view participant web results.
 *
 * If no token is provided, the PGx report will default to authenticating the
 * participant and showing the particpant's PGx results.
 */
interface Props {
  token?: string;
}

export const PgxReport: React.FC<Props> = (props) => {
  const { token } = props;
  const intl = useIntl();
  const { data: report, isLoading } = useHealthResultsQuery({
    moduleType: ModuleType.PGX,
    token,
  });
  const { locale } = usePreferredLocale();
  const reportPdfUrl = useReportPdfUrl({ moduleType: ModuleType.PGX });
  const { tabId, setTab } = useLocationControlledTabs(PGX_LOCATION_CONTROLLED_TABS_PROPS);
  const dnaAndMedicineRef = useRef<HTMLDivElement>(null);
  if (isLoading || !report || !tabId) {
    return null;
  }
  const messages = {
    pageTitle: intl.formatMessage({ id: 'PGx_Home_Helmet_Title' }),
  };

  return (
    <>
      <Helmet>
        {/* declarative intl syntax injects a span, which causes issues with Helmet title */}
        <title>{messages.pageTitle}</title>
      </Helmet>
      <Container>
        <ResearchResultWarningBanner>
          <Text>
            <FormattedMessage id="PGx_Research_Result_Warning" />
          </Text>
        </ResearchResultWarningBanner>
      </Container>
      <BackButtonBar to="/" />
      <Spacer variant="large" />
      <Title align="center">
        <FormattedMessage id="PGX__Title" />
      </Title>
      <Spacer variant="xxLarge" />
      <FullWidthTabsWithPanel
        tabsConfig={{
          [PgxTabIds.YOUR_RESULTS]: {
            label: <FormattedMessage id="PGX__Your_Results_Tab" />,
            component: (
              <YourResultsTab
                report={report as PgxV1ReportPayload}
                dnaAndMedicineRef={dnaAndMedicineRef}
              />
            ),
          },
          [PgxTabIds.BEYOND_DNA]: {
            label: <FormattedMessage id="PGX__Beyond_DNA_Tab" />,
            component: (
              <BeyondDnaTab
                changeToDnaAndMedicine={() => {
                  setTab(PgxTabIds.YOUR_RESULTS);
                  // Run this async so it runs after the tab has finished rendering.
                  setTimeout(() => {
                    if (dnaAndMedicineRef.current) {
                      scrollIntoView(dnaAndMedicineRef.current);
                    }
                  }, 0);
                }}
              />
            ),
          },
          [PgxTabIds.DISCUSS_RESULTS]: {
            label: <FormattedMessage id="PGX__Discuss_Results_Tab" />,
            component: <DiscussResultsTab />,
          },
        }}
        tabId={tabId}
        setTab={setTab}
      />
      <Container>
        <QuizAndMoreInformationSection
          quizName="aou_survey_pgx"
          moreInformationSection={
            <MoreInformationSection
              tabId={tabId}
              moduleType={ModuleType.PGX}
              moreInformationCards={{
                [PgxTabIds.YOUR_RESULTS]: (
                  <MoreInformationCard
                    iconSrc={getReportIconSrc(ReportIconType.YOUR_RESULTS_PGX)}
                    title={<FormattedMessage id="PGX__Your_Results_Tab" />}
                    onClick={() => {
                      trackSegmentEvent({
                        eventType: SegmentEventType.PGX_QUICK_LINK_YOUR_RESULTS,
                      });
                      setTab(PgxTabIds.YOUR_RESULTS);
                    }}
                  />
                ),
                [PgxTabIds.BEYOND_DNA]: (
                  <MoreInformationCard
                    iconSrc={getReportIconSrc(ReportIconType.BEYOND_DNA)}
                    title={<FormattedMessage id="PGX__Beyond_DNA_Tab" />}
                    onClick={() => {
                      trackSegmentEvent({ eventType: SegmentEventType.PGX_QUICK_LINK_BEYOND_DNA });
                      setTab(PgxTabIds.BEYOND_DNA);
                    }}
                  />
                ),
                [PgxTabIds.DISCUSS_RESULTS]: (
                  <MoreInformationCard
                    iconSrc={getReportIconSrc(ReportIconType.DISCUSS_RESULTS)}
                    title={<FormattedMessage id="PGX__Discuss_Results_Tab" />}
                    onClick={() => {
                      trackSegmentEvent({
                        eventType: SegmentEventType.PGX_QUICK_LINK_DISCUSS_RESULTS,
                      });
                      setTab(PgxTabIds.DISCUSS_RESULTS);
                    }}
                  />
                ),
              }}
              infoSheetUrl={PGX_INFO_SHEET_URLS[locale]}
              reportPdfUrl={reportPdfUrl}
            />
          }
        />
      </Container>
      <Spacer variant="medium" />
      <ResultViewed moduleType={ModuleType.PGX} />
    </>
  );
};
