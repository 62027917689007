import { useMutation } from 'react-query';

import { api } from 'lib/api';
import { ApiError } from 'lib/api/types';

import { InformingLoopStartedPayload } from './types';

export function useCreateInformingLoopStartedEventMutation() {
  return useMutation<Response, ApiError, InformingLoopStartedPayload>(
    (json: InformingLoopStartedPayload) =>
      api('aou_informing_loop_started', { method: 'POST', json })
  );
}
