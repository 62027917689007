import { HdrV1Gene } from '@color/aou-reports';
import {
  AverageRiskLabel,
  Box,
  Button,
  Grid,
  InlineSpacer,
  RiskCard,
  RiskWithResultLabel,
  Spacer,
  TPercentageBarProps,
  Text,
  Title,
  useMediaQuery,
} from '@color/continuum';
// TODO Import from CDS rather than MUI. In CDS-341 we turned on lint warnings on direct MUI imports. This line is disabled because GP CI sets the rule to error instead of warn.
// Swap this for a CDS import instead of a MUI import.
// eslint-disable-next-line no-restricted-imports
import { Theme } from '@mui/material';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SEX_SPECIFIC_RISK_CONTENT } from './constants';
import { diseases } from './content';
import { HdrV1RiskCharts, RiskChart } from './types';
import { WhyDoesThisMatterDialog } from './WhyDoesThisMatterDialog';

interface IRiskChartsProps {
  gene: HdrV1Gene;
}

const getResultRiskBar = (data: RiskChart): TPercentageBarProps | undefined => {
  const { resultRange } = data;

  if (resultRange == null) {
    return undefined;
  }

  if (resultRange.length === 2) {
    return {
      variant: 'range',
      minValue: Math.min(...resultRange),
      maxValue: Math.max(...resultRange),
    };
  }

  return {
    variant: 'exact',
    value: resultRange[0],
  };
};

const getAverageRiskBar = (data: RiskChart): TPercentageBarProps | undefined => {
  const { averageRisk, resultRange } = data;

  if (resultRange == null || averageRisk == null) {
    return undefined;
  }

  if (averageRisk.length === 2) {
    return {
      variant: 'range',
      minValue: Math.min(...averageRisk),
      maxValue: Math.max(...averageRisk),
    };
  }

  return {
    variant: 'exact',
    value: averageRisk[0],
  };
};

const averageRiskMessage = {
  id: 'HDR_Positive_Risk__Average_Risk',
  description: 'Average risk row label',
  defaultMessage: 'Average risk in the U.S. <sup>{reference}</sup> : {averageRisk}',
};

interface IProps extends IRiskChartsProps {
  riskData: RiskChart[];
  sex: string;
}

const RiskData: FC<IProps> = (props) => {
  const intl = useIntl();
  const { sex, riskData, gene } = props;
  const data = riskData.filter((e) => e.sexAtBirth === sex);
  return (
    <>
      <WhyDoesThisMatterDialog />
      <Spacer variant="medium" />
      {sex === 'Female' ? (
        <>
          <Text>
            <FormattedMessage id="HDR_Positive_Risk__Screen_If_Female_P2" />
          </Text>
          <Spacer variant="small" />
          {SEX_SPECIFIC_RISK_CONTENT[gene as keyof typeof SEX_SPECIFIC_RISK_CONTENT].riskFemale}
          <Spacer variant="small" />
          <Text>
            <FormattedMessage
              id="HDR_Positive_Risk__Risk_If_Female_Chart_Description"
              values={{ gene }}
            />
          </Text>
        </>
      ) : (
        <>
          <Text>
            <FormattedMessage id="HDR_Positive_Risk__Screen_If_Male_P2" />
          </Text>
          <Spacer variant="small" />
          {SEX_SPECIFIC_RISK_CONTENT[gene as keyof typeof SEX_SPECIFIC_RISK_CONTENT].riskMale}
          <Spacer variant="small" />
          <Text>
            <FormattedMessage
              id="HDR_Positive_Risk__Risk_If_Male_Chart_Description"
              values={{ gene }}
            />
          </Text>
        </>
      )}

      <Spacer variant="medium" />
      {data.map((item) => {
        const resultLabel =
          typeof item.resultLabel === 'object' && item.resultLabel !== null
            ? intl.formatMessage(item.resultLabel)
            : item.resultLabel;
        return (
          <>
            <RiskCard
              disease={intl.formatMessage(diseases[item.disease as keyof typeof diseases])}
              riskByAge={item.age}
              riskWithResultPercentageBarProps={getResultRiskBar(item)}
              riskWithResultRowLabel={
                <RiskWithResultLabel
                  gene={item.gene}
                  riskWithResultText={resultLabel}
                  riskWithResultReference={item.resultReference}
                />
              }
              averageRiskPercentageBarProps={getAverageRiskBar(item)}
              averageRiskRowLabel={
                item.averageRiskLabel.length > 0 ? (
                  <AverageRiskLabel
                    averageRiskText={item.averageRiskLabel}
                    averageRiskReference={item.averageRiskReference.toString()}
                    averageRiskMessage={averageRiskMessage}
                  />
                ) : null
              }
            />
            <Spacer variant="small" />
          </>
        );
      })}
      <Spacer variant="small" />
      <Text variant="body2">
        <FormattedMessage id="HDR_Positive_Risk__Risk_Charts_Disclaimer" />
      </Text>
    </>
  );
};
export const RiskCharts: React.FC<IRiskChartsProps> = (props) => {
  const { gene } = props;
  const riskData: RiskChart[] = HdrV1RiskCharts[gene];
  const isSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const [sex, setSex] = useState<'Male' | 'Female'>('Female');
  return (
    <Grid container spacing={!isSmallScreen ? 4 : 0}>
      {isSmallScreen ? (
        <>
          <Grid item xs={12}>
            <Title variant="headline2" textAlign="center">
              <FormattedMessage id="HDR_Positive_Risk__Risk_if_Header" />
            </Title>
            <Spacer variant="medium" />
            <Box sx={{ justifyContent: 'center', display: 'flex' }}>
              <Button
                variant={sex === 'Female' ? 'color-secondary' : 'color-tertiary'}
                sx={{ borderRadius: 20 }}
                onClick={() => {
                  setSex('Female');
                }}
              >
                <FormattedMessage id="HDR_Positive_Risk__Risk_if_Female_Button" />
              </Button>
              <InlineSpacer variant="medium" />
              <Button
                variant={sex === 'Male' ? 'color-secondary' : 'color-tertiary'}
                sx={{ borderRadius: 20 }}
                onClick={() => {
                  setSex('Male');
                }}
              >
                <FormattedMessage id="HDR_Positive_Risk__Risk_if_Male_Button" />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Spacer variant="small" />
            <RiskData gene={gene} riskData={riskData} sex={sex} />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={6}>
            <Title variant="headline2">
              <FormattedMessage id="HDR_Positive_Risk__Risk_if_Female_Header" />
            </Title>
            <Spacer variant="small" />
            <RiskData gene={gene} riskData={riskData} sex="Female" />
          </Grid>
          <Grid item xs={6}>
            <Title variant="headline2">
              <FormattedMessage id="HDR_Positive_Risk__Risk_If_Male_Header" />
            </Title>
            <Spacer variant="small" />
            <RiskData gene={gene} riskData={riskData} sex="Male" />
          </Grid>
        </>
      )}
    </Grid>
  );
};
