import { FormattedMessage, useIntl } from 'react-intl';

import { ModuleType } from 'lib/analytics/types';

interface OptionsSectionContent {
  title: JSX.Element;
  description: JSX.Element;
  promptForToggle: JSX.Element;
  successToastMessage: string;
}

export function useOptionsSectionContent(moduleType: ModuleType): OptionsSectionContent {
  const intl = useIntl();
  const MODULE_TO_CONTENT = {
    [ModuleType.GEM]: {
      title: (
        <FormattedMessage
          defaultMessage="Genetic ancestry and traits"
          description="Title for genetic ancestry and traits options section"
        />
      ),
      description: (
        <FormattedMessage
          defaultMessage="This includes your genetic ancestry, bitter taste perception, cilantro preference, earwax type, and lactose intolerance results."
          description="Description for genetic ancestry and traits options section"
        />
      ),
      promptForToggle: (
        <FormattedMessage
          defaultMessage="Show genetic ancestry and traits results"
          description="Text next to toggle for genetic ancestry and traits options section"
        />
      ),
      successToastMessage: intl.formatMessage({
        defaultMessage: 'Your genetic ancestry and trait results have been hidden.',
        description: 'Toast after hiding genetic ancestry and traits results',
      }),
    },
    [ModuleType.HDR]: {
      title: <FormattedMessage id="Options_Page__HDR_Title" />,
      description: <FormattedMessage id="HDR_Options_Details" />,
      promptForToggle: <FormattedMessage id="Options_Page__HDR_Toggle_Label" />,
      successToastMessage: intl.formatMessage({
        defaultMessage: 'Your hereditary disease risk results have been hidden.',
        description: 'Toast after hiding hereditary disease risk results',
      }),
    },
    [ModuleType.PGX]: {
      title: <FormattedMessage id="Options_Page__PGX_Title" />,
      description: <FormattedMessage id="PGx_Options_Details" />,
      promptForToggle: <FormattedMessage id="Options_Page__PGX_Toggle_Label" />,
      successToastMessage: intl.formatMessage({
        defaultMessage: 'Your medicine and your DNA results have been hidden.',
        description: 'Toast after hiding medicine and your DNA results',
      }),
    },
  };
  return MODULE_TO_CONTENT[moduleType];
}
