import { Spacer } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';

import chatIcon from 'assets/images/icons/chat.svg';
import emailIcon from 'assets/images/icons/email-icon-light.svg';
import phoneIcon from 'assets/images/icons/phone-icon-light.svg';
import ttyIcon from 'assets/images/icons/tty-icon-light.svg';
import { FOOTER_EMAIL_ADDRESS, FOOTER_PHONE_NUMBER } from 'lib/constants';

import { SUPPORT_CHAT_URLS } from '../constants';
import { useFooterContent } from '../content';
import { FooterContactRow } from './FooterContactRow';

export const FooterContactLinks: React.FC = () => {
  const footerContent = useFooterContent();
  const { locale } = usePreferredLocale();
  return (
    <>
      <FooterContactRow
        icon={emailIcon}
        iconAlt=""
        linkText={FOOTER_EMAIL_ADDRESS}
        linkTo={`mailto:${FOOTER_EMAIL_ADDRESS}`}
      />
      <Spacer variant="medium" />
      <FooterContactRow
        icon={phoneIcon}
        iconAlt=""
        linkText={FOOTER_PHONE_NUMBER}
        linkTo={`tel:${FOOTER_PHONE_NUMBER}`}
      />
      <Spacer variant="medium" />
      <FooterContactRow
        icon={ttyIcon}
        iconAlt=""
        linkText={footerContent.ttyText}
        linkTo="tel:711"
      />
      <Spacer variant="medium" />
      <FooterContactRow
        icon={chatIcon}
        iconAlt=""
        linkText={footerContent.liveChat}
        linkTo={SUPPORT_CHAT_URLS[locale]}
      />
    </>
  );
};
