import { Environment } from '@color/lib';

import { config } from 'config';
import { AppEnvironment } from 'config/types';
import { IDP_PTSC } from 'lib/constants';

import { AuthStrategy } from './types';

export const SSO_IDENTITY_PROVIDER: string = IDP_PTSC;

export const SSO_LOGIN_ENDPOINT: string = `${config.API_HOST_URL}api/v1/aou_single_sign_on_start`;
export const SSO_REFRESH_SESSION_ENDPOINT: string = 'aou_refresh_session';

// Force localhost dev to make more frequent requests, every 10 seconds.
// In other environments, the refresh interval is 3 minutes, chosen arbitrarily
export const SSO_REFRESH_INTERVAL_IN_MILLISECONDS: number =
  config.ENVIRONMENT === Environment.LOCAL ? 10 * 1000 : 3 * 60 * 1000;

export const AUTH_STRATEGIES: Record<AppEnvironment, AuthStrategy[]> = {
  [Environment.LOCAL]: [AuthStrategy.PASSWORD],
  // Staging shares a database with the rest of Color, and is used by developers.
  // It's acceptable to expose the `/login` page and allow simple password-backed login.
  [Environment.STAGING]: [AuthStrategy.PASSWORD, AuthStrategy.SSO],
  // The testing environment is meant to be as close to prod-like as possible.
  // It's meant to be used by our partners, and we'll have created users proactively for them.
  // The `/login` page is made available for GC and Support Staff access
  [Environment.TESTING]: [AuthStrategy.PASSWORD, AuthStrategy.SSO],
  [Environment.PRODUCTION]: [AuthStrategy.PASSWORD, AuthStrategy.SSO],
};
