import { ExternalLink, Spacer, Text } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { config } from 'config';

import { HDR_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  hdrILModule4PageTitle: {
    description: 'HDR Informing Loop Page Title - Module 4',
    defaultMessage: 'Insurance',
  },
});

export const HdrInformingLoopModule4: React.FC = () => {
  const { locale } = usePreferredLocale();
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.hdrILModule4PageTitle)}
      module={{
        analyticsInformingLoopName: HDR_INFORMING_LOOP_NAME,
        analyticsPageName: '4. Insurance',
        image: CircularImageType.INSURANCE_DOCUMENT,
        title: (
          <FormattedMessage
            description="HDR informing loop page 4 title"
            defaultMessage="Could learning about my hereditary disease risk affect my insurance?"
          />
        ),
        content: (
          <>
            <Text>
              <ul>
                <li>
                  <FormattedMessage
                    description="HDR informing loop page 4 content bullet 1"
                    defaultMessage="<b>Your health insurance will not be affected.</b> Health insurers <b>cannot</b> use DNA information to decide if they will cover you, change your coverage, cancel your coverage, or charge you more."
                  />
                </li>
                <Spacer variant="small" />
                <ul>
                  <li>
                    <FormattedMessage
                      description="HDR informing loop page 4 content bullet 1.1"
                      defaultMessage="A federal law stops them from using DNA information in this way. To learn more about that law, visit the {learningCenterLink}."
                      values={{
                        learningCenterLink: (
                          <ExternalLink
                            to={config.LEARNING_CENTER_GENETIC_TESTING_URL[locale].href}
                          >
                            <FormattedMessage
                              description="Learning center link text"
                              defaultMessage="Learning Center"
                            />
                          </ExternalLink>
                        ),
                      }}
                    />
                  </li>
                </ul>
                <Spacer variant="medium" />
                <li>
                  <FormattedMessage
                    description="HDR informing loop page 4 content bullet 2"
                    defaultMessage="Disability, life, and long-term care insurance is different. In most places, <b>disability, life, and long-term care</b> insurers <b>can</b> use DNA information to decide if they will cover you and how much to charge you."
                  />
                </li>
              </ul>
            </Text>
          </>
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
