import { Image } from '@color/continuum';
import { kebabCase } from 'lodash';
import requireContext from 'require-context.macro';

import { ResultBannerIconType } from './types';

const ResultBannerIcons = requireContext(
  '../../../assets/images/result-banner-icons',
  true,
  /\.svg$/
);

export interface ResultBannerIconProps {
  icon: ResultBannerIconType;
}

export const ResultBannerIcon: React.FC<ResultBannerIconProps> = (props) => {
  const { icon } = props;
  const image = ResultBannerIcons(`./${kebabCase(icon)}.svg`).default;
  return <Image width="72px" height="72px" src={image} alt="" />;
};
