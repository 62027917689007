import { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { useAppAuthContext } from 'components/App/Providers/AppAuthProvider';
import { AuthStrategy } from 'components/App/Providers/AppAuthProvider/types';
import { PasswordLogin } from 'components/Dev/PasswordLogin';
import { GemAuthError } from 'components/GemAuthError';
import { HdrReport } from 'components/HealthRelatedResults/HdrReport';
import { PgxReport } from 'components/HealthRelatedResults/PgxReport/PgxReport';
import { useCachedQueryParam } from 'hooks/useCachedQueryParam';
import { usePathParamLocale } from 'hooks/usePathParamLocale';
import { usePageViews } from 'lib/analytics/analytics';

import { AuthenticatedRoutes } from './AuthenticatedRoutes';
import { HDR_REPORT_PATH, PGX_REPORT_PATH } from './constants';

export const AppRoutes: React.FC = () => {
  const { pathname, search, state } = useLocation();
  const { authStrategies, isAuthenticated } = useAppAuthContext();
  usePathParamLocale();
  usePageViews();

  const history = useHistory();
  const idp = useCachedQueryParam('idp');
  // During the informing loops, there will be other history.push() calls that will
  // cause warnings telling us not to change state in a render function
  // moving this to a useEffect fixes that
  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    const params = new URLSearchParams(search);
    if (!params.get('idp') && idp) {
      if (params.toString() === '') {
        history.push({ search: `?idp=${idp}`, state });
      } else {
        history.push({ search: `${params.toString()}&idp=${idp}`, state });
      }
    }
  }, [history, idp, search, state]);

  // In legacy GEM, all routes started with /aou.
  if (pathname.startsWith('/aou')) {
    return <Redirect to={pathname.replace('/aou', '')} />;
  }

  // Authenticated users should be able to access all GP routes.
  if (isAuthenticated) {
    return <AuthenticatedRoutes />;
  }

  // Let the API call use report access token auth for Counseling sessions
  const token = new URLSearchParams(search).get('token');
  if (token) {
    return (
      <Switch>
        <Route path={`${HDR_REPORT_PATH}`}>
          <HdrReport token={token} />
        </Route>
        <Route path={`${PGX_REPORT_PATH}`}>
          <PgxReport token={token} />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      {
        // Unauthenticated users in staging and local development should be able to access
        // the login route to log in with email/password auth.
        authStrategies.includes(AuthStrategy.PASSWORD) && (
          <Route exact path="/login">
            <PasswordLogin />
          </Route>
        )
      }
      {
        // Unauthenticated users should only have access to the error route.
      }
      <Route exact path="/error">
        <GemAuthError />
      </Route>
    </Switch>
  );
};
