import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormikDialog,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  getMuiClassSelector,
} from '@color/continuum';
import { Formik } from 'formik';
import { camelCase, startCase } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { IAppointment, Timezones } from '../types';

interface ITimezoneDialogProps {
  currentTimezone: string;
  setAppointment: Dispatch<SetStateAction<IAppointment>>;
  dialogIsOpen: boolean;
  setDialogIsOpen: Dispatch<React.SetStateAction<boolean>>;
}

export const TimezoneDialog: React.FC<ITimezoneDialogProps> = (props) => {
  const { currentTimezone, setAppointment, dialogIsOpen, setDialogIsOpen } = props;
  const [dialogValue, setDialogValue] = useState(currentTimezone);

  const intl = useIntl();
  const messages = {
    title: intl.formatMessage({ id: 'Schedule_Timezone__Title' }),
    subtitle: intl.formatMessage({ id: 'Schedule_Timezone__Subtitle' }),
    location: intl.formatMessage({ id: 'Schedule_Timezone__Your_Location' }),
    submit: intl.formatMessage({ id: 'Schedule_Timezone__Submit' }),
    cancel: intl.formatMessage({ id: 'Schedule_Timezone__Cancel' }),
  };
  return (
    <Formik
      initialValues={{
        selectedTimezone: dialogValue,
      }}
      validationSchema={Yup.object().shape({
        selectedTimezone: Yup.string(),
      })}
      onSubmit={() => {
        setAppointment((prevState) => ({
          ...prevState,
          timezone: dialogValue,
        }));
        setDialogIsOpen(false);
      }}
    >
      <FormikDialog
        maxWidth="sm"
        fullWidth
        dialogIsOpen={dialogIsOpen}
        onClose={() => setDialogIsOpen(false)}
        submitButtonProps={{
          children: messages.submit,
          isLoading: false,
        }}
        cancelButtonProps={{ children: messages.cancel }}
        title={messages.title}
        subtitle={<Text>{messages.subtitle}</Text>}
        isFullScreenOnMobile={false}
      >
        <Box
          sx={{
            maxHeight: '45vh',
          }}
        >
          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <RadioGroup
              aria-label="selected-timezone"
              value={dialogValue}
              onChange={(event) => {
                setDialogValue(event.target.value);
              }}
            >
              {Object.keys(Timezones).map((timezone) => {
                return (
                  <>
                    <FormControlLabel
                      value={timezone}
                      control={<Radio size="small" />}
                      sx={{
                        width: '100%',
                        alignItems: 'flex-start',
                        [getMuiClassSelector('& .MuiFormControlLabel-label')]: {
                          paddingTop: 1,
                        },
                      }}
                      label={
                        <>
                          <Text bold>
                            {startCase(
                              camelCase(
                                new Intl.DateTimeFormat(intl.locale, {
                                  timeZoneName: 'long',
                                  timeZone: timezone,
                                })
                                  .format(new Date())
                                  .split(',')[1]
                              )
                            )}
                          </Text>
                          {timezone === Intl.DateTimeFormat().resolvedOptions().timeZone && (
                            <>
                              <Spacer variant="small" />
                              <Text>{messages.location}</Text>
                              <Spacer variant="small" />
                            </>
                          )}
                        </>
                      }
                    />
                    {timezone === Intl.DateTimeFormat().resolvedOptions().timeZone && (
                      <Divider aria-hidden="true" variant="fullWidth" />
                    )}
                    <Spacer variant="medium" />
                  </>
                );
              })}
            </RadioGroup>
          </FormControl>
        </Box>
      </FormikDialog>
    </Formik>
  );
};
