import { GpDialog, LocationFlowProvider } from '@color/continuum';

import { GemInformingLoopRoutes } from './GemInformingLoopRoutes';
import { useGemInformingLoopLocations } from './locations';

export const GemInformingLoop: React.FC = () => {
  return (
    <GpDialog open scroll="body" fullScreen aria-labelledby="dialogTitle">
      <LocationFlowProvider locations={useGemInformingLoopLocations()}>
        <GemInformingLoopRoutes />
      </LocationFlowProvider>
    </GpDialog>
  );
};
