import { Spacer, Text } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { AccordionIconType } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionIcon/types';
import { AccordionWithIcon } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionWithIcon';
import { WeLookedAtTheseGenesTable } from 'components/HealthRelatedResults/components/WeLookedAtTheseGenesTable/WeLookedAtTheseGenesTable';

export const WeLookedAtTheseGenes: React.FC = () => (
  <AccordionWithIcon
    icon={AccordionIconType.GENES}
    title={<FormattedMessage id="HDR_Positive__We_Looked_At_These_Genes_Section_Title" />}
  >
    <Text paragraph>
      <FormattedMessage id="HDR_Positive__We_Looked_At_These_Genes_Paragraph_1" />
    </Text>
    <Text paragraph>
      <FormattedMessage id="HDR_Positive__We_Looked_At_These_Genes_Paragraph_2" />
    </Text>
    <Text paragraph>
      <FormattedMessage id="HDR_Positive__We_Looked_At_These_Genes_Paragraph_3" />
    </Text>
    <WeLookedAtTheseGenesTable />
    <Spacer variant="medium" />
  </AccordionWithIcon>
);
