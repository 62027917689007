// TODO Import from CDS rather than MUI. In CDS-341 we turned on lint warnings on direct MUI imports. This line is disabled because GP CI sets the rule to error instead of warn.
// Swap this for a CDS import instead of a MUI import.
// eslint-disable-next-line no-restricted-imports
import { ButtonProps } from '@mui/material';
import { useFormikContext } from 'formik';

import { SubmitButton } from './SubmitButton';

interface Props extends ButtonProps {
  isLoading: boolean;
}

export const FormikSubmitButton: React.FC<Props> = (props) => {
  const { ...buttonProps } = props;
  const { submitCount, isValid, errors, initialValues } = useFormikContext<{}>();
  const formIsValid = submitCount === 0 || isValid;
  // Use fieldCount as a proxy for form complexity
  const fieldCount = Object.keys(initialValues).length;
  const errorsAreVisible = fieldCount > 1 && !formIsValid;

  return (
    <SubmitButton
      data-testid="SubmitButton"
      {...buttonProps}
      formIsValid={isValid}
      errors={errorsAreVisible ? errors : undefined}
    />
  );
};
