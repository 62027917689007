import { Button, useLocationFlowContext } from '@color/continuum';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { InformingLoopFooter } from '../InformingLoopFooter/InformingLoopFooter';

export const FirstModuleInformingLoopFooter: React.FC = () => {
  const { goToNextLocation } = useLocationFlowContext();
  const history = useHistory();
  return (
    <InformingLoopFooter
      leftButton={
        <Button
          variant="color-secondary"
          onClick={() => {
            history.push(`/`);
          }}
        >
          <FormattedMessage
            defaultMessage="Cancel"
            description="Informing loop footer cancel button text"
          />
        </Button>
      }
      rightButton={
        <Button onClick={() => goToNextLocation()}>
          <FormattedMessage
            defaultMessage="Next"
            description="Informing loop first module next button"
          />
        </Button>
      }
    />
  );
};
