import { ExternalLink, InternalButtonLink, Spacer, Text } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { config } from 'config';

import { PGX_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  pgxILNotSurePageTitle: {
    description: 'PGx Informing Loop Decision Page Title - Not Sure',
    defaultMessage: 'Decision - Not Sure',
  },
});

export const PgxInformingLoopDecisionMaybeLater: React.FC = () => {
  const { locale } = usePreferredLocale();
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.pgxILNotSurePageTitle)}
      module={{
        analyticsInformingLoopName: PGX_INFORMING_LOOP_NAME,
        analyticsPageName: '8. Not Sure',
        image: CircularImageType.GENES_DRUGS,
        title: <FormattedMessage id="PGX_IL_Not_Sure__Title" />,
        content: (
          <>
            <Text bold paragraph>
              <FormattedMessage
                description="PGX informing loop not sure decision page paragraph 1"
                defaultMessage="Your decision: I’m not sure right now."
              />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="PGX informing loop no decision page paragraph 2"
                defaultMessage="Take your time so you can make a decision that’s right for you. To learn more about DNA and medicine, visit the {learningCenterLink}. Learning more may help you decide if you want to see this information."
                values={{
                  learningCenterLink: (
                    <ExternalLink to={config.LEARNING_CENTER_GENES_AND_MEDICINE_URL[locale].href}>
                      <FormattedMessage
                        description="Learning center link text"
                        defaultMessage="Learning Center"
                      />
                    </ExternalLink>
                  ),
                }}
              />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="PGX informing loop no decision page paragraph 3"
                defaultMessage="If you change your mind and would like to get your results about medicine and your DNA, you can update your decision in the “My Data” section of your account at any time."
              />
              <Spacer variant="medium" />
              <InternalButtonLink to="/">
                <FormattedMessage
                  defaultMessage="Back to DNA Results"
                  description="PGX informing loop final page no decision content back to results button"
                />
              </InternalButtonLink>
            </Text>
          </>
        ),
      }}
    />
  );
};
