import {
  Grid,
  IMuiSwitchProps,
  MuiFormLabel,
  MuiSwitch,
  getMuiClassSelector,
  mergeSxProps,
} from '@color/continuum';
import { FormattedMessage } from 'react-intl';

export const OptionsToggleSwitch: React.FC<IMuiSwitchProps> = (props) => {
  const { sx } = props;
  return (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item>
        <MuiFormLabel>
          <FormattedMessage defaultMessage="Hide" />
        </MuiFormLabel>
      </Grid>
      <Grid item>
        <MuiSwitch
          {...props}
          sx={mergeSxProps(
            (theme) => ({
              [getMuiClassSelector('& .MuiSwitch-switchBase')]: {
                color: theme.palette.grey[400],
                [getMuiClassSelector('&.Mui-checked')]: {
                  color: theme.palette.success.main,
                  [getMuiClassSelector('& + .MuiSwitch-track')]: {
                    backgroundColor: '#b6e6d4', // TODO this color is not on the theme
                    opacity: 1,
                  },
                },
              },
              [getMuiClassSelector('& .MuiSwitch-track')]: {
                backgroundColor: theme.palette.common.black,
                opacity: 0.12,
              },
            }),
            sx
          )}
          inputProps={{
            'aria-labelledby': 'togglePromptLabel',
          }}
        />
      </Grid>
      <Grid item>
        <MuiFormLabel>
          <FormattedMessage defaultMessage="Show" />
        </MuiFormLabel>
      </Grid>
    </Grid>
  );
};
