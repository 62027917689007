import { Box, InlineSpacer, Spacer, useMediaQuery, useTheme } from '@color/continuum';
import { range } from 'lodash';
import { Fragment, ReactNode, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { mod } from 'react-swipeable-views-core';
import { virtualize } from 'react-swipeable-views-utils';

import { InfoCarouselLeftButton, InfoCarouselRightButton } from './InfoCarouselButtons';
import { InfoCarouselContent } from './InfoCarouselContent';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

export interface InfoCarouselProps {
  content: ReactNode[];
}

export const InfoCarousel: React.FC<InfoCarouselProps> = (props: InfoCarouselProps) => {
  const { content } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const numSteps = content.length;
  const [index, setIndex] = useState<number>(0);
  return (
    <Box sx={{ backgroundColor: theme.palette.grey[100] }}>
      <Spacer variant="xLarge" />
      <Box display="flex" alignItems="center">
        {!isMobile && <InfoCarouselLeftButton onClick={() => setIndex(index - 1)} />}
        <Box display="flex" flexShrink={1} minWidth={0}>
          <VirtualizeSwipeableViews
            disabled={!isMobile}
            index={index}
            onChangeIndex={(newIndex) => setIndex(newIndex)}
            slideRenderer={({ index: slideIndex, key }) => (
              <InfoCarouselContent key={key} content={content[mod(slideIndex, numSteps)]} />
            )}
          />
        </Box>
        {!isMobile && <InfoCarouselRightButton onClick={() => setIndex(index + 1)} />}
      </Box>
      <Spacer variant="small" />
      <Box display="flex" justifyContent="center">
        {range(numSteps).map((step: number) => (
          <Fragment key={step}>
            <Box
              sx={{
                display: 'inline',
                height: '10px',
                width: '10px',
                borderRadius: '50%',
                backgroundColor:
                  step <= mod(index, numSteps)
                    ? theme.palette.primary.main
                    : theme.palette.grey[400],
              }}
            />
            {step <= numSteps - 1 && <InlineSpacer variant="small" />}
          </Fragment>
        ))}
      </Box>
      <Spacer variant="xLarge" />
    </Box>
  );
};
