import { HdrV1VariantDetails } from '@color/aou-reports';
import { Box, Spacer } from '@color/continuum';

import { MethodsAndLimitations } from '../../MethodsAndLimitations';
import { AdditionalInformation } from '../Sections/AdditionalInformation';
import { CommonQuestions } from '../Sections/CommonQuestions';
import { TechnicalReport } from '../Sections/TechnicalReport';
import { WeLookedAtTheseGenes } from '../Sections/WeLookedAtTheseGenes';
import { YourResults } from '../Sections/YourResults';

interface IYourResultsProps {
  genomeCenterSiteName: string;
  cvlSiteName: string;
  multiVariantGeneDetails: HdrV1VariantDetails[];
}
export const YourResultsTab: React.FC<IYourResultsProps> = (props) => {
  const { genomeCenterSiteName, cvlSiteName, multiVariantGeneDetails } = props;
  return (
    <>
      <YourResults multiVariantGeneDetails={multiVariantGeneDetails} />
      <Spacer variant="xxLarge" />
      <Box paddingX={10} paddingY={2}>
        <AdditionalInformation />
        <Spacer variant="small" />
        <CommonQuestions />
        <Spacer variant="small" />
        <WeLookedAtTheseGenes />
        <Spacer variant="small" />
        <TechnicalReport
          isMultiVariant={multiVariantGeneDetails.length > 1}
          multiVariantGeneDetails={multiVariantGeneDetails}
        />
        <Spacer variant="small" />
        <MethodsAndLimitations genomeCenterSite={genomeCenterSiteName} cvlSite={cvlSiteName} />
      </Box>
    </>
  );
};
