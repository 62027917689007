import { IconButton } from '@color/continuum';
import MuiCloseIcon from '@mui/icons-material/Close';

interface ICloseIconProps {
  'aria-label': string;
  onClick: () => void;
}

// Fixme, Rename this to `CloseIconButton`
export const CloseIcon: React.FC<ICloseIconProps> = (props) => {
  return (
    <IconButton
      variant="iconOnly"
      sx={{
        margin: '28px 28px 0 0', // Mocks show 40px margins, icon button comes with 12px padding, 28 is the diff
      }}
      {...props}
    >
      <MuiCloseIcon
        sx={{
          height: {
            xs: '12px',
            sm: '24px',
          },
          width: {
            xs: '12px',
            sm: '24px',
          },
        }}
      />
    </IconButton>
  );
};
