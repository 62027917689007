import { addDays, startOfDay, startOfWeek } from 'date-fns';
import { useState } from 'react';

/**
 * Hook which manages schedule navigation state.
 *
 * The state starts on the current date, and exposes functions to move
 * forwards / backwards by weeks or days.
 */
export const useScheduleNavigationState = () => {
  const currentDate = new Date();

  // Track which week is currently selected, relative to the current week.
  const [weekOffset, setWeekOffset] = useState<number>(0);
  // Track which day is currently selected, relative to the current day.
  const [dayOffset, setDayOffset] = useState<number>(0);

  const selectedDate = addDays(currentDate, dayOffset + 7 * weekOffset);
  return {
    incrementWeek: () => setWeekOffset((prevOffset) => prevOffset + 1),
    decrementWeek: () => setWeekOffset((prevOffset) => prevOffset - 1),
    incrementDay: () => setDayOffset((prevOffset) => prevOffset + 1),
    decrementDay: () => setDayOffset((prevOffset) => prevOffset - 1),
    startOfSelectedWeek: startOfWeek(selectedDate),
    startOfSelectedDay: startOfDay(selectedDate),
  };
};
