/**
 * Custom hook that uses config values for the current identity provider (idp).
 * We are pulling the idp from the query params.
 * If the idp is not in the query params, we will default to PTSC.
 * We default to PTSC because they were the only portal for GP until we added Care Evolution (CE)
 * CE will ensure any of their links to GP will contain the "idp=ce" query param.
 * GP will also persist the "idp=ce" query param when navigating within GP.
 * Having the query param allows users to bookmark pages in GP and get directed to the CE portal when logging in.
 */
import { useLocation } from 'react-router-dom';

import { SSO_IDENTITY_PROVIDER } from 'components/App/Providers/AppAuthProvider/constants';
import { config, getIdpOverrides } from 'config';

export const useConfigForIdp = () => {
  const { search } = useLocation();
  const idp = new URLSearchParams(search).get('idp') || SSO_IDENTITY_PROVIDER;
  return { ...config, ...getIdpOverrides(idp) };
};
