import { Spacer, Text } from '@color/continuum';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

import { HDR_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  hdrILModule5PageTitle: {
    description: 'HDR Informing Loop Page Title - Module 5',
    defaultMessage: 'Emotional Impact',
  },
});

export const HdrInformingLoopModule5: React.FC = () => {
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.hdrILModule5PageTitle)}
      module={{
        analyticsInformingLoopName: HDR_INFORMING_LOOP_NAME,
        analyticsPageName: '5. Emotional Effects',
        image: CircularImageType.THINKING_PERSON,
        title: (
          <FormattedMessage
            description="HDR informing loop page 5 title"
            defaultMessage="How could learning about my hereditary disease risk affect me emotionally?"
          />
        ),
        content: (
          <>
            <Text>
              <ul>
                <li>
                  <FormattedMessage
                    description="HDR informing loop page 5 content bullet 1"
                    defaultMessage="You could get information you weren’t expecting in your results. For example:"
                  />
                </li>
                <Spacer variant="small" />
                <ul>
                  <li>
                    <FormattedMessage
                      description="HDR informing loop page 5 content bullet 1.1"
                      defaultMessage="You might discover that you are at risk for a disease that has not been seen in other members of your family."
                    />
                  </li>
                </ul>
                <Spacer variant="medium" />
                <li>
                  <FormattedMessage
                    description="HDR informing loop page 5 content bullet 2"
                    defaultMessage="Your results could make you worried or confused. For example:"
                  />
                </li>
                <Spacer variant="small" />
                <ul>
                  <li>
                    <FormattedMessage
                      description="HDR informing loop page 5 content bullet 2.1"
                      defaultMessage="You may be afraid of passing a hereditary disease risk on to your children."
                    />
                  </li>
                  <Spacer variant="xSmall" />
                  <li>
                    <FormattedMessage
                      description="HDR informing loop page 5 content bullet 2.2"
                      defaultMessage="Your results may make you wonder if you are related to a family member in the way you thought. Keep in mind that DNA results are not always the same for all blood relatives."
                    />
                  </li>
                </ul>
              </ul>
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="HDR informing loop page 5 content paragraph 1"
                defaultMessage="You can contact the <i>All of Us</i> Support Center at any time. We can help answer your questions. We can also help you find more resources."
              />
            </Text>
          </>
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
