import { FormattedMessage } from 'react-intl';

import { Trait } from '../../types';

interface ExpansionPanelContent {
  title: JSX.Element;
  text: JSX.Element;
}

const content: Record<Trait, ExpansionPanelContent[]> = {
  [Trait.BITTERNESS]: [
    {
      title: (
        <FormattedMessage
          description="bitterness trait results expansion panel 1 title"
          defaultMessage="Beer's bitter, too."
        />
      ),
      text: (
        <FormattedMessage
          description="bitterness trait results expansion panel 1 text"
          defaultMessage="A standardized bitterness ranking doesn't exist for food, but it does for beer. Beers are assigned a number of International Bitterness Units, or IBUs, based on a chemical measurement<sup>8,9</sup> of the amount of “bittering” compounds they contain. However, IBUs don't account for how all beer ingredients and flavors play off of each other. This means a beer with 20 IBUs may actually taste more bitter than a beer with 50 IBUs."
        />
      ),
    },
    {
      title: (
        <FormattedMessage
          description="bitterness trait results expansion panel 2 title"
          defaultMessage="The evolution of bitter (non-)tasting"
        />
      ),
      text: (
        <FormattedMessage
          description="bitterness trait results expansion panel 2 text"
          defaultMessage="Scientists believe that it's good for humans to detect bitter flavors. It means we can stay away from potentially dangerous or poisonous foods, which are often bitter. So why do about 25% of us lack the ability to taste PTC and related bitter compounds? Studies on the evolution of the <i>TAS2R38</i> gene suggest that it might be helpful for some people to taste fewer bitter compounds so they can have a more well-rounded diet.<sup>11</sup> Others argue that it's just pure chance.<sup>12</sup> Interestingly, scientists have learned that genetics also influence bitter taste in our ape relatives.<sup>13</sup>"
        />
      ),
    },
  ],
  [Trait.CILANTRO]: [
    {
      title: (
        <FormattedMessage
          description="cilantro trait results expansion panel 1 title"
          defaultMessage="You got it from your mom."
        />
      ),
      text: (
        <FormattedMessage
          description="cilantro trait results expansion panel 1 text"
          defaultMessage="The formula or breastmilk you ate as an infant<sup>9,10,11</sup>—and even what your mother ate while she was pregnant<sup>10,11</sup>—can influence your food preferences. Flavor and odor compounds from foods can be shared through amniotic fluid and breastmilk, so the things your mother ate likely influenced you too.<sup>12</sup> Formula can also influence food preferences because different types of formula have different flavors and smells, so you’re more likely to develop preferences similar to the type of formula you were fed as a baby.<sup>9,10,11</sup>"
        />
      ),
    },
    {
      title: (
        <FormattedMessage
          description="cilantro trait results expansion panel 2 title"
          defaultMessage="Don't like cilantro? Try crushing the leaves or eating the seeds."
        />
      ),
      text: (
        <FormattedMessage
          description="cilantro trait results expansion panel 2 text"
          defaultMessage="<p>If you don’t like the taste of fresh cilantro leaves, take heart: there are other ways you can enjoy the plant. Dried cilantro has fewer aldehydes than fresh leaves, making it less likely that you will experience the unpleasant smell. Cutting fresh cilantro leaves and leaving them exposed to the air turns on enzymes in the leaves. The enzymes help break down the aldehydes and reduce the stinking compounds.<sup>13</sup></p>
          <p>Did you know that cilantro and coriander are the same thing? In the US, whole or ground dried cilantro seeds are called coriander, which you may like even if you don’t like cilantro. Coriander has fewer aldehydes than the fresh cilantro leaves, too.</p>"
        />
      ),
    },
  ],
  [Trait.EARWAX]: [
    {
      title: (
        <FormattedMessage
          description="earwax trait results expansion panel 1 title"
          defaultMessage="Don't reach for that cotton swab!"
        />
      ),
      text: (
        <FormattedMessage
          description="earwax trait results expansion panel 1 text"
          defaultMessage="Our ears are self-cleaning, so most of us should not need to remove excess earwax. If you do experience a wax buildup, don’t use a cotton swab. They can push the earwax in further and damage the eardrum. The American Academy of Otolaryngology recommends using drops to soften wax and washing out the ear if necessary.<sup>9</sup> Keep your ears away from ear candles too, as these are also an unsafe option for removing earwax.<sup>10</sup>"
        />
      ),
    },
    {
      title: (
        <FormattedMessage
          description="earwax trait results expansion panel 2 title"
          defaultMessage="Earwax type is influenced by migration."
        />
      ),
      text: (
        <FormattedMessage
          description="earwax trait results expansion panel 2 text"
          defaultMessage="Scientists have looked at different populations to see how earwax type varies across the world. This has helped us understand the migrations of our ancestors. Dry earwax is very common in East Asia (80-95% of people), particularly in Japan. It is found less frequently in the Middle East and South Asia (30-50%). In African and European populations, dry earwax is almost non-existent (less than 3%).<sup>13,14</sup> Some Native Americans also have dry earwax, which suggests some of their ancestors may have traveled into the Americas from East Asia.<sup>15</sup>"
        />
      ),
    },
  ],
  [Trait.LACTOSE]: [
    {
      title: (
        <FormattedMessage
          description="lactose trait results expansion panel 1 title"
          defaultMessage="Can lactose tolerance change with age?"
        />
      ),
      text: (
        <FormattedMessage
          description="lactose trait results expansion panel 1 text"
          defaultMessage="Most human newborns need milk to survive. So the human body evolved so that babies can digest lactose. As babies get older, milk is replaced with other foods. The body doesn't need to keep producing as much lactase. Because of this, some people have a hard time digesting lactose as they get older. While this process is still being studied, it seems that cultural, nutritional, and environmental factors all play a role.<sup>7</sup>"
        />
      ),
    },
    {
      title: (
        <FormattedMessage
          description="lactose trait results expansion panel 2 title"
          defaultMessage="How common is lactose intolerance around the world?"
        />
      ),
      text: (
        <FormattedMessage
          description="lactose trait results expansion panel 2 text"
          defaultMessage="Lactose intolerance is very common around the world, but varies among different ethnic and racial groups. In some parts of Asia, up to 90% of individuals say they experience discomfort after eating dairy products.<sup>2,3</sup> This is also commonly reported by people with West African, Mediterranean, Ashkenazi Jewish, and Middle Eastern ancestry. Less than 25% of people of northern European descent say they experience such symptoms.<sup>7</sup>"
        />
      ),
    },
  ],
};

export function useTraitResultsExpansionPanelContent(
  trait: Trait,
  panel: number
): ExpansionPanelContent {
  return content[trait][panel - 1];
}
