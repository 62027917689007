import { Box, Button, Container, GpDialog, Grid, Spacer, Text, Title } from '@color/continuum';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { CloseIcon } from 'components/Appointments/CloseIcon';
import { ContactInfoForm } from 'components/Appointments/ContactInfo/ContactInfoForm';
import { InformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooter/InformingLoopFooter';
import { ProgressBar } from 'components/common/ProgressBar/ProgressBar';
import { SimplePage } from 'components/common/SimplePage/SimplePage';
import { logSchedulingPageView } from 'lib/analytics/analytics';
import { ModuleType, SegmentPageType } from 'lib/analytics/types';

import { IAppointment } from '../types';
import { ContactInfoDialog } from './ContactInfoDialog';

interface Props {
  handleContactInfoClick: () => void;
  handleCancelClick: () => void;
  appointment: IAppointment;
  setAppointment: (appointment: IAppointment) => void;
  hasPreviousAppointment: boolean;
}

interface IContactInfo {
  language: string;
  phoneCountryCode: number;
  phoneNumber: string;
  location: string;
}

export const ContactInfo: React.FC<Props> = (props) => {
  const {
    handleContactInfoClick,
    handleCancelClick,
    appointment,
    setAppointment,
    hasPreviousAppointment,
  } = props;
  const [showContactInfoDialog, setShowContactInfoDialog] = useState<boolean>(false);
  const [contactInfo, setContactInfo] = useState<IContactInfo>({
    language: '',
    phoneCountryCode: 1,
    phoneNumber: '',
    location: '',
  });
  const history = useHistory();
  const location = useLocation();

  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    logSchedulingPageView(appointment.type, SegmentPageType.GC_SCHEDULING_CONTACT_INFO);
  }, [appointment]);

  const handleBackButtonClick = () => {
    // if you have a location key that means you routed in-app
    if (location.key) {
      history.goBack();
    } else {
      // workaround to send back to Page 1, where routing to HDR_APPOINTMENT_SCHEDULING_PATH doesn't work since it's currently technically on that route
      history.go(0);
    }
  };

  const handleContactInfoSubmit = () => {
    setAppointment({
      ...appointment,
      location: contactInfo.location,
      language: contactInfo.language,
      phoneCountryCode: contactInfo.phoneCountryCode,
      phoneNumber: contactInfo.phoneNumber,
      // reset the appointment timestamp so participant must pick a new time
      timestamp: undefined,
    });
    handleContactInfoClick();
  };

  const intl = useIntl();
  const messages = {
    title: intl.formatMessage({ id: 'Scheduling_Contact__Title' }),
    text: intl.formatMessage({ id: 'Scheduling_Contact__Text' }),
    back: intl.formatMessage({ id: 'Scheduling_Contact__Button__Back' }),
    next: intl.formatMessage({ id: 'Scheduling_Contact__Button__Next' }),
    pageTitleHdr: intl.formatMessage({ id: 'HDR_Contact_Helmet_Title' }),
    pageTitlePgx: intl.formatMessage({ id: 'PGX_Contact_Helmet_Title' }),
    breadcrumbText: intl.formatMessage({ id: 'Scheduling_Contact__Breadcrumb_Text' }),
    languageRequired: intl.formatMessage({ id: 'Scheduling_Contact__Language__Required' }),
    locationRequired: intl.formatMessage({ id: 'Scheduling_Contact__Location__Required' }),
    phoneNumberRequired: intl.formatMessage({ id: 'Scheduling_Contact__Phone_Number__Required' }),
    phoneNumberMinLength: intl.formatMessage({
      id: 'Scheduling_Contact__Phone_Number__Min_Length',
    }),
    phoneNumberMaxLength: intl.formatMessage({
      id: 'Scheduling_Contact__Phone_Number__Max_Length',
    }),
    phoneCountryCodeRequired: intl.formatMessage({
      id: 'Scheduling_Contact__Country_Code__Required',
    }),
    toastMessage: intl.formatMessage({ id: 'Scheduling_Error_Toast_Message' }),
  };
  const schema = yup.object({
    language: yup.string().required(messages.languageRequired),
    location: yup.string().required(messages.locationRequired),
    phoneNumber: yup
      .string()
      .min(10, messages.phoneNumberMinLength)
      .max(10, messages.phoneNumberMaxLength)
      .required(messages.phoneNumberRequired),
    phoneCountryCode: yup.number().min(1).max(999).required(messages.phoneCountryCodeRequired),
  });
  return (
    <GpDialog fullScreen open>
      <ProgressBar currentStep={1} numSteps={2} />
      <Box display="flex" justifyContent="flex-end">
        <CloseIcon aria-label={messages.back} onClick={handleBackButtonClick} />
      </Box>
      <Formik
        initialValues={{ ...appointment }}
        onSubmit={(values) => {
          // If the participant is editing their previous appointment
          // and changed their appointment language or location show dialog
          // letting them know they'll need to pick a new appointment time
          if (
            hasPreviousAppointment &&
            (appointment.language !== values.language || appointment.location !== values.location)
          ) {
            setContactInfo({
              ...contactInfo,
              location: values.location,
              language: values.language,
              phoneCountryCode: values.phoneCountryCode,
              phoneNumber: values.phoneNumber,
            });
            setShowContactInfoDialog(true);
          } else {
            setAppointment({
              ...appointment,
              location: values.location,
              language: values.language,
              phoneCountryCode: values.phoneCountryCode,
              phoneNumber: values.phoneNumber,
            });
            handleContactInfoClick();
          }
        }}
        validationSchema={schema}
      >
        <Form>
          <SimplePage
            helmetTitle={
              appointment.type === ModuleType.PGX ? messages.pageTitlePgx : messages.pageTitleHdr
            }
          >
            <Spacer variant="small" />
            <Container>
              <Spacer variant="small" />
              <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                  <Title variant="headline1">{messages.title}</Title>
                  <Spacer variant="small" />
                  <Text>{messages.text}</Text>
                  <Spacer variant="xxLarge" />
                  <ContactInfoForm />
                  <Spacer variant="xxLarge" />
                </Grid>
              </Grid>
              <Spacer variant="xxxLarge" />
            </Container>
          </SimplePage>
          <InformingLoopFooter
            leftButton={
              <Button variant="color-secondary" onClick={handleCancelClick}>
                {messages.back}
              </Button>
            }
            rightButton={<Button type="submit">{messages.next}</Button>}
          />
        </Form>
      </Formik>
      {showContactInfoDialog && (
        <ContactInfoDialog
          handleContactInfoDialogConfirm={handleContactInfoSubmit}
          setShowContactInfoDialog={setShowContactInfoDialog}
        />
      )}
    </GpDialog>
  );
};
