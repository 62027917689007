export enum ReportIconType {
  ANCESTRY_AND_TRAITS = 'ancestry-and-traits',
  ANCESTRY = 'ancestry',
  BITTERNESS = 'bitterness',
  CILANTRO = 'cilantro',
  EARWAX = 'earwax',
  LACTOSE = 'lactose',
  YOUR_RESULTS_HDR = 'your-results-hdr',
  HEALTHY_LIFESTYLES = 'healthy-lifestyles',
  DISCUSS_RESULTS = 'discuss-results',
  RISK_WITH_GENE = 'risk-with-gene',
  NEXT_STEPS = 'next-steps',
  YOUR_RESULTS_PGX = 'your-results-pgx',
  BEYOND_DNA = 'beyond-dna',
}
