import { Text } from '@color/continuum';
import { FormattedMessage, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

export const PgxPreResultsModule2: React.FC = () => {
  const intl = useIntl();
  const messages = {
    pageTitle: intl.formatMessage({ id: 'PGX_PreResults_2_Helmet_Title' }),
  };

  return (
    <InformingLoopModule
      helmetTitle={messages.pageTitle}
      module={{
        analyticsInformingLoopName: 'PGx Pre Results',
        analyticsPageName: '2. Results',
        image: CircularImageType.GENES_DRUGS,
        title: <FormattedMessage id="PGX_Pre_Results_Page_2__Title" />,
        content: (
          <>
            <Text paragraph>
              <FormattedMessage id="PGX_Pre_Results_Page_2__Paragraph_1" />
            </Text>
            <Text paragraph>
              <FormattedMessage id="PGX_Pre_Results_Page_2__Paragraph_2" />
            </Text>
            <ol>
              <li>
                <Text paragraph>
                  <FormattedMessage id="PGX_Pre_Results_Page_2__Bullet_1" />
                </Text>
              </li>
              <li>
                <Text paragraph>
                  <FormattedMessage id="PGX_Pre_Results_Page_2__Bullet_2" />
                </Text>
              </li>
              <li>
                <Text paragraph>
                  <FormattedMessage id="PGX_Pre_Results_Page_2__Bullet_3" />
                </Text>
              </li>
            </ol>
            <Text paragraph>
              <FormattedMessage id="PGX_Pre_Results_Page_2__Paragraph_3" />
            </Text>
          </>
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
