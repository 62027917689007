import { GpDialog, LocationFlowProvider } from '@color/continuum';
import { useEffect } from 'react';

import { trackAnalyticsEvent } from 'lib/analytics';
import { AnalyticsPlatform, EventType } from 'lib/analytics/types';

import { HdrPreResultsRoutes } from './HdrPreResultsRoutes';
import { useHdrPreResultsLocations } from './locations';

export const HdrPreResults: React.FC = () => {
  /**
   * `useEffect` is often dangerous and unnecessary. This usage needs review by #ux-programs team.
   * See https://getcolor.atlassian.net/browse/UXTASK-1406
   */
  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    trackAnalyticsEvent(
      { eventType: EventType.STARTED_HDR_PRE_RESULTS },
      AnalyticsPlatform.DEPRECATED_EVENT_LOG
    );
  }, []);

  return (
    <GpDialog open scroll="body" fullScreen aria-labelledby="dialogTitle">
      <LocationFlowProvider locations={useHdrPreResultsLocations()}>
        <HdrPreResultsRoutes />
      </LocationFlowProvider>
    </GpDialog>
  );
};
