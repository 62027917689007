import { kebabCase } from 'lodash';
import requireContext from 'require-context.macro';

import { CircularImageType } from './types';

const CircularImages = requireContext('../../../assets/images/circular-images', true, /\.svg$/);

export const getCircularImageSrc = (icon: CircularImageType): string => {
  return CircularImages(`./${kebabCase(icon)}.svg`).default;
};
