import { ExternalLink, InternalButtonLink, Spacer, Text } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { config } from 'config';

import { HDR_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  hdrILNotSurePageTitle: {
    description: 'HDR Informing Loop Decision Page Title - Not Sure',
    defaultMessage: 'Not Sure',
  },
});

export const HdrInformingLoopDecisionMaybeLater: React.FC = () => {
  const { locale } = usePreferredLocale();
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.hdrILNotSurePageTitle)}
      module={{
        analyticsInformingLoopName: HDR_INFORMING_LOOP_NAME,
        analyticsPageName: '10. Not Sure',
        image: CircularImageType.GENES_FAMILY,
        title: <FormattedMessage id="HDR_IL_Not_Sure__Title" />,
        content: (
          <>
            <Text bold paragraph>
              <FormattedMessage
                description="HDR informing loop not sure decision screen paragraph 1"
                defaultMessage="Your decision: I’m not sure right now."
              />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="HDR informing loop not sure decision screen paragraph 2"
                defaultMessage="Take your time so you can make a decision that’s right for you. To learn more about DNA and hereditary disease risk, visit the {learningCenterLink}. Learning more may help you decide if you want to see this information."
                values={{
                  learningCenterLink: (
                    <ExternalLink to={config.LEARNING_CENTER_GENES_AND_HEALTH_URL[locale].href}>
                      <FormattedMessage
                        description="Learning center link text"
                        defaultMessage="Learning Center"
                      />
                    </ExternalLink>
                  ),
                }}
              />
            </Text>
            <Text paragraph>
              <FormattedMessage id="HDR_IL_Not_Sure__Paragraph_3" />
            </Text>
            <Spacer variant="medium" />
            <InternalButtonLink to="/">
              <FormattedMessage
                defaultMessage="Back to DNA Results"
                description="HDR informing loop final page not sure decision content back to results button"
              />
            </InternalButtonLink>
          </>
        ),
      }}
    />
  );
};
