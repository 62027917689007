import { useLocationFlowContext } from '@color/continuum';

import { ProgressBar } from './ProgressBar';

// A special wrapper around the ProgressBar component which takes the currentStep
// and numSteps variables from the current location flow context.
export const FlowProgressBar: React.FC = () => {
  const { currentLocationIndex, locations } = useLocationFlowContext();

  return <ProgressBar currentStep={currentLocationIndex + 1} numSteps={locations.length} />;
};
