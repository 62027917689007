export enum CircularImageType {
  // Ancestry Informing Loop Images (in order of appearance)
  ANCESTRY_MAP_POINTS = 'ancestry-map-points',
  ANCESTRY_FACTORS = 'ancestry-factors',
  ANCESTRY_REPORT = 'ancestry-report',

  // GEM Informing Loop Images (in order of appearance)
  PEOPLE_CLIPBOARD = 'people-clipboard',
  TRAITS_CLIPBOARD = 'traits-clipboard',
  RADIO_BUTTONS_BLUE = 'radio-buttons-blue',
  EXCLAIMATION_POINT_DOCUMENT_BLUE = 'exclaimation-point-document-blue',
  STAR_DOCUMENT_BLUE = 'star-document-blue',
  MAGNIFYING_GLASS_DOCUMENT_BLUE = 'magnifying-glass-document-blue',
  CHECK_MARK_DOCUMENT_BLUE = 'check-mark-document-blue',
  GENES_PEOPLE = 'genes-people',

  // HDR Informing Loop Images (in order of appearance)
  DNA_CLIPBOARD_RED = 'dna-clipboard-red',
  HEART_AND_RIBBON = 'heart-and-ribbon',
  EXCLAIMATION_POINT_DOCUMENT_RED = 'exclaimation-point-document-red',
  INSURANCE_DOCUMENT = 'insurance-document',
  THINKING_PERSON = 'thinking-person',
  STAR_DOCUMENT_RED = 'star-document-red',
  MAGNIFYING_GLASS_DOCUMENT_RED = 'magnifying-glass-document-red',
  RADIO_BUTTONS_RED = 'radio-buttons-red',
  CHECK_MARK_DOCUMENT_RED = 'check-mark-document-red',
  GENES_FAMILY = 'genes-family',

  // PGX Informing Loop Images (in order of appearance)
  DRUGS_CLIPBOARD = 'drugs-clipboard',
  GENES_DRUGS = 'genes-drugs',
  // (the below are repeated images from the HDR Informing Loop,
  //  included here to make it easy to see which are used in the PGX Informing Loop)
  // EXCLAIMATION_POINT_DOCUMENT_RED
  // STAR_DOCUMENT_RED
  // MAGNIFYING_GLASS_DOCUMENT_RED
  // RADIO_BUTTONS_RED
  // CHECK_MARK_DOCUMENT_RED
  // GENES_DRUGS

  // HDR Pre Results Screen
  GENES_ENVELOPE = 'genes-envelope',
  MICROSCOPE = 'microscope',
  DOCTOR = 'doctor',
  // HEART_AND_RIBBON (duplicated from HDR IL)
  QUESTION_BUBBLE = 'question-bubble',

  // PGx Pre Results Screen
  DRUGS_ENVELOPE = 'drugs-envelope',
  // GENES_DRUGS (duplicated from PGx IL)
  // QUESTION_BUBBLE (duplicated from HDR Pre Results Screen)

  // HDR Uninformative Results
  GENETIC_COUNSELOR = 'genetic-counselor',
  GENES = 'genes',
  CLINICAL_TEST = 'clinical-test',
  FOOD = 'food',
  STEPS = 'steps',
  HEALTHY_LIFESTYLE = 'healthy-lifestyle',
  HEALTH_HISTORY = 'health-history',
  PILL_BOTTLE = 'pill-bottle',
  // EXCLAIMATION_POINT_DOCUMENT_RED
  // HEART_AND_RIBBON
  // MICROSCOPE

  // HDR Positive Results
  FAMILY = 'family',
  // EXCLAIMIATION_POINT_DOCUMENT_RED
  // SHARE PROVIDER
  HOSIPITAL_LOCATION = 'hosipital-location',
}
