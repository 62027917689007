import { ExternalLink, InternalButtonLink, Spacer, Text } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { config } from 'config';

import { HDR_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  hdrILYesPageTitle: {
    description: 'HDR Informing Loop Decision Page Title - Yes',
    defaultMessage: 'Decision - Yes',
  },
});

export const HdrInformingLoopDecisionYes: React.FC = () => {
  const { locale } = usePreferredLocale();
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.hdrILYesPageTitle)}
      module={{
        analyticsInformingLoopName: HDR_INFORMING_LOOP_NAME,
        analyticsPageName: '10. Yes',
        image: CircularImageType.GENES_FAMILY,
        title: <FormattedMessage id="HDR_IL_Yes__Title" />,
        content: (
          <>
            <Text bold paragraph>
              <FormattedMessage id="HDR_IL_Yes__Paragraph_1" />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="HDR informing loop yes decision screen paragraph 2"
                defaultMessage="These results may take a few weeks to a few months to generate. We will notify you when your results are ready. In the meantime, you can learn more about DNA and hereditary disease risk in the {learningCenterLink}."
                values={{
                  learningCenterLink: (
                    <ExternalLink to={config.LEARNING_CENTER_GENES_AND_HEALTH_URL[locale].href}>
                      <FormattedMessage
                        description="Learning center link text"
                        defaultMessage="Learning Center"
                      />
                    </ExternalLink>
                  ),
                }}
              />
            </Text>
            <Spacer variant="medium" />
            <InternalButtonLink to="/">
              <FormattedMessage
                defaultMessage="Back to DNA Results"
                description="HDR informing loop final page yes decision content back to results button"
              />
            </InternalButtonLink>
          </>
        ),
      }}
    />
  );
};
