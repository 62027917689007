import { Box, Container, Span } from '@color/continuum';
import { range } from 'lodash';
import { Fragment } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  progressBarLabel: {
    defaultMessage: 'Getting your results',
    description: 'Progress Bar label text for screen reader users',
  },
});

export interface ProgressBarProps {
  currentStep: number;
  numSteps: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = (props: ProgressBarProps) => {
  // Note: `current` is 1-indexed.
  const { currentStep, numSteps } = props;
  const intl = useIntl();
  return (
    <Container disableGutters maxWidth={false}>
      <Box
        display="flex"
        role="progressbar"
        aria-valuenow={currentStep}
        aria-valuemin={1}
        aria-valuemax={numSteps}
        aria-label={intl.formatMessage(messages.progressBarLabel)}
        sx={{
          backgroundColor: (theme) => theme.palette.grey[100],
        }}
      >
        {range(numSteps).map((step: number) => (
          <Fragment key={step}>
            <Box
              sx={{
                height: '8px',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  step < currentStep ? theme.palette.success.icon : undefined,
              }}
            />
            {step < numSteps - 1 && <Span width="4px" />}
          </Fragment>
        ))}
      </Box>
    </Container>
  );
};
