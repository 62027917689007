import { ExternalLink } from '@color/continuum';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { config } from 'config';
import { trackAnalyticsEvent } from 'lib/analytics';
import { AnalyticsPlatform, ModuleType, SegmentEventType } from 'lib/analytics/types';

const DOWNLOAD_GC_NOTE_ENDPOINT = `${config.API_HOST_URL}api/v1/aou_download_latest_gc_note_for_appointment`;

interface IViewNotesLinkProps {
  appointmentId: number;
  moduleType: ModuleType;
}

export const ViewNotesLink: FC<IViewNotesLinkProps> = (props) => {
  const { appointmentId, moduleType } = props;
  return (
    <ExternalLink
      underline="always"
      to={`${DOWNLOAD_GC_NOTE_ENDPOINT}?appointment=${appointmentId}`}
      onClick={() => {
        trackAnalyticsEvent(
          {
            eventType:
              moduleType === ModuleType.HDR
                ? SegmentEventType.HDR_GC_NOTE_DOWNLOADED
                : SegmentEventType.PGX_GC_NOTE_DOWNLOADED,
          },
          AnalyticsPlatform.SEGMENT
        );
      }}
    >
      <FormattedMessage id="Appt_View_Notes" />
    </ExternalLink>
  );
};
