import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

const messages = defineMessages({
  gemILModule3PageTitle: {
    description: 'GEM informing loop - module 3 page title',
    defaultMessage: 'Choices',
  },
});

export const GemInformingLoopModule3: React.FC = () => {
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.gemILModule3PageTitle)}
      module={{
        analyticsInformingLoopName: 'GEM Informing Loop',
        analyticsPageName: '3. Options',
        image: CircularImageType.RADIO_BUTTONS_BLUE,
        title: (
          <FormattedMessage
            description="GEM informing loop page 3 title"
            defaultMessage="What are my choices?"
          />
        ),
        content: (
          <FormattedMessage
            description="GEM informing loop page 3 content"
            defaultMessage="<p>It is your choice whether you want your genetic ancestry and trait results. The genetic ancestry results are separate from the results on each individual trait.</p>
            <p>If you say ‘Yes’:</p>
            <ul>
                <li>You will be able to view each of your trait results. There are separate results for each trait.</li>
                <li>We will give you a little more information about genetic ancestry results. Then you can decide if you want to see your results.</li>
            </ul>
            <p>You can also say ‘No’ now and change your mind later. We won’t generate your personalized DNA results until you tell us you want them. You will be able to decide separately if you want other types of DNA results, such as health-related results. We will send you messages when these results are available.</p>"
          />
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
