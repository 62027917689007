import { MuiThemeProviders, useMediaQuery, useTheme as useThemeV5 } from '@color/continuum';
// TODO Import from CDS rather than MUI. In CDS-341 we turned on lint warnings on direct MUI imports. This line is disabled because GP CI sets the rule to error instead of warn.
// Swap this for a CDS import instead of a MUI import.
// eslint-disable-next-line no-restricted-imports
import { createTheme as createThemeV5 } from '@mui/material';

import { DesktopFooter } from './DesktopFooter';
import { MobileFooter } from './MobileFooter';

export const Footer: React.FC = () => {
  const themeV5 = useThemeV5();
  const isMobile = useMediaQuery(themeV5.breakpoints.down('sm'));

  const footerThemeV5 = createThemeV5(themeV5, {
    /**
     * FIXME, move color overrides into palette object here.
     * The blue background would be `primary` color.
     */
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: themeV5.palette.primary.contrastText,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: themeV5.palette.common.white,
            textDecorationColor: 'none',
          },
        },
      },
    },
  });
  return (
    <MuiThemeProviders themeV5={footerThemeV5}>
      {isMobile ? <MobileFooter /> : <DesktopFooter />}
    </MuiThemeProviders>
  );
};
