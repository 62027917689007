import { HdrV1Gene } from '@color/aou-reports';
import { CommonDialog, DialogContent, IDialogProps, Spacer, Text } from '@color/continuum';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { EmailRelativesButton } from '../EmailRelativesButton';

interface IShareResultsWithFamilyDialogProps extends Omit<IDialogProps, 'title' | 'actions'> {
  // This is the specific gene we mention in the letter.
  gene: HdrV1Gene;
}

export const ShareResultsWithFamilyDialog: FC<IShareResultsWithFamilyDialogProps> = (props) => {
  const { gene, ...dialogProps } = props;
  return (
    <CommonDialog
      title={<FormattedMessage id="HDR_Family_Letter_Header1" />}
      actions={
        <>
          <Text variant="body2">
            <FormattedMessage id="HDR_Positive_Next_Steps__Talk_With_Family_P5" />
          </Text>
          <EmailRelativesButton gene={gene} />
        </>
      }
      isFullScreenOnMobile={false}
      {...dialogProps}
    >
      <DialogContent>
        <Text>
          <FormattedMessage id="HDR_Family_Letter_Salutation" />
        </Text>
        <Spacer variant="medium" />
        <Text paragraph>
          <FormattedMessage id="HDR_Family_Letter_P1" />
        </Text>
        <Text paragraph>
          <FormattedMessage id="HDR_Family_Letter_P2" values={{ gene }} />
        </Text>
        <Spacer variant="medium" />
        <Text bold>
          <FormattedMessage id="HDR_Family_Letter_Header2" />
        </Text>
        <Spacer variant="medium" />
        <Text paragraph>
          <FormattedMessage id="HDR_Family_Letter_P3" />
        </Text>
        <Text paragraph>
          <FormattedMessage id="HDR_Family_Letter_P4" />
        </Text>
      </DialogContent>
    </CommonDialog>
  );
};
