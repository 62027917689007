import { Box, InlineSpacer, Spacer, Text, Title, useMediaQuery, useTheme } from '@color/continuum';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export interface InfoCarouselContentProps {
  content: ReactNode;
}
export const InfoCarouselContent: React.FC<InfoCarouselContentProps> = (
  props: InfoCarouselContentProps
) => {
  const { content } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box paddingX={isMobile ? 5 : 8}>
      {isMobile && <InlineSpacer variant="xxxLarge" />}
      <Title variant="headline3">
        <FormattedMessage
          defaultMessage="Did you know?"
          description="Ancestry info carousel title"
        />
      </Title>
      <Spacer variant="small" />
      <Text>{content}</Text>
      {isMobile && <InlineSpacer variant="xxxLarge" />}
    </Box>
  );
};
