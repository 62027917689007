import { Helmet } from 'react-helmet-async';

interface MetaProps {
  name: string;
  content: string;
}

interface Props {
  helmetTitle: string;
  metaProps?: Array<MetaProps>;
  children?: React.ReactNode;
}

export const SimplePage: React.FC<Props> = (props) => {
  const { helmetTitle, metaProps, children } = props;

  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
        {metaProps && metaProps.map((metaProp) => <meta key={metaProp.name} {...metaProp} />)}
      </Helmet>
      {children}
    </>
  );
};
