import { BaseSelectProps, FormHelperText, TextField, useTheme } from '@color/continuum';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReportProblemIcon from '@mui/icons-material/ReportProblemOutlined';
import { useField } from 'formik';
import { ReactNode } from 'react';

export interface IFormikSelectFieldProps extends BaseSelectProps {
  name: string;
  label: ReactNode;
  children: React.ReactNode;
}

export const FormikSelectField: React.FC<IFormikSelectFieldProps> = (props) => {
  const { name, label, children, required } = props;
  const [field, meta] = useField(name);
  const errorMessage = meta.touched ? meta.error : undefined;

  const theme = useTheme();
  return (
    <>
      <TextField
        sx={{
          textAlign: 'left',
          label: { color: errorMessage ? `${theme.palette.error.text} !important` : null },
          fieldset: { borderColor: errorMessage ? `${theme.palette.error.text} !important` : null },
        }}
        id={name}
        select
        label={label}
        optionalIndicatorIsEnabled={false}
        required={required}
        SelectProps={{
          MenuProps: {
            sx: { maxHeight: '40%' },
          },
          IconComponent: () =>
            errorMessage ? (
              <ReportProblemIcon color="error" sx={{ marginRight: 1.5 }} />
            ) : (
              <KeyboardArrowDownIcon
                color={errorMessage ? 'error' : 'action'}
                sx={{ marginRight: 1.5 }}
              />
            ),
        }}
        {...field}
      >
        {children}
      </TextField>
      {errorMessage && <FormHelperText sx={{ color: 'error.text' }}>{errorMessage}</FormHelperText>}
    </>
  );
};
