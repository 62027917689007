import { Container, Title } from '@color/continuum';

import { PasswordLoginForm } from './PasswordLoginForm';

export const PasswordLogin: React.FC = () => {
  return (
    <Container>
      <Title>Password Login</Title>
      <PasswordLoginForm />
    </Container>
  );
};
