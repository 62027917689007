import {
  Box,
  Button,
  FormFieldSection,
  FormGridContainer,
  FormikForm,
  FormikFormLayout,
  FormikSubmitButton,
  FormikTextField,
  Grid,
  InnerPageContainer,
  SimpleTooltip,
  Text,
  useMediaQuery,
  useTheme,
} from '@color/continuum';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { CloseIcon } from 'components/Appointments/CloseIcon';
import { CircularImageType } from 'components/common/CircularImage/types';
import { getCircularImageSrc } from 'components/common/CircularImage/utils';
import { FormikStateField } from 'components/common/Forms/FormikStateField/FormikStateField';
import { InformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooter/InformingLoopFooter';
import { InformingLoopQuestionsSection } from 'components/common/InformingLoop/InformingLoopQuestionsSection/InformingLoopQuestionsSection';

interface Props {
  onCancel: () => void;
}

export const FormFields: FC<Props> = (props) => {
  const { onCancel } = props;
  const imageUrl = getCircularImageSrc(CircularImageType.HOSIPITAL_LOCATION);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const intl = useIntl();
  return (
    <FormikForm>
      <Box sx={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
        <Box sx={{ flexGrow: 0 }}>
          <Box display="flex" justifyContent="flex-end">
            <CloseIcon aria-label="Cancel" onClick={onCancel} />
          </Box>
          <InnerPageContainer>
            <Grid container spacing={4}>
              <Grid item md={8}>
                <FormikFormLayout
                  titleContent={<FormattedMessage id="HDR_Fax_Modal_Title" />}
                  subtitleContent={
                    <Text>
                      <FormattedMessage id="HDR_Fax_Modal_P1" />{' '}
                      <SimpleTooltip
                        content={<FormattedMessage id="HDR_Fax_Modal_Tooltip" />}
                        aria-label="Why do we fax Results?"
                      />
                    </Text>
                  }
                >
                  <FormFieldSection titleContent={<FormattedMessage id="HDR_Fax_Modal_Header1" />}>
                    <FormGridContainer>
                      <Grid item sm={6} xs={12}>
                        <FormikTextField
                          name="firstName"
                          type="text"
                          label={<FormattedMessage id="HDR_Fax_Modal_First_Name" />}
                          required
                          requiredIndicatorIsEnabled
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <FormikTextField
                          name="lastName"
                          type="text"
                          label={<FormattedMessage id="HDR_Fax_Modal_Last_Name" />}
                          required
                          requiredIndicatorIsEnabled
                        />
                      </Grid>
                    </FormGridContainer>
                  </FormFieldSection>

                  <FormFieldSection titleContent={<FormattedMessage id="HDR_Fax_Modal_Header2" />}>
                    <FormGridContainer>
                      <Grid item sm={6} xs={12}>
                        <FormikTextField
                          name="phoneNumber"
                          type="text"
                          label={<FormattedMessage id="HDR_Fax_Modal_Phone" />}
                          required
                          requiredIndicatorIsEnabled
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <FormikTextField
                          name="faxNumber"
                          type="text"
                          label={<FormattedMessage id="HDR_Fax_Modal_Fax" />}
                          required
                          requiredIndicatorIsEnabled
                        />
                      </Grid>
                    </FormGridContainer>
                  </FormFieldSection>

                  <FormFieldSection titleContent={<FormattedMessage id="HDR_Fax_Modal_Header3" />}>
                    <FormGridContainer>
                      <Grid item sm={6} xs={12}>
                        <FormikTextField
                          name="city"
                          type="text"
                          label={<FormattedMessage id="HDR_Fax_Modal_City" />}
                          required
                          requiredIndicatorIsEnabled
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <FormikStateField
                          name="state"
                          label={intl.formatMessage({ id: 'HDR_Fax_Modal_State' })}
                          required
                        />
                      </Grid>
                    </FormGridContainer>
                  </FormFieldSection>
                </FormikFormLayout>{' '}
              </Grid>
              {!isMobile && (
                <Grid item md={4}>
                  <img src={imageUrl} alt="" />
                </Grid>
              )}
            </Grid>
          </InnerPageContainer>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 0 }}>
          <InformingLoopQuestionsSection />
          <InformingLoopFooter
            leftButton={
              <Button variant="color-secondary" onClick={onCancel}>
                <FormattedMessage id="HDR_Fax_Modal_Cancel_Button" />
              </Button>
            }
            rightButton={
              <FormikSubmitButton errorMessageIsVisible={false} isLoading={false}>
                <FormattedMessage id="HDR_Fax_Modal_Fax_Button" />
              </FormikSubmitButton>
            }
          />
        </Box>
      </Box>
    </FormikForm>
  );
};
