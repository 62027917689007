import { Spacer, Text } from '@color/continuum';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

import { HDR_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  hdrILModule3PageTitle: {
    description: 'HDR Informing Loop Page Title - Module 3',
    defaultMessage: 'Risks',
  },
});

export const HdrInformingLoopModule3: React.FC = () => {
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.hdrILModule3PageTitle)}
      module={{
        analyticsInformingLoopName: HDR_INFORMING_LOOP_NAME,
        analyticsPageName: '3. Risks',
        image: CircularImageType.EXCLAIMATION_POINT_DOCUMENT_RED,
        title: <FormattedMessage id="HDR_IL_3__Title" />,
        content: (
          <>
            <Text paragraph>
              <FormattedMessage
                description="HDR informing loop page 3 content paragraph 1"
                defaultMessage="When you signed the Consent to Get DNA Results, you learned that there are different kinds of risks to learning about your DNA. The next three screens will review some that are important to remember for these results."
              />
            </Text>
            <Text>
              <ul>
                <li>
                  <FormattedMessage
                    description="HDR informing loop page 3 content bullet 1"
                    defaultMessage="These are research results. Only clinical results can be used directly in medical care."
                  />
                </li>
                <Spacer variant="small" />
                <ul>
                  <li>
                    <FormattedMessage
                      description="HDR informing loop page 3 content bullet 1.1"
                      defaultMessage="If your results show an increased risk of developing a serious health condition, you will be given the option to confirm the result with a clinical DNA test."
                    />
                  </li>
                  <Spacer variant="xSmall" />
                  <li>
                    <FormattedMessage
                      description="HDR informing loop page 3 content bullet 1.2"
                      defaultMessage="If a clinical DNA test confirms the result, your doctor or health care provider may recommend changes to your medical care. Those changes could cost more than your current care. You can decide what care is right for you."
                    />
                  </li>
                </ul>
              </ul>
            </Text>
          </>
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
