import { useAouReportsMessages } from '@color/aou-reports';
import { Box, Text, useComponentsMessages } from '@color/continuum';
import { Locale, usePreferredLocale } from '@color/lib';
import { ReactNode, createElement } from 'react';
import { IntlProvider } from 'react-intl';

import en from 'translations/compiled-lang/en.json';
import es from 'translations/compiled-lang/es.json';

export const SUPPORTED_HTML_TAGS = [
  'b',
  'i',
  'ul',
  'li',
  'ol',
  'p',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'sup',
  'em',
  'strong',
];

const HTML_TAG_OVERRIDES: { [tag: string]: (chunks: ReactNode) => JSX.Element } = {
  p: (chunks) => <Text paragraph>{chunks}</Text>,
  li: (chunks) => (
    <Box
      component="li"
      sx={{
        marginBottom: 1,
      }}
    >
      <Text>{chunks}</Text>
    </Box>
  ),
};

const HTML_TAG_VALUES = SUPPORTED_HTML_TAGS.reduce((htmlValues, tag) => {
  const defaultElement = (chunks: ReactNode) => createElement(tag, {}, chunks);
  return { ...htmlValues, [tag]: HTML_TAG_OVERRIDES[tag] || defaultElement };
}, {});

interface Props {
  children?: React.ReactNode;
}

export const AppIntlProvider: React.FC<Props> = (props) => {
  const { children } = props;
  const { locale } = usePreferredLocale();
  const baseMessages = locale === Locale.SPANISH ? es : en;
  const componentsMessages = useComponentsMessages({ locale });
  const aouReportsMessages = useAouReportsMessages({ locale });

  const mergedMessages: Record<string, string> = {
    ...componentsMessages,
    ...baseMessages,
    ...aouReportsMessages,
  };

  return (
    <IntlProvider
      locale={locale}
      messages={mergedMessages}
      defaultRichTextElements={HTML_TAG_VALUES}
    >
      {children}
    </IntlProvider>
  );
};
