import { CircularProgress } from '@color/continuum';
// TODO Import from CDS rather than MUI. In CDS-341 we turned on lint warnings on direct MUI imports. This line is disabled because GP CI sets the rule to error instead of warn.
// Swap this for a CDS import instead of a MUI import.
// eslint-disable-next-line no-restricted-imports
import { Button, ButtonProps } from '@mui/material';
import { FormikErrors } from 'formik';

interface Props extends ButtonProps {
  isLoading: boolean;
  formIsValid?: boolean;
  errors?: FormikErrors<any>;
}

export const SubmitButton: React.FC<Props> = (props) => {
  const { isLoading, children, formIsValid, ...buttonProps } = props;

  return (
    <Button
      variant="contained"
      color="secondary"
      size="large"
      type="submit"
      disabled={!formIsValid}
      endIcon={isLoading ? <CircularProgress color="inherit" size="1em" /> : undefined}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};
