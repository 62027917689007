import {
  PGXV1_GENE_TO_MEDICINES,
  PgxV1Gene,
  PgxV1VariantDetails,
  getDnaAndMedicineTableContent,
} from '@color/aou-reports';
import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  ImageDivider,
  ListItem,
  OrderedList,
  Spacer,
  Span,
  Text,
  Title,
  UnorderedList,
} from '@color/continuum';
import { Fragment, ReactNode, RefObject } from 'react';
import { FormattedMessage } from 'react-intl';

import DnaBanner from 'assets/images/dna-banner.svg';
import { CircularImageType } from 'components/common/CircularImage/types';
import { ResultBanner, ResultBannerIconType } from 'components/common/ResultBanner';
import { ImageWithContentSection } from 'components/HealthRelatedResults/components/ImageWithContentSection/ImageWithContentSection';

import { DnaAndMedicineTable } from './DnaAndMedicineTable';
import { PgxGeneInfoCard } from './PgxGeneInfoCard/PgxGeneInfoCard';

interface IYourResultsProps {
  variantDetails: PgxV1VariantDetails[];
  dnaAndMedicineRef: RefObject<HTMLSpanElement>;
}

export const YourResults: React.FC<IYourResultsProps> = (props) => {
  const { variantDetails, dnaAndMedicineRef } = props;
  let dnaAndMedicineTableContent: Map<PgxV1Gene, ReactNode[]> | null = null;

  dnaAndMedicineTableContent = getDnaAndMedicineTableContent(
    variantDetails.map(({ gene, phenotype }) => [gene, phenotype])
  );

  if (dnaAndMedicineTableContent === null) {
    dnaAndMedicineTableContent = new Map<PgxV1Gene, ReactNode[]>(
      variantDetails.map(({ gene }) => [gene, PGXV1_GENE_TO_MEDICINES[gene]])
    );
  }
  return (
    <>
      <Spacer variant="xxLarge" />
      <Container>
        <Title variant="headline2">
          <FormattedMessage id="PGX__Results_Before_Results_Subsection_Title" />
        </Title>
        <Spacer variant="medium" />
        <Text paragraph>
          <FormattedMessage id="PGX__Results_Before_Results_Paragraph_1" />
        </Text>

        <Spacer variant="xxLarge" />
        <Divider aria-hidden="true" />
        <Spacer variant="xxLarge" />

        <ImageWithContentSection
          title={<FormattedMessage id="PGX__Results_Genes_Affect_Subsection_Title" />}
          description={
            <UnorderedList>
              <ListItem>
                <Text>
                  <FormattedMessage id="PGX__Results_Genes_Affect_Bullet_1" />
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <FormattedMessage id="PGX__Results_Genes_Affect_Bullet_2" />
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <FormattedMessage id="PGX__Results_Genes_Affect_Bullet_3" />
                </Text>
              </ListItem>
            </UnorderedList>
          }
          image={CircularImageType.GENES_DRUGS}
          imageRight
        />

        <Spacer variant="xxLarge" />
        <Divider aria-hidden="true" />
        <Spacer variant="xxLarge" />

        <ImageWithContentSection
          title={<FormattedMessage id="PGX__Results_Information_Used_Subsection_Title" />}
          description={
            <>
              <Text paragraph>
                <FormattedMessage id="PGX__Results_Information_Used_Paragraph_1" />
              </Text>
              <Text paragraph>
                <FormattedMessage id="PGX__Results_Information_Used_Paragraph_2" />
              </Text>
            </>
          }
          image={CircularImageType.CLINICAL_TEST}
        />
      </Container>
      <Spacer variant="xxxLarge" />
      <ImageDivider imageSrc={DnaBanner} />
      <Spacer variant="xxxLarge" />
      <Container>
        <ImageWithContentSection
          title={<FormattedMessage id="PGX__Results_Important_Subsection_Title" />}
          description={
            <UnorderedList>
              <ListItem>
                <Text>
                  <FormattedMessage id="PGX__Results_Important_Bullet_1" />
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <FormattedMessage id="PGX__Results_Important_Bullet_2" />
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <FormattedMessage id="PGX__Results_Important_Bullet_3" />
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <FormattedMessage id="PGX__Results_Important_Bullet_4" />
                </Text>
              </ListItem>
            </UnorderedList>
          }
          image={CircularImageType.EXCLAIMATION_POINT_DOCUMENT_RED}
          imageRight
        />
        <Spacer variant="xxxLarge" />
        <ImageWithContentSection
          title={<FormattedMessage id="PGX__Results_Understanding_Report_Section_Title" />}
          description={
            <>
              <Text paragraph>
                <FormattedMessage id="PGX__Results_Understanding_Report_Paragraph_1" />
              </Text>
              <OrderedList>
                <ListItem>
                  <Text>
                    <FormattedMessage id="PGX__Results_Understanding_Report_Bullet_1" />
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    <FormattedMessage id="PGX__Results_Understanding_Report_Bullet_2" />
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    <FormattedMessage id="PGX__Results_Understanding_Report_Bullet_3" />
                  </Text>
                </ListItem>
              </OrderedList>
            </>
          }
          image={CircularImageType.MICROSCOPE}
        />
      </Container>
      <Spacer variant="xxxLarge" />
      <ResultBanner
        icon={ResultBannerIconType.DRUGS_CLIPBOARD}
        title={<FormattedMessage id="PGX__Genetic_Result_Banner_Title" />}
        subtitle={<FormattedMessage id="PGX__Genetic_Result_Banner_Subtitle" />}
      />
      <Spacer variant="xxLarge" />
      <Container>
        <Title variant="headline2">
          <FormattedMessage id="PGX__How_To_Read_Results_Subsection_Title" />
        </Title>
        <Spacer variant="medium" />
        <Text paragraph>
          <FormattedMessage id="PGX__How_To_Read_Results_Paragraph_1" />
        </Text>
        <Card>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                backgroundColor: (theme) => theme.palette.primary[100],
              }}
            >
              <Box p={4}>
                <Title variant="headline4">
                  1. <FormattedMessage id="PGX__Gene_Label" />
                </Title>
                <Spacer variant="medium" />
                <Text paragraph>
                  <FormattedMessage id="PGX__How_To_Read_Gene" />
                </Text>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box p={4}>
                <Title variant="headline4">
                  2. <FormattedMessage id="PGX__Version_Label" />
                </Title>
                <Spacer variant="medium" />
                <Text paragraph>
                  <FormattedMessage id="PGX__How_To_Read_Version" />
                </Text>
                <Title variant="headline4">
                  3. <FormattedMessage id="PGX__What_It_Means_Label" />
                </Title>
                <Spacer variant="medium" />
                <Text paragraph>
                  <FormattedMessage id="PGX__How_To_Read_What_It_Means" />
                </Text>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Container>
      <Spacer variant="xxxLarge" />
      <Container>
        <Title variant="headline2">
          <FormattedMessage id="PGX_Your_Results__Your_Results_Title" />
        </Title>
        <Spacer variant="medium" />
        {variantDetails
          .sort((a, b) => {
            const gene1 = a.gene.toLowerCase();
            const gene2 = b.gene.toLowerCase();
            if (gene1 < gene2) {
              return -1;
            }
            if (gene1 > gene2) {
              return 1;
            }
            return 0;
          })
          .map((variantDetail) => (
            <Fragment key={variantDetail.gene}>
              <PgxGeneInfoCard
                {...variantDetail}
                hasDnaMedicine={
                  dnaAndMedicineTableContent
                    ? dnaAndMedicineTableContent.has(variantDetail.gene)
                    : false
                }
              />
              <Spacer variant="medium" />
            </Fragment>
          ))}
      </Container>
      <Spacer variant="xxxLarge" />
      <Span
        aria-hidden
        ref={dnaAndMedicineRef}
        sx={{
          // Make sure when we scroll to this span, there is a margin the size
          // of the nav bar so it doesn't get covered.
          scrollMarginTop: '64px',
        }}
      />
      <ResultBanner
        icon={ResultBannerIconType.DRUGS}
        title={<FormattedMessage id="PGX__Medicine_Result_Banner_Title" />}
        subtitle={<FormattedMessage id="PGX__Medicine_Result_Banner_Subtitle" />}
      />
      <Spacer variant="xxxLarge" />
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Title variant="headline2">
              <FormattedMessage id="PGX_This_Table_Points_Out_Header" />
            </Title>
            <Spacer variant="medium" />
            <Text paragraph>
              <FormattedMessage id="PGX_This_Table_Points_Out_P1" />
            </Text>
            <Title variant="headline3">
              <FormattedMessage id="PGX_These_Medicines_Header" />
            </Title>
            <Spacer variant="medium" />
            <Text paragraph>
              <FormattedMessage id="PGX_These_Medicines_P1" />
            </Text>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DnaAndMedicineTable variantDetails={variantDetails} />
          </Grid>
        </Grid>

        <Spacer variant="xxLarge" />
        <Divider aria-hidden="true" />
        <Spacer variant="xxLarge" />

        <ImageWithContentSection
          title={<FormattedMessage id="PGX__Important_Genetic_Information_Subsection_Title" />}
          description={
            <>
              <UnorderedList>
                <ListItem>
                  <Text>
                    <FormattedMessage id="PGX__Important_Genetic_Information_Bullet_1" />
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    <FormattedMessage id="PGX__Important_Genetic_Information_Bullet_2" />
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    <FormattedMessage id="PGX__Important_Genetic_Information_Bullet_3" />
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    <FormattedMessage id="PGX__Important_Genetic_Information_Bullet_4" />
                  </Text>
                </ListItem>
              </UnorderedList>
              <Spacer variant="medium" />
              <Text paragraph>
                <FormattedMessage id="PGX__Important_Genetic_Information_Paragraph_1" />
              </Text>
            </>
          }
          image={CircularImageType.EXCLAIMATION_POINT_DOCUMENT_RED}
          imageRight
        />
      </Container>
    </>
  );
};
