import {
  Box,
  Container,
  ExternalButtonLink,
  ExternalLink,
  Spacer,
  Text,
  Title,
  useMediaQuery,
  useTheme,
} from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { BreadcrumbsBar } from 'components/common/BreadcrumbsBar';
import { useConfigForIdp } from 'hooks/useConfigForIdp';
import { FOOTER_EMAIL_ADDRESS, FOOTER_PHONE_NUMBER } from 'lib/constants';

export const GemAuthError: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { MY_DATA_URL } = useConfigForIdp();

  return (
    <>
      <BreadcrumbsBar />
      <Container>
        {isMobile ? (
          <>
            <Spacer variant="xxxLarge" />
            <Spacer variant="xLarge" />
          </>
        ) : (
          <Spacer variant="large" />
        )}
        <Box alignItems="center" display="flex" flexDirection="column">
          <Title align="center">
            <FormattedMessage
              description="Title of page shown when there is an issue during authentication"
              defaultMessage="We're having trouble loading your DNA results."
            />
          </Title>
          <Spacer variant="medium" />
          <Text align="center">
            <FormattedMessage
              description="Content of page shown when there is an issue during authentication"
              defaultMessage="Please check back later. You can also contact support at {supportTelephone} or {supportEmail}."
              values={{
                supportTelephone: (
                  <ExternalLink to={`tel:${FOOTER_PHONE_NUMBER}`}>
                    {FOOTER_PHONE_NUMBER}
                  </ExternalLink>
                ),
                supportEmail: (
                  <ExternalLink to={`mailto:${FOOTER_EMAIL_ADDRESS}`}>
                    {FOOTER_EMAIL_ADDRESS}
                  </ExternalLink>
                ),
              }}
            />
          </Text>
          <Spacer variant="xxLarge" />
          <ExternalButtonLink to={MY_DATA_URL.href} linkShouldOpenInCurrentTab>
            <FormattedMessage
              description="Text on button that takes user back to participant portal dashboard"
              defaultMessage="Return to My Data"
            />
          </ExternalButtonLink>
        </Box>
      </Container>
    </>
  );
};
