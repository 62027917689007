import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

const messages = defineMessages({
  gemILModule6PageTitle: {
    description: 'GEM informing loop - module 6 page title',
    defaultMessage: 'Limits',
  },
});

export const GemInformingLoopModule6: React.FC = () => {
  const intl = useIntl();
  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.gemILModule6PageTitle)}
      module={{
        analyticsInformingLoopName: 'GEM Informing Loop',
        analyticsPageName: '6. Limitations',
        image: CircularImageType.MAGNIFYING_GLASS_DOCUMENT_BLUE,
        title: (
          <FormattedMessage
            description="GEM informing loop page 6 title"
            defaultMessage="What are the limits of genetic ancestry and trait results from <i>All of Us</i>?"
          />
        ),
        content: (
          <FormattedMessage
            description="GEM informing loop page 6 content"
            defaultMessage="<ol>
            <li>Genetic ancestry and trait results are meant to be educational and interesting. These results are <b>not medical advice or meant to affect your health care.</b></li>
            <li>These results are based on current scientific understanding. As we learn more information, we will look at your DNA again and update your genetic ancestry and trait results.</li>
            <li>Your genetic ancestry and trait results will not tell you how you are related to anyone else.</li>
            <li>For some people, the results won't match your actual experience. The specific genes in your DNA that we look at can have a big impact on your traits. But they are not the full story. Your lifestyle, environment, and other genes in your DNA can also affect your traits.</li>
            <li>Your family’s personal stories, history, and cultural or religious beliefs may not be the same as what you see in your genetic ancestry results. That doesn’t mean what you know about your ancestry is wrong. There is more to your ancestry than what we can learn from your DNA.</li>
            <li>Your genetic ancestry results will not tell you if you belong to specific groups, tribes, or nations. It also will not give you rights as part of any group, tribe, or nation. Your results can only tell you where your ancestors might have lived in the last few hundred years.</li>
            </ol>"
          />
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
