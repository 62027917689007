import { ExternalLink } from '@color/continuum';
import { ReactNode } from 'react';

import { Trait } from '../../types';

const content: Record<Trait, ReactNode[]> = {
  [Trait.BITTERNESS]: [
    'Wiener A, Shudler M, Levit A, Niv MY. BitterDB: a database of bitter compounds. Nucleic Acids Res. 2012;40(Database issue):D413-D419.',
    'Kim U-K, Jorgenson E, Coon H, Leppert M, Risch N, Drayna D. Positional cloning of the human quantitative trait locus underlying taste sensitivity to phenylthiocarbamide. Science. 2003;299(5610):1221-1225.',
    'Khataan NH, Stewart L, Brenner DM, Cornelis MC, El-Sohemy A. TAS2R38 genotypes and phenylthiocarbamide bitter taste perception in a population of young adults. J Nutrigenet Nutrigenomics. 2009;2(4-5):251-256.',
    'Whissell-Buechy D. Effects of age and sex on taste sensitivity to phenylthiocarbamide (PTC) in the Berkeley Guidance sample. Chem Senses. 1990;15(1):39-57.',
    'Fischer ME, Cruickshanks KJ, Pankow JS, et al. The associations between 6-n-propylthiouracil (PROP) intensity and taste intensities differ by TAS2R38 haplotype. J Nutrigenet Nutrigenomics. 2014;7(3):143-152.',
    'Drewnowski A, Gomez-Carneros C. Bitter taste, phytonutrients, and the consumer: a review. Am J Clin Nutr. 2000;72:1424-1435.',
    <>
      Vegetables and Fruits. The Nutrition Source.{' '}
      <ExternalLink to="https://www.hsph.harvard.edu/nutritionsource/what-should-you-eat/vegetables-and-fruits/">
        https://www.hsph.harvard.edu/nutritionsource/what-should-you-eat/vegetables-and-fruits/
      </ExternalLink>
      . Published September 18, 2012. Accessed August 13, 2018.
    </>,
    'Christensen J, Ladefoged AM, Nørgaard L. Rapid Determination of Bitterness in Beer Using Fluorescence Spectroscopy and Chemometrics. J Inst Brew. 2005;111(1):3-10.',
    'Benard M. Determination of Repeatability and Reproducibility of EBC Accepted Methods: V - Beer. J Inst Brew. 2000;106(3):135-138.',
    'Bufe B, Breslin PAS, Kuhn C, et al. The molecular basis of individual differences in phenylthiocarbamide and propylthiouracil bitterness perception. Curr Biol. 2005;15(4):322-327.',
    'Kim UK, Drayna D. Genetics of individual differences in bitter taste perception: lessons from the PTC gene. Clin Genet. 2005;67(4):275-280.',
    'Risso DS, Mezzavilla M, Pagani L, et al. Global diversity in the TAS2R38 bitter taste receptor: revisiting a classic evolutionary PROPosal. Sci Rep. 2016;6:25506.',
    'Wooding S, Kim U-K, Bamshad MJ, Larsen J, Jorde LB, Drayna D. Natural selection and molecular evolution in PTC, a bitter-taste receptor gene. Am J Hum Genet. 2004;74(4):637-646.',
    'Hayes JE, Bartoshuk LM, Kidd JR, Duffy VB. Supertasting and PROP bitterness depends on more than the TAS2R38 gene. Chem Senses. 2008;33(3):255-265.',
  ],
  [Trait.CILANTRO]: [
    'Knaapila A, Hwang L-D, Lysenko A, et al. Genetic analysis of chemosensory traits in human twins. Chem Senses. 2012;37(9):869-881.',
    'Eriksson N, Wu S, Do CB, et al. A genetic variant near olfactory receptor genes influences cilantro preference. Flavour. 2012;1(1):22.',
    'Lek M, Karczewski KJ, Minikel EV, et al. Analysis of protein-coding genetic variation in 60,706 humans. Nature. 2016;536(7616):285-291.',
    'Axelson ML. The impact of culture on food-related behavior. Annu Rev Nutr. 1986;6:345-363.',
    'Mauer L, El-Sohemy A. Prevalence of cilantro (Coriandrum sativum) disliking among different ethnocultural groups. Flavour. 2012;1(1):8.',
    'Buck L, Axel R. A novel multigene family may encode odorant receptors: a molecular basis for odor recognition. Cell. 1991;65(1):175-187.',
    'Niimura Y. Evolutionary dynamics of olfactory receptor genes in chordates: interaction between environments and genomic contents. Hum Genomics. 2009;4(2):107-118.',
    'Kobayashi M. Functional Organization of the Human Gustatory Cortex. J Oral Biosci. 2006;48(4):244-260.',
    'Mennella JA, Forestell CA, Morgan LK, Beauchamp GK. Early milk feeding influences taste acceptance and liking during infancy. Am J Clin Nutr. 2009;90(3):780S - 788S.',
    'Mennella JA, Jagnow CP, Beauchamp GK. Prenatal and postnatal flavor learning by human infants. Pediatrics. 2001;107(6):E88.',
    'Beauchamp GK, Mennella JA. Flavor perception in human infants: development and functional significance. Digestion. 2011;83 Suppl 1:1-6.',
    'Beauchamp GK, Mennella JA. Early flavor learning and its impact on later feeding behavior. J Pediatr Gastroenterol Nutr. 2009;48 Suppl 1:S25-S30.',
    'To Quynh CT, Iijima Y, Kubota K. Influence of the isolation procedure on coriander leaf volatiles with some correlation to the enzymatic activity. J Agric Food Chem. 2010;58(2):1093-1099.',
  ],
  [Trait.EARWAX]: [
    'Schwaab M, Gurr A, Neumann A, Dazert S, Minovi A. Human antimicrobial proteins in ear wax. Eur J Clin Microbiol Infect Dis. 2011;30(8):997-1004.',
    'Baumann T, Bergmann S, Schmidt-Rose T, et al. Glutathione-conjugated sulfanylalkanols are substrates for ABCC11 and γ-glutamyl transferase 1: a potential new pathway for the formation of odorant precursors in the apocrine sweat gland. Exp Dermatol. 2014;23(4):247-252.',
    'Martin A, Saathoff M, Kuhn F, Max H, Terstegen L, Natsch A. A functional ABCC11 allele is essential in the biochemical formation of human axillary odor. J Invest Dermatol. 2010;130(2):529-540.',
    'Prokop-Prigge KA, Greene K, Varallo L, Wysocki CJ, Preti G. The Effect of Ethnicity on Human Axillary Odorant Production. J Chem Ecol. 2016;42(1):33-39.',
    'Nakano M, Miwa N, Hirano A, Yoshiura K-I, Niikawa N. A strong association of axillary osmidrosis with the wet earwax type determined by genotyping of the ABCC11 gene. BMC Genet. 2009;10:42.',
    'Prokop-Prigge KA, Thaler E, Wysocki CJ, Preti G. Identification of volatile organic compounds in human cerumen. J Chromatogr B Analyt Technol Biomed Life Sci. 2014;953-954:48-52.',
    'Prokop-Prigge KA, Mansfield CJ, Parker MR, et al. Ethnic/racial and genetic influences on cerumen odorant profiles. J Chem Ecol. 2015;41(1):67-74.',
    'Trumble SJ, Robinson EM, Berman-Kowalewski M, Potter CW, Usenko S. Blue whale earplug reveals lifetime contaminant exposure and hormone profiles. Proc Natl Acad Sci U S A. 2013;110(42):16922-16926.',
    <>
      Earwax and Care. American Academy of Otolaryngology-Head and Neck Surgery.{' '}
      <ExternalLink to="https://www.entnet.org//content/earwax-and-care">
        https://www.entnet.org//content/earwax-and-care
      </ExternalLink>
      . Published April 17, 2014. Accessed July 16, 2018.
    </>,
    <>
      Office of the Commissioner. Consumer Updates - Don’t Get Burned: Stay Away From Ear Candles.{' '}
      <ExternalLink to="<ExternalLink>">
        https://www.fda.gov/forconsumers/consumerupdates/ucm200277.htm
      </ExternalLink>
      . Accessed July 16, 2018.
    </>,
    'Yoshiura K-I, Kinoshita A, Ishida T, et al. A SNP in the ABCC11 gene is the determinant of human earwax type. Nat Genet. 2006;38(3):324-330.',
    'Matsunaga E. The dimorphism in human normal cerumen. Ann Hum Genet. 1962;25:273-286.',
    'Petrakis NL, Pingle U, Petrakis SJ, Petrakis SL. Evidence for a genetic cline in earwax types in the Middle East and Southeast Asia. Am J Phys Anthropol. 1971;35(1):141-144.',
    'Sato T, Amano T, Ono H, et al. Allele frequencies of the ABCC11 gene for earwax phenotypes among ancient populations of Hokkaido, Japan. J Hum Genet. 2009;54(7):409-413.',
    'Petrakis NL, Molohon KT, Tepper DJ. Cerumen in American Indians: genetic implications of sticky and dry types. Science. 1967;158(3805):1192-1193.',
    'Tomita H, Yamada K, Ghadami M, et al. Mapping of the wet/dry earwax locus to the pericentromeric region of chromosome 16. Lancet. 2002;359(9322):2000-2002.',
  ],
  [Trait.LACTOSE]: [
    'Mattar R, de Campos Mazo DF, Carrilho FJ. Lactose intolerance: diagnosis, genetic, and clinical factors. Clin Exp Gastroenterol. 2012;5:113-121.',
    'Misselwitz B, Pohl D, Frühauf H, Fried M, Vavricka SR, Fox M. Lactose malabsorption and intolerance: pathogenesis, diagnosis and treatment. United European Gastroenterol J. 2013;1(3):151-159.',
    'Lukito W, Malik SG, Surono IS, Wahlqvist ML. From “lactose intolerance” to “lactose nutrition.” Asia Pac J Clin Nutr. 2015;24 Suppl 1:S1-S8.',
    <>
      NIH Lactose Intolerance Conference - Panel Statement. Accessed April 24, 2020.{' '}
      <ExternalLink to="https://consensus.nih.gov/2010/lactosestatement.htm">
        https://consensus.nih.gov/2010/lactosestatement.htm
      </ExternalLink>
    </>,
    <>
      Office of Dietary Supplements - Calcium. Accessed April 24, 2020.{' '}
      <ExternalLink to="https://ods.od.nih.gov/factsheets/Calcium-Consumer/">
        https://ods.od.nih.gov/factsheets/Calcium-Consumer/
      </ExternalLink>
    </>,
    <>
      Office of Dietary Supplements - Vitamin D. Accessed April 24, 2020.{' '}
      <ExternalLink to="https://ods.od.nih.gov/factsheets/VitaminD-Consumer/">
        https://ods.od.nih.gov/factsheets/VitaminD-Consumer/
      </ExternalLink>
    </>,
    'Vuorisalo T, Arjamaa O, Vasemägi A, Taavitsainen J-P, Tourunen A, Saloniemi I. High lactose tolerance in North Europeans: a result of migration, not in situ milk consumption. Perspect Biol Med. 2012;55(2):163-174.',
    'Yang Q, Lin SL, Au Yeung SL, et al. Genetically predicted milk consumption and bone health, ischemic heart disease and type 2 diabetes: a Mendelian randomization study. Eur J Clin Nutr. 2017;71(8):1008-1012.',
    'Enattah NS, Sahi T, Savilahti E, Terwilliger JD, Peltonen L, Järvelä I. Identification of a variant associated with adult-type hypolactasia. Nat Genet. 2002;30(2):233-237.',
    'Fang L, Ahn JK, Wodziak D, Sibley E. The human lactase persistence-associated SNP -13910*T enables in vivo functional persistence of lactase promoter-reporter transgene expression. Hum Genet. 2012;131(7):1153-1159.',
    'Liebert A, Jones BL, Danielsen ET, Olsen AK, Swallow DM, Troelsen JT. In Vitro Functional Analyses of Infrequent Nucleotide Variants in the Lactase Enhancer Reveal Different Molecular Routes to Increased Lactase Promoter Activity and Lactase Persistence. Ann Hum Genet. 2016;80(6):307-318.',
    'Tishkoff SA, Reed FA, Ranciaro A, et al. Convergent adaptation of human lactase persistence in Africa and Europe. Nat Genet. 2007;39(1):31-40.',
    'Mattar R, Monteiro M do S, Villares CA, dos Santos AF, Carrilho FJ. Single nucleotide polymorphism C/T(-13910), located upstream of the lactase gene, associated with adult-type hypolactasia: validation for clinical practice. Clin Biochem. 2008;41(7-8):628-630.',
  ],
};

export function getTraitResultsReferences(trait: Trait): ReactNode[] {
  return content[trait];
}
