import { ISimpleTooltipProps, SimpleTooltip } from '@color/continuum';

export interface IGpTooltipProps extends ISimpleTooltipProps {}

export const GpTooltip: React.FC<IGpTooltipProps> = (props: IGpTooltipProps) => {
  return (
    <SimpleTooltip
      {...props}
      // A very short delay so that the tooltip opens right away after a press (user does not need to long press).
      enterTouchDelay={0}
      // Unfortunately there isn't a way to tell MUI to not close the tooltip, we can only give a timeout for how
      // long after the user stops tapping the tooltip we should close the tooltip box.
      // We make this an hour, which should be sufficient to simulate staying open until the user taps away.
      leaveTouchDelay={1000 * 60 * 60}
    />
  );
};
