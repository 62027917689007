// This file was moved back to GP from CDS, since it is a) not heavily shared with other projects, and
// b) the types and functionality should live with the component, not separately.

export enum QuestionFormat {
  SELECT = 'select',
  MULTI_SELECT = 'multiselect',
  SCALE = 'scale',
  TEXT = 'text',
  NUMERIC = 'numeric',
  DATE = 'date',
  STATE_OF_RESIDENCE = 'state_of_residence',
  COMPOUND_DATE = 'compound_date',
}

export interface IQuizPrerequisites {
  [id: string]: {
    answerDoesNotContain?: string[];
    answerDoesContain?: string[];
  };
}

export interface IQuizAdditionalInformation {
  descriptionTitle?: string;
  descriptionText?: string;
}

export interface IQuizChoice {
  id: number;
  title: string;
  subtitle?: string;
  // There is a circular reference here, but it seems to work.
  // eslint-disable-next-line no-use-before-define
  question?: IQuizQuestion;
  isExclusive: boolean;
}

export interface IScaleQuestionDetails {
  scaleLabels: string[];
}

export interface IQuizQuestion {
  id: string;
  title: string;
  subtitle?: string;
  description?: string;
  isOptional?: boolean;
  prerequisites?: IQuizPrerequisites;
  format: QuestionFormat;
  additionalInformation: IQuizAdditionalInformation;
  imageTag: string;
  placeholder?: string;
  choices: IQuizChoice[];
  validator?: 'age';
  scaleQuestionDetails: IScaleQuestionDetails | null;
}

export interface IQuizScale {
  subtitle?: string;
  // There is a circular reference here, but it seems to work.
  // eslint-disable-next-line no-use-before-define
  question?: IQuizQuestion;
  scaleQuestionDetails: IScaleQuestionDetails | null;
}

export interface ITransitionScreen {
  title: string;
  subtitle?: string;
  body: string;
  ctaText: string;
}

export enum QuizItemType {
  QUESTION = 'question',
  TRANSITION_SCREEN = 'transition_screen',
}

export interface IQuizItem {
  question?: IQuizQuestion;
  transitionScreen?: ITransitionScreen;
  itemType: QuizItemType;
}

export interface IQuiz {
  name: string;
  items: IQuizItem[];
  // TODO(Kiril): is_completed should come from the response API, not from the quiz one.
  isCompleted: boolean;
}

export type TQuizAnswer = string[] | string | undefined;
export type TAnswerMap = { [id: string]: TQuizAnswer };

export interface IQuizResponse {
  questionId: string;
  answer: TQuizAnswer;
  sessionId: string;
}

export interface IQuizChartResponse {
  serializedAnswer: string;
  percentage: number;
}

export interface IQuestionResultsListResponse {
  count: number;
  next: object | null;
  previous: object | null;
  results: IQuizResponse[];
}

export interface IFinishQuizScreenProps {
  onPrevious: () => void;
  answers: TAnswerMap;
  sessionId?: string;
}

export interface ISaveAnswerResponsePayload {
  sessionId: string;
}

export interface ISaveAnswerRequest {
  questionId: string;
  answer: TQuizAnswer;
  answerChoices?: number[];
  quizName: string;
  sessionId?: string;
}

export interface IQuizSessionUpdateRequest {
  sessionId: string;
  isCompleted: boolean;
}
