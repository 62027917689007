import { InternalLink, Spacer, Text, Title } from '@color/continuum';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { HDR_APPOINTMENT_SCHEDULING_PATH } from 'components/App/AppRoutes/constants';
import { AccordionIconType } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionIcon/types';
import { AccordionWithIcon } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionWithIcon';
import { trackSegmentEvent } from 'lib/analytics/segment';
import { SegmentEventType } from 'lib/analytics/types';

export const CommonQuestions: React.FC = () => (
  <AccordionWithIcon
    title={<FormattedMessage id="HDR_Positive__Common_Questions_Section_Title" />}
    icon={AccordionIconType.QUESTION_MARK}
  >
    <Title variant="headline3">
      <FormattedMessage id="HDR_Positive__Who_Can_I_Talk_To_Subsection_Title" />
    </Title>
    <Spacer variant="small" />
    <Text paragraph>
      <FormattedMessage
        id="HDR_Positive__Who_Can_I_Talk_To_Paragraph_1"
        values={{
          link: (chunks: ReactNode) => (
            <InternalLink
              to={HDR_APPOINTMENT_SCHEDULING_PATH}
              onClick={() => {
                trackSegmentEvent({ eventType: SegmentEventType.HDR_YOUR_RESULTS_GC_SCHEDULING });
              }}
            >
              {chunks}
            </InternalLink>
          ),
        }}
      />
    </Text>
    <Spacer variant="large" />
    <Title variant="headline3">
      <FormattedMessage id="HDR_Positive__What_Did_You_Actually_Find_Subsection_Title" />
    </Title>
    <Spacer variant="small" />
    <Text paragraph>
      <FormattedMessage id="HDR_Positive__What_Did_You_Actually_Find_Paragraph_1" />
    </Text>
    <Text paragraph>
      <FormattedMessage id="HDR_Positive__What_Did_You_Actually_Find_Paragraph_2" />
    </Text>
    <Text paragraph>
      <FormattedMessage id="HDR_Positive__What_Did_You_Actually_Find_Paragraph_3" />
    </Text>
    <Spacer variant="large" />
    <Title variant="headline3">
      <FormattedMessage id="HDR_Positive__How_Did_I_Get_This_Version_Subsection_Title" />
    </Title>
    <Spacer variant="small" />
    <Text paragraph>
      <FormattedMessage id="HDR_Positive__How_Did_I_Get_This_Version_Paragraph_1" />
    </Text>
    <Text paragraph>
      <FormattedMessage id="HDR_Positive__How_Did_I_Get_This_Version_Paragraph_2" />
    </Text>
    <Spacer variant="large" />
    <Title variant="headline3">
      <FormattedMessage id="HDR_Positive__What_Is_Technical_Report_Subsection_Title" />
    </Title>
    <Spacer variant="small" />
    <Text paragraph>
      <FormattedMessage id="HDR_Positive__What_Is_Technical_Report_Paragraph_1" />
    </Text>
    <Spacer variant="large" />
    <Title variant="headline3">
      <FormattedMessage id="HDR_Positive__Why_Does_This_Result_Need_To_Be_Confirmed_Subsection_Title" />
    </Title>
    <Spacer variant="small" />
    <Text paragraph>
      <FormattedMessage id="HDR_Positive__Why_Does_This_Result_need_To_Be_Confirmed_Paragraph_1" />
    </Text>
    <Spacer variant="large" />
    <Title variant="headline3">
      <FormattedMessage id="HDR_Positive__Could_My_Result_Change_Subsection_Title" />
    </Title>
    <Spacer variant="small" />
    <Text paragraph>
      <FormattedMessage id="HDR_Positive__Could_My_Result_Change_Paragraph_1" />
    </Text>
  </AccordionWithIcon>
);
