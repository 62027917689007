import { InlineSpacer, InternalButtonLink, Spacer } from '@color/continuum';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FormattedMessage } from 'react-intl';

export interface BackButtonBarProps {
  to: string;
}

export const BackButtonBar: React.FC<BackButtonBarProps> = (props: BackButtonBarProps) => {
  const { to } = props;

  return (
    <>
      <Spacer variant="small" />
      {
        /* Horizontal padding that matches the padding of the navbar */
        <InlineSpacer variant="large" />
      }
      <InternalButtonLink to={to} variant="color-tertiary" startIcon={<ArrowBackIcon />}>
        <FormattedMessage defaultMessage="Back" description="Back button text" />
      </InternalButtonLink>
    </>
  );
};
