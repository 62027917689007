import { ModuleType } from '../types';

export enum ResultDecisionValue {
  YES = 'yes',
  NO = 'no',
  MAYBE_LATER = 'maybe_later',
  HIDE = 'hide',
}

export interface ResultDecision {
  decisionValue: ResultDecisionValue;
  moduleType: ModuleType;
  decisionAt: string;
  resultVisible: boolean;
}

export interface ResultDecisionData {
  resultDecisions: ResultDecision[];
}

export interface ResultDecisionPayload {
  decisionValue: ResultDecisionValue;
  moduleType: ModuleType;
}
