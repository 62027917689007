import { formatInTimeZone } from 'date-fns-tz';
// Format date in short stripped format Date => 20180111T010000Z
export const formatIso8601UtcDate = (time: Date) =>
  formatInTimeZone(time, 'UTC', "yyyyMMdd'T'HHmmss'Z'");

interface ICalendarProps {
  startDate: Date;
  endDate: Date;
  description: string;
  appointmentName: string;
  location: string;
  timezone: string;
}

export const createGoogleCalendarLink = (props: ICalendarProps) => {
  const { startDate, endDate, description, appointmentName, location, timezone } = props;
  // https://stackoverflow.com/questions/22757908/google-calendar-render-action-template-parameter-documentation
  // FIXME - fields like description and location should probably be encoded using encodeURIComponent
  return (
    `https://calendar.google.com/calendar/render?` +
    `action=TEMPLATE&` +
    `text=${encodeURIComponent(appointmentName)}&` +
    `details=${encodeURIComponent(description)}&` +
    `ctz=${encodeURIComponent(timezone)}&` +
    `dates=${formatIso8601UtcDate(startDate)}/${formatIso8601UtcDate(endDate)}&` +
    `location=${location}`
  );
};

export const createICalBody = ({
  startDate,
  endDate,
  description,
  appointmentName,
  location,
  timezone,
}: ICalendarProps) => {
  // https://tools.ietf.org/html/rfc5545
  // Using Date.now here to allow mocking just Date.now function
  const now = new Date(Date.now());
  // new lines must be escaped
  const IDescription = description.replace(/\n/g, '\\n');

  // Commas in location must be escaped for iCal
  const iLocation = location.replace(/,/g, '\\,');

  return (
    `BEGIN:VCALENDAR\n` +
    `TZID:${encodeURIComponent(timezone)}\n` +
    `VERSION:2.0\n` +
    `PRODID:-//Color Health. Schedulizer/NONSGML//EN\n` +
    `BEGIN:VEVENT\n` +
    `UID:${formatIso8601UtcDate(now)}-appointments@color.com\n` +
    `DTSTAMP:${formatIso8601UtcDate(now)}\n` +
    `DTSTART:${formatIso8601UtcDate(startDate)}\n` +
    `DTEND:${formatIso8601UtcDate(endDate)}\n` +
    `SUMMARY:${appointmentName}\n` +
    `DESCRIPTION:${IDescription}\n` +
    `LOCATION:${iLocation}\n` +
    `END:VEVENT\n` +
    `END:VCALENDAR`
  );
};

export const createICalLink = (ICalendarProps: ICalendarProps) => {
  const iCalText = createICalBody(ICalendarProps);
  const blob = new Blob([iCalText], { type: 'application/octet-stream' });
  return window.URL.createObjectURL(blob);
};
