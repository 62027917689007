import { Text } from '@color/continuum';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PGX_REPORT_PATH } from 'components/App/AppRoutes/constants';
import { CircularImageType } from 'components/common/CircularImage/types';
import { FinalPreResultsModuleFooter } from 'components/common/InformingLoop/InformingLoopFooters/FinalPreResultsModuleFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { trackAnalyticsEvent } from 'lib/analytics';
import { AnalyticsPlatform, EventType } from 'lib/analytics/types';

export const PgxPreResultsModule3: React.FC = () => {
  const history = useHistory();
  const intl = useIntl();
  const messages = {
    pageTitle: intl.formatMessage({ id: 'PGX_PreResults_3_Helmet_Title' }),
  };

  return (
    <InformingLoopModule
      helmetTitle={messages.pageTitle}
      module={{
        analyticsInformingLoopName: 'PGx Pre Results',
        analyticsPageName: '3. Questions',
        image: CircularImageType.QUESTION_BUBBLE,
        title: <FormattedMessage id="PGX_Pre_Results_Page_3__Title" />,
        content: (
          <>
            <Text paragraph>
              <FormattedMessage id="PGX_Pre_Results_Page_3__Paragraph_1" />
            </Text>
            <Text paragraph>
              <FormattedMessage id="PGX_Pre_Results_Page_3__Paragraph_2" />
            </Text>
          </>
        ),
      }}
      footer={
        <FinalPreResultsModuleFooter
          onViewMyResultsClick={async () => {
            try {
              await trackAnalyticsEvent(
                { eventType: EventType.CHOSE_VIEW_AFTER_PGX_PRE_RESULTS },
                AnalyticsPlatform.DEPRECATED_EVENT_LOG
              );
              history.push(PGX_REPORT_PATH);
            } catch {
              // TODO(ALLOFUS-2452): Do something on error when we figure out the UX.
            }
          }}
        />
      }
    />
  );
};
