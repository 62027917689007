import { usePreferredLocale } from '@color/lib';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { User } from 'components/App/Providers/AppAuthProvider/types';

import { createEventLog } from './api';
import {
  identifySegmentUser,
  logSegmentPageView,
  resetSegmentUser,
  trackSegmentEvent,
} from './segment';
import {
  AnalyticsPlatform,
  EventProperties,
  ModuleType,
  NewEventPayload,
  SegmentPageType,
} from './types';

// Asynchronously log an event on multiple analytics platforms
export async function trackAnalyticsEvent(event: NewEventPayload, platform: AnalyticsPlatform) {
  if (platform === AnalyticsPlatform.DEPRECATED_EVENT_LOG) {
    // eslint-disable-next-line no-console
    await createEventLog(event).catch(console.error);
  }
  if (platform === AnalyticsPlatform.SEGMENT) {
    trackSegmentEvent(event);
  }
}

// Identify the user by their Color-generated patient ID.
// We use the patient ID because it's not considered PHI and can be used to correlate tracking events back to the user in the internal data warehouse.
export function identifyAnalyticsUser(user: User) {
  identifySegmentUser(user.patientId);
}

// Resets the id, including anonymousId, and clears traits for the currently identified user and group.
export function resetAnalyticsUser() {
  resetSegmentUser();
}

// Logs in Segment that the user has seen a page.
export function logAnalyticsPageView(name: string, properties?: EventProperties) {
  logSegmentPageView(name, properties);
}

export function logSchedulingPageView(appointmentType: string, page: SegmentPageType) {
  const moduleType =
    appointmentType === ModuleType.PGX
      ? ModuleType.PGX.toUpperCase()
      : ModuleType.HDR.toUpperCase();

  logAnalyticsPageView(`${moduleType} ${page}`);
}
// Logs page views in Segment and includes the user's language as aou_language
export function usePageViews() {
  const location = useLocation();
  const { locale } = usePreferredLocale();

  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    window.analytics.page(location.pathname, { aou_language: locale });
  }, [location, locale]);
}
