import { Text } from '@color/continuum';
import { useEffect } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { FirstModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/FirstModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { useCreateInformingLoopStartedEventMutation } from 'lib/analytics/informing-loop-started';
import { ModuleType } from 'lib/analytics/types';

import { PGX_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  pgxILModule1PageTitle: {
    description: 'PGx Informing Loop Page Title - Module 1',
    defaultMessage: 'Getting Results',
  },
});

export const PgxInformingLoopModule1: React.FC = () => {
  const { mutateAsync: saveInformingLoopStarted } = useCreateInformingLoopStartedEventMutation();
  const intl = useIntl();

  /**
   * `useEffect` is often dangerous and unnecessary. This usage needs review by #ux-programs team.
   * See https://getcolor.atlassian.net/browse/UXTASK-1406
   */
  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    saveInformingLoopStarted({ moduleType: ModuleType.PGX });
  }, [saveInformingLoopStarted]);

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.pgxILModule1PageTitle)}
      module={{
        analyticsInformingLoopName: PGX_INFORMING_LOOP_NAME,
        analyticsPageName: '1. Start',
        image: CircularImageType.DRUGS_CLIPBOARD,
        title: <FormattedMessage id="PGX_IL_1__Title" />,
        content: (
          <>
            <Text paragraph>
              <FormattedMessage id="PGX_IL_1__Paragraph_1" />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="PGX informing loop page 1 paragraph 2"
                defaultMessage="At the end, you will be asked to decide if you want these results. You can decide “Yes,” “No,” or “I’m not sure right now.” If you decide “Yes,” results may take a few weeks to a few months to generate."
              />
            </Text>
          </>
        ),
      }}
      footer={<FirstModuleInformingLoopFooter />}
    />
  );
};
