import { Locale } from '@color/lib';

export const FOOTER_PHONE_NUMBER = '(844) 842-2855';
export const FOOTER_EMAIL_ADDRESS = 'help@joinallofus.org';
export const FOOTER_TTY_NUMBER = '711';
export const GP_APPOINTMENT_DURATION = { minutes: 45 };
export const HDR_GENES_LIST_URLS: Record<Locale, string> = {
  [Locale.ENGLISH]: 'https://www.joinallofus.org/genomics/hereditary-disease-risk',
  [Locale.SPANISH]: 'https://www.joinallofus.org/es/genomics/hereditary-disease-risk',
} as Record<Locale, string>;
export const PGX_GENES_LIST_URLS: Record<Locale, string> = {
  [Locale.ENGLISH]: 'https://www.joinallofus.org/genomics/medicine-and-your-dna',
  [Locale.SPANISH]: 'https://www.joinallofus.org/es/genomics/medicine-and-your-dna',
} as Record<Locale, string>;
export const HDR_POSITIVE_INFO_SHEET_URLS: Record<Locale, string> = {
  [Locale.ENGLISH]:
    'https://prod-static.color.com/pdfs/aou/en/All_of_Us_HDR_Positive_Info_Sheet.pdf',
  [Locale.SPANISH]:
    'https://prod-static.color.com/pdfs/aou/es/All_of_Us_HDR_Positive_Info_Sheet.pdf',
} as Record<Locale, string>;
export const HDR_UNINFORMATIVE_INFO_SHEET_URLS: Record<Locale, string> = {
  [Locale.ENGLISH]:
    'https://prod-static.color.com/pdfs/aou/en/All_of_Us_HDR_Negative_Info_Sheet.pdf',
  [Locale.SPANISH]:
    'https://prod-static.color.com/pdfs/aou/es/All_of_Us_HDR_Negative_Info_Sheet.pdf',
} as Record<Locale, string>;
export const PGX_INFO_SHEET_URLS: Record<Locale, string> = {
  [Locale.ENGLISH]: 'https://prod-static.color.com/pdfs/aou/en/All_of_Us_PGx_Info_Sheet.pdf',
  [Locale.SPANISH]: 'https://prod-static.color.com/pdfs/aou/es/All_of_Us_PGx_Info_Sheet.pdf',
} as Record<Locale, string>;

/**
 * Mapping of tz database timezones to the canonical one that Color uses.
 *
 * Note, some timezones such as "CST6CDT" are canonical; however, Color always
 * uses "America/Chicago" as the canonical entry to represent CT.
 *
 * Values used are all from the 2022e release of the tz database
 * https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 *
 * Sorted from west to east (roughly) and alphabetically within each UTC offset
 */
export const TIME_ZONE_TO_COLOR_CANONICAL_TIME_ZONE = {
  // Samoa Time
  'Pacific/Midway': 'Pacific/Pago_Pago',
  'Pacific/Pago_Pago': 'Pacific/Pago_Pago',
  'Pacific/Samoa': 'Pacific/Pago_Pago',
  'US/Samoa': 'Pacific/Pago_Pago',
  // Aleutian Time
  'America/Adak': 'America/Adak',
  'America/Atka': 'America/Adak',
  'US/Aleutian': 'America/Adak',
  // Hawaiian Time
  HST: 'Pacific/Honolulu',
  'Pacific/Honolulu': 'Pacific/Honolulu',
  'Pacific/Johnston': 'Pacific/Honolulu',
  'US/Hawaii': 'Pacific/Honolulu',
  // Alaska Time
  'America/Anchorage': 'America/Anchorage',
  'America/Juneau': 'America/Anchorage',
  'America/Metlakatla': 'America/Anchorage',
  'America/Nome': 'America/Anchorage',
  'America/Sitka': 'America/Anchorage',
  'America/Yakutat': 'America/Anchorage',
  'US/Alaska': 'America/Anchorage',
  // Pacific Time
  'America/Los_Angeles': 'America/Los_Angeles',
  PST8PDT: 'America/Los_Angeles',
  'US/Pacific': 'America/Los_Angeles', // nosemgrep:semgrep-rules.canonical-tz-pacific
  // Mountain Time
  'America/Boise': 'America/Denver',
  'America/Denver': 'America/Denver',
  'America/Shiprock': 'America/Denver',
  MST7MDT: 'America/Denver',
  Navajo: 'America/Denver',
  'US/Mountain': 'America/Denver', // nosemgrep:semgrep-rules.canonical-tz-mountain
  // Arizona Time
  'America/Creston': 'America/Phoenix',
  'America/Phoenix': 'America/Phoenix',
  MST: 'America/Phoenix',
  'US/Arizona': 'America/Phoenix',
  // Central Time
  'America/Chicago': 'America/Chicago',
  'America/Indiana/Knox': 'America/Chicago',
  'America/Indiana/Tell_City': 'America/Chicago',
  'America/Knox_IN': 'America/Chicago',
  'America/Menominee': 'America/Chicago',
  'America/North_Dakota/Beulah': 'America/Chicago',
  'America/North_Dakota/Center': 'America/Chicago',
  'America/North_Dakota/New_Salem': 'America/Chicago',
  CST6CDT: 'America/Chicago',
  'US/Central': 'America/Chicago', // nosemgrep:semgrep-rules.canonical-tz-central
  'US/Indiana-Starke': 'America/Chicago',
  // Eastern Time
  'America/Detroit': 'America/New_York',
  'America/Fort_Wayne': 'America/New_York',
  'America/Indiana/Indianapolis': 'America/New_York',
  'America/Indiana/Marengo': 'America/New_York',
  'America/Indiana/Petersburg': 'America/New_York',
  'America/Indiana/Vevay': 'America/New_York',
  'America/Indiana/Vincennes': 'America/New_York',
  'America/Indiana/Winamac': 'America/New_York',
  'America/Indianapolis': 'America/New_York',
  'America/Kentucky/Louisville': 'America/New_York',
  'America/Kentucky/Monticello': 'America/New_York',
  'America/Louisville': 'America/New_York',
  'America/New_York': 'America/New_York',
  EST5EDT: 'America/New_York',
  'US/East-Indiana': 'America/New_York',
  'US/Eastern': 'America/New_York', // nosemgrep:semgrep-rules.canonical-tz-eastern
  'US/Michigan': 'America/New_York',
  // Atlantic Time
  'America/Anguilla': 'America/Puerto_Rico',
  'America/Antigua': 'America/Puerto_Rico',
  'America/Aruba': 'America/Puerto_Rico',
  'America/Blanc-Sablon': 'America/Puerto_Rico',
  'America/Curacao': 'America/Puerto_Rico',
  'America/Dominica': 'America/Puerto_Rico',
  'America/Grenada': 'America/Puerto_Rico',
  'America/Guadeloupe': 'America/Puerto_Rico',
  'America/Kralendijk': 'America/Puerto_Rico',
  'America/Lower_Princes': 'America/Puerto_Rico',
  'America/Marigot': 'America/Puerto_Rico',
  'America/Montserrat': 'America/Puerto_Rico',
  'America/Port_of_Spain': 'America/Puerto_Rico',
  'America/Puerto_Rico': 'America/Puerto_Rico',
  'America/St_Barthelemy': 'America/Puerto_Rico',
  'America/St_Kitts': 'America/Puerto_Rico',
  'America/St_Lucia': 'America/Puerto_Rico',
  'America/St_Thomas': 'America/Puerto_Rico',
  'America/St_Vincent': 'America/Puerto_Rico',
  'America/Tortola': 'America/Puerto_Rico',
  'America/Virgin': 'America/Puerto_Rico',
};

export const IDP_CE = 'ce';
export const IDP_PTSC = 'ptsc';
