import { HdrV1Gene, getDisorderAcronymsForGene, getGeneSpecificContent } from '@color/aou-reports';
import { ActionLink, Spacer, Table, TableBody, TableCell, TableRow, Text } from '@color/continuum';
import { ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const getDisorders = (gene: HdrV1Gene) => {
  const disorders = getDisorderAcronymsForGene(gene);
  const results: ReactNode[] = [];

  disorders.forEach((disorder: string) => {
    if (results.length === 1) {
      results.push(
        <span>
          {' '}
          <FormattedMessage id="MYH7_Gene_Table_And" />{' '}
        </span>
      );
    }

    results.push(getGeneSpecificContent(gene, disorder)?.providerDisorder);
  });

  return results;
};

const TABLE_DATA = Object.keys(HdrV1Gene).map((gene) => ({
  id: gene.toString(),
  gene: gene.toString(),
  disorder: getDisorders(HdrV1Gene[gene as keyof typeof HdrV1Gene]),
}));

const NUMBER_OF_ROWS_IN_SHORT_TABLE = 17;

export const WeLookedAtTheseGenesTable: React.FC = () => {
  const intl = useIntl();
  const [showFullTable, setShowFullTable] = useState<boolean>(false);
  return (
    <>
      <Table
        columns={[
          {
            id: 'gene',
            name: intl.formatMessage({
              id: 'HDR_Positive__Genetic_Results_Table_Header_Gene_Label',
            }),
            isSortable: false,
            widthRatio: 0.1,
          },
          {
            id: 'disorder',
            name: intl.formatMessage({
              id: 'HDR_Positive__Genetic_Results_Table_Header_Disorder_Label',
            }),
            isSortable: false,
            widthRatio: 0.9,
          },
        ]}
        isLoading={false}
        tableOptions={{ sortDirections: {}, setSortDirections: () => null }}
      >
        <TableBody>
          {TABLE_DATA.slice(
            0,
            showFullTable ? TABLE_DATA.length : NUMBER_OF_ROWS_IN_SHORT_TABLE
          ).map(({ id, gene, disorder }) => (
            <TableRow hover key={id}>
              <TableCell>
                <Text bold>
                  <em>{gene}</em>
                </Text>
              </TableCell>
              <TableCell>
                <Text>{disorder}</Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Spacer variant="medium" />
      <ActionLink onClick={() => setShowFullTable((showFullTableValue) => !showFullTableValue)}>
        {showFullTable ? (
          <FormattedMessage id="HDR_Positive__Show_less" />
        ) : (
          <FormattedMessage id="HDR_Positive__Show_more" />
        )}
      </ActionLink>
    </>
  );
};
