import { Revision } from '@color/aou-reports';
import { ActionLink, Box, Container, GpDialog, useDialogState } from '@color/continuum';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { CloseIcon } from 'components/Appointments/CloseIcon';

import { RevisionListItem } from './RevisionListItem';

export interface IRevisionHistoryModal {
  revisions: Revision[];
}
export const RevisionHistoryModal: FC<IRevisionHistoryModal> = (props) => {
  const { revisions } = props;
  const { dialogIsOpen, openDialog, closeDialog } = useDialogState();

  return (
    <>
      <ActionLink onClick={() => openDialog()}>
        <FormattedMessage id="Hdr_Report_Revision_History_Action_Link" />
      </ActionLink>
      <GpDialog title="Common Dialog" onClose={() => closeDialog()} open={dialogIsOpen}>
        <Box display="flex" justifyContent="flex-end">
          <CloseIcon onClick={closeDialog} aria-label="" />
        </Box>
        <Container>
          {revisions.map((revision) => (
            <RevisionListItem revision={revision} key={revision.revision} />
          ))}
        </Container>
      </GpDialog>
    </>
  );
};
