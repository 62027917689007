import { Grid, useMediaQuery, useTheme } from '@color/continuum';
import { ReactNode, forwardRef } from 'react';

import { QuizContainer } from '../Quiz/QuizContainer';

export interface QuizAndMoreInformationSectionProps {
  quizName: string;
  moreInformationSection: ReactNode;
}

export const QuizAndMoreInformationSection = forwardRef(
  (props: QuizAndMoreInformationSectionProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
    const { quizName, moreInformationSection } = props;
    return (
      <Grid container spacing={isTablet ? 4 : 6} ref={ref}>
        <Grid item xs={12} md={7}>
          <QuizContainer quizName={quizName} />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            /**
             * NOTE - hiding overflow items is fairly unusual.
             * I.e. we are saying that we won't show you stuff because you are on a small screen.
             */
            overflow: 'hidden',
          }}
        >
          {moreInformationSection}
        </Grid>
      </Grid>
    );
  }
);
