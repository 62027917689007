import { CVL_TO_NAME, GENOME_CENTER_TO_NAME, ReportPayload } from '@color/aou-reports';
import {
  Container,
  Divider,
  Grid,
  IconBulletList,
  ImageDivider,
  InternalLink,
  ListItem,
  Spacer,
  Text,
  Title,
  UnorderedList,
} from '@color/continuum';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import DnaBanner from 'assets/images/dna-banner.svg';
import { HDR_APPOINTMENT_SCHEDULING_PATH } from 'components/App/AppRoutes/constants';
import { CircularImageType } from 'components/common/CircularImage/types';
import { ResultBanner, ResultBannerIconType } from 'components/common/ResultBanner';
import { ImageWithContentSection } from 'components/HealthRelatedResults/components/ImageWithContentSection/ImageWithContentSection';

import { CommonQuestions } from './components/CommonQuestions';
import { MethodsAndLimitations } from './components/MethodsAndLimitations';
import { WeLookedAtTheseGenes } from './components/WeLookedAtTheseGenes';

interface IYourResultsTabProps {
  report: ReportPayload;
}

export const YourResultsTab: React.FC<IYourResultsTabProps> = (props) => {
  const {
    report: {
      reportInfo: { genomeCenter, clinicalValidationLab },
    },
  } = props;
  return (
    <>
      <Spacer variant="xxLarge" />
      <ResultBanner
        icon={ResultBannerIconType.GENES_CLIPBOARD}
        title={<FormattedMessage id="HDR_Uninformative__Result_Banner_Title" />}
        subtitle={<FormattedMessage id="HDR_Uninformative__Result_Banner_Subtitle" />}
      />
      <Spacer variant="xxxLarge" />
      <Container>
        <Title variant="headline2">
          <FormattedMessage id="HDR_Uninformative__What_Does_This_Mean_Subsection_Title" />
        </Title>
        <Spacer variant="medium" />
        <Title variant="headline3">
          <FormattedMessage id="HDR_Uninformative__What_Does_This_Mean_Paragraph_1" />
        </Title>
        <Spacer variant="small" />
        <Text paragraph>
          <FormattedMessage id="HDR_Uninformative__What_Does_This_Mean_Paragraph_2" />
        </Text>
        <Spacer variant="xxLarge" />
        <Divider aria-hidden="true" />
        <Spacer variant="xxLarge" />
        <ImageWithContentSection
          title={
            <Title variant="headline2">
              <FormattedMessage id="HDR_Uninformative__Important_Subsection_Title" />
            </Title>
          }
          description={
            <UnorderedList>
              <ListItem>
                <Text>
                  <FormattedMessage id="HDR_Uninformative__Important_Subsection_Bullet_1" />
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <FormattedMessage id="HDR_Uninformative__Important_Subsection_Bullet_2" />
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <FormattedMessage id="HDR_Uninformative__Important_Subsection_Bullet_3" />
                </Text>
              </ListItem>
            </UnorderedList>
          }
          image={CircularImageType.EXCLAIMATION_POINT_DOCUMENT_RED}
          imageRight
        />
      </Container>
      <Spacer variant="xxxLarge" />
      <ImageDivider imageSrc={DnaBanner} />
      <Spacer variant="xxxLarge" />
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Title variant="headline2">
              <FormattedMessage id="HDR_Uninformative__Thats_Good_Subsection_Title" />
            </Title>
            <Spacer variant="medium" />
            <Text paragraph>
              <FormattedMessage id="HDR_Uninformative__Thats_Good_Paragraph_1" />
            </Text>
            <Text paragraph bold>
              <FormattedMessage id="HDR_Uninformative__Thats_Good_Paragraph_2" />
            </Text>
            <Text paragraph>
              <FormattedMessage id="HDR_Uninformative__Thats_Good_Paragraph_3" />
            </Text>
          </Grid>
          <Grid item xs={12} md={6}>
            <Title variant="headline2">
              <FormattedMessage id="HDR_Uninformative__What_Next_Subsection_Title" />
            </Title>
            <Spacer variant="medium" />
            <Text paragraph>
              <FormattedMessage id="HDR_Uninformative__What_Next_Paragraph_1" />
            </Text>
            <IconBulletList
              listItems={[
                {
                  content: (
                    <Text>
                      <FormattedMessage id="HDR_Uninformative__What_Next_Bullet_1" />
                    </Text>
                  ),
                  contentId: '1',
                },
                {
                  content: (
                    <Text>
                      <FormattedMessage id="HDR_Uninformative__What_Next_Bullet_2" />
                    </Text>
                  ),
                  contentId: '2',
                },
                {
                  content: (
                    <Text>
                      <FormattedMessage id="HDR_Uninformative__What_Next_Bullet_3" />
                    </Text>
                  ),
                  contentId: '3',
                },
                {
                  content: (
                    <Text>
                      <FormattedMessage id="HDR_Uninformative__What_Next_Bullet_4" />
                    </Text>
                  ),
                  contentId: '4',
                },
                {
                  content: (
                    <Text>
                      <FormattedMessage id="HDR_Uninformative__What_Next_Bullet_5" />
                    </Text>
                  ),
                  contentId: '5',
                },
                {
                  content: (
                    <Text>
                      <FormattedMessage id="HDR_Uninformative__What_Next_Bullet_6" />
                    </Text>
                  ),
                  contentId: '6',
                },
                {
                  content: (
                    <Text>
                      <FormattedMessage id="HDR_Uninformative__What_Next_Bullet_7" />
                    </Text>
                  ),
                  contentId: '7',
                },
              ]}
            />
            <Spacer variant="medium" />
            <Text paragraph>
              <FormattedMessage id="HDR_Uninformative__What_Next_Paragraph_2" />
            </Text>
          </Grid>
        </Grid>
        <Spacer variant="xxLarge" />
        <Divider aria-hidden="true" />
        <Spacer variant="xxLarge" />
        <ImageWithContentSection
          title={
            <Title variant="headline2">
              <FormattedMessage id="HDR_Uninformative__Understanding_Subsection_Title" />
            </Title>
          }
          description={
            <Text paragraph>
              <FormattedMessage id="HDR_Uninformative__Understanding_Paragraph_1" />
            </Text>
          }
          image={CircularImageType.HEART_AND_RIBBON}
          imageRight
        />
        <Spacer variant="xxLarge" />
        <Divider aria-hidden="true" />
        <Spacer variant="xxLarge" />
        <ImageWithContentSection
          title={
            <Title variant="headline2">
              <FormattedMessage id="HDR_Uninformative__Look_Subsection_Title" />
            </Title>
          }
          description={
            <>
              <Text paragraph>
                <FormattedMessage id="HDR_Uninformative__Look_Paragraph_1" />
              </Text>
              <Text paragraph>
                <FormattedMessage id="HDR_Uninformative__Look_Paragraph_2" />
              </Text>
            </>
          }
          image={CircularImageType.MICROSCOPE}
        />
        <Spacer variant="xxLarge" />
        <Divider aria-hidden="true" />
        <Spacer variant="xxLarge" />
        <ImageWithContentSection
          title={
            <Title variant="headline2">
              <FormattedMessage id="HDR_Uninformative__Questions_Subsection_Title" />
            </Title>
          }
          description={
            <Text paragraph>
              <FormattedMessage
                id="HDR_Uninformative__Questions_Paragraph_1"
                values={{
                  link: (chunks: ReactNode) => (
                    <InternalLink to={HDR_APPOINTMENT_SCHEDULING_PATH}>{chunks}</InternalLink>
                  ),
                }}
              />
            </Text>
          }
          image={CircularImageType.GENETIC_COUNSELOR}
          imageRight
        />
        <Spacer variant="xLarge" />
        <CommonQuestions />
        <Spacer variant="medium" />
        <WeLookedAtTheseGenes />
        <Spacer variant="medium" />
        <MethodsAndLimitations
          genomeCenterSite={GENOME_CENTER_TO_NAME[genomeCenter]}
          cvlSite={CVL_TO_NAME[clinicalValidationLab]}
        />
      </Container>
    </>
  );
};
