import { Box, useMediaQuery, useTheme } from '@color/continuum';
import { ReactNode } from 'react';

export interface InformingLoopFooterProps {
  // TODO @rymarren: We should probably instead just accept an ReactNode that contains all of the buttons here with a proper layout.
  // This isn't the best pattern since we have to manually format the buttons in the footer, we should probably just do that formatting
  // in the InformingLoop component since it has all the information to do that declaratively.
  leftButton: ReactNode;
  rightButton: ReactNode;
}

export const InformingLoopFooter: React.FC<InformingLoopFooterProps> = (
  props: InformingLoopFooterProps
) => {
  const { leftButton, rightButton } = props;
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      boxShadow={3}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      px={isMobile ? 2 : 5}
      py={1}
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 1,
        backgroundColor: theme.palette.background.default,
      }}
    >
      {leftButton}
      <Box flexGrow={1} />
      {rightButton}
    </Box>
  );
};
