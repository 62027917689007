import { QueryClientProvider } from 'react-query';

import { apiQueryClient } from 'lib/api';

interface Props {
  children?: React.ReactNode;
}

export const AppQueryClientProvider: React.FC<Props> = (props) => {
  const { children } = props;
  return <QueryClientProvider client={apiQueryClient}>{children}</QueryClientProvider>;
};
