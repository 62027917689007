import { Image } from '@color/continuum';
import { kebabCase } from 'lodash';
import requireContext from 'require-context.macro';

import { AncestryTutorialImageType } from './types';

const AncestryTutorialImages = requireContext(
  '../../assets/images/ancestry-tutorial-images',
  true,
  /\.svg$/
);

export interface AncestryTutorialImageProps {
  image: AncestryTutorialImageType;
}

export const AncestryTutorialImage: React.FC<AncestryTutorialImageProps> = (props) => {
  const { image } = props;
  const imageSrc = AncestryTutorialImages(`./${kebabCase(image)}.svg`).default;
  return <Image sx={{ height: '100%', width: '100%' }} src={imageSrc} alt="" />;
};
