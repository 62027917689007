import { Divider, MenuItem, Spacer } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { FC } from 'react';

import { languagesList } from '../constants';
import { FormikSelectField } from '../FormikSelectField/FormikSelectField';

interface IFormikLanguageField {
  label: string;
  name: string;
}
export const FormikLanguageField: FC<IFormikLanguageField> = (props) => {
  const { label, name } = props;
  const { locale } = usePreferredLocale();
  const langaugeByLocale = languagesList[locale];
  return (
    <FormikSelectField name={name} label={label}>
      {Object.keys(langaugeByLocale).map((language: string) =>
        ['en', 'es'].includes(langaugeByLocale[language]) ? (
          <MenuItem value={langaugeByLocale[language]} key={language}>
            {language}
          </MenuItem>
        ) : null
      )}
      <Spacer variant="small" />
      <Divider orientation="horizontal" role="presentation">
        More language options
      </Divider>
      <Spacer variant="small" />
      {Object.keys(langaugeByLocale).map((language: string) =>
        ['en', 'es'].includes(langaugeByLocale[language]) ? null : (
          <MenuItem value={langaugeByLocale[language]} key={language}>
            {language}
          </MenuItem>
        )
      )}
    </FormikSelectField>
  );
};
