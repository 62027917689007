import { useMutation } from 'react-query';

import { api } from 'lib/api';
import { ApiError } from 'lib/api/types';

import { HealthResultViewedPayload } from './types';

export function useCreateHealthResultViewedEventMutation(token?: string) {
  return useMutation<Response, ApiError, HealthResultViewedPayload>(
    (json: HealthResultViewedPayload) =>
      api('aou_health_result_viewed', {
        method: 'POST',
        json,
        searchParams: token ? { token } : {},
      })
  );
}
