import { HdrV1VariantDetails } from '@color/aou-reports';
import { Container, Grid, Spacer, Text, Title } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { SEX_SPECIFIC_RISK_CONTENT } from './constants';
import { getSexAgnosticGeneSpecificRiskContent } from './getSexAgnosticGeneSpecificRiskContent';
import { WhyDoesThisMatterDialog } from './WhyDoesThisMatterDialog';

interface IDoctorsUseDNAProps {
  hasRiskCharts: boolean;
  variantDetails: HdrV1VariantDetails;
}
export const DoctorsUseDna: React.FC<IDoctorsUseDNAProps> = (props) => {
  const {
    hasRiskCharts,
    variantDetails: { gene, reportedDisorder },
  } = props;
  return (
    <Container>
      <Title textAlign="center" variant="headline2">
        <FormattedMessage id="HDR_Positive_Risk__Doctors_Use_DNA_Header" />
      </Title>
      <Spacer variant="medium" />
      <Text textAlign="center">
        <FormattedMessage id="HDR_Positive_Risk__Doctors_Use_DNA_P1" />
      </Text>
      <Spacer variant="large" />
      {!hasRiskCharts && (
        <>
          <Title variant="headline3">
            <FormattedMessage id="HDR_Positive_Risk__Screen_If_Female_P2" />
          </Title>
          <Spacer variant="small" />
          {getSexAgnosticGeneSpecificRiskContent(gene, reportedDisorder).screenAll}
        </>
      )}
      {hasRiskCharts && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Title variant="headline3">
              <FormattedMessage id="HDR_Positive_Risk__Screen_If_Female_Header" />
            </Title>
            <Spacer variant="small" />
            <WhyDoesThisMatterDialog />
            <Spacer variant="medium" />
            <Text>
              <FormattedMessage id="HDR_Positive_Risk__Screen_If_Female_P2" />
            </Text>
            <Spacer variant="small" />
            {SEX_SPECIFIC_RISK_CONTENT[gene as keyof typeof SEX_SPECIFIC_RISK_CONTENT].screenFemale}
          </Grid>
          <Grid item xs={12} md={6}>
            <Title variant="headline3">
              <FormattedMessage id="HDR_Positive_Risk__Screen_If_Male_Header" />
            </Title>
            <Spacer variant="small" />
            <WhyDoesThisMatterDialog />
            <Spacer variant="medium" />
            <Text>
              <FormattedMessage id="HDR_Positive_Risk__Screen_If_Male_P2" />
            </Text>
            <Spacer variant="small" />
            {SEX_SPECIFIC_RISK_CONTENT[gene as keyof typeof SEX_SPECIFIC_RISK_CONTENT].screenMale}
          </Grid>
        </Grid>
      )}
      <Spacer variant="large" />
    </Container>
  );
};
