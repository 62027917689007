import { convertObjectKeysToCamelCase, convertObjectKeysToSnakeCase } from '@color/lib';
import Cookies from 'js-cookie';
import ky from 'ky';
import { isPlainObject, isString } from 'lodash';

import { ApiError, ApiErrorPayload, ApiRequestOptions, ApiResponse } from './types';
import { formatDjangoSerializerValidationError } from './util';

const UNSPECIFIED_SERVER_ERROR: ApiErrorPayload = {
  errorMessage: 'Failed to process request.',
  status: 500,
};

async function handleError(response: ApiResponse) {
  let errorMessage: string;

  const errorPayload = await response.json();
  if (!errorPayload) {
    errorMessage = response.statusText;
  } else if (isString(errorPayload)) {
    errorMessage = errorPayload;
  } else if (response.status === 400) {
    errorMessage = formatDjangoSerializerValidationError(errorPayload);
  } else {
    // TODO: Update error serialization when GP endpoints are added.
    // This logic is likely to change depending on how the GP backend formats its error responses.
    errorMessage = errorPayload?.errorMessage || errorPayload?.detail;
  }

  // TODO: Log error when we have a logging library.
  throw new ApiError({
    ...UNSPECIFIED_SERVER_ERROR,
    errorMessage,
    status: response.status,
  });
}

async function handleSuccess(response: ApiResponse) {
  try {
    response.payload = await response.json();
  } catch {
    response.payload = undefined;
  }
}

export const api = ky.create({
  prefixUrl: '/api/v1/',
  retry: 0,
  throwHttpErrors: false,
  parseJson: async (text) => {
    try {
      const json = await JSON.parse(text);
      // Convert response to camelCase because we prefer working with camelCase in Javascript
      return convertObjectKeysToCamelCase(json);
    } catch (SyntaxError) {
      return text;
    }
  },
  hooks: {
    beforeRequest: [
      (request: Request) => {
        request.headers.set('X-CSRFToken', Cookies.get('csrftoken') || '');
      },
      (request: Request, options: ApiRequestOptions) => {
        // Convert body to snake case because that's what the Python backend expects
        let body;
        if (options?.json) {
          if (!isPlainObject(options.json)) {
            // We do a manual type-check to ensure options.json is always an object.
            // Ideally, we could define the type of options accepted by the Ky instance to be ApiRequestOptions rather than KyOptions.
            throw new TypeError(
              `Type '${typeof options.json}' is not assignable to type 'object | undefined'`
            );
          }
          body = JSON.stringify(convertObjectKeysToSnakeCase(options.json));
        }
        // We do a manual type-check to prevent users from passing in options.body.
        // Ideally, we could define the type of options accepted by the Ky instance to be ApiRequestOptions rather than KyOptions.
        // When casting the KyOptions object to an ApiRequestOptions type here, Typescript does not error on extra properties.
        // We will need to change this condition and the ApiRequestOptions type definition if we start accepting multi-part payloads.
        // @ts-ignore: Property 'body' does not exist on type 'ApiRequestOptions'
        else if (options?.body) {
          throw new TypeError("Property 'body' does not exist on type 'ApiRequestOptions'");
        }

        // Convert query params to snake case because that's what the Python backend expects
        let { url } = request;
        const [baseUrl, searchParams] = url.split('?');
        if (searchParams) {
          const searchParamsObject: object = Object.fromEntries(new URLSearchParams(searchParams));
          const convertedSearchParams = new URLSearchParams(
            convertObjectKeysToSnakeCase(searchParamsObject) as URLSearchParams
          );
          url = `${baseUrl}?${convertedSearchParams.toString()}`;
        }

        return new Request(url, {
          body,
          method: request.method,
          credentials: request.credentials,
          headers: request.headers,
        });
      },
    ],
    afterResponse: [
      async (_request, _options, response): Promise<ApiResponse> => {
        const apiResponse = response as ApiResponse;
        if (response.ok) {
          await handleSuccess(apiResponse);
        } else {
          await handleError(apiResponse);
        }
        return apiResponse;
      },
    ],
  },
});
