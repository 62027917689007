import { Button, useLocationFlowContext } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { InformingLoopFooter } from '../InformingLoopFooter/InformingLoopFooter';

interface Props {
  onViewMyResultsClick: () => void;
}

export const FinalPreResultsModuleFooter: React.FC<Props> = (props: Props) => {
  const { onViewMyResultsClick } = props;
  const { goToPreviousLocation } = useLocationFlowContext();
  return (
    <InformingLoopFooter
      leftButton={
        <Button variant="color-secondary" onClick={() => goToPreviousLocation()}>
          <FormattedMessage
            defaultMessage="Previous"
            description="Informing loop footer previous button text"
          />
        </Button>
      }
      rightButton={
        <Button onClick={onViewMyResultsClick}>
          <FormattedMessage
            description="Ancestry Informing Loop view results button text"
            defaultMessage="View My Results"
          />
        </Button>
      }
    />
  );
};
