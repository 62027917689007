import { ExternalLink } from '@color/continuum';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { createGoogleCalendarLink, createICalLink } from 'lib/utils/calendar';

export interface IAddToCalendarProps {
  appointmentName: string;
  location: string;
  description: string;
  startDate: Date;
  endDate: Date;
  timezone: string;
}
export const AddToCalendar: FC<IAddToCalendarProps> = (props) => {
  const { appointmentName, location, description, startDate, endDate, timezone } = props;

  return (
    <>
      <ExternalLink
        to={createGoogleCalendarLink({
          startDate,
          endDate,
          description,
          appointmentName,
          location,
          timezone,
        })}
      >
        <FormattedMessage id="Scheduling_Confirmation__Calendar_Google" />
      </ExternalLink>
      {' | '}
      <ExternalLink
        to={createICalLink({
          startDate,
          endDate,
          description,
          appointmentName,
          location,
          timezone,
        })}
        download="all_of_us_appointment.ics"
      >
        <FormattedMessage id="Scheduling_Confirmation__Calendar_Outlook" />
      </ExternalLink>
    </>
  );
};
