/**
 * Custom hook that provides Location values for the current identity provider (idp).
 * We are pulling the idp from the query params.
 * If the idp is not in the query params, we will default to PTSC.
 * We default to PTSC because they were the only portal for GP until we added Care Evolution (CE)
 * We need this hook to add the idp query param to the Location search field.
 * That is because the logic around prev/next relies on exact matches of the Location object.
 * And CE users will have a "idp=ce" query param in url/Location.
 *
 * param: locations - keyed object of pathnames
 * returns: keyed object of Location objects
 *
 * Example:
 * Input:
{
    MODULE_1: {
        pathname: `${PGX_INFORMING_LOOP_PATH}/module-1`,
    },
    MODULE_2: {
        pathname: `${PGX_INFORMING_LOOP_PATH}/module-2`,
    },
}
 * Output:
{
    MODULE_1: {
      pathname: `${PGX_INFORMING_LOOP_PATH}/module-1`,
      state,
      hash: '',
      search: searchParams,
    },
    MODULE_2: {
      pathname: `${PGX_INFORMING_LOOP_PATH}/module-2`,
      state,
      hash: '',
      search: searchParams,
    },
}
 * where state comes from useLocation() and searchParams is the query params with the idp added (e.g. ?idp=ce)
 *
 */

import { useLocation } from 'react-router-dom';

import { LocationDictionary } from 'components/common/InformingLoop/types';

import { useCachedQueryParam } from './useCachedQueryParam';

type InputLocationDictionary = {
  [key: string]: { pathname: string };
};

export const useLocationsForIdp = (locations: InputLocationDictionary) => {
  const { state, search } = useLocation();

  const idp = useCachedQueryParam('idp');

  const params = new URLSearchParams(search);
  let searchParams = '';
  if (!params.get('idp') && idp) {
    if (params.toString() === '') {
      searchParams = `?idp=${idp}`;
    } else {
      searchParams = `?${params.toString()}&idp=${idp}`;
    }
  } else if (params.toString() !== '') {
    searchParams = `?${params.toString()}`;
  }
  const updatedLocations = {} as LocationDictionary;
  Object.keys(locations).forEach((key) => {
    updatedLocations[key] = {
      pathname: locations[key].pathname,
      state,
      hash: '',
      search: searchParams,
    };
  });

  return updatedLocations;
};
