import { Box, Image } from '@color/continuum';
import { kebabCase } from 'lodash';
import { Fragment } from 'react';
import requireContext from 'require-context.macro';

import MapImage from 'assets/images/ancestry-map-images/ancestry-map.svg';
import { ParentPopulation, Subpopulation } from 'components/AncestryResults/types';

/*
Note: The following regions do not have a corresponding map blob image:
    SAS
    AFR
    EUR
    EAS
    MIDDLE_EASTERN_AND_NORTH_AFRICAN
    EAST_AFRICA
    IBERIA
    EAST_CHINA
    EAST_INDIA_AND_SRILANKA
    NORTH_INDIA_AND_WEST_ASIA
    NORTH_ITALY
    CENTRAL_AND_EAST_AFRICA
    Northwest_Asia
    Southeast_Asia
    Central_East_Asia
    East_Europe
    South_India
    East_India_Bengal
*/
const ancestryMapBlobImages = requireContext(
  '../../../../../../../assets/images/ancestry-map-images/regions',
  true,
  /\.svg$/
);

const ancestryMapBlobImageKeys = new Set(ancestryMapBlobImages.keys());

const getMapBlobImagePath = (population: string) => `./region-${kebabCase(population)}.svg`;

export interface MapProps {
  populations: (ParentPopulation | Subpopulation)[];
}

export const Map: React.FC<MapProps> = (props: MapProps) => {
  const { populations } = props;
  return (
    <Box position="relative" top={0} left={0}>
      <Image src={MapImage} alt="" width="100%" />
      {populations.map((population) =>
        ancestryMapBlobImageKeys.has(getMapBlobImagePath(population)) ? (
          <Image
            sx={{
              position: 'absolute',
              width: '100%',
              top: 0,
              left: 0,
            }}
            key={population}
            src={ancestryMapBlobImages(getMapBlobImagePath(population)).default}
            alt=""
          />
        ) : (
          <Fragment key={population} />
        )
      )}
    </Box>
  );
};
