import { PgxV1Gene, Phenotype } from '@color/aou-reports';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export const GENE_DESCRIPTIONS: Record<PgxV1Gene, ReactNode> = {
  CYP2C19: <FormattedMessage id="PGX_Genes__CYP2C19_Description" />,
  DPYD: <FormattedMessage id="PGX_Genes__DPYD_Description" />,
  G6PD: <FormattedMessage id="PGX_Genes__G6PD_Description" />,
  NUDT15: <FormattedMessage id="PGX_Genes__NUDT15_Description" />,
  SLCO1B1: <FormattedMessage id="PGX_Genes__SLCO1B1_Description" />,
  TPMT: <FormattedMessage id="PGX_Genes__TPMT_Description" />,
  UGT1A1: <FormattedMessage id="PGX_Genes__UGT1A1_Description" />,
};

export const PHENOTYPE_TERMS: Record<Phenotype, ReactNode> = {
  'decreased function': <FormattedMessage id="PGX_Decreased_SLCO1B1_Metabolizer__phenotypeTerm" />,
  'intermediate metabolizer': <FormattedMessage id="PGX_Intermediate_Metabolizer__phenotypeTerm" />,
  'likely intermediate metabolizer': (
    <FormattedMessage id="PGX_Likely_Intermediate_Metabolizer__phenotypeTerm" />
  ),
  'likely poor metabolizer': <FormattedMessage id="PGX_Likely_Poor_Metabolizer__phenotypeTerm" />,
  'normal function': <FormattedMessage id="PGX_Normal_SLCO1B1_Metabolizer__phenotypeTerm" />,
  'normal metabolizer': <FormattedMessage id="PGX_Normal_Metabolizer__phenotypeTerm" />,
  'poor function': <FormattedMessage id="PGX_Poor_SLCO1B1_Metabolizer__phenotypeTerm" />,
  'poor metabolizer': <FormattedMessage id="PGX_Poor_Metabolizer__phenotypeTerm" />,
  'rapid metabolizer': <FormattedMessage id="PGX_Rapid_Metabolizer__phenotypeTerm" />,
  'ultrarapid metabolizer': <FormattedMessage id="PGX_Ultrarapid_Metabolizer__phenotypeTerm" />,
  deficient: <FormattedMessage id="PGX_Deficient_G6PD_Metabolizer__phenotypeTerm" />,
  variable: <FormattedMessage id="PGX_Variant_G6PD_Metabolizer__phenotypeTerm" />,
  normal: <FormattedMessage id="PGX_Normal_G6PD_Metabolizer__phenotypeTerm" />,
  indeterminate: <FormattedMessage id="PGX_Indeterminate_Metabolizer__phenotypeTerm" />,
};

export const PHENOTYPE_DESCRIPTIONS: Record<Phenotype, ReactNode> = {
  'decreased function': (
    <FormattedMessage id="PGX_Decreased_SLCO1B1_Metabolizer__phenotypeDefinition" />
  ),
  'intermediate metabolizer': (
    <FormattedMessage id="PGX_Intermediate_Metabolizer__phenotypeDefinition" />
  ),
  'likely intermediate metabolizer': (
    <FormattedMessage id="PGX_Likely_Intermediate_Metabolizer__phenotypeDefinition" />
  ),
  'likely poor metabolizer': (
    <FormattedMessage id="PGX_Likely_Poor_Metabolizer__phenotypeDefinition" />
  ),
  'normal function': <FormattedMessage id="PGX_Normal_SLCO1B1_Metabolizer__phenotypeDefinition" />,
  'normal metabolizer': <FormattedMessage id="PGX_Normal_Metabolizer__phenotypeDefinition" />,
  'poor function': <FormattedMessage id="PGX_Poor_SLCO1B1_Metabolizer__phenotypeDefinition" />,
  'poor metabolizer': <FormattedMessage id="PGX_Poor_Metabolizer__phenotypeDefinition" />,
  'rapid metabolizer': <FormattedMessage id="PGX_Rapid_Metabolizer__phenotypeDefinition" />,
  'ultrarapid metabolizer': (
    <FormattedMessage id="PGX_Ultrarapid_Metabolizer__phenotypeDefinition" />
  ),
  deficient: <FormattedMessage id="PGX_Deficient_G6PD_Metabolizer__phenotypeDefinition" />,
  variable: <FormattedMessage id="PGX_Variant_G6PD_Metabolizer__phenotypeDefinition" />,
  normal: <FormattedMessage id="PGX_Normal_G6PD_Metabolizer__phenotypeDefinition" />,
  indeterminate: <FormattedMessage id="PGX_Indeterminate_Metabolizer__phenotypeDefinition" />,
};
