import { ReportPayload } from '@color/aou-reports';
import { Locale, usePreferredLocale } from '@color/lib';
import { UseQueryOptions, useQuery } from 'react-query';

import { ModuleType } from 'lib/analytics/types';
import { ApiError } from 'lib/api/types';

interface HealthResultsQueryParams {
  moduleType: ModuleType.HDR | ModuleType.PGX;
  token?: string;
}

export const useHealthResultsQuery = (
  params?: HealthResultsQueryParams,
  options?: UseQueryOptions<ReportPayload, ApiError>
) => {
  return useQuery<ReportPayload, ApiError>(
    ['aou_health_results', { searchParams: params }],
    options
  );
};

interface ReportPdfsPayload {
  en: string;
  es: string;
}

interface ReportPdfsQueryParams {
  moduleType: ModuleType.HDR | ModuleType.PGX;
}

const useReportPdfsQuery = (
  params: ReportPdfsQueryParams,
  options?: UseQueryOptions<ReportPdfsPayload, ApiError>
) => useQuery<ReportPdfsPayload, ApiError>(['aou_report_pdfs', { searchParams: params }], options);

interface IUseReportPdfUrlProps {
  moduleType: ModuleType.HDR | ModuleType.PGX;
}

export const useReportPdfUrl = (props: IUseReportPdfUrlProps) => {
  const { moduleType } = props;
  const { locale } = usePreferredLocale();
  const { data, isLoading } = useReportPdfsQuery({ moduleType });
  if (isLoading || !data) {
    return undefined;
  }
  return locale === Locale.SPANISH ? data?.es : data?.en;
};
