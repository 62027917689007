import { Button } from '@color/continuum';
// eslint-disable-next-line no-restricted-imports
import { Theme } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import leftArrowIcon from 'assets/images/icons/left-arrow-icon.png';

interface GoBackButtonProps {
  onClick: () => void;
}

export const GoBackButton: React.FC<GoBackButtonProps> = (props: GoBackButtonProps) => {
  const { onClick } = props;
  return (
    <Button
      variant="color-tertiary"
      onClick={() => onClick()}
      startIcon={<img src={leftArrowIcon} alt="" />}
      sx={(theme: Theme) => ({
        backgroundColor: theme.palette.background.default,
      })}
    >
      <FormattedMessage
        description="Button text for going back within GP survey component"
        defaultMessage="Go Back"
      />
    </Button>
  );
};
