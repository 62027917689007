import { capitalize, startCase } from 'lodash';

function formatErrorMessage(field: string, message: string): string {
  let formattedMessage = message;

  // Convert field-specific errors like { "firstName": "This field is required" } => "first name is required"
  if (field !== 'nonFieldErrors') {
    const formattedField = startCase(field).toLowerCase();
    formattedMessage = formattedMessage.replace('This field', formattedField);
  }

  // Add periods to the end of error messages, so that there is a clear separation between multiple messages
  if (!formattedMessage.endsWith('.')) {
    formattedMessage = `${formattedMessage}.`;
  }

  // Capitalize the first letter
  return capitalize(formattedMessage);
}

// Utility function to format an error payload from a Django REST Serializer into a single error message
export function formatDjangoSerializerValidationError(errorPayload: object): string {
  const errors: string[] = [];
  Object.entries(errorPayload).forEach(
    ([field, errorMessages]: [field: string, errorMessages: string[]]) => {
      const formattedErrors = errorMessages.map((message) => formatErrorMessage(field, message));
      errors.push(...formattedErrors);
    }
  );
  return errors.join(' ');
}
