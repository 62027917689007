import { HdrV1Gene } from '@color/aou-reports';
import { ActionLink, useDialogState } from '@color/continuum';
import { FC } from 'react';

import { ShareResultsWithFamilyDialog } from '../ShareResultsWithFamilyDialog';

interface IShareResultsWithFamilyLinkProps {
  // This is the specific gene we mention in the letter.
  gene: HdrV1Gene;
  children?: React.ReactNode;
}

/**
 * This component renders a link which, when clicked, will open the share with family dialog..
 */
export const ShareResultsWithFamilyLink: FC<IShareResultsWithFamilyLinkProps> = (props) => {
  const { children, gene } = props;
  const { dialogIsOpen, closeDialog, openDialog } = useDialogState();
  return (
    <>
      <ActionLink onClick={openDialog} variant="body1">
        {children}
      </ActionLink>
      <ShareResultsWithFamilyDialog gene={gene} dialogIsOpen={dialogIsOpen} onClose={closeDialog} />
    </>
  );
};
