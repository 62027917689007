import {
  PGXV1_GENE_TO_MEDICINES,
  PgxV1Gene,
  PgxV1VariantDetails,
  getDnaAndMedicineTableContent,
} from '@color/aou-reports';
import { Box, ListItem, Spacer, Text, Title, UnorderedList } from '@color/continuum';
// TODO Import from CDS rather than MUI. In CDS-341 we turned on lint warnings on direct MUI imports. This line is disabled because GP CI sets the rule to error instead of warn.
// Swap this for a CDS import instead of a MUI import.
// eslint-disable-next-line no-restricted-imports
import { Link } from '@mui/material';
import { Fragment, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export interface IDnaAndMedicineTableProps {
  variantDetails: PgxV1VariantDetails[];
}
export const DnaAndMedicineTable: React.FC<IDnaAndMedicineTableProps> = (props) => {
  const { variantDetails } = props;
  let dnaAndMedicineTableContent = null;

  dnaAndMedicineTableContent = getDnaAndMedicineTableContent(
    variantDetails.map(({ gene, phenotype }) => [gene, phenotype])
  );

  if (dnaAndMedicineTableContent === null) {
    dnaAndMedicineTableContent = new Map<PgxV1Gene, ReactNode[]>(
      variantDetails.map(({ gene }) => [gene, PGXV1_GENE_TO_MEDICINES[gene]])
    );
  }
  // If there is not at least one medicine to show, we return an empty table message instead.
  if (Array.from(dnaAndMedicineTableContent.values()).flat().length === 0) {
    return <FormattedMessage id="PGX_Table_empty_state_medicine" />;
  }
  return (
    <>
      <Box
        sx={{
          border: '1px solid black',
          width: '100%',
        }}
      >
        {Array.from(dnaAndMedicineTableContent.entries()).map(([gene, medicines]) => (
          <Fragment key={gene}>
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.action.iconUnfocused,
                padding: 3,
              }}
              id={`dna-medicine-${gene}`}
            >
              <Text variant="body2" color="primary.contrastText">
                <FormattedMessage id="PGX__Gene_Label" />
              </Text>
              <Link
                href={`#pgx-info-card-${gene}`}
                sx={{
                  textDecorationColor: (theme) => theme.palette.primary.contrastText,
                  '&:hover': {
                    textDecorationColor: (theme) => theme.palette.primary.contrastText,
                  },
                }}
              >
                <Title variant="headline2" color="primary.contrastText">
                  <em>{gene.toString()}</em>
                </Title>
              </Link>
            </Box>
            <Box p={2}>
              <UnorderedList>
                {medicines.map((medicine: ReactNode, index: number) => (
                  <ListItem key={index}>
                    <Text>{medicine}</Text>
                  </ListItem>
                ))}
              </UnorderedList>
            </Box>
          </Fragment>
        ))}
      </Box>
      <Spacer variant="medium" />
      <Text paragraph>
        <FormattedMessage id="PGX_Medicine_Listed_Here" />
      </Text>
    </>
  );
};
