import { Location, LocationState } from 'history';

import { LocationDictionary } from 'components/common/InformingLoop/types';
import { useLocationsForIdp } from 'hooks/useLocationsForIdp';

export const ANCESTRY_INFORMING_LOOP_BASE_PATH = 'welcome/ancestry';

export const useAncestryInformingLoopLocationsByModule: () => LocationDictionary = () => {
  return useLocationsForIdp({
    MODULE_1: {
      pathname: `/${ANCESTRY_INFORMING_LOOP_BASE_PATH}/module-1`,
    },
    MODULE_2: {
      pathname: `/${ANCESTRY_INFORMING_LOOP_BASE_PATH}/module-2`,
    },
    MODULE_3: {
      pathname: `/${ANCESTRY_INFORMING_LOOP_BASE_PATH}/module-3`,
    },
  });
};

export const useAncestryInformingLoopLocations: () => Location<LocationState>[] = () =>
  Object.values(useAncestryInformingLoopLocationsByModule());
