import { Box, Grid, useMediaQuery, useTheme } from '@color/continuum';

import { useAncestryQuery } from 'components/AncestryResults/api';
import { PopulationBreakdown } from 'components/AncestryResults/components/PopulationBreakdown/PopulationBreakdown';
import { useAncestryPopulationsContent } from 'components/AncestryResults/content';
import { AncestryComposition } from 'components/AncestryResults/types';
import { getNonZeroCompositions, getSubpopulations } from 'components/AncestryResults/util';

import { Map } from './components/Map/Map';

export const MapAndPopulationDetails: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: ancestryData } = useAncestryQuery();
  const ancestryContent = useAncestryPopulationsContent();
  if (!ancestryData) {
    return null;
  }
  const nonZeroCompositions = getNonZeroCompositions(ancestryData.compositions);
  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.primary[100],
      }}
    >
      <Grid item sm={12} md={8}>
        <Box py={6} pl={isMobile ? 0 : 5} pr={isMobile ? 0 : 5}>
          <Map populations={getSubpopulations(ancestryData.compositions)} />
        </Box>
      </Grid>
      <Grid item sm={12} md={4}>
        <Box
          pl={isMobile ? 2 : 0}
          pr={isMobile ? 2 : 8}
          pt={isMobile ? 0 : 6}
          pb={isMobile ? 5 : 6}
        >
          <PopulationBreakdown
            isOverviewPageBreakdown
            data={nonZeroCompositions.map((composition: AncestryComposition) => ({
              title: ancestryContent.populations[composition.population].title,
              percentage: composition.percentage,
              subpopulations: composition.subcompositions.map((subcomposition) => ({
                title: ancestryContent.subpopulations[subcomposition.population].title,
                examples: ancestryContent.subpopulations[subcomposition.population].examples,
                percentage: subcomposition.percentage,
              })),
            }))}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
