import { ITextProps } from '@color/continuum';

import { FooterText } from './FooterText';

const FOOTER_DISCLAIMER_TEXT_SX = {
  fontSize: '12px',
  lineHeight: '14px',
};

export const FooterDisclaimerText: React.FC<ITextProps> = (props) => {
  const { sx } = props;
  return (
    <FooterText
      {...props}
      sx={{
        ...FOOTER_DISCLAIMER_TEXT_SX,
        ...sx,
      }}
    />
  );
};
