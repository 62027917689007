import { HdrV1VariantDetails, Revision } from '@color/aou-reports';
import { Container, MoreInformationCard, Spacer, Text, Title } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';

import { BackButtonBar } from 'components/common/BackButtonBar';
import { QuizAndMoreInformationSection } from 'components/common/Reports/QuizAndMoreInformationSection';
import { ReportIconType } from 'components/common/Reports/ReportIcon/types';
import { getReportIconSrc } from 'components/common/Reports/ReportIcon/utils';
import { useReportPdfUrl } from 'components/HealthRelatedResults/api';
import { useLocationControlledTabs } from 'components/HealthRelatedResults/components/FullWidthTabsWithPanel';
import { FullWidthTabsWithPanel } from 'components/HealthRelatedResults/components/FullWidthTabsWithPanel/FullWidthTabsWithPanel';
import { MoreInformationSection } from 'components/HealthRelatedResults/components/MoreInformationSection/MoreInformationSection';
import { ReReleaseBanner } from 'components/HealthRelatedResults/components/ReReleaseBanner/ReReleaseBanner';
import { ResearchResultWarningBanner } from 'components/HealthRelatedResults/components/ResearchResultWarningBanner/ResearchResultWarningBanner';
import { ResultViewed } from 'components/HealthRelatedResults/components/ResultViewed/ResultViewed';
import { trackSegmentEvent } from 'lib/analytics/segment';
import { ModuleType, SegmentEventType } from 'lib/analytics/types';
import { HDR_POSITIVE_INFO_SHEET_URLS } from 'lib/constants';

import { HDR_POSITIVE_LOCATION_CONTROLLED_TABS_PROPS } from './constants';
import { NextStepsTab } from './Tabs/NextStepsTab';
import { RiskWithGeneTab } from './Tabs/RiskWithGeneTab';
import { YourResultsTab } from './Tabs/YourResultsTab';
import { HdrPositiveTabIds } from './types';

interface IHdrPositiveReportProps {
  geneVariantDetails: HdrV1VariantDetails[];
  cvlSiteName: string;
  genomeCenterSiteName: string;
  token?: string;
  isRevision: Boolean;
  revisions?: Revision[];
  revision?: number;
}

export const HdrPositiveReport: React.FC<IHdrPositiveReportProps> = (props) => {
  const {
    geneVariantDetails,
    cvlSiteName,
    genomeCenterSiteName,
    token,
    isRevision,
    revisions,
    revision,
  } = props;
  const intl = useIntl();
  const messages = {
    pageTitle: intl.formatMessage({ id: 'PGx_Home_Helmet_Title' }),
  };
  const { locale } = usePreferredLocale();
  const reportPdfUrl = useReportPdfUrl({ moduleType: ModuleType.HDR });
  const { tabId, setTab } = useLocationControlledTabs(HDR_POSITIVE_LOCATION_CONTROLLED_TABS_PROPS);
  if (!tabId) {
    return null;
  }
  const firstVariantDetails = geneVariantDetails[0];
  return (
    <>
      <Helmet>
        {/* declarative intl syntax injects a span, which causes issues with Helmet title */}
        <title>{messages.pageTitle}</title>
      </Helmet>
      <Container>
        <ResearchResultWarningBanner>
          <Text>
            <FormattedMessage id="HDR_Uninformative__Research_Result_Warning" />
          </Text>
        </ResearchResultWarningBanner>
        {isRevision && <ReReleaseBanner revisions={revisions!} revision={revision!} />}
      </Container>
      <BackButtonBar to="/" />
      <Spacer variant="large" />
      <Title align="center">
        <FormattedMessage id="HDR_Positive__Title" />
      </Title>
      <Spacer variant="xxLarge" />
      <FullWidthTabsWithPanel
        tabId={tabId}
        setTab={setTab}
        tabsConfig={{
          [HdrPositiveTabIds.YOUR_RESULTS]: {
            label: <FormattedMessage id="HDR_Positive__Your_Results_Tab" />,
            component: (
              <YourResultsTab
                multiVariantGeneDetails={geneVariantDetails}
                genomeCenterSiteName={genomeCenterSiteName}
                cvlSiteName={cvlSiteName}
              />
            ),
          },
          [HdrPositiveTabIds.RISK_WITH_GENE]: {
            label: (
              <FormattedMessage
                id="HDR_Positive__Risk_Tab"
                values={{ gene: firstVariantDetails.gene }}
              />
            ),
            component: (
              <RiskWithGeneTab
                firstVariantDetails={firstVariantDetails}
                isMultiVariant={geneVariantDetails.length > 1}
              />
            ),
          },
          [HdrPositiveTabIds.NEXT_STEPS]: {
            label: <FormattedMessage id="HDR_Positive__Next_Steps_Tab" />,
            component: <NextStepsTab gene={firstVariantDetails.gene} />,
          },
        }}
      />
      <Spacer variant="medium" />
      <Container>
        <QuizAndMoreInformationSection
          quizName="aou_survey_hdr_positive"
          moreInformationSection={
            <MoreInformationSection
              tabId={tabId}
              moduleType={ModuleType.HDR}
              moreInformationCards={{
                [HdrPositiveTabIds.YOUR_RESULTS]: (
                  <MoreInformationCard
                    iconSrc={getReportIconSrc(ReportIconType.YOUR_RESULTS_HDR)}
                    title={<FormattedMessage id="HDR_Positive__Your_Results_Tab" />}
                    onClick={() => {
                      trackSegmentEvent({
                        eventType: SegmentEventType.HDR_POSITIVE_QUICK_LINK_YOUR_RESULTS,
                      });
                      setTab(HdrPositiveTabIds.YOUR_RESULTS);
                    }}
                  />
                ),
                [HdrPositiveTabIds.RISK_WITH_GENE]: (
                  <MoreInformationCard
                    iconSrc={getReportIconSrc(ReportIconType.RISK_WITH_GENE)}
                    title={
                      <FormattedMessage
                        id="HDR_Positive__Risk_Tab"
                        values={{ gene: firstVariantDetails.gene }}
                      />
                    }
                    onClick={() => {
                      trackSegmentEvent({
                        eventType: SegmentEventType.HDR_POSITIVE_QUICK_LINK_RISK_WITH_GENE,
                      });
                      setTab(HdrPositiveTabIds.RISK_WITH_GENE);
                    }}
                  />
                ),
                [HdrPositiveTabIds.NEXT_STEPS]: (
                  <MoreInformationCard
                    iconSrc={getReportIconSrc(ReportIconType.NEXT_STEPS)}
                    title={<FormattedMessage id="HDR_Positive__Next_Steps_Tab" />}
                    onClick={() => {
                      trackSegmentEvent({
                        eventType: SegmentEventType.HDR_POSITIVE_QUICK_LINK_NEXT_STEPS,
                      });
                      setTab(HdrPositiveTabIds.NEXT_STEPS);
                    }}
                  />
                ),
              }}
              infoSheetUrl={HDR_POSITIVE_INFO_SHEET_URLS[locale]}
              reportPdfUrl={reportPdfUrl}
            />
          }
        />
        <Spacer variant="medium" />
      </Container>
      <ResultViewed moduleType={ModuleType.HDR} token={token} />
    </>
  );
};
