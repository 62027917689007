import { IconBulletList, Spacer, Text } from '@color/continuum';
import { FormattedMessage, IntlShape, defineMessages, useIntl } from 'react-intl';
import requireContext from 'require-context.macro';

import { GpTooltip } from 'components/common/GpTooltip/GpTooltip';

import { Trait, TraitOutcome } from '../../types';
import { TraitGenotypeChart } from './TraitGenotypeChart';

const TraitReportImages = requireContext(
  '../../../../assets/images/trait-report-images',
  true,
  /\.svg$/
);

const messages = defineMessages({
  bitternessSection1Title: {
    description: 'bitterness trait results section 1 title',
    defaultMessage: 'The science of bitter taste',
  },
  bitternessSection1Subcontent1Title: {
    description: 'bitterness trait results section 1 subcontent 1 title',
    defaultMessage: 'We have five types of taste.',
  },
  bitternessSection1Subcontent1Text: {
    description: 'bitterness trait results section 1 subcontent 1 text',
    defaultMessage:
      'We have five types of taste that allow us to sense flavors in our foods. Experts think that these five tastes evolved in us to help us eat nutritious foods and avoid possibly toxic or dangerous substances.',
  },
  bitternessSection1Subcontent2Title: {
    description: 'bitterness trait results section 1 subcontent 2 title',
    defaultMessage: 'Humans can taste many types of bitterness.',
  },
  bitternessSection1Subcontent2Text: {
    description: 'bitterness trait results section 1 subcontent 2 text',
    defaultMessage:
      'Our tongues have very complicated taste receptors that can sense many different compounds in our food. There are at least 25 different types of bitter taste receptors. Our brains combine the messages sent by taste receptors with other senses, like smell, texture, and temperature, into flavor. Scientists have recorded more than 550 compounds that people taste as bitter.<sup>1</sup>',
  },
  bitternessSection1Subcontent3Title: {
    description: 'bitterness trait results section 1 subcontent 3 title',
    defaultMessage: 'Can you taste the difference?',
  },
  bitternessSection1Subcontent3Text: {
    description: 'bitterness trait results section 1 subcontent 3 text',
    defaultMessage: `<p>The way we taste and respond to different foods comes from our genes, but other factors can make a difference in how we taste, too. Up to 85% of bitter taste is explained by our genes,<sup>2</sup> but characteristics such as sex<sup>2-4</sup> and age<sup>4</sup> may play a role in how strongly we taste bitter flavor. Additionally, some studies suggest that the five types of taste can all affect the intensity of bitterness that a person experiences.<sup>5</sup></p>
    <p>Culture and environment can play a role in bitterness tasting, too. Take coffee as an example: many people dislike black coffee the first time they drink it but become accustomed to it over time when it's something that everyone around them is drinking.</p>`,
  },
  bitternessSection1Subcontent4Title: {
    description: 'bitterness trait results section 1 subcontent 3 title',
    defaultMessage: 'What about spicy?',
  },
  bitternessSection1Subcontent4Text: {
    description: 'bitterness trait results section 1 subcontent 4 text',
    defaultMessage:
      "Unlike bitter, spicy isn't a type of taste receptor. The taste we associate with spicy foods is communicated to our brain through pain receptors called nociceptors. Nociceptors help us sense, respond to, and avoid danger. Pain receptors are found in and on our skin, mouth, nose, and eyes. No pain, no (spicy) gain!",
  },
  bitternessSection2Title: {
    description: 'bitterness trait results section 2 title',
    defaultMessage: 'Bitterness and food',
  },
  bitternessSection2Subcontent1Title: {
    description: 'bitterness trait results section 2 subcontent 1 title',
    defaultMessage: 'Many healthy foods are bitter.',
  },
  bitternessSection2Subcontent1Text: {
    description: 'bitterness trait results section 2 subcontent 1 text',
    defaultMessage:
      "Plants produce many types of compounds. Some of them are there to help the plant grow and thrive. Bitter compounds may have developed to discourage animals from eating the plant. Many humans also find these plant ingredients icky-tasting.<sup>6</sup> However, eating bitter vegetables like brussels sprouts, broccoli, and kale is good for us.<sup>7</sup> Fortunately, there are ways to hide the bitter taste in how they're cooked.",
  },
  bitternessSection2Subcontent2Title: {
    description: 'bitterness trait results section 2 subcontent 2 title',
    defaultMessage: 'Food science to the rescue',
  },
  bitternessSection2Subcontent2Text: {
    description: 'bitterness trait results section 2 subcontent 2 text',
    defaultMessage:
      'Humans have been changing their food to make it tastier for thousands of years. Roasting foods like peanuts and chocolate gives them their flavor. Roasting vegetables makes them taste less bitter.',
  },
  bitternessSection3Subcontent2Title: {
    description: 'bitterness trait results section 3 subcontent 2 title',
    defaultMessage: 'Scientific details',
  },
  bitternessSection3Subcontent2Text: {
    description: 'bitterness trait results section 3 subcontent 2 text',
    defaultMessage: `<p>The <i>TAS2R38</i> gene makes a bitter taste receptor, which is part of our taste buds. Bitter taste receptors interact with our food and send signals to our brain that get interpreted as flavor.</p>
    <p>We analyzed your DNA for the presence of a genetic variant {tooltip} that alters <i>TAS2R38</i> to allow for the detection of the bitter taste associated with PTC and similar compounds.<sup>10</sup></p>`,
  },
  bitternessSection3Subcontent2Tooltip: {
    description: 'bitterness trait results section 3 subcontent 2 tooltip',
    defaultMessage:
      'Genetic variants are differences in our genes that make us unique. Everyone has millions of variants in their DNA. They influence things like the way we look, the foods we like or dislike, and how our bodies work. Genetic variants are usually inherited from our parents. This is why we have so much in common with members of our family.',
  },
  bitternessSection3Subcontent2TooltipLabel: {
    description: 'bitterness trait results section 3 subcontent 2 tooltip accesible label',
    defaultMessage: 'Genetic Taste Variant Info',
  },
  cilantroSection1Title: {
    description: 'cilantro trait results section 1 title',
    defaultMessage: 'Beyond DNA',
  },
  cilantroSection1Subcontent1Title: {
    description: 'cilantro trait results section 1 subcontent 1 title',
    defaultMessage: "It's nature vs. nurture.",
  },
  cilantroSection1Subcontent1Text: {
    description: 'cilantro trait results section 1 subcontent 1 text',
    defaultMessage:
      'People seem to either love or hate cilantro. But how much of this preference is controlled by nature (genetics) vs. nurture (other environmental factors)? Some studies estimate that 38% of cilantro preference is controlled by genetics,<sup>1</sup> but the place in your DNA that we looked at only contributes a little to your cilantro preference.<sup>2</sup> This trait is influenced by other genetic and non-genetic factors.',
  },
  cilantroSection1Subcontent2Title: {
    description: 'cilantro trait results section 1 subcontent 2 title',
    defaultMessage: 'Culture, ancestry, and ethnicity matter.',
  },
  cilantroSection1Subcontent2Text: {
    description: 'cilantro trait results section 1 subcontent 2 text',
    defaultMessage: `<p>People from Europe, East Asia, and Latin America are more likely to have a genetic predisposition to dislike cilantro.<sup>3</sup> But our culture and ethnicity, which refers to the group of people with which we share language, culture, and religion, also play important roles in our relationship with food.<sup>4</sup></p>
    <p>People with Middle Eastern genetic ancestry and Hispanic ethnicity are the least likely to dislike cilantro, even though Hispanics have a higher genetic likelihood of disliking it. This may be because cultural exposure to cilantro through cuisine may override a genetic tendency to dislike it. People from Africa, East Asia, and Europe are more likely to dislike or not yet to have tried cilantro.<sup>5</sup></p>`,
  },
  cilantroSection2Title: {
    description: 'cilantro trait results section 2 title',
    defaultMessage: 'Smell and taste, a delectable relationship',
  },
  cilantroSection2Subcontent1Title: {
    description: 'cilantro trait results section 2 subcontent 1 title',
    defaultMessage: 'Our sense of smell is strong.',
  },
  cilantroSection2Subcontent1Text: {
    description: 'cilantro trait results section 2 subcontent 1 text',
    defaultMessage:
      'The inside of our nose has special cells that interact with things in the environment that produce odors.<sup>6</sup> Slight differences between olfactory receptor neurons allow us to smell more than 10,000 different compounds.<sup>7</sup>',
  },
  cilantroSection2Subcontent2Title: {
    description: 'cilantro trait results section 2 subcontent 2 title',
    defaultMessage: 'Smell and taste work together.',
  },
  cilantroSection2Subcontent2Text: {
    description: 'cilantro trait results section 2 subcontent 2 text',
    defaultMessage:
      "We often associate a food's flavor with our ability to taste it, but flavor is much more than that. In addition to taste, the mouth registers temperature, texture, and pain (spiciness). These sensations are communicated to the brain. Then, these messages are combined with smells sensed by our nose to produce complex and memorable flavors.<sup>8</sup> The genetic change we test for modifies cilantro’s smell for some people and therefore contributes to taste preference.",
  },
  cilantroSection2Subcontent3Title: {
    description: 'cilantro trait results section 2 subcontent 3 title',
    defaultMessage: 'Aldehydes make cilantro soapy for some.',
  },
  cilantroSection2Subcontent3Text: {
    description: 'cilantro trait results section 2 subcontent 3 text',
    defaultMessage:
      "<p>Aldehydes, a type of compound, give cilantro its smell. There are many kinds of aldehydes. Many common foods like vanilla, cinnamon, lemongrass, and oranges get their characteristic smells from aldehydes. Aldehydes are also found in soap and give stinkbugs their odor. Some people interpret cilantro's aldehydes as fragrant and citrusy, while others perceive them as soapy or stinky.</p>",
  },
  cilantroSection3Subcontent2Title: {
    description: 'cilantro trait results section 3 subcontent 2 title',
    defaultMessage: 'Scientific details',
  },
  cilantroSection3Subcontent2Text: {
    description: 'cilantro trait results section 3 subcontent 2 text',
    defaultMessage:
      '<i>OR6A2</i> makes a sensor in the nose that helps us perceive smells. Changes near <i>OR6A2</i> may impact whether you find cilantro fragrant and citrusy, or soapy or moldy.<sup>2</sup>',
  },
  earwaxSection1Title: {
    description: 'earwax trait results section 1 title',
    defaultMessage: 'Earwax keeps our ears healthy.',
  },
  earwaxSection1Subcontent1Title: {
    description: 'earwax trait results section 1 subcontent 1 title',
    defaultMessage: 'What is earwax, anyway?',
  },
  earwaxSection1Subcontent1Text: {
    description: 'earwax trait results section 1 subcontent 1 text',
    defaultMessage:
      'Our skin produces sweat and oil. This happens in our ears, too. The ceruminous gland is a type of sweat gland located in our ears. It secretes a thick liquid that combines with oil, skin, and dust in the ear to produce earwax, or cerumen.',
  },
  earwaxSection1Subcontent2Title: {
    description: 'earwax trait results section 1 subcontent 2 title',
    defaultMessage: 'Earwax protects our ears.',
  },
  earwaxSection1Subcontent2Text: {
    description: 'earwax trait results section 1 subcontent 2 text',
    defaultMessage:
      "Earwax does a few things to protect the health of your ears, whether it's dry or wet:",
  },
  earwaxSection1Subcontent3Title: {
    description: 'earwax trait results section 1 subcontent 3 title',
    defaultMessage: 'Get the bad stuff out.',
  },
  earwaxSection1Subcontent3Text: {
    description: 'earwax trait results section 1 subcontent 3 text',
    defaultMessage:
      'Earwax traps unwanted particles inside the ear. Then, with movement of the jaw, it moves out of the ear canal. Earwax can also protect our ears from bacteria and fungi.<sup>1</sup> These microbes can’t survive very well in the acidic environment of healthy earwax.',
  },
  earwaxSection2Title: {
    description: 'earwax trait results section 2 title',
    defaultMessage: 'Lend an ear to these earwax tales.',
  },
  earwaxSection2Subcontent1Title: {
    description: 'earwax trait results section 2 subcontent 1 title',
    defaultMessage: 'Wet earwax meets body odor.',
  },
  earwaxSection2Subcontent1Text: {
    description: 'earwax trait results section 2 subcontent 1 text',
    defaultMessage: `<p>When bacteria on our skin mixes with our sweat, we get body odor. Sweat is a mix of liquid and fats. People with wet earwax, whose cells produce more fat into their ears, will also have more fat in their sweat.</p>
    <p>Fatty sweat gives bacteria more to munch on, which helps them make more stinky compounds that we associate with body odor. Scientists have found that people with wet earwax have different body odor than people with dry earwax.<sup>2-5</sup> The earwax itself smells different, too.<sup>6,7</sup></p>`,
  },
  earwaxSection2Subcontent2Title: {
    description: 'earwax trait results section 2 subcontent 2 title',
    defaultMessage: 'Earwax reveals the life and history of whales.',
  },
  earwaxSection2Subcontent2Text: {
    description: 'earwax trait results section 2 subcontent 2 text',
    defaultMessage:
      "Humans aren't the only animals that produce earwax. In the blue whale earwax never leaves the ear canal. Instead, it grows into an earplug. Scientists can analyze the chemicals in the earplug to determine times in a whale's life, such as when it hit puberty, was stressed out, or came into contact with pollutants.<sup>8</sup>",
  },
  earwaxSection3Subcontent2Title: {
    description: 'earwax trait results section 3 subcontent 2 title',
    defaultMessage: 'Scientific details',
  },
  earwaxSection3Subcontent2Text: {
    description: 'earwax trait results section 3 subcontent 2 text',
    defaultMessage:
      '<i>ABCC11</i> helps move fat and substances in and out of cells. Changes in this gene impact how active <i>ABCC11</i> is, which influences the consistency of earwax. Normal <i>ABCC11</i> activity creates wet earwax while reduced ABCC11 activity results in dry earwax.',
  },
  lactoseSection1Title: {
    description: 'lactose trait results section 1 title',
    defaultMessage: 'Lactose intolerance, and other gut feelings',
  },
  lactoseSection1Subcontent1Title: {
    description: 'lactose trait results section 1 subcontent 1 title',
    defaultMessage: 'Are there signs you might be lactose intolerant?',
  },
  lactoseSection1Subcontent1Text: {
    description: 'lactose trait results section 1 subcontent 1 text',
    defaultMessage: `<p>If you have bloating, gas, abdominal pain, or diarrhea about 30 minutes to two hours after eating dairy products, you may be lactose intolerant. These symptoms happen because your small intestine doesn't have enough lactase enzyme to break down lactose.<sup>1</sup> Lactose is a type of sugar found in milk and other dairy products. Enzymes are things that speed up different chemical reactions in the body.</p>
    <p>Not everyone with lactose intolerance has all of these symptoms. Symptoms can be different between individuals.</p>`,
  },
  lactoseSection1Subcontent2Title: {
    description: 'lactose trait results section 1 subcontent 2 title',
    defaultMessage: 'Go beyond your genetic results.',
  },
  lactoseSection1Subcontent2Text: {
    description: 'lactose trait results section 1 subcontent 2 text',
    defaultMessage: `<p>Some people might not experience symptoms at all, even if they have the genetic variant.{tooltip} Many factors other than genetics can affect how a person reacts to lactose, such as:<sup>1</sup></p>
<ul>
  <li>The amount and type of food a person eats</li>
  <li>The amount of lactose in the food a person eats</li>
  <li>A person’s unique gut bacteria and other enzymes that break down food</li>
  <li>Other genetic factors</li>
</ul>

<p>The intensity of symptoms caused by lactose intolerance can range from mild to severe. You could be having a reaction and not notice it. It’s also possible that symptoms may not show up until you’re older.</p>`,
  },
  lactoseSection1Subcontent2Tooltip: {
    description: 'lactose trait results section 1 subcontent 2 text tooltip',
    defaultMessage:
      'Genetic variants are differences in our genes that make us unique. Everyone has millions of variants in their DNA. They influence things like the way we look, the foods we like or dislike, and how our bodies work. Genetic variants are usually inherited from our parents. This is why we have so much in common with members of our family.',
  },
  lactoseSection1Subcontent2TooltipLabel: {
    description: 'lactose trait results section 1 subcontent 2 tooltip accessible label',
    defaultMessage: 'Lactose Genetic Variant Info',
  },
  lactoseSection1Subcontent3Title: {
    description: 'lactose trait results section 1 subcontent 3 title',
    defaultMessage: 'What else could be causing your upset stomach?',
  },
  lactoseSection2Title: {
    description: 'lactose trait results section 2 title',
    defaultMessage: 'How to minimize symptoms and maximize nutrition',
  },
  lactoseSection2Subcontent1Text: {
    description: 'lactose trait results section 2 subcontent 1 text',
    defaultMessage:
      '<p>While the symptoms of lactose intolerance can be uncomfortable, they can usually be managed. Here are some ways to reduce lactose and still get enough calcium and vitamin D in your diet. Calcium and vitamin D are needed to build and maintain bone strength throughout life.</p>',
  },
  lactoseSection2Subcontent2Title: {
    description: 'lactose trait results section 2 subcontent 2 title',
    defaultMessage: 'Consider reducing lactose.',
  },
  lactoseSection2Subcontent3Title: {
    description: 'lactose trait results section 2 subcontent 3 title',
    defaultMessage: 'Try other sources of calcium.',
  },
  lactoseSection2Subcontent3Text: {
    description: 'lactose trait results section 2 subcontent 3 text',
    defaultMessage:
      'If dairy isn’t going to work for you at all, there are other ways to get calcium. The following foods are rich in calcium, and can help build and maintain bone strength:<sup>5</sup>',
  },
  lactoseSection2Subcontent4Title: {
    description: 'lactose trait results section 2 subcontent 4 title',
    defaultMessage: 'What about vitamin D?',
  },
  lactoseSection2Subcontent4Text: {
    description: 'lactose trait results section 2 subcontent 4 text',
    defaultMessage:
      'Don’t forget your vitamin D. While you can take a supplement, you can also find it in the following foods:<sup>6</sup>',
  },
  lactoseSection3Subcontent2Title: {
    description: 'lactose trait results section 3 subcontent 2 title',
    defaultMessage: 'Scientific details',
  },
  lactoseSection3Subcontent2Text: {
    description: 'lactose trait results section 3 subcontent 2 text',
    defaultMessage:
      'The <i>LCT</i> gene is responsible for making lactase. The position in the DNA that was analyzed is near the <i>LCT</i> gene and helps regulate when and how much lactase enzyme the <i>LCT</i> gene makes.<sup>8</sup>',
  },
  dnaSectionTitle: {
    description: 'trait results DNA section title',
    defaultMessage: 'What we looked at and why',
  },
});

interface SubcontentMessages {
  title?: string;
  image?: JSX.Element;
  content: JSX.Element;
}

type SectionColumn = SubcontentMessages[];

interface TraitResultsSectionContent {
  title: string;
  image?: JSX.Element;
  columns: SectionColumn[];
}

function getContent(
  intl: IntlShape,
  traitOutcome: TraitOutcome
): Record<Trait, TraitResultsSectionContent[]> {
  return {
    [Trait.BITTERNESS]: [
      {
        title: intl.formatMessage(messages.bitternessSection1Title),
        columns: [
          [
            {
              title: intl.formatMessage(messages.bitternessSection1Subcontent1Title),
              content: (
                <>
                  <FormattedMessage {...messages.bitternessSection1Subcontent1Text} />
                  <Spacer variant="large" />
                  <IconBulletList
                    listItems={[
                      {
                        deprecatedImage: (
                          <img
                            src={TraitReportImages('./bitter-taste-bullet.svg').default}
                            alt=""
                          />
                        ),
                        content: (
                          <FormattedMessage
                            defaultMessage="<b>Bitter:</b> such as coffee, kale, and chocolate"
                            description="bitterness section 1 subcontent 1 bullet point 1"
                          />
                        ),
                        contentId: 'bitter-taste',
                      },
                      {
                        deprecatedImage: (
                          <img src={TraitReportImages('./salty-taste-bullet.svg').default} alt="" />
                        ),
                        content: (
                          <FormattedMessage
                            defaultMessage="<b>Salty:</b> such as pretzels, popcorn, and french fries"
                            description="bitterness section 1 subcontent 1 bullet point 2"
                          />
                        ),
                        contentId: 'salty-taste',
                      },
                      {
                        deprecatedImage: (
                          <img src={TraitReportImages('./sour-taste-bullet.svg').default} alt="" />
                        ),
                        content: (
                          <FormattedMessage
                            defaultMessage="<b>Sour:</b> such as lemons, rhubarb, and sauerkraut"
                            description="bitterness section 1 subcontent 1 bullet point 3"
                          />
                        ),
                        contentId: 'sour-taste',
                      },
                      {
                        deprecatedImage: (
                          <img src={TraitReportImages('./sweet-taste-bullet.svg').default} alt="" />
                        ),
                        content: (
                          <FormattedMessage
                            defaultMessage="<b>Sweet:</b> such as candies, strawberries, and cakes"
                            description="bitterness section 1 subcontent 1 bullet point 4"
                          />
                        ),
                        contentId: 'sweet-taste',
                      },
                      {
                        deprecatedImage: (
                          <img src={TraitReportImages('./umami-taste-bullet.svg').default} alt="" />
                        ),
                        content: (
                          <FormattedMessage
                            defaultMessage="<b>Umami, or savory:</b> such as mushrooms, soy sauce, and cured meats"
                            description="bitterness section 1 subcontent 1 bullet point 5"
                          />
                        ),
                        contentId: 'umami-taste',
                      },
                    ]}
                  />
                </>
              ),
            },
            {
              title: intl.formatMessage(messages.bitternessSection1Subcontent2Title),
              content: <FormattedMessage {...messages.bitternessSection1Subcontent2Text} />,
            },
          ],
          [
            {
              title: intl.formatMessage(messages.bitternessSection1Subcontent3Title),
              content: <FormattedMessage {...messages.bitternessSection1Subcontent3Text} />,
            },
            {
              title: intl.formatMessage(messages.bitternessSection1Subcontent4Title),
              content: <FormattedMessage {...messages.bitternessSection1Subcontent4Text} />,
            },
          ],
        ],
      },
      {
        title: intl.formatMessage(messages.bitternessSection2Title),
        image: <img src={TraitReportImages('./bitter-foods.svg').default} alt="" />,
        columns: [
          [
            {
              title: intl.formatMessage(messages.bitternessSection2Subcontent1Title),
              content: <FormattedMessage {...messages.bitternessSection2Subcontent1Text} />,
            },
          ],
          [
            {
              title: intl.formatMessage(messages.bitternessSection2Subcontent2Title),
              content: <FormattedMessage {...messages.bitternessSection2Subcontent2Text} />,
            },
          ],
        ],
      },
      {
        title: intl.formatMessage(messages.dnaSectionTitle),
        columns: [
          [
            {
              content: (
                <FormattedMessage
                  description="bitterness trait results section 3 subcontent 1 text"
                  defaultMessage="<p>We looked at a place in your DNA that influences if you can taste a bitter compound called phenylthiocarbamide, or PTC.<sup>2</sup> While PTC itself does not exist in food, some vegetables contain a bitter compound similar to PTC.</p><ul><li><b>People who can taste PTC</b> can taste similar bitter compounds in vegetables like raw broccoli and brussels sprouts. About 75% of people are able to taste PTC and related compounds.<sup>3</sup></li><li><b>People who can't taste PTC</b> don't taste similar bitter compounds in food.</li></ul><p>Bitter taste perception is also influenced by other genetic and non-genetic factors.</p>"
                />
              ),
            },
            {
              title: intl.formatMessage(messages.bitternessSection3Subcontent2Title),
              content: (
                <FormattedMessage
                  {...messages.bitternessSection3Subcontent2Text}
                  values={{
                    tooltip: (
                      <GpTooltip
                        aria-label={intl.formatMessage(
                          messages.bitternessSection3Subcontent2TooltipLabel
                        )}
                        content={
                          <Text>
                            <FormattedMessage {...messages.bitternessSection3Subcontent2Tooltip} />
                          </Text>
                        }
                      />
                    ),
                  }}
                />
              ),
            },
          ],
          [
            {
              content: (
                <TraitGenotypeChart
                  gene="TAS2R38"
                  rsids={traitOutcome.rsids}
                  genotypes={traitOutcome.genotypes}
                />
              ),
            },
          ],
        ],
      },
    ],
    [Trait.CILANTRO]: [
      {
        title: intl.formatMessage(messages.cilantroSection1Title),
        image: <img src={TraitReportImages('./cilantro-people.svg').default} alt="" />,
        columns: [
          [
            {
              title: intl.formatMessage(messages.cilantroSection1Subcontent1Title),
              content: <FormattedMessage {...messages.cilantroSection1Subcontent1Text} />,
            },
          ],
          [
            {
              title: intl.formatMessage(messages.cilantroSection1Subcontent2Title),
              content: <FormattedMessage {...messages.cilantroSection1Subcontent2Text} />,
            },
          ],
        ],
      },
      {
        title: intl.formatMessage(messages.cilantroSection2Title),
        columns: [
          [
            {
              title: intl.formatMessage(messages.cilantroSection2Subcontent1Title),
              content: <FormattedMessage {...messages.cilantroSection2Subcontent1Text} />,
            },
            {
              title: intl.formatMessage(messages.cilantroSection2Subcontent2Title),
              content: <FormattedMessage {...messages.cilantroSection2Subcontent2Text} />,
            },
          ],
          [
            {
              title: intl.formatMessage(messages.cilantroSection2Subcontent3Title),
              image: <img src={TraitReportImages('./cilantro-leaf.svg').default} alt="" />,
              content: <FormattedMessage {...messages.cilantroSection2Subcontent3Text} />,
            },
          ],
        ],
      },
      {
        title: intl.formatMessage(messages.dnaSectionTitle),
        columns: [
          [
            {
              content: (
                <FormattedMessage
                  description="cilantro trait results section 3 subcontent 1 text"
                  defaultMessage="<p>We looked at a place in your DNA that influences if you have a slightly higher chance of liking or disliking cilantro.<sup>2</sup> The percent of people across the world who dislike cilantro ranges from 3-21%.<sup>5</sup></p><ul><li><b>People who have slightly higher chances of liking cilantro</b> may find it fragrant and citrusy.</li><li><b>People who have slightly higher chances of disliking cilantro</b> may find it soapy or moldy.</li></ul><p>This place in your DNA only predicts a small amount of your chances of liking or disliking cilantro. Environmental and other genetic factors also play a role.</p>"
                />
              ),
            },
            {
              title: intl.formatMessage(messages.cilantroSection3Subcontent2Title),
              content: <FormattedMessage {...messages.cilantroSection3Subcontent2Text} />,
            },
          ],
          [
            {
              content: (
                <TraitGenotypeChart
                  gene="OR6A2"
                  isNearGene
                  rsids={traitOutcome.rsids}
                  genotypes={traitOutcome.genotypes}
                />
              ),
            },
          ],
        ],
      },
    ],
    [Trait.EARWAX]: [
      {
        title: intl.formatMessage(messages.earwaxSection1Title),
        columns: [
          [
            {
              title: intl.formatMessage(messages.earwaxSection1Subcontent1Title),
              content: <FormattedMessage {...messages.earwaxSection1Subcontent1Text} />,
            },
            {
              title: intl.formatMessage(messages.earwaxSection1Subcontent2Title),
              content: (
                <>
                  <FormattedMessage {...messages.earwaxSection1Subcontent2Text} />
                  <Spacer variant="large" />
                  <IconBulletList
                    listItems={[
                      {
                        deprecatedImage: (
                          <img src={TraitReportImages('./earwax-ear-bullet.svg').default} alt="" />
                        ),
                        content: (
                          <FormattedMessage
                            defaultMessage="Prevents the ear canal and eardrum from drying out"
                            description="earwax section 1 subcontent 2 bullet point 1"
                          />
                        ),
                        contentId: 'earwax-ear',
                      },
                      {
                        deprecatedImage: (
                          <img
                            src={TraitReportImages('./earwax-water-bullet.svg').default}
                            alt=""
                          />
                        ),
                        content: (
                          <FormattedMessage
                            defaultMessage="Helps keep water away from the eardrum"
                            description="earwax section 1 subcontent 2 bullet point 2"
                          />
                        ),
                        contentId: 'earwax-water',
                      },
                      {
                        deprecatedImage: (
                          <img src={TraitReportImages('./earwax-dust-bullet.svg').default} alt="" />
                        ),
                        content: (
                          <FormattedMessage
                            defaultMessage="Traps dust, bugs, and other foreign objects"
                            description="earwax section 1 subcontent 2 bullet point 3"
                          />
                        ),
                        contentId: 'earwax-dust',
                      },
                    ]}
                  />
                </>
              ),
            },
          ],
          [
            {
              title: intl.formatMessage(messages.earwaxSection1Subcontent3Title),
              content: <FormattedMessage {...messages.earwaxSection1Subcontent3Text} />,
            },
          ],
        ],
      },
      {
        title: intl.formatMessage(messages.earwaxSection2Title),
        columns: [
          [
            {
              title: intl.formatMessage(messages.earwaxSection2Subcontent1Title),
              image: <img src={TraitReportImages('./earwax-body-odor.svg').default} alt="" />,
              content: <FormattedMessage {...messages.earwaxSection2Subcontent1Text} />,
            },
          ],
          [
            {
              title: intl.formatMessage(messages.earwaxSection2Subcontent2Title),
              image: <img src={TraitReportImages('./earwax-whale.svg').default} alt="" />,
              content: <FormattedMessage {...messages.earwaxSection2Subcontent2Text} />,
            },
          ],
        ],
      },
      {
        title: intl.formatMessage(messages.dnaSectionTitle),
        columns: [
          [
            {
              content: (
                <FormattedMessage
                  description="earwax trait results section 3 subcontent 1 text"
                  defaultMessage="<p>We looked at a place in your DNA that influences whether your earwax is wet or dry.<sup>11</sup> Humans have two types of earwax:<sup>12</sup></p><ul><li><b>People who have dry earwax</b> have flaky, crumbly earwax that is light in color. It may be tan or grey.</li><li><b>People who have wet earwax</b> have sticky, thick earwax that's darker in color. It may be yellow or brown.</li></ul><p>While this place in your DNA strongly predicts the consistency of your earwax, other environmental or genetic factors may play a role.</p>"
                />
              ),
            },
            {
              title: intl.formatMessage(messages.earwaxSection3Subcontent2Title),
              content: <FormattedMessage {...messages.earwaxSection3Subcontent2Text} />,
            },
          ],
          [
            {
              content: (
                <TraitGenotypeChart
                  gene="ABCC11"
                  rsids={traitOutcome.rsids}
                  genotypes={traitOutcome.genotypes}
                />
              ),
            },
          ],
        ],
      },
    ],
    [Trait.LACTOSE]: [
      {
        title: intl.formatMessage(messages.lactoseSection1Title),
        columns: [
          [
            {
              title: intl.formatMessage(messages.lactoseSection1Subcontent1Title),
              content: <FormattedMessage {...messages.lactoseSection1Subcontent1Text} />,
            },
            {
              title: intl.formatMessage(messages.lactoseSection1Subcontent2Title),
              content: (
                <FormattedMessage
                  {...messages.lactoseSection1Subcontent2Text}
                  values={{
                    tooltip: (
                      <GpTooltip
                        aria-label={intl.formatMessage(
                          messages.lactoseSection1Subcontent2TooltipLabel
                        )}
                        content={
                          <Text>
                            <FormattedMessage {...messages.lactoseSection1Subcontent2Tooltip} />
                          </Text>
                        }
                      />
                    ),
                  }}
                />
              ),
            },
          ],
          [
            {
              title: intl.formatMessage(messages.lactoseSection1Subcontent3Title),
              image: <img src={TraitReportImages('./lactose-upset-stomach.svg').default} alt="" />,
              content: (
                <FormattedMessage
                  description="lactose trait results section 1 subcontent 3 text"
                  defaultMessage="<p>Bloating, gas, and stomach pain are relatively common and might happen as a result of:<sup>2</sup></p><ul><li>Sensitivities to foods, such as fiber-rich foods (e.g. beans), spicy foods, or greasy/fatty foods</li><li>An allergy to cow’s milk, usually a reaction to certain proteins like casein or whey</li><li>An imbalance of the bacteria that lives in the gut</li><li>Other digestive issues like irritable bowel syndrome, celiac disease, surgeries, or digestive tract infections (which may also lead to a decrease in the amount of lactase in the small intestine)</li><li>Other genetic variants that we did not look at</li></ul>"
                />
              ),
            },
          ],
        ],
      },
      {
        title: intl.formatMessage(messages.lactoseSection2Title),
        columns: [
          [
            {
              content: <FormattedMessage {...messages.lactoseSection2Subcontent1Text} />,
            },
            {
              title: intl.formatMessage(messages.lactoseSection2Subcontent2Title),
              image: <img src={TraitReportImages('./lactose-foods.svg').default} alt="" />,
              content: (
                <FormattedMessage
                  description="lactose trait results section 2 subcontent 2 text"
                  defaultMessage="<p>Many people with lactose intolerance can consume small amounts of foods with lactose and not experience symptoms. About 12g of lactose (the amount you’d find in a cup of milk) should be OK for most people.<sup>1,3</sup> Some people might find it helpful to try:<sup>2,4</sup></p><ul><li>Smaller amounts of food with lactose eaten throughout the day (rather than large amounts all at once)</li><li>Aged dairy products like hard cheeses, which tend to have less lactose</li><li>Fermented dairy products, like yogurt with live cultures</li><li>Lactase supplements, probiotics, or lactose-reduced milk</li></ul>"
                />
              ),
            },
          ],
          [
            {
              title: intl.formatMessage(messages.lactoseSection2Subcontent3Title),
              content: (
                <>
                  <FormattedMessage {...messages.lactoseSection2Subcontent3Text} />
                  <Spacer variant="small" />
                  <IconBulletList
                    alignListItemText="flex-start"
                    listItems={[
                      {
                        deprecatedImage: (
                          <img src={TraitReportImages('./checkmark-bullet.svg').default} alt="" />
                        ),
                        content: (
                          <FormattedMessage
                            defaultMessage="Dark leafy greens, like spinach, kale, or collard greens"
                            description="lactose section 2 subcontent 3 bullet point 1"
                          />
                        ),
                        contentId: 'lactose-section-2-bullet-1',
                      },
                      {
                        deprecatedImage: (
                          <img src={TraitReportImages('./checkmark-bullet.svg').default} alt="" />
                        ),
                        content: (
                          <FormattedMessage
                            defaultMessage="Fresh or canned fish"
                            description="lactose section 2 subcontent 3 bullet point 2"
                          />
                        ),
                        contentId: 'lactose-section-2-bullet-2',
                      },
                      {
                        deprecatedImage: (
                          <img src={TraitReportImages('./checkmark-bullet.svg').default} alt="" />
                        ),
                        content: (
                          <FormattedMessage
                            defaultMessage="Foods that have had extra calcium added to them, like orange juice or some cereals"
                            description="lactose section 2 subcontent 3 bullet point 3"
                          />
                        ),
                        contentId: 'lactose-section-2-bullet-3',
                      },
                    ]}
                  />
                </>
              ),
            },
            {
              title: intl.formatMessage(messages.lactoseSection2Subcontent4Title),
              content: (
                <>
                  <FormattedMessage {...messages.lactoseSection2Subcontent4Text} />
                  <Spacer variant="large" />
                  <IconBulletList
                    listItems={[
                      {
                        deprecatedImage: (
                          <img
                            src={TraitReportImages('./lactose-fish-bullet.svg').default}
                            alt=""
                          />
                        ),
                        content: (
                          <FormattedMessage
                            defaultMessage="Fatty fish, like tuna, mackerel, or salmon"
                            description="lactose section 2 subcontent 4 bullet point 1"
                          />
                        ),
                        contentId: 'lactose-fish',
                      },
                      {
                        deprecatedImage: (
                          <img
                            src={TraitReportImages('./lactose-orange-juice-bullet.svg').default}
                            alt=""
                          />
                        ),
                        content: (
                          <FormattedMessage
                            defaultMessage="Vitamin D-fortified foods, like orange juice, soy milk, or cereals"
                            description="lactose section 2 subcontent 4 bullet point 2"
                          />
                        ),
                        contentId: 'lactose-orange-juice',
                      },
                      {
                        deprecatedImage: (
                          <img
                            src={TraitReportImages('./lactose-beef-liver-bullet.svg').default}
                            alt=""
                          />
                        ),
                        content: (
                          <FormattedMessage
                            defaultMessage="Beef liver"
                            description="lactose section 2 subcontent 4 bullet point 3"
                          />
                        ),
                        contentId: 'lactose-beef-liver',
                      },
                      {
                        deprecatedImage: (
                          <img
                            src={TraitReportImages('./lactose-egg-yolk-bullet.svg').default}
                            alt=""
                          />
                        ),
                        content: (
                          <FormattedMessage
                            defaultMessage="Egg yolks"
                            description="lactose section 2 subcontent 4 bullet point 4"
                          />
                        ),
                        contentId: 'lactose-egg-yolk',
                      },
                    ]}
                  />
                </>
              ),
            },
          ],
        ],
      },
      {
        title: intl.formatMessage(messages.dnaSectionTitle),
        columns: [
          [
            {
              content: (
                <FormattedMessage
                  description="lactose trait results section 3 subcontent 1 text"
                  defaultMessage="<p>We looked at a place in your DNA that influences how much lactase enzyme your body makes.</p><ul><li><b>People who do not experience lactose intolerance</b> are usually able to digest lactose because they produce enough lactase enzyme</li><li><b>People who experience lactose intolerance</b> may not be able to digest lactose because they don’t produce enough lactase enzyme</li></ul><p>Lactose intolerance may be caused by other genetic and non-genetic factors.</p>"
                />
              ),
            },
            {
              title: intl.formatMessage(messages.lactoseSection3Subcontent2Title),
              content: <FormattedMessage {...messages.lactoseSection3Subcontent2Text} />,
            },
          ],
          [
            {
              content: (
                <TraitGenotypeChart
                  gene="LCT"
                  isNearGene
                  rsids={traitOutcome.rsids}
                  genotypes={traitOutcome.genotypes}
                />
              ),
            },
          ],
        ],
      },
    ],
  };
}

export function useTraitResultsSectionContent(
  trait: Trait,
  traitOutcome: TraitOutcome,
  section: number
): TraitResultsSectionContent {
  const intl = useIntl();
  return getContent(intl, traitOutcome)[trait][section - 1];
}
