import { MessageDescriptor, defineMessages } from 'react-intl';

import { Trait } from 'components/TraitResults/types';

const messages = defineMessages({
  bitternessTitle: {
    description: 'bitterness taste dashboard card title',
    defaultMessage: 'Bitter taste perception',
  },
  bitternessSubtitle: {
    description: 'bitterness taste dashboard card subtitle',
    defaultMessage: 'Learn what your genes can tell you about how you taste bitter things.',
  },
  bitternessAccessibleButtonText: {
    description: 'bitterness view results accessible button text',
    defaultMessage: 'View bitterness taste perception trait results',
  },
  cilantroTitle: {
    description: 'cilantro dashboard card title',
    defaultMessage: 'Cilantro preference',
  },
  cilantroSubtitle: {
    description: 'cilantro dashboard card subtitle',
    defaultMessage:
      'Your genes play a role in whether you love cilantro or think it tastes like soap.',
  },
  cilantroAccessibleButtonText: {
    description: 'cilantro view results accessible button text',
    defaultMessage: 'View cilantro preference trait results',
  },
  earwaxTitle: {
    description: 'earwax dashboard card title',
    defaultMessage: 'Earwax type',
  },
  earwaxSubtitle: {
    description: 'earwax dashboard card subtitle',
    defaultMessage: 'Flaky or sticky? Earwax type is in your genes.',
  },
  earwaxAccessibleButtonText: {
    description: 'earwax view results accessible button text',
    defaultMessage: 'View earwax type trait results',
  },
  lactoseTitle: {
    description: 'lactose dashboard card title',
    defaultMessage: 'Lactose intolerance',
  },
  lactoseSubtitle: {
    description: 'lactose dashboard card subtitle',
    defaultMessage: 'Your genes have a say in how well you digest lactose products like milk.',
  },
  lactoseAccessibleButtonText: {
    description: 'lactose view results accessible button text',
    defaultMessage: 'View lactose intolerance trait results',
  },
});

export const TRAIT_RESULT_CARD_CONTENT: Record<
  Trait,
  { title: MessageDescriptor; subtitle: MessageDescriptor; accessibleButtonText: MessageDescriptor }
> = {
  [Trait.BITTERNESS]: {
    title: messages.bitternessTitle,
    subtitle: messages.bitternessSubtitle,
    accessibleButtonText: messages.bitternessAccessibleButtonText,
  },
  [Trait.CILANTRO]: {
    title: messages.cilantroTitle,
    subtitle: messages.cilantroSubtitle,
    accessibleButtonText: messages.cilantroAccessibleButtonText,
  },
  [Trait.EARWAX]: {
    title: messages.earwaxTitle,
    subtitle: messages.earwaxSubtitle,
    accessibleButtonText: messages.earwaxAccessibleButtonText,
  },
  [Trait.LACTOSE]: {
    title: messages.lactoseTitle,
    subtitle: messages.lactoseSubtitle,
    accessibleButtonText: messages.lactoseAccessibleButtonText,
  },
};
