import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  disclaimerText: {
    description: 'All of Us footer disclaimer test',
    defaultMessage:
      '<i>All of Us</i> is a research program and does not provide health care or medical advice.',
  },
  contactSectionTitle: {
    description: 'All of Us footer contact section title',
    defaultMessage: 'Contact',
  },
  learnMoreSectionTitle: {
    description: 'All of Us footer learn more section title',
    defaultMessage: 'Learn more',
  },
  followUsSectionTitle: {
    description: 'All of Us footer follow us section title',
    defaultMessage: 'Follow us',
  },
  faq: {
    description: 'All of Us footer faq link text',
    defaultMessage: 'FAQ',
  },
  copyright: {
    description: 'All of Us footer copyright text',
    defaultMessage: 'Copyright © 2021',
  },
  privacyPolicy: {
    description: 'All of Us footer privacy policy link text',
    defaultMessage: 'Privacy Policy',
  },
  securityPolicy: {
    description: 'All of Us footer security policy link text',
    defaultMessage: 'Security Policy',
  },
  terms: {
    description: 'All of Us footer terms link text',
    defaultMessage: 'Terms',
  },
  liveChat: {
    description: 'All of Us footer live chat link text',
    defaultMessage: 'Live chat',
  },
  ttyText: {
    description: 'All of Us footer TTY text',
    defaultMessage: 'TTY dial 711',
  },
  subscribeToNewsletter: {
    description: 'All of Us footer subscribe to newsletter text',
    defaultMessage: 'Subscribe to Newsletter',
  },
});

export const useFooterContent = () => {
  const intl = useIntl();
  return {
    disclaimerText: intl.formatMessage(messages.disclaimerText),
    contactSectionTitle: intl.formatMessage(messages.contactSectionTitle),
    learnMoreSectionTitle: intl.formatMessage(messages.learnMoreSectionTitle),
    followUsSectionTitle: intl.formatMessage(messages.followUsSectionTitle),
    faq: intl.formatMessage(messages.faq),
    copyright: intl.formatMessage(messages.copyright),
    privacyPolicy: intl.formatMessage(messages.privacyPolicy),
    securityPolicy: intl.formatMessage(messages.securityPolicy),
    terms: intl.formatMessage(messages.terms),
    liveChat: intl.formatMessage(messages.liveChat),
    ttyText: intl.formatMessage(messages.ttyText),
    subscribeToNewsletter: intl.formatMessage(messages.subscribeToNewsletter),
  };
};
