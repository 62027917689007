import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { AncestryPopulations } from './AncestryPopulations';
import { AncestryResultsOverview } from './AncestryResultsOverview/AncestryResultsOverview';

export const AncestryResults: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <AncestryResultsOverview />
      </Route>
      <Route exact path={`${path}/populations`}>
        <AncestryPopulations />
      </Route>
      <Route>Not Found</Route>
    </Switch>
  );
};
