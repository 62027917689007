import { Revision } from '@color/aou-reports';
import { Spacer, Text, Title } from '@color/continuum';
import { formatLongIsoDateString, usePreferredLocale } from '@color/lib';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { RevisionDescription } from './RevisionDescription';

interface IRevisionListItem {
  revision: Revision;
}
export const RevisionListItem: FC<IRevisionListItem> = (props) => {
  const { revision } = props;
  const { reportDate, revisionReason } = revision;
  const { locale } = usePreferredLocale();

  return (
    <>
      <Title variant="headline3">
        <FormattedMessage
          id="Hdr_Report_Revision_Date"
          values={{ date: formatLongIsoDateString(reportDate, locale) }}
        />
      </Title>
      <Spacer variant="medium" />

      <Text>{revisionReason && <RevisionDescription revisionReason={revisionReason} />}</Text>
      <Spacer variant="medium" />
    </>
  );
};
