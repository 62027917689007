import { MessageDescriptor, defineMessages } from 'react-intl';

import { Trait } from '../../types';

const messages = defineMessages({
  bitterTitle: {
    description: 'bitter trait results header title',
    defaultMessage: 'Bitter taste perception',
  },
  bitterSubtitle: {
    description: 'bitter trait results header subtitle',
    defaultMessage: 'Some people taste bitterness in foods more than others.',
  },
  cilantroTitle: {
    description: 'cilantro trait results header title',
    defaultMessage: 'Cilantro preference',
  },
  cilantroSubtitle: {
    description: 'cilantro trait results header subtitle',
    defaultMessage: 'Some people like the taste of cilantro and others think it tastes like soap.',
  },
  earwaxTitle: {
    description: 'earwax trait results header title',
    defaultMessage: 'Earwax type',
  },
  earwaxSubtitle: {
    description: 'earwax trait results header subtitle',
    defaultMessage: 'Did you know that your earwax might be wet or dry, depending on your genes?',
  },
  lactoseTitle: {
    description: 'lactose trait results header title',
    defaultMessage: 'Lactose intolerance',
  },
  lactoseSubtitle: {
    description: 'lactose trait results header subtitle',
    defaultMessage:
      'Lactose intolerance means you have trouble digesting something called lactose that’s in dairy products like milk or ice cream.',
  },
});

interface TraitResultHeaderMessages {
  title: MessageDescriptor;
  subtitle: MessageDescriptor;
}

export const TRAIT_RESULTS_HEADER_CONTENT: Record<Trait, TraitResultHeaderMessages> = {
  [Trait.BITTERNESS]: {
    title: messages.bitterTitle,
    subtitle: messages.bitterSubtitle,
  },
  [Trait.CILANTRO]: {
    title: messages.cilantroTitle,
    subtitle: messages.cilantroSubtitle,
  },
  [Trait.EARWAX]: {
    title: messages.earwaxTitle,
    subtitle: messages.earwaxSubtitle,
  },
  [Trait.LACTOSE]: {
    title: messages.lactoseTitle,
    subtitle: messages.lactoseSubtitle,
  },
};
