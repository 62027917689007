import { ActionLink, Container, ExternalLink, Spacer, Title } from '@color/continuum';
import { scrollIntoView } from '@color/lib';
import { useRef } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';

import { AncestryTutorial } from 'components/AncestryTutorial/AncestryTutorial';
import { BackButtonBar } from 'components/common/BackButtonBar';
import { AncestryAndTraitsOtherResultsSection } from 'components/common/Reports/AncestryAndTraitsOtherResultsSection/AncestryAndTraitsOtherResultsSection';
import { DisclaimerSection } from 'components/common/Reports/DisclaimerSection/DisclaimerSection';
import { ExpansionPanel } from 'components/common/Reports/ExpansionPanel/ExpansionPanel';
import { QuizAndMoreInformationSection } from 'components/common/Reports/QuizAndMoreInformationSection';
import { References } from 'components/common/Reports/References/References';
import { SimplePage } from 'components/common/SimplePage/SimplePage';

import { useAncestryQuery } from '../api';
import { useAncestryPopulationsContent } from '../content';
import { AncestryComposition } from '../types';
import { getNonZeroCompositions } from '../util';
import { AncestryResultsHeader } from './components/AncestryResultsHeader/AncestryResultsHeader';
import { InfoCarousel } from './components/InfoCarousel/InfoCarousel';
import { PopulationDetails } from './components/MapAndPopulationDetails/components/PopulationDetails/PopulationDetails';
import { MapAndPopulationDetails } from './components/MapAndPopulationDetails/MapAndPopulationDetails';

const messages = defineMessages({
  ancestryPageTitle: {
    description: 'Ancestry Page Title',
    defaultMessage: 'Genetic Ancestry',
  },
});

export const AncestryResultsOverview: React.FC = () => {
  const { data: ancestryData, error, isLoading } = useAncestryQuery({ logView: true });
  const ancestryContent = useAncestryPopulationsContent();
  const quizSectionRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  if (isLoading) {
    return null;
  }

  if (error) {
    return <Redirect to="/" />;
  }

  return (
    <SimplePage helmetTitle={intl.formatMessage(messages.ancestryPageTitle)}>
      <BackButtonBar to="/" />
      <Spacer variant="xLarge" />
      <AncestryResultsHeader />
      <Spacer variant="xxLarge" />
      {ancestryData && (
        <>
          <MapAndPopulationDetails />
          {getNonZeroCompositions(ancestryData.compositions).map(
            (composition: AncestryComposition) => {
              const { title, descriptions, facts, carousel } = ancestryContent.populations[
                composition.population
              ];
              return (
                <Container key={composition.population}>
                  <PopulationDetails
                    population={composition.population}
                    title={title}
                    descriptions={descriptions}
                    facts={facts}
                  />
                  <Spacer variant="xxxLarge" />
                  <InfoCarousel content={carousel} />
                  <Spacer variant="xLarge" />
                  <Spacer variant="xLarge" />
                </Container>
              );
            }
          )}
        </>
      )}
      <AncestryTutorial />
      <Container>
        <Spacer variant="xLarge" />
        <Spacer variant="xLarge" />
        <Title>
          <FormattedMessage
            description="Ancestry results information section title"
            defaultMessage="Covering the basics: ancestry, genetic ancestry, and ethnicity"
          />
        </Title>
        <Spacer variant="xLarge" />
        <ExpansionPanel
          title={
            <FormattedMessage
              description="Ancestry results overview expansion panel 1 title"
              defaultMessage="What is ancestry?"
            />
          }
          text={
            <FormattedMessage
              description="Ancestry results overview expansion panel 1 body"
              defaultMessage="Your ancestry is who you descended from and where they lived. This information is usually based on stories passed down from generation to generation. Historical records also help. All this information can be used to help build your family tree."
            />
          }
        />
        <Spacer variant="medium" />
        <ExpansionPanel
          title={
            <FormattedMessage
              description="Ancestry results overview expansion panel 2 title"
              defaultMessage="What is genetic ancestry?"
            />
          }
          text={
            <FormattedMessage
              description="Ancestry results overview expansion panel 2 body"
              defaultMessage="Your genetic ancestry tells you where your recent ancestors likely lived. This is based on your DNA. Before global travel became possible, people from the same region shared patterns of DNA. As they migrated and blended with others from different geographic regions, these DNA patterns became mixed."
            />
          }
        />
        <Spacer variant="medium" />
        <ExpansionPanel
          title={
            <FormattedMessage
              description="Ancestry results overview expansion panel 3 title"
              defaultMessage="How is genetic ancestry different from race and ethnicity?"
            />
          }
          text={
            <FormattedMessage
              description="Ancestry results overview expansion panel 3 body"
              defaultMessage="Race and ethnicity are categories created by humans and are not determined by your DNA. They are usually based on physical features such as skin color, or shared language and culture. Your genetic ancestry also may not match your family stories or religious and cultural beliefs. People of the same ethnicity often share the same genetic ancestry. But this isn't always the case."
            />
          }
        />
        <Spacer variant="medium" />
        <ExpansionPanel
          title={
            <FormattedMessage
              description="Ancestry results overview expansion panel 4 title"
              defaultMessage="Why might my genetic ancestry insights be different from what I expected?"
            />
          }
          text={
            <FormattedMessage
              description="Ancestry results overview expansion panel 2 body"
              defaultMessage="Genetic ancestry tells you where your recent ancestors likely lived in the last few hundred years. The countries and borders we know today were different then. This may lead to surprising genetic likeness between parts of the world that seem very different now. Are your results surprising? {tellUsMoreLink}"
              values={{
                tellUsMoreLink: (
                  <ActionLink
                    onClick={() => {
                      if (quizSectionRef.current) {
                        scrollIntoView(quizSectionRef.current, { behavior: 'smooth' });
                      }
                    }}
                  >
                    <FormattedMessage
                      description="Tell us more link text"
                      defaultMessage="Tell us more"
                    />
                  </ActionLink>
                ),
              }}
            />
          }
        />
        <Spacer variant="xLarge" />
        <Spacer variant="xLarge" />
        <QuizAndMoreInformationSection
          ref={quizSectionRef}
          quizName="ancestry_survey_01_22"
          moreInformationSection={<AncestryAndTraitsOtherResultsSection />}
        />
      </Container>
      <Spacer variant="xLarge" />
      <Spacer variant="xLarge" />
      <DisclaimerSection
        sections={[
          {
            header: (
              <FormattedMessage
                description="Ancestry disclaimer section 1 header"
                defaultMessage="To determine your genetic ancestry, we compared your DNA to publicly-available information from people in the 1000 Genomes Project,<sup>22</sup> the Human Origins dataset,<sup>12</sup> and the Human Genome Diversity Project.<sup>23</sup>"
              />
            ),
            text: (
              <FormattedMessage
                description="Ancestry disclaimer section 1 text"
                defaultMessage="People who participated in these projects can trace their recent ancestors from the past 400-500 years to specific geographic regions in the Americas, southeast and northern Asia, Europe, the Middle East and North Africa, Oceania, South and West Africa, and sub-Saharan Africa, which we call reference populations."
              />
            ),
          },
          {
            header: (
              <FormattedMessage
                description="Ancestry disclaimer section 2 text"
                defaultMessage="Reference populations represent broad geographic areas, and the people who contributed their information for these projects may be different from you."
              />
            ),
            text: (
              <FormattedMessage
                description="Ancestry disclaimer section 2 text"
                defaultMessage="As we build larger and more precise reference populations, our genetic ancestry groups will become more specific."
              />
            ),
          },
          {
            header: (
              <FormattedMessage
                description="Ancestry disclaimer section 3 header"
                defaultMessage="These results are not intended for clinical use."
              />
            ),
            text: (
              <FormattedMessage
                description="Ancestry disclaimer section 3 text"
                defaultMessage="The health facts for genetic ancestry groups are intended as general information only and should not be understood as a diagnosis or medical advice for you."
              />
            ),
          },
        ]}
      />
      <References
        references={[
          'Posth C, Nakatsuka N, Lazaridis I, et al. Reconstructing the Deep Population History of Central and South America. Cell. 2018;175(5):1185-1197.e22.',
          'Dean L. Blood Group Antigens Are Surface Markers on the Red Blood Cell Membrane. National Center for Biotechnology Information (US); 2005.',
          'Lindo J, Haas R, Hofman C, et al. The genetic prehistory of the Andean highlands 7000 years BP through European contact. Sci Adv. 2018;4(11):eaau4921.',
          'Ávila-Arcos MC, McManus KF, Sandoval K, et al. Population History and Gene Divergence in Native Mexicans Inferred from 76 Human Exomes. Mol Biol Evol. 2020;37(4):994-1006.',
          'Ilardo MA, Moltke I, Korneliussen TS, et al. Physiological and Genetic Adaptations to Diving in Sea Nomads. Cell. 2018;173(3):569-580.e15.',
          'Siska V, Jones ER, Jeon S, et al. Genome-wide data from two early Neolithic East Asian individuals dating to 7700 years ago. Sci Adv. 2017;3(2):e1601877.',
          'Park Y. Why is type 1 diabetes uncommon in Asia? Ann N Y Acad Sci. 2006;1079:31-40.',
          'Olalde I, Allentoft ME, Sánchez-Quinto F, et al. Derived immune and ancestral pigmentation alleles in a 7,000-year-old Mesolithic European. Nature. 2014;507(7491):225-228.',
          <>
            Barnes H. How many redheads are there in the world? BBC.{' '}
            <ExternalLink to="https://www.bbc.com/news/magazine-24331615">
              https://www.bbc.com/news/magazine-24331615
            </ExternalLink>
            . Published October 2, 2013. Accessed September 10, 2018.
          </>,
          'Enattah NS, Jensen TGK, Nielsen M, et al. Independent introduction of two lactase-persistence alleles into human populations reflects different history of adaptation to milk culture. Am J Hum Genet. 2008;82(1):57-72.',
          'Bashwari LA, Al-Mulhim AA, Ahmad MS, Ahmed MA. Frequency of ABO blood groups in the Eastern region of Saudi Arabia. Saudi Med J. 2001;22(11):1008-1012.',
          'Lazaridis I, Nadel D, Rollefson G, et al. Genomic insights into the origin of farming in the ancient Near East. Nature. 2016;536(7617):419-424.',
          'Kayser M. The human genetic history of Oceania: near and remote views of dispersal. Curr Biol. 2010;20(4):R194-R201.',
          <>
            Kenny EE, Timpson NJ, Sikora M, et al. Melanesian Blond Hair Is Caused by an Amino Acid
            Change in TYRP1. Science. 2012;336.{' '}
            <ExternalLink to="http://science.sciencemag.org.ucsf.idm.oclc.org/content/sci/336/6081/554.full.pdf">
              http://science.sciencemag.org.ucsf.idm.oclc.org/content/sci/336/6081/554.full.pdf
            </ExternalLink>
            .
          </>,
          'Hussels IE, Morton NE. Pingelap and Mokil Atolls: achromatopsia. Am J Hum Genet. 1972;24(3):304-309.',
          'Vernot B, Tucci S, Kelso J, et al. Excavating Neandertal and Denisovan DNA from the genomes of Melanesian individuals. Science. 2016;352(6282):235-239.',
          'Swallow DM. Genetics of lactase persistence and lactose intolerance. Annu Rev Genet. 2003;37:197-219.',
          'Modell B, Darlison M. Global epidemiology of haemoglobin disorders and derived service indicators. Bull World Health Organ. 2008;86(6):480-487.',
          'Rees DC, Williams TN, Gladwin MT. Sickle-cell disease. Lancet. 2010;376(9757):2018-2031.',
          'Campbell MC, Tishkoff SA. African genetic diversity: implications for human demographic history, modern human origins, and complex disease mapping. Annu Rev Genomics Hum Genet. 2008;9:403-433.',
          'Adegbidi H, Yedomon H, Atadokpede F, Balley-Pognon M-C, do Ango-Padonou F. Skin cancers at the National University Hospital of Cotonou from 1985 to 2004. Int J Dermatol. 2007;46 Suppl 1:26-29.',
          <>
            1000 Genomes | A Deep Catalog of Human Genetic Variation.{' '}
            <ExternalLink to="http://www.internationalgenome.org/home">
              http://www.internationalgenome.org/home
            </ExternalLink>
            . Accessed March 7, 2019.
          </>,
          'Cavalli-Sforza LL. The Human Genome Diversity Project: past, present and future. Nat Rev Genet. 2005;6(4):333-340.',
        ]}
      />
    </SimplePage>
  );
};
