import { Image } from '@color/continuum';

import { CircularImageType } from 'components/common/CircularImage/types';
import { getCircularImageSrc } from 'components/common/CircularImage/utils';

export const OverviewImage: React.FC = () => {
  const src = getCircularImageSrc(CircularImageType.GENETIC_COUNSELOR);
  return (
    <Image
      src={src}
      alt=""
      sx={{
        height: {
          md: '340px',
        },
        width: {
          xs: '70%',
          md: '340px',
        },
        marginLeft: '15%',
      }}
    />
  );
};
