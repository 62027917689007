import { Container, Divider, ExternalLink, InternalLink, Spacer, Text } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { HDR_APPOINTMENT_SCHEDULING_PATH } from 'components/App/AppRoutes/constants';
import { CircularImageType } from 'components/common/CircularImage/types';
import { ImageWithContentSection } from 'components/HealthRelatedResults/components/ImageWithContentSection/ImageWithContentSection';
import { HDR_UNINFORMATIVE_INFO_SHEET_URLS } from 'lib/constants';

export const DiscussResultsTab: React.FC = () => {
  const { locale } = usePreferredLocale();
  return (
    <Container>
      <Spacer variant="xxLarge" />
      <ImageWithContentSection
        title={
          <FormattedMessage id="HDR_Uninformative_Discuss_results__Discussing_Results_Doctor_Header" />
        }
        description={
          <>
            <Text paragraph>
              <FormattedMessage id="HDR_Uninformative_Discuss_results__Discussing_Results_Doctor_P1" />
            </Text>
            <Text paragraph>
              <FormattedMessage id="HDR_Uninformative_Discuss_results__Discussing_Results_Doctor_P2" />
            </Text>
            <Text paragraph>
              <FormattedMessage
                id="HDR_Uninformative_Discuss_results__Discussing_Results_Doctor_P3"
                values={{
                  link: (chunks: ReactNode) => (
                    <ExternalLink to={HDR_UNINFORMATIVE_INFO_SHEET_URLS[locale]} download>
                      {chunks}
                    </ExternalLink>
                  ),
                }}
              />
            </Text>
          </>
        }
        image={CircularImageType.DOCTOR}
      />
      <Spacer variant="xxxLarge" />
      <Divider aria-hidden="true" />
      <Spacer variant="xxxLarge" />
      <ImageWithContentSection
        title={
          <FormattedMessage id="HDR_Uninformative_Discuss_results__Discussing_Results_Family_Header" />
        }
        description={
          <>
            <Text paragraph>
              <FormattedMessage id="HDR_Uninformative_Discuss_results__Discussing_Results_Family_P1" />
            </Text>
            <Text paragraph>
              <FormattedMessage id="HDR_Uninformative_Discuss_results__Discussing_Results_Family_P2" />
            </Text>
          </>
        }
        image={CircularImageType.FAMILY}
        imageRight
      />
      <Spacer variant="xxxLarge" />
      <Divider aria-hidden="true" />
      <Spacer variant="xxxLarge" />
      <ImageWithContentSection
        title={<FormattedMessage id="HDR_Uninformative_Discuss_More_Questions" />}
        description={
          <Text paragraph>
            <FormattedMessage
              id="HDR_Uninformative_Discuss_results__Questions_Results_P1"
              values={{
                link: (chunks: ReactNode) => (
                  <InternalLink to={HDR_APPOINTMENT_SCHEDULING_PATH}>{chunks}</InternalLink>
                ),
              }}
            />
          </Text>
        }
        image={CircularImageType.GENETIC_COUNSELOR}
      />
      <Spacer variant="medium" />
      <Divider aria-hidden="true" />
      <Spacer variant="medium" />
    </Container>
  );
};
