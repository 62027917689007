import { Grid, Spacer, Title, useMediaQuery, useTheme } from '@color/continuum';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { ParentPopulation } from 'components/AncestryResults/types';

import { PopulationDetailsImage } from './PopulationDetailsImage';
import { ParentPopulationToImageMapping } from './types';

export interface PopulationDetailsProps {
  title: string;
  descriptions: ReactNode[];
  facts: ReactNode[];
  population: ParentPopulation;
}

export const PopulationDetails: React.FC<PopulationDetailsProps> = (
  props: PopulationDetailsProps
) => {
  const { title, descriptions, facts, population } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Grid container spacing={isMobile ? 5 : 10}>
      <Grid item xs={12} sm={7} md={8}>
        <Spacer variant="xLarge" />
        <Spacer variant="xLarge" />
        <Title>{title}</Title>
        <Spacer variant="medium" />
        {descriptions}
        <Title variant="headline3" component="h2">
          <FormattedMessage
            description="Text describing connections of a population"
            defaultMessage="Connections near and far"
          />
        </Title>
        <Spacer variant="small" />
        {facts}
      </Grid>
      <Grid item xs={12} sm={5} md={4}>
        <PopulationDetailsImage image={ParentPopulationToImageMapping[population]} />
      </Grid>
    </Grid>
  );
};
