export const diseases = {
  'Any Cancer': {
    id: 'TP53__disorderFemale1',
  },
  'Brain Cancer': {
    id: 'TP53__disorderFemale3',
  },
  'Breast Cancer': {
    id: 'BRCA1__disorderFemale1',
  },
  'Colorectal Cancer': {
    id: 'APC__disorderFemale1',
  },
  'Kidney Cancer': {
    id: 'PTEN__disorderFemale3',
  },
  Melanoma: {
    id: 'BRCA2__disorderFemale3',
  },
  'Other Cancers': {
    id: 'APC__disorderFemale2',
  },
  'Ovarian Cancer': {
    id: 'BRCA1__disorderFemale2',
  },
  'Pancreatic Cancer': {
    id: 'BRCA1__disorderFemale3',
  },
  'Prostate Cancer': {
    id: 'BRCA1__disorderMale3',
  },
  'Sarcoma (Soft Tissue and Bone)': {
    id: 'TP53__disorderFemale4',
  },
  'Small Bowel Cancer': {
    id: 'MUTYH__disorderFemale2',
  },
  'Stomach Cancer': {
    id: 'BMPR1A__disorderFemale2',
  },
  'Thyroid Cancer': {
    id: 'PTEN__disorderFemale2',
  },
  'Uterine Cancer': {
    id: 'MLH1__disorderFemale1',
  },
};
