import { Box, Spacer, useMediaQuery, useRangedIndex, useTheme } from '@color/continuum';
import { useIntl } from 'react-intl';

import { AncestryTutorialHeader } from './AncestryTutorialHeader';
import { AncestryTutorialLayout } from './AncestryTutorialLayout';
import { getAncestryTutorialContent } from './content';

export const AncestryTutorial: React.FC = () => {
  const intl = useIntl();
  const modules = getAncestryTutorialContent(intl);
  const { index, setIndex, hasNext, hasPrevious, goNext, goPrevious } = useRangedIndex({
    count: modules.length,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box px={isMobile ? 2 : 5}>
      <AncestryTutorialHeader currentStep={index + 1} maxStep={modules.length} />
      <Box
        sx={{
          borderColor: theme.palette.grey[200],
          borderStyle: 'solid',
          borderWidth: '1px',
          borderTopWidth: '0px',
        }}
      >
        <Spacer variant="large" />
        <AncestryTutorialLayout
          {...modules[index]}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          onBack={goPrevious}
          onContinue={goNext}
          onStartOver={() => {
            setIndex(0);
          }}
        />
      </Box>
    </Box>
  );
};
