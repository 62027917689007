import { ParentPopulation } from 'components/AncestryResults/types';

export enum PopulationDetailsImageType {
  AMERICAS = 'americas',
  EAST_AND_NORTH_ASIA = 'east-and-north-asia',
  EUROPE = 'europe',
  MIDDLE_EAST_AND_NORTH_AFRICA = 'middle-east-and-north-africa',
  OCEANIA = 'oceania',
  SOUTHERN_AND_WESTERN_ASIA = 'southern-and-west-asia',
  SUB_SAHARAN_AFRICA = 'sub-saharan-africa',
}

export const ParentPopulationToImageMapping: Record<
  ParentPopulation,
  PopulationDetailsImageType
> = {
  [ParentPopulation.SOUTHERN_AND_WESTERN_ASIA]:
    PopulationDetailsImageType.SOUTHERN_AND_WESTERN_ASIA,
  [ParentPopulation.SUB_SAHARAN_AFRICA]: PopulationDetailsImageType.SUB_SAHARAN_AFRICA,
  [ParentPopulation.AMERICAS]: PopulationDetailsImageType.AMERICAS,
  [ParentPopulation.EUROPE]: PopulationDetailsImageType.EUROPE,
  [ParentPopulation.EAST_AND_NORTH_ASIA]: PopulationDetailsImageType.EAST_AND_NORTH_ASIA,
  [ParentPopulation.OCEANIA]: PopulationDetailsImageType.OCEANIA,
  [ParentPopulation.MIDDLE_EAST_AND_NORTH_AFRICA]:
    PopulationDetailsImageType.MIDDLE_EAST_AND_NORTH_AFRICA,
};
