import { Redirect, Route, Switch } from 'react-router-dom';

import { usePgxPreResultsLocationsByModule } from './locations';
import {
  PgxPreResultsModule1,
  PgxPreResultsModule2,
  PgxPreResultsModule3,
} from './PgxPreResultsModules';

export const PgxPreResultsRoutes = () => {
  const locations = usePgxPreResultsLocationsByModule();
  return (
    <Switch>
      {/* Redirect to the first module */}
      <Route exact path={locations.MODULE_1.pathname}>
        <PgxPreResultsModule1 />
      </Route>
      <Route exact path={locations.MODULE_2.pathname}>
        <PgxPreResultsModule2 />
      </Route>
      <Route exact path={locations.MODULE_3.pathname}>
        <PgxPreResultsModule3 />
      </Route>
      <Redirect to={locations.MODULE_1} />
    </Switch>
  );
};
