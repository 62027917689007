import { Container, Divider, InternalLink, Spacer, Text } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  DOWNLOAD_PDF_PAGE_PATH,
  PGX_APPOINTMENT_SCHEDULING_PATH,
} from 'components/App/AppRoutes/constants';
import { CircularImageType } from 'components/common/CircularImage/types';
import { ImageWithContentSection } from 'components/HealthRelatedResults/components/ImageWithContentSection/ImageWithContentSection';
import { trackSegmentEvent } from 'lib/analytics/segment';
import { ModuleType, SegmentEventType } from 'lib/analytics/types';
import { PGX_INFO_SHEET_URLS } from 'lib/constants';

export const DiscussResultsTab: React.FC = () => {
  const { locale } = usePreferredLocale();
  return (
    <Container>
      <Spacer variant="xxLarge" />
      <ImageWithContentSection
        title={<FormattedMessage id="PGX_Discuss_Results__Discuss_With_Doctor_Header" />}
        description={
          <>
            <Text paragraph>
              <FormattedMessage id="PGX_Discuss_Results__Discuss_With_Doctor_P1" />
            </Text>
            <Text paragraph>
              <FormattedMessage
                id="PGX_Discuss_Results__Discuss_With_Doctor_P2"
                values={{
                  link: (chunks: ReactNode) => (
                    <InternalLink
                      // TODO: We should have a shared utility to generate these URLs.
                      to={`${DOWNLOAD_PDF_PAGE_PATH}?${new URLSearchParams({
                        pdfUrl: PGX_INFO_SHEET_URLS[locale],
                        moduleType: ModuleType.PGX.toString(),
                      }).toString()}`}
                    >
                      {chunks}
                    </InternalLink>
                  ),
                }}
              />
            </Text>
          </>
        }
        image={CircularImageType.DOCTOR}
      />
      <Spacer variant="xxxLarge" />
      <Divider aria-hidden="true" />
      <Spacer variant="xxxLarge" />
      <ImageWithContentSection
        title={<FormattedMessage id="PGX_Discuss_More_Questions" />}
        description={
          <Text paragraph>
            <FormattedMessage
              id="PGX_Discuss_Results__Questions_Results_P1"
              values={{
                link: (chunks: ReactNode) => (
                  <InternalLink
                    to={PGX_APPOINTMENT_SCHEDULING_PATH}
                    onClick={() => {
                      trackSegmentEvent({
                        eventType: SegmentEventType.PGX_DISCUSS_RESULTS_GC_SCHEDULING,
                      });
                    }}
                  >
                    {chunks}
                  </InternalLink>
                ),
              }}
            />
          </Text>
        }
        image={CircularImageType.GENETIC_COUNSELOR}
        imageRight
      />
      <Spacer variant="xxxLarge" />
      <Divider aria-hidden="true" />
      <Spacer variant="xxxLarge" />
    </Container>
  );
};
