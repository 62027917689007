import { HdrV1Gene, MultipleDisorderHdrV1GeneType } from '@color/aou-reports';
import { Text } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import {
  MultipleDisorderSexAgnosticRiskContent,
  SexAgnosticRiskContent,
  SexSpecificContentHdrV1GeneType,
  SexSpecificRiskContent,
} from './types';

export const SEX_SPECIFIC_HDR_V1_GENES: Set<HdrV1Gene> = new Set<HdrV1Gene>([
  HdrV1Gene.BRCA1,
  HdrV1Gene.BRCA2,
  HdrV1Gene.MSH2,
  HdrV1Gene.MLH1,
  HdrV1Gene.APC,
  HdrV1Gene.MSH6,
  HdrV1Gene.TP53,
  HdrV1Gene.PTEN,
  HdrV1Gene.PMS2,
  HdrV1Gene.MUTYH,
  HdrV1Gene.STK11,
  HdrV1Gene.SMAD4,
  HdrV1Gene.BMPR1A,
]);

export const SEX_SPECIFIC_RISK_CONTENT: Record<
  SexSpecificContentHdrV1GeneType,
  SexSpecificRiskContent
> = {
  [HdrV1Gene.APC]: {
    riskFemale: <FormattedMessage id="APC__riskFemale" />,
    riskMale: <FormattedMessage id="APC__riskMale" />,
    screenFemale: <FormattedMessage id="APC__screenFemale" />,
    screenMale: <FormattedMessage id="APC__screenMale" />,
    riskAge: <FormattedMessage id="APC__riskAge" />,
    maleDisorders: [
      <FormattedMessage id="APC__disorderMale1" />,
      <FormattedMessage id="APC__disorderMale2" />,
    ],
    femaleDisorders: [
      <FormattedMessage id="APC__disorderFemale1" />,
      <FormattedMessage id="APC__disorderFemale2" />,
    ],
  },
  [HdrV1Gene.BMPR1A]: {
    riskFemale: <FormattedMessage id="BMPR1A__riskFemale" />,
    riskMale: <FormattedMessage id="BMPR1A__riskMale" />,
    screenFemale: <FormattedMessage id="BMPR1A__screenFemale" />,
    screenMale: <FormattedMessage id="BMPR1A__screenMale" />,
    riskAge: <FormattedMessage id="BMPR1A__riskAge" />,
    maleDisorders: [
      <FormattedMessage id="BMPR1A__disorderMale1" />,
      <FormattedMessage id="BMPR1A__disorderMale2" />,
      <FormattedMessage id="BMPR1A__disorderMale3" />,
    ],
    femaleDisorders: [
      <FormattedMessage id="BMPR1A__disorderFemale1" />,
      <FormattedMessage id="BMPR1A__disorderFemale2" />,
      <FormattedMessage id="BMPR1A__disorderFemale3" />,
    ],
  },
  [HdrV1Gene.BRCA1]: {
    riskFemale: <FormattedMessage id="BRCA1__riskFemale" />,
    riskMale: <FormattedMessage id="BRCA1__riskMale" />,
    screenFemale: <FormattedMessage id="BRCA1__screenFemale" />,
    screenMale: <FormattedMessage id="BRCA1__screenMale" />,
    riskAge: <FormattedMessage id="BRCA1__riskAge" />,
    maleDisorders: [
      <FormattedMessage id="BRCA1__disorderMale1" />,
      <FormattedMessage id="BRCA1__disorderMale2" />,
      <FormattedMessage id="BRCA1__disorderMale3" />,
    ],
    femaleDisorders: [
      <FormattedMessage id="BRCA1__disorderFemale1" />,
      <FormattedMessage id="BRCA1__disorderFemale2" />,
      <FormattedMessage id="BRCA1__disorderFemale3" />,
    ],
  },
  [HdrV1Gene.BRCA2]: {
    riskFemale: <FormattedMessage id="BRCA2__riskFemale" />,
    riskMale: <FormattedMessage id="BRCA2__riskMale" />,
    screenFemale: <FormattedMessage id="BRCA2__screenFemale" />,
    screenMale: <FormattedMessage id="BRCA2__screenMale" />,
    riskAge: <FormattedMessage id="BRCA2__riskAge" />,
    maleDisorders: [
      <FormattedMessage id="BRCA2__disorderMale1" />,
      <FormattedMessage id="BRCA2__disorderMale2" />,
      <FormattedMessage id="BRCA2__disorderMale3" />,
      <FormattedMessage id="BRCA2__disorderMale4" />,
    ],
    femaleDisorders: [
      <FormattedMessage id="BRCA2__disorderFemale1" />,
      <FormattedMessage id="BRCA2__disorderFemale2" />,
      <FormattedMessage id="BRCA2__disorderFemale3" />,
      <FormattedMessage id="BRCA2__disorderFemale4" />,
    ],
  },
  [HdrV1Gene.MLH1]: {
    riskFemale: <FormattedMessage id="MLH1__riskFemale" />,
    riskMale: <FormattedMessage id="MLH1__riskMale" />,
    screenFemale: <FormattedMessage id="MLH1__screenFemale" />,
    screenMale: <FormattedMessage id="MLH1__screenMale" />,
    riskAge: <FormattedMessage id="MLH1__riskAge" />,
    maleDisorders: [
      <FormattedMessage id="MLH1__disorderMale1" />,
      <FormattedMessage id="MLH1__disorderMale2" />,
    ],
    femaleDisorders: [
      <FormattedMessage id="MLH1__disorderFemale1" />,
      <FormattedMessage id="MLH1__disorderFemale2" />,
      <FormattedMessage id="MLH1__disorderFemale3" />,
      <FormattedMessage id="MLH1__disorderFemale4" />,
    ],
  },
  [HdrV1Gene.MSH2]: {
    riskFemale: <FormattedMessage id="MSH2__riskFemale" />,
    riskMale: <FormattedMessage id="MSH2__riskMale" />,
    screenFemale: <FormattedMessage id="MSH2__screenFemale" />,
    screenMale: <FormattedMessage id="MSH2__screenMale" />,
    riskAge: <FormattedMessage id="MSH2__riskAge" />,
    maleDisorders: [
      <FormattedMessage id="MSH2__disorderMale1" />,
      <FormattedMessage id="MSH2__disorderMale2" />,
    ],
    femaleDisorders: [
      <FormattedMessage id="MSH2__disorderFemale1" />,
      <FormattedMessage id="MSH2__disorderFemale2" />,
      <FormattedMessage id="MSH2__disorderFemale3" />,
      <FormattedMessage id="MSH2__disorderFemale4" />,
    ],
  },
  [HdrV1Gene.MSH6]: {
    riskFemale: <FormattedMessage id="MSH6__riskFemale" />,
    riskMale: <FormattedMessage id="MSH6__riskMale" />,
    screenFemale: <FormattedMessage id="MSH6__screenFemale" />,
    screenMale: <FormattedMessage id="MSH6__screenMale" />,
    riskAge: <FormattedMessage id="MSH6__riskAge" />,
    maleDisorders: [
      <FormattedMessage id="MSH6__disorderMale1" />,
      <FormattedMessage id="MSH6__disorderMale2" />,
    ],
    femaleDisorders: [
      <FormattedMessage id="MSH6__disorderFemale1" />,
      <FormattedMessage id="MSH6__disorderFemale2" />,
      <FormattedMessage id="MSH6__disorderFemale3" />,
      <FormattedMessage id="MSH6__disorderFemale4" />,
    ],
  },
  [HdrV1Gene.MUTYH]: {
    riskFemale: <FormattedMessage id="MUTYH__riskFemale" />,
    riskMale: <FormattedMessage id="MUTYH__riskMale" />,
    screenFemale: <FormattedMessage id="MUTYH__screenFemale" />,
    screenMale: <FormattedMessage id="MUTYH__screenMale" />,
    riskAge: <FormattedMessage id="MUTYH__riskAge" />,
    maleDisorders: [
      <FormattedMessage id="MUTYH__disorderMale1" />,
      <FormattedMessage id="MUTYH__disorderMale2" />,
    ],
    femaleDisorders: [
      <FormattedMessage id="MUTYH__disorderFemale1" />,
      <FormattedMessage id="MUTYH__disorderFemale2" />,
    ],
  },
  [HdrV1Gene.PMS2]: {
    riskFemale: <FormattedMessage id="PMS2__riskFemale" />,
    riskMale: <FormattedMessage id="PMS2__riskMale" />,
    screenFemale: <FormattedMessage id="PMS2__screenFemale" />,
    screenMale: <FormattedMessage id="PMS2__screenMale" />,
    riskAge: <FormattedMessage id="PMS2__riskAge" />,
    maleDisorders: [
      <FormattedMessage id="PMS2__disorderMale1" />,
      <FormattedMessage id="PMS2__disorderMale2" />,
    ],
    femaleDisorders: [
      <FormattedMessage id="PMS2__disorderFemale1" />,
      <FormattedMessage id="PMS2__disorderFemale2" />,
      <FormattedMessage id="PMS2__disorderFemale3" />,
    ],
  },
  [HdrV1Gene.PTEN]: {
    riskFemale: <FormattedMessage id="PTEN__riskFemale" />,
    riskMale: <FormattedMessage id="PTEN__riskMale" />,
    screenFemale: <FormattedMessage id="PTEN__screenFemale" />,
    screenMale: <FormattedMessage id="PTEN__screenMale" />,
    riskAge: <FormattedMessage id="PTEN__riskAge" />,
    maleDisorders: [
      <FormattedMessage id="PTEN__disorderMale1" />,
      <FormattedMessage id="PTEN__disorderMale2" />,
      <FormattedMessage id="PTEN__disorderMale3" />,
    ],
    femaleDisorders: [
      <FormattedMessage id="PTEN__disorderFemale1" />,
      <FormattedMessage id="PTEN__disorderFemale2" />,
      <FormattedMessage id="PTEN__disorderFemale3" />,
      <FormattedMessage id="PTEN__disorderFemale4" />,
      <FormattedMessage id="PTEN__disorderFemale5" />,
    ],
  },
  [HdrV1Gene.SMAD4]: {
    riskFemale: <FormattedMessage id="SMAD4__riskFemale" />,
    riskMale: <FormattedMessage id="SMAD4__riskMale" />,
    screenFemale: <FormattedMessage id="SMAD4__screenFemale" />,
    screenMale: <FormattedMessage id="SMAD4__screenMale" />,
    riskAge: <FormattedMessage id="SMAD4__riskAge" />,
    maleDisorders: [
      <FormattedMessage id="SMAD4__disorderMale1" />,
      <FormattedMessage id="SMAD4__disorderMale2" />,
      <FormattedMessage id="SMAD4__disorderMale3" />,
    ],
    femaleDisorders: [
      <FormattedMessage id="SMAD4__disorderFemale1" />,
      <FormattedMessage id="SMAD4__disorderFemale2" />,
      <FormattedMessage id="SMAD4__disorderFemale3" />,
    ],
  },
  [HdrV1Gene.STK11]: {
    riskFemale: <FormattedMessage id="STK11__riskFemale" />,
    riskMale: <FormattedMessage id="STK11__riskMale" />,
    screenFemale: <FormattedMessage id="STK11__screenFemale" />,
    screenMale: <FormattedMessage id="STK11__screenMale" />,
    riskAge: <FormattedMessage id="STK11__riskAge" />,
    maleDisorders: [
      <FormattedMessage id="STK11__disorderMale1" />,
      <FormattedMessage id="STK11__disorderMale2" />,
      <FormattedMessage id="STK11__disorderMale3" />,
      <FormattedMessage id="STK11__disorderMale4" />,
    ],
    femaleDisorders: [
      <FormattedMessage id="STK11__disorderFemale1" />,
      <FormattedMessage id="STK11__disorderFemale2" />,
      <FormattedMessage id="STK11__disorderFemale3" />,
      <FormattedMessage id="STK11__disorderFemale4" />,
      <FormattedMessage id="STK11__disorderFemale5" />,
    ],
  },
  [HdrV1Gene.TP53]: {
    riskFemale: (
      <>
        <FormattedMessage id="TP53__riskFemale" />
        <Text paragraph>
          <FormattedMessage id="TP53__riskAdditional" />
        </Text>
      </>
    ),
    riskMale: (
      <>
        <FormattedMessage id="TP53__riskMale" />
        <Text paragraph>
          <FormattedMessage id="TP53__riskAdditional" />
        </Text>
      </>
    ),
    screenFemale: <FormattedMessage id="TP53__screenFemale" />,
    screenMale: <FormattedMessage id="TP53__screenMale" />,
    riskAge: <FormattedMessage id="TP53__riskAge" />,
    maleDisorders: [
      <FormattedMessage id="TP53__disorderMale1" />,
      <FormattedMessage id="TP53__disorderMale2" />,
      <FormattedMessage id="TP53__disorderMale3" />,
      <FormattedMessage id="TP53__disorderMale4" />,
    ],
    femaleDisorders: [
      <FormattedMessage id="TP53__disorderFemale1" />,
      <FormattedMessage id="TP53__disorderFemale2" />,
      <FormattedMessage id="TP53__disorderFemale3" />,
      <FormattedMessage id="TP53__disorderFemale4" />,
      <FormattedMessage id="TP53__disorderFemale5" />,
    ],
  },
};

export const MULTIPLE_DISORDER_SEX_AGNOSTIC_RISK_CONTENT: Record<
  MultipleDisorderHdrV1GeneType,
  MultipleDisorderSexAgnosticRiskContent
> = {
  [HdrV1Gene.MYH7]: {
    HCM: {
      riskAll: <FormattedMessage id="MYH7_HCM__riskAll" />,
      screenAll: <FormattedMessage id="MYH7_HCM__screenAll" />,
    },
    DCM: {
      riskAll: <FormattedMessage id="MYH7_DCM__riskAll" />,
      screenAll: <FormattedMessage id="MYH7_DCM__screenAll" />,
    },
  },
  [HdrV1Gene.SCN5A]: {
    LQTS: {
      riskAll: <FormattedMessage id="SCN5A_LQTS__riskAll" />,
      screenAll: <FormattedMessage id="SCN5A_LQTS__screenAll" />,
    },
    BRS: {
      riskAll: <FormattedMessage id="SCN5A_BRS__riskAll" />,
      screenAll: <FormattedMessage id="SCN5A_BRS__screenAll" />,
    },
  },
  [HdrV1Gene.TNNT2]: {
    HCM: {
      riskAll: <FormattedMessage id="TNNT2_HCM__riskAll" />,
      screenAll: <FormattedMessage id="TNNT2_HCM__screenAll" />,
    },
    DCM: {
      riskAll: <FormattedMessage id="TNNT2_DCM__riskAll" />,
      screenAll: <FormattedMessage id="TNNT2_DCM__screenAll" />,
    },
  },
};

export const SINGLE_DISORDER_SEX_AGNOSTIC_RISK_CONTENT: Record<
  Exclude<HdrV1Gene, SexSpecificContentHdrV1GeneType | MultipleDisorderHdrV1GeneType>,
  SexAgnosticRiskContent
> = {
  [HdrV1Gene.ACTA2]: {
    riskAll: <FormattedMessage id="ACTA2__riskAll" />,
    screenAll: <FormattedMessage id="ACTA2__screenAll" />,
  },
  [HdrV1Gene.ACTC1]: {
    riskAll: <FormattedMessage id="ACTC1__riskAll" />,
    screenAll: <FormattedMessage id="ACTC1__screenAll" />,
  },
  [HdrV1Gene.APOB]: {
    riskAll: <FormattedMessage id="APOB__riskAll" />,
    screenAll: <FormattedMessage id="APOB__screenAll" />,
  },
  [HdrV1Gene.ATP7B]: {
    riskAll: <FormattedMessage id="ATP7B__riskAll" />,
    screenAll: <FormattedMessage id="ATP7B__screenAll" />,
  },
  [HdrV1Gene.CACNA1S]: {
    riskAll: <FormattedMessage id="CACNA1S__riskAll" />,
    screenAll: <FormattedMessage id="CACNA1S__screenAll" />,
  },
  [HdrV1Gene.COL3A1]: {
    riskAll: <FormattedMessage id="COL3A1__riskAll" />,
    screenAll: <FormattedMessage id="COL3A1__screenAll" />,
  },
  [HdrV1Gene.DSC2]: {
    riskAll: <FormattedMessage id="DSC2__riskAll" />,
    screenAll: <FormattedMessage id="DSC2__screenAll" />,
  },
  [HdrV1Gene.DSG2]: {
    riskAll: <FormattedMessage id="DSG2__riskAll" />,
    screenAll: <FormattedMessage id="DSG2__screenAll" />,
  },
  [HdrV1Gene.DSP]: {
    riskAll: <FormattedMessage id="DSP__riskAll" />,
    screenAll: <FormattedMessage id="DSP__screenAll" />,
  },
  [HdrV1Gene.FBN1]: {
    riskAll: <FormattedMessage id="FBN1__riskAll" />,
    screenAll: <FormattedMessage id="FBN1__screenAll" />,
  },
  [HdrV1Gene.GLA]: {
    riskAll: <FormattedMessage id="GLA__riskAll" />,
    screenAll: <FormattedMessage id="GLA__screenAll" />,
  },
  [HdrV1Gene.KCNH2]: {
    riskAll: <FormattedMessage id="KCNH2__riskAll" />,
    screenAll: <FormattedMessage id="KCNH2__screenAll" />,
  },
  [HdrV1Gene.KCNQ1]: {
    riskAll: <FormattedMessage id="KCNQ1__riskAll" />,
    screenAll: <FormattedMessage id="KCNQ1__screenAll" />,
  },
  [HdrV1Gene.LDLR]: {
    riskAll: <FormattedMessage id="LDLR__riskAll" />,
    screenAll: <FormattedMessage id="LDLR__screenAll" />,
  },
  [HdrV1Gene.LMNA]: {
    riskAll: <FormattedMessage id="LMNA__riskAll" />,
    screenAll: <FormattedMessage id="LMNA__screenAll" />,
  },
  [HdrV1Gene.MEN1]: {
    riskAll: <FormattedMessage id="MEN1__riskAll" />,
    screenAll: <FormattedMessage id="MEN1__screenAll" />,
  },
  [HdrV1Gene.MYBPC3]: {
    riskAll: <FormattedMessage id="MYBPC3__riskAll" />,
    screenAll: <FormattedMessage id="MYBPC3__screenAll" />,
  },
  [HdrV1Gene.MYH11]: {
    riskAll: <FormattedMessage id="MYH11__riskAll" />,
    screenAll: <FormattedMessage id="MYH11__screenAll" />,
  },
  [HdrV1Gene.MYL2]: {
    riskAll: <FormattedMessage id="MYL2__riskAll" />,
    screenAll: <FormattedMessage id="MYL2__screenAll" />,
  },
  [HdrV1Gene.MYL3]: {
    riskAll: <FormattedMessage id="MYL3__riskAll" />,
    screenAll: <FormattedMessage id="MYL3__screenAll" />,
  },
  [HdrV1Gene.NF2]: {
    riskAll: <FormattedMessage id="NF2__riskAll" />,
    screenAll: <FormattedMessage id="NF2__screenAll" />,
  },
  [HdrV1Gene.OTC]: {
    riskAll: <FormattedMessage id="OTC__riskAll" />,
    screenAll: <FormattedMessage id="OTC__screenAll" />,
  },
  [HdrV1Gene.PCSK9]: {
    riskAll: <FormattedMessage id="PCSK9__riskAll" />,
    screenAll: <FormattedMessage id="PCSK9__screenAll" />,
  },
  [HdrV1Gene.PKP2]: {
    riskAll: <FormattedMessage id="PKP2__riskAll" />,
    screenAll: <FormattedMessage id="PKP2__screenAll" />,
  },
  [HdrV1Gene.PRKAG2]: {
    riskAll: <FormattedMessage id="PRKAG2__riskAll" />,
    screenAll: <FormattedMessage id="PRKAG2__screenAll" />,
  },
  [HdrV1Gene.RB1]: {
    riskAll: <FormattedMessage id="RB1__riskAll" />,
    screenAll: <FormattedMessage id="RB1__screenAll" />,
  },
  [HdrV1Gene.RET]: {
    riskAll: <FormattedMessage id="RET__riskAll" />,
    screenAll: <FormattedMessage id="RET__screenAll" />,
  },
  [HdrV1Gene.RYR1]: {
    riskAll: <FormattedMessage id="RYR1__riskAll" />,
    screenAll: <FormattedMessage id="RYR1__screenAll" />,
  },
  [HdrV1Gene.RYR2]: {
    riskAll: <FormattedMessage id="RYR2__riskAll" />,
    screenAll: <FormattedMessage id="RYR2__screenAll" />,
  },
  [HdrV1Gene.SDHAF2]: {
    riskAll: <FormattedMessage id="SDHAF2__riskAll" />,
    screenAll: <FormattedMessage id="SDHAF2__screenAll" />,
  },
  [HdrV1Gene.SDHB]: {
    riskAll: <FormattedMessage id="SDHB__riskAll" />,
    screenAll: <FormattedMessage id="SDHB__screenAll" />,
  },
  [HdrV1Gene.SDHC]: {
    riskAll: <FormattedMessage id="SDHC__riskAll" />,
    screenAll: <FormattedMessage id="SDHC__screenAll" />,
  },
  [HdrV1Gene.SDHD]: {
    riskAll: <FormattedMessage id="SDHD__riskAll" />,
    screenAll: <FormattedMessage id="SDHD__screenAll" />,
  },
  [HdrV1Gene.SMAD3]: {
    riskAll: <FormattedMessage id="SMAD3__riskAll" />,
    screenAll: <FormattedMessage id="SMAD3__screenAll" />,
  },
  [HdrV1Gene.TGFBR1]: {
    riskAll: <FormattedMessage id="TGFBR1__riskAll" />,
    screenAll: <FormattedMessage id="TGFBR1__screenAll" />,
  },
  [HdrV1Gene.TGFBR2]: {
    riskAll: <FormattedMessage id="TGFBR2__riskAll" />,
    screenAll: <FormattedMessage id="TGFBR2__screenAll" />,
  },
  [HdrV1Gene.TMEM43]: {
    riskAll: <FormattedMessage id="TMEM43__riskAll" />,
    screenAll: <FormattedMessage id="TMEM43__screenAll" />,
  },
  [HdrV1Gene.TNNI3]: {
    riskAll: <FormattedMessage id="TNNI3__riskAll" />,
    screenAll: <FormattedMessage id="TNNI3__screenAll" />,
  },
  [HdrV1Gene.TPM1]: {
    riskAll: <FormattedMessage id="TPM1__riskAll" />,
    screenAll: <FormattedMessage id="TPM1__screenAll" />,
  },
  [HdrV1Gene.TSC1]: {
    riskAll: <FormattedMessage id="TSC1__riskAll" />,
    screenAll: <FormattedMessage id="TSC1__screenAll" />,
  },
  [HdrV1Gene.TSC2]: {
    riskAll: <FormattedMessage id="TSC2__riskAll" />,
    screenAll: <FormattedMessage id="TSC2__screenAll" />,
  },
  [HdrV1Gene.VHL]: {
    riskAll: <FormattedMessage id="VHL__riskAll" />,
    screenAll: <FormattedMessage id="VHL__screenAll" />,
  },
  [HdrV1Gene.WT1]: {
    riskAll: <FormattedMessage id="WT1__riskAll" />,
    screenAll: <FormattedMessage id="WT1__screenAll" />,
  },
};
