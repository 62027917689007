import { useUrlQueryParam } from '@color/lib';
import { useState } from 'react';

// NOTE: copied from src/projects/home/frontend/src/ui/Care/Eligibility/LoggedInEligibilityFlow/utils.ts
// cannot import since GP and Home are peers
export function useCachedQueryParam(paramKey: string) {
  const [value, setValue] = useState<string>();
  const { queryParam } = useUrlQueryParam(paramKey);
  if (queryParam && queryParam !== value) {
    setValue(queryParam);
  }
  return queryParam || value;
}
