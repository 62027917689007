import { AppMuiProviders, gpThemeV5 } from '@color/continuum';
import { SnackbarProvider } from 'notistack';

interface Props {
  children?: React.ReactNode;
}

export const AppThemeProvider: React.FC<Props> = ({ children }) => {
  return (
    <AppMuiProviders themeV5={gpThemeV5}>
      <SnackbarProvider
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        preventDuplicate
      >
        {children}
      </SnackbarProvider>
    </AppMuiProviders>
  );
};
