import { FormattedMessage } from 'react-intl';

import { Trait } from '../../types';

interface DisclaimerSection {
  header: JSX.Element;
  text: JSX.Element;
}

const content: Record<Trait, DisclaimerSection[]> = {
  [Trait.BITTERNESS]: [
    {
      header: (
        <FormattedMessage
          description="Bitterness trait results disclaimer section 1 title"
          defaultMessage="This test won't tell you whether you definitely can taste bitterness."
        />
      ),
      text: (
        <FormattedMessage
          description="Bitterness trait results disclaimer section 1 text"
          defaultMessage="There are other things that might affect what you can taste. This information is based on science that could change over time as scientists learn more about genetics. We looked at a place in your DNA that research studies have found to be linked with the ability to taste bitterness.<sup>2,3,5,10,14</sup>"
        />
      ),
    },
    {
      header: (
        <FormattedMessage
          description="Bitterness trait results disclaimer section 2 title"
          defaultMessage="Scientists understand bitter taste perception better in some populations than others."
        />
      ),
      text: (
        <FormattedMessage
          description="Bitterness trait results disclaimer section 2 text"
          defaultMessage="This science is based on studies of people with European and East Asian genetic ancestry.<sup>8</sup>"
        />
      ),
    },
    {
      header: (
        <FormattedMessage
          description="Bitterness trait results disclaimer section 3 title"
          defaultMessage="This information shouldn't be used to make any medical decisions."
        />
      ),
      text: (
        <FormattedMessage
          description="Bitterness trait results disclaimer section 3 text"
          defaultMessage="Talk to a doctor before making any major lifestyle changes, or if you have any concerns about your health."
        />
      ),
    },
  ],
  [Trait.CILANTRO]: [
    {
      header: (
        <FormattedMessage
          description="Cilantro trait results disclaimer section 1 title"
          defaultMessage="This test does not tell you if you dislike cilantro."
        />
      ),
      text: (
        <FormattedMessage
          description="Cilantro trait results disclaimer section 1 text"
          defaultMessage="There are other things that might affect whether you like cilantro.<sup>3-5</sup> This information is based on science that could change over time as scientists learn more about genetics. We looked at a place in your DNA that research studies have found to be linked to cilantro like or dislike.<sup>2</sup>"
        />
      ),
    },
    {
      header: (
        <FormattedMessage
          description="Cilantro trait results disclaimer section 2 title"
          defaultMessage="Scientists understand cilantro preference better in some populations than others."
        />
      ),
      text: (
        <FormattedMessage
          description="Cilantro trait results disclaimer section 2 text"
          defaultMessage="This science is based on studies of people with European and East Asian genetic ancestry.<sup>2</sup>"
        />
      ),
    },
    {
      header: (
        <FormattedMessage
          description="Cilantro trait results disclaimer section 3 title"
          defaultMessage="This information shouldn't be used to make any medical decisions."
        />
      ),
      text: (
        <FormattedMessage
          description="Cilantro trait results disclaimer section 3 text"
          defaultMessage="Talk to a doctor before making any major lifestyle changes, or if you have any concerns about your health."
        />
      ),
    },
  ],
  [Trait.EARWAX]: [
    {
      header: (
        <FormattedMessage
          description="Earwax trait results disclaimer section 1 title"
          defaultMessage="This test won't tell you which type of earwax you have."
        />
      ),
      text: (
        <FormattedMessage
          description="Earwax trait results disclaimer section 1 text"
          defaultMessage="This information is based on science that could change over time as scientists learn more about genetics. We looked at a place in your DNA that research studies have found to be linked with the type of earwax someone has.<sup>1,16</sup>"
        />
      ),
    },
    {
      header: (
        <FormattedMessage
          description="Earwax trait results disclaimer section 2 title"
          defaultMessage="Other factors may influence what earwax is like."
        />
      ),
      text: (
        <FormattedMessage
          description="Earwax trait results disclaimer section 2 text"
          defaultMessage="Things like the soap you use, whether you have dry skin, and your stress level can also make a difference in how your earwax looks and feels."
        />
      ),
    },
  ],
  [Trait.LACTOSE]: [
    {
      header: (
        <FormattedMessage
          description="Lactose trait results disclaimer section 1 title"
          defaultMessage="This test won't tell you whether you're lactose intolerant."
        />
      ),
      text: (
        <FormattedMessage
          description="Lactose trait results disclaimer section 1 text"
          defaultMessage="There are other things that might affect whether you can digest lactose. This information is based on science that could change over time as scientists learn more about genetics. We looked at a place in your DNA that research studies have found to be linked with the ability to digest lactose, but future studies may change how this place is interpreted in different populations.<sup>9-13</sup>"
        />
      ),
    },
    {
      header: (
        <FormattedMessage
          description="Lactose trait results disclaimer section 2 title"
          defaultMessage="Scientists understand lactose intolerance better in some populations than others."
        />
      ),
      text: (
        <FormattedMessage
          description="Lactose trait results disclaimer section 2 text"
          defaultMessage="This science is based on studies of people with European genetic ancestry. A few small studies have been done in other genetic ancestry groups, such as African and Middle Eastern."
        />
      ),
    },
    {
      header: (
        <FormattedMessage
          description="Lactose trait results disclaimer section 3 title"
          defaultMessage="This information shouldn't be used to make any medical decisions."
        />
      ),
      text: (
        <FormattedMessage
          description="Lactose trait results disclaimer section 3 text"
          defaultMessage="Talk to a doctor before making any major lifestyle changes, or if you have any concerns about your health."
        />
      ),
    },
  ],
};

export function useTraitResultsDisclaimerSectionContent(trait: Trait): DisclaimerSection[] {
  return content[trait];
}
