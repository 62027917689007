import { Text } from '@color/continuum';
import { useEffect } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { FirstModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/FirstModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { useCreateInformingLoopStartedEventMutation } from 'lib/analytics/informing-loop-started';
import { ModuleType } from 'lib/analytics/types';

const messages = defineMessages({
  gemILModule1PageTitle: {
    description: 'GEM informing loop - module 1 page title',
    defaultMessage: 'Getting Results',
  },
});

export const GemInformingLoopModule1: React.FC = () => {
  const { mutateAsync: saveInformingLoopStarted } = useCreateInformingLoopStartedEventMutation();
  const intl = useIntl();

  /**
   * `useEffect` is often dangerous and unnecessary. This usage needs review by #ux-programs team.
   * See https://getcolor.atlassian.net/browse/UXTASK-1406
   */
  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    saveInformingLoopStarted({ moduleType: ModuleType.GEM });
  }, [saveInformingLoopStarted]);

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.gemILModule1PageTitle)}
      module={{
        analyticsInformingLoopName: 'GEM Informing Loop',
        analyticsPageName: '1. Start',
        image: CircularImageType.PEOPLE_CLIPBOARD,
        title: (
          <FormattedMessage
            description="GEM informing loop page 1 title"
            defaultMessage="How do I get my genetic ancestry and trait results?"
          />
        ),
        content: (
          <Text>
            <FormattedMessage
              description="GEM informing loop page 1 content"
              defaultMessage="You can decide if you want your genetic ancestry and trait results. These results are meant to be interesting and do not contain health-related information. There are both benefits and risks to getting these DNA results. Before you decide, please review the following information."
            />
          </Text>
        ),
      }}
      footer={<FirstModuleInformingLoopFooter />}
    />
  );
};
