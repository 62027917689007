import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';

import { LocationWithDecision } from 'components/common/InformingLoop/types';
import { useResultDecisionsQuery } from 'lib/analytics/result-decisions/api';
import { ModuleType } from 'lib/analytics/types';

interface Props {
  moduleType: ModuleType;
}

// This hook will return true if two conditions are met
// 1. The user has already completed the Informing Loop for the given moduleType
// 2. The user got to the Informing Loop page via an external link, not an internal link
// otherwise, it will return false.
//
// Components that make use of this hook should redirect to the Dashboard if 'true' is returned.
// We cannot do the redirect here since that changes state.
export const useUserWasExternallyLinkedToCompletedInformingLoop = (props: Props) => {
  const { moduleType } = props;
  const { state } = useLocation<LocationWithDecision>();
  const {
    data: resultDecisionsData,
    isLoading: resultDecisionsAreLoading,
  } = useResultDecisionsQuery({ moduleType });

  // We set the `state.backgroundLocation` when internally redirecting to the GEM/HDR/PGX Informing Loops,
  // so we can check that state to see if the user was linked from an external source (e.g. PTSC notifications).
  // TODO @rymarren: We should instead be more explicit and set / check a different property of the state such as
  // `state.navigatedFromGp` or similar.
  if (
    // We don't know if the user has completed the Informing Loop yet, so we can't redirect
    resultDecisionsAreLoading ||
    // The user was externally linked to the Informing Loop
    state?.backgroundLocation ||
    // The user has not completed the Informing Loop yet
    isEmpty(resultDecisionsData?.resultDecisions) ||
    // The user was externally linked, and the Informing Loop was just completed by the participant
    state?.decision
  ) {
    return false;
  }
  return true;
};
