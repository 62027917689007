import { Text } from '@color/continuum';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { GpTooltip } from 'components/common/GpTooltip/GpTooltip';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

import { PGX_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  pgxILModule2PageTitle: {
    description: 'PGx Informing Loop Page Title - Module 2',
    defaultMessage: 'What You Can Learn',
  },
  pgxIlTooltipLabelMedicines: {
    description: 'PGX Informing Loop Medicines tooltip accessible label',
    defaultMessage: 'Medicines Info',
  },
});

export const PgxInformingLoopModule2: React.FC = () => {
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.pgxILModule2PageTitle)}
      module={{
        analyticsInformingLoopName: PGX_INFORMING_LOOP_NAME,
        analyticsPageName: '2. About Results',
        image: CircularImageType.GENES_DRUGS,
        title: <FormattedMessage id="PGX_IL_2__Title" />,
        content: (
          <>
            <Text paragraph>
              <FormattedMessage
                description="PGX informing loop page 2 paragraph 1"
                defaultMessage="Certain genes in our DNA affect how we respond to some medicines. {tooltip} Some genes help move medicines to the right part of your body. Some genes help break down medicines and clear them from your body. Some genes even change medicines into a form that makes them work properly."
                values={{
                  tooltip: (
                    <GpTooltip
                      aria-label={intl.formatMessage(messages.pgxIlTooltipLabelMedicines)}
                      content={
                        <>
                          <Text paragraph>
                            <FormattedMessage
                              description="PGX informing loop page 2 paragraph 1 tooltip paragraph 1"
                              defaultMessage="The technical word for this kind of information is “pharmacogenetics.” In some cases, this information may help doctors and other health care providers choose what medicines to give you and how much."
                            />
                          </Text>
                          <Text paragraph>
                            <FormattedMessage
                              description="PGX informing loop page 2 paragraph 1 tooltip paragraph 2"
                              defaultMessage="There are a lot of genes that can affect how your body responds to medicines. We won’t look at all of them. We will only look at a few of these genes that are currently known to affect how your body may respond to some medicines."
                            />
                          </Text>
                        </>
                      }
                    />
                  ),
                }}
              />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="PGX informing loop page 2 paragraph"
                defaultMessage="Small differences in your DNA can change how these genes work. These differences can be one reason some medicines work differently for different people."
              />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="PGX informing loop page 3 paragraph"
                defaultMessage="These results will look at a few of the genes in your DNA that can affect how your body responds to some medicines. For example, one important gene is called <i>CYP2C19</i>. People with a different version of the <i>CYP2C19</i> gene may break down some medicines more slowly than other people. This includes medicines such as some antidepressants and a blood thinner."
              />
            </Text>
          </>
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
