// This file was moved back to GP from CDS, since it is a) not heavily shared with other projects, and
// b) the types and functionality should live with the component, not separately.
// FIXME: Change this file to isQuizItemApplicable to be more specific.
import { isArray, snakeCase } from 'lodash';

import { IQuizItem, TAnswerMap } from '../types';

/**
 * Returns whether or not this QuizItem should be shown to the user in the current session.
 * @param quizItem The quiz item to check.
 * @param answers The answers for this quiz so far.
 */
export function isItemApplicable(
  quizItem: IQuizItem,
  answers: TAnswerMap,
  allQuizItems: IQuizItem[]
) {
  const prerequisites = quizItem?.question?.prerequisites;
  if (!prerequisites) {
    return true;
  }

  return Object.keys(prerequisites).every((id) => {
    const prerequisite = prerequisites[id];
    const { answerDoesNotContain } = prerequisite;
    const { answerDoesContain } = prerequisite;

    const hasPrerequisite = Boolean(answerDoesContain || answerDoesNotContain);
    if (!hasPrerequisite) {
      // The prerequisite object exists, but no conditions are attached to it.
      return true;
    }

    // This recursively checks that for each pre-requisite, an associated quiz item is applicable.
    // This prevents issues with extra questions showing up in quizes if a user goes back and changes an answer.
    // TODO: Add checks to make sure this is a DAG. Given our admin interface, it is currently possible
    // to add a quiz that is not a DAG, but that quiz isn't valid anyway.
    const prerequisiteQuizItems = allQuizItems.filter(
      (item) => item.question?.id === snakeCase(id)
    );
    if (
      answerDoesContain &&
      prerequisiteQuizItems.length &&
      !prerequisiteQuizItems.some((item) => isItemApplicable(item, answers, allQuizItems))
    ) {
      return false;
    }

    let currentAnswers = answers[snakeCase(id)];
    if (currentAnswers === undefined) {
      // If the previous question was not answered at all, then the "answerDoesContain" prerequisite does not apply
      if (answerDoesContain) {
        return false;
      }
      return true;
    }

    currentAnswers = isArray(currentAnswers) ? currentAnswers : [currentAnswers];

    if (answerDoesNotContain) {
      // If any of the current answers are in the "does not contain" list, the prerequisite fails
      return !currentAnswers.some((answer) => answerDoesNotContain.includes(answer));
    }

    // At least one of the answers needs to be in the "does contain" list
    return currentAnswers.some((answer) => answerDoesContain!!.includes(answer));
  });
}
