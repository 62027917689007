import {
  ActionLink,
  Button,
  CommonDialog,
  DialogContent,
  ListItem,
  Spacer,
  Text,
  UnorderedList,
  useDialogState,
} from '@color/continuum';
// TODO Import from CDS rather than MUI. In CDS-341 we turned on lint warnings on direct MUI imports. This line is disabled because GP CI sets the rule to error instead of warn.
// Swap this for a CDS import instead of a MUI import.
// eslint-disable-next-line no-restricted-imports
import { Box, DialogContentText, Theme, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const WhyDoesThisMatterDialog: React.FC = () => {
  const { dialogIsOpen, openDialog, closeDialog } = useDialogState();

  const clickButton = () => {
    closeDialog();
  };
  const isSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <Box sx={isSmallScreen ? { display: 'flex', justifyContent: 'center' } : {}}>
        <ActionLink onClick={() => openDialog()}>
          <Text>
            <FormattedMessage id="HDR_Positive_Risk__Screen_If_Female_P1" />
          </Text>
        </ActionLink>
      </Box>
      <CommonDialog
        title={<FormattedMessage id="HDR_Positive_Risk__Screen_If_Female_P1" />}
        onClose={() => closeDialog()}
        dialogIsOpen={dialogIsOpen}
        scroll="paper"
        actions={
          <>
            <Button onClick={() => clickButton()} variant="color-primary" autoFocus>
              OK
            </Button>
          </>
        }
        isFullScreenOnMobile={false}
      >
        <DialogContent>
          <DialogContentText>
            <Text>
              <FormattedMessage id="HDR_Positive_Risk__Risk_Why_Matter_P1" />
            </Text>
            <Spacer variant="small" />
            <UnorderedList>
              <ListItem>
                <Text>
                  <FormattedMessage id="HDR_Positive_Risk__Risk_Why_Matter_B1" />
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <FormattedMessage id="HDR_Positive_Risk__Risk_Why_Matter_B2" />
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <FormattedMessage id="HDR_Positive_Risk__Risk_Why_Matter_B3" />
                </Text>
              </ListItem>
            </UnorderedList>
          </DialogContentText>
        </DialogContent>
      </CommonDialog>
    </>
  );
};
