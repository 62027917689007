import { ExternalLink, InternalButtonLink, Spacer } from '@color/continuum';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { useConfigForIdp } from 'hooks/useConfigForIdp';

const messages = defineMessages({
  finalModulePageTitleYes: {
    description: 'GEM informing loop final module page title - yes decision',
    defaultMessage: 'Yes',
  },
  finalModuleTitle: {
    description: 'GEM informing loop final page title',
    defaultMessage:
      'Thanks for taking the time to consider the benefits and risks of receiving genetic ancestry and trait results.',
  },
});

export const GemInformingLoopDecisionYes: React.FC = () => {
  const intl = useIntl();
  const { MY_DATA_URL } = useConfigForIdp();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.finalModulePageTitleYes)}
      module={{
        analyticsInformingLoopName: 'GEM Informing Loop',
        analyticsPageName: '8. Yes',
        image: CircularImageType.GENES_PEOPLE,
        title: intl.formatMessage(messages.finalModuleTitle),
        content: (
          <>
            <FormattedMessage
              description="GEM informing loop final page yes decision content"
              defaultMessage="You can now view your results. To access your results later, log in to your <i>All of Us</i> account, go to {myData}, and click DNA Results."
              values={{
                myData: (
                  <ExternalLink to={MY_DATA_URL.href} linkShouldOpenInCurrentTab>
                    <FormattedMessage
                      description="GEM informing loop final page yes decision content patient portal link name"
                      defaultMessage="My Data"
                    />
                  </ExternalLink>
                ),
              }}
            />
            <Spacer variant="medium" />
            <InternalButtonLink to="/">
              <FormattedMessage
                defaultMessage="View My Results"
                description="GEM informing loop final page yes decision content back to results button"
              />
            </InternalButtonLink>
          </>
        ),
      }}
    />
  );
};
