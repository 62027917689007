import { Box, Tab, TabPanel, Tabs } from '@color/continuum';
import { kebabCase } from 'lodash';

import { ITabConfig, ITabsConfig } from './types';

export interface IFullWidthTabsWithPanelProps {
  tabsConfig: ITabsConfig;
  tabId: string;
  setTab: (value: string) => void;
}

export const FullWidthTabsWithPanel: React.FC<IFullWidthTabsWithPanelProps> = (props) => {
  const { tabsConfig, tabId, setTab } = props;
  const activeTab: ITabConfig | undefined = tabsConfig[tabId];
  return (
    <>
      <Tabs
        variant="fullWidth"
        value={tabId}
        onChange={(_event, value) => setTab(value)}
        aria-label="Report Navigation Tabs"
      >
        {Object.keys(tabsConfig).map((tabsConfigKey) => {
          const tab = tabsConfig[tabsConfigKey];
          return (
            <Tab
              label={<Box>{tab.label}</Box>}
              id={kebabCase(`${tab.label}-tab`)}
              aria-controls={kebabCase(`${tab.label}-tabpanel`)}
              key={tabsConfigKey}
              value={tabsConfigKey}
            />
          );
        })}
      </Tabs>
      <TabPanel id={tabId} aria-labelledby={`${activeTab.label}`} key={tabId}>
        {activeTab?.component}
      </TabPanel>
    </>
  );
};
