import { Breadcrumbs, Container, ExternalLink, InternalLink, Spacer, Text } from '@color/continuum';
import { FormattedMessage, defineMessages } from 'react-intl';

import { useConfigForIdp } from 'hooks/useConfigForIdp';

const messages = defineMessages({
  myData: {
    description: 'Breadcrumbs link back to PTSC text',
    defaultMessage: 'My Data',
  },
  dnaResults: {
    description: 'Breadcrumbs link to GP dashboard',
    defaultMessage: 'DNA Results',
  },
});

interface Props {
  children?: React.ReactNode;
}

export const BreadcrumbsBar: React.FC<Props> = (props) => {
  const { children } = props;
  const { MY_DATA_URL } = useConfigForIdp();
  // We only link to the DNA results page if there are more breadcrumbs to the right.
  // Otherwise, we should just show the breadcrumb text with no link style (accomplished by not setting `to`).
  const dnaResultsBreadcrumb = children ? (
    <InternalLink to="/">
      <FormattedMessage {...messages.dnaResults} />
    </InternalLink>
  ) : (
    <Text>
      <FormattedMessage {...messages.dnaResults} />
    </Text>
  );

  return (
    <Container>
      <Spacer variant="medium" />
      <Breadcrumbs>
        <ExternalLink to={MY_DATA_URL.href} linkShouldOpenInCurrentTab>
          <FormattedMessage {...messages.myData} />
        </ExternalLink>
        {dnaResultsBreadcrumb}
        {children}
      </Breadcrumbs>
      <Spacer variant="medium" />
    </Container>
  );
};
