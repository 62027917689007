import { Environment } from '@color/lib';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { config } from 'config';

/**
 * Return a ratio of traces that should actually be sent to Sentry.
 */
function getTracesSampleRate(): number {
  return config.ENVIRONMENT === Environment.PRODUCTION ? 0.1 : 1.0;
}

const getSentryConfig = (): Sentry.BrowserOptions => ({
  dsn: config.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: config.ENVIRONMENT,
  tracesSampleRate: getTracesSampleRate(),
  ignoreErrors: [
    'msDiscoverChatAvailable', // [ENG-912]: Bug in iOS Edge
    '__gCrWeb.instantSearch', // Bug in iOS Chrome
  ],
  release: process.env.REACT_APP_COLOR_VERSION || 'N/A',
});

/**
 * Configure Sentry for the environment that GP runs in.
 *
 * This method should be called as soon as possible in the app's lifecycle.
 * (Ideally, invoke it before `<App />` is even rendered)
 */
export function initializeSentry(): void {
  const sentryDsn = config.SENTRY_DSN;
  if (!sentryDsn) {
    if (config.ENVIRONMENT !== Environment.LOCAL) {
      // eslint-disable-next-line no-console
      console.warn('Will not send any logging to Sentry');
    }
    return;
  }

  Sentry.init(getSentryConfig());
}
