import { useMutation } from 'react-query';

import { api } from 'lib/api';
import { ApiError } from 'lib/api/types';

import { IProviderInformation } from './types';

export const useCreateProviderInformationMutation = () =>
  useMutation<Response, ApiError, IProviderInformation>((json: IProviderInformation) =>
    api('aou_provider_info', { method: 'POST', json })
  );
