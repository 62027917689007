import {
  Container,
  Divider,
  ExternalLink,
  Grid,
  IconBulletList,
  ImageDivider,
  ListItem,
  Spacer,
  Text,
  UnorderedList,
} from '@color/continuum';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import DnaBanner from 'assets/images/dna-banner.svg';
import { CircularImageType } from 'components/common/CircularImage/types';
import { ImageWithContentSection } from 'components/HealthRelatedResults/components/ImageWithContentSection/ImageWithContentSection';

import { StatsGeneGridItem } from './StatsGeneGridItem';

export const HealthyLifestylesTab: React.FC = () => (
  <>
    <Container>
      <Spacer variant="xxLarge" />
      <ImageWithContentSection
        title={<FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Good_Health_Header" />}
        description={
          <Text paragraph>
            <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Good_Health_P1" />
          </Text>
        }
        image={CircularImageType.GENES}
        imageRight
      />
      <Spacer variant="xxxLarge" />
      <Grid container columnSpacing={10}>
        <StatsGeneGridItem
          title={<FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__20000_P1" />}
          description={<FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__20000_P2" />}
          value={20000}
        />
        <StatsGeneGridItem
          title={<FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__59_P1" />}
          description={<FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__59_P2" />}
          value={59}
        />
      </Grid>
      <Spacer variant="xxxLarge" />
      <Text align="center">
        <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Your_Genes_Just_One" />
      </Text>
      <Spacer variant="xxxLarge" />
    </Container>
    <ImageDivider imageSrc={DnaBanner} />
    <Container>
      <Spacer variant="xxxLarge" />
      <ImageWithContentSection
        title={<FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Keep_Checking_Header" />}
        description={
          <>
            <Text paragraph>
              <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Keep_Checking_P1" />
            </Text>
            <Text paragraph>
              <FormattedMessage
                id="HDR_Uninformative_Healthy_Lifestyles__Keep_Checking_P2"
                values={{
                  link: (chunks: ReactNode) => (
                    <ExternalLink to="https://www.nccn.org">{chunks}</ExternalLink>
                  ),
                }}
              />
            </Text>
            <IconBulletList
              listItems={[
                {
                  content: (
                    <Text variant="body1">
                      <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Keep_Checking_P3" />
                    </Text>
                  ),
                  contentId: 'mammograms',
                },
                {
                  content: (
                    <Text variant="body1">
                      <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Keep_Checking_P4" />
                    </Text>
                  ),
                  contentId: 'colonoscopies',
                },
                {
                  content: (
                    <Text variant="body1">
                      <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Keep_Checking_P5" />
                    </Text>
                  ),
                  contentId: 'prostate exams',
                },
                {
                  content: (
                    <Text variant="body1">
                      <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Keep_Checking_P6" />
                    </Text>
                  ),
                  contentId: 'skin checks',
                },
              ]}
            />
          </>
        }
        image={CircularImageType.CLINICAL_TEST}
      />
      <Spacer variant="xxxLarge" />
      <Divider aria-hidden="true" />
      <Spacer variant="xxxLarge" />
      <ImageWithContentSection
        title={<FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Take_A_Look_Header" />}
        description={
          <>
            <Text paragraph>
              <FormattedMessage
                id="HDR_Uninformative_Healthy_Lifestyles__Take_A_Look_P1"
                values={{
                  link1: (chunks: ReactNode) => (
                    <ExternalLink to="https://www.heart.org/">{chunks}</ExternalLink>
                  ),
                  link2: (chunks: ReactNode) => (
                    <ExternalLink to="https://www.nhlbi.nih.gov/">{chunks}</ExternalLink>
                  ),
                }}
              />
            </Text>
            <UnorderedList>
              <ListItem>
                <Text variant="body1">
                  <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Take_A_Look_P2" />
                </Text>
              </ListItem>
              <ListItem>
                <Text variant="body1">
                  <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Take_A_Look_P3" />
                </Text>
              </ListItem>
              <ListItem>
                <Text variant="body1">
                  <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Take_A_Look_P4" />
                </Text>
              </ListItem>
              <ListItem>
                <Text variant="body1">
                  <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Take_A_Look_P5" />
                </Text>
              </ListItem>
            </UnorderedList>
            <Spacer variant="small" />
            <Text paragraph>
              <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Take_A_Look_P6" />
            </Text>
            <UnorderedList>
              <ListItem>
                <Text variant="body1">
                  <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Take_A_Look_P7" />
                </Text>
              </ListItem>
              <ListItem>
                <Text variant="body1">
                  <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Take_A_Look_P8" />
                </Text>
              </ListItem>
              <ListItem>
                <Text variant="body1">
                  <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Take_A_Look_P9" />
                </Text>
              </ListItem>
              <ListItem>
                <Text variant="body1">
                  <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Take_A_Look_P10" />
                </Text>
              </ListItem>
              <ListItem>
                <Text variant="body1">
                  <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Take_A_Look_P11" />
                </Text>
              </ListItem>
            </UnorderedList>
          </>
        }
        image={CircularImageType.FOOD}
      />
      <Spacer variant="xxxLarge" />
      <Divider aria-hidden="true" />
      <Spacer variant="xxxLarge" />
      <ImageWithContentSection
        title={<FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Make_Sure_Header" />}
        description={
          <>
            <Text paragraph>
              <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Make_Sure_P1" />
            </Text>
            <Text paragraph>
              <FormattedMessage
                id="HDR_Uninformative_Healthy_Lifestyles__Make_Sure_P2"
                values={{
                  link: (chunks: ReactNode) => (
                    <ExternalLink to="https://www.hhs.gov/programs/prevention-and-wellness/nutrition-and-fitness/index.html">
                      {chunks}
                    </ExternalLink>
                  ),
                }}
              />
            </Text>
          </>
        }
        image={CircularImageType.STEPS}
      />
      <Spacer variant="xxxLarge" />
      <Divider aria-hidden="true" />
      <Spacer variant="xxxLarge" />
      <Spacer variant="large" />
      <ImageWithContentSection
        title={
          <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Maintain_Healthy_Header" />
        }
        description={
          <>
            <Text paragraph>
              <FormattedMessage id="HDR_Uninformative_Healthy_Lifestyles__Maintain_Healthy_P1" />
            </Text>
            <Text paragraph>
              <FormattedMessage
                id="HDR_Uninformative_Healthy_Lifestyles__Maintain_Healthy_P2_V2"
                values={{
                  link: (chunks: ReactNode) => (
                    <ExternalLink to="https://www.nih.gov/health-information/your-healthiest-self-wellness-toolkits">
                      {chunks}
                    </ExternalLink>
                  ),
                }}
              />
            </Text>
          </>
        }
        image={CircularImageType.HEALTHY_LIFESTYLE}
      />
      <Spacer variant="xxxLarge" />
      <Divider aria-hidden="true" />
      <Spacer variant="xxxLarge" />
    </Container>
  </>
);
