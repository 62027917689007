import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Spacer, Text } from '@color/continuum';
// TODO Import from CDS rather than MUI. In CDS-341 we turned on lint warnings on direct MUI imports. This line is disabled because GP CI sets the rule to error instead of warn.
// Swap this for a CDS import instead of a MUI import.
// eslint-disable-next-line no-restricted-imports
import { Card } from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
/**
 * This is a special import that configures the PDF.js worker properly.
 * This only works with Webpack4 which GP and GP stories use.
 * See here for more details: react-pdf/dist/esm/entry.webpack5
 * We will need to change this if we upgrade to Webpack5.
 */
import { Document, Page, pdfjs } from 'react-pdf/dist/esm';
import { useElementSize } from 'usehooks-ts';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// TODO: (akislyuk) Remove this polyfill after Promise.withResolvers is universally supported
if (typeof Promise.withResolvers === 'undefined') {
  if (window) {
    // @ts-expect-error Promise.withResolvers polyfill
    window.Promise.withResolvers = function () {
      let resolve;
      let reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
  }
}

export interface IPdfViewerProps {
  pdfUrl: string;
}

// TODO(ALLOFUS-1848): This component needs to make the PDF size responsive.
// There is not a simple option for this, we can do something like this:
// https://github.com/wojtekmaj/react-pdf/issues/718
export const PdfViewer: React.FC<IPdfViewerProps> = (props) => {
  const { pdfUrl } = props;
  const [numberOfPages, setNumberOfPages] = useState<number>(0);

  const [pageWidth, setPageWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);
  const [ref, { width: wrapperWidth, height: wrapperHeight }] = useElementSize();

  const pageWidthIsGreaterThanHeight = useMemo(() => {
    const widthRatio = pageWidth / wrapperWidth;
    const heightRatio = pageHeight / wrapperHeight;
    return widthRatio > heightRatio;
  }, [pageHeight, pageWidth, wrapperWidth, wrapperHeight]);

  return (
    <Card ref={ref}>
      <Document
        file={pdfUrl}
        loading={
          <Text>
            <FormattedMessage id="Pdf_Viewer_Loading_Text" />
          </Text>
        }
        onLoadSuccess={({ numPages }: { numPages: number }) => setNumberOfPages(numPages)}
      >
        {[...Array(numberOfPages).keys()].map((index) => (
          <Fragment key={index}>
            {index !== 0 && <Spacer variant="medium" />}
            <Page
              renderAnnotationLayer={false}
              pageNumber={index + 1}
              width={pageWidthIsGreaterThanHeight ? wrapperWidth : undefined}
              height={!pageWidthIsGreaterThanHeight ? wrapperHeight : undefined}
              onLoadSuccess={(page) => {
                setPageWidth(page.width);
                setPageHeight(page.height);
              }}
            />
          </Fragment>
        ))}
      </Document>
    </Card>
  );
};
