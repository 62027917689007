import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { LocationWithDecision } from 'components/common/InformingLoop/types';
import { ModuleType } from 'lib/analytics/types';

import { useUserWasExternallyLinkedToCompletedInformingLoop } from '../useUserWasExternallyLinkedToCompletedInformingLoop';
import { usePgxInformingLoopLocationsByModule } from './locations';
import {
  PgxInformingLoopDecisionMaybeLater,
  PgxInformingLoopDecisionModule,
  PgxInformingLoopDecisionNo,
  PgxInformingLoopDecisionYes,
  PgxInformingLoopModule1,
  PgxInformingLoopModule2,
  PgxInformingLoopModule3,
  PgxInformingLoopModule4,
  PgxInformingLoopModule5,
  PgxInformingLoopModule6,
} from './PgxInformingLoopModules';

export const PgxInformingLoopRoutes = () => {
  const { path } = useRouteMatch();
  const locations = usePgxInformingLoopLocationsByModule();
  const { state } = useLocation<LocationWithDecision>();
  const shouldRedirectToDashboard = useUserWasExternallyLinkedToCompletedInformingLoop({
    moduleType: ModuleType.PGX,
  });

  return (
    <Switch>
      {shouldRedirectToDashboard && <Redirect to="/" />}
      {/* Redirect to the first module */}
      <Redirect exact path={`${path}`} to={locations.MODULE_1} />
      <Route exact path={locations.MODULE_1.pathname}>
        <PgxInformingLoopModule1 />
      </Route>
      <Route exact path={locations.MODULE_2.pathname}>
        <PgxInformingLoopModule2 />
      </Route>
      <Route exact path={locations.MODULE_3.pathname}>
        <PgxInformingLoopModule3 />
      </Route>
      <Route exact path={locations.MODULE_4.pathname}>
        <PgxInformingLoopModule4 />
      </Route>
      <Route exact path={locations.MODULE_5.pathname}>
        <PgxInformingLoopModule5 />
      </Route>
      <Route exact path={locations.MODULE_6.pathname}>
        <PgxInformingLoopModule6 />
      </Route>
      <Route exact path={locations.DECISION.pathname}>
        <PgxInformingLoopDecisionModule />
      </Route>
      <Route exact path={locations.RESULT.pathname}>
        {state?.decision === 'yes' && <PgxInformingLoopDecisionYes />}
        {state?.decision === 'no' && <PgxInformingLoopDecisionNo />}
        {state?.decision === 'maybe-later' && <PgxInformingLoopDecisionMaybeLater />}
      </Route>
    </Switch>
  );
};
