import {
  ActionLink,
  ExternalButtonLink,
  ExternalLink,
  InlineSpacer,
  Spacer,
  Text,
  Title,
} from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// TODO Import from CDS rather than MUI. In CDS-341 we turned on lint warnings on direct MUI imports. This line is disabled because GP CI sets the rule to error instead of warn.
// Swap this for a CDS import instead of a MUI import.
// eslint-disable-next-line no-restricted-imports
import { Box, Container } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { useResultStatuses } from 'components/Dashboard/api';
import { trackAnalyticsEvent } from 'lib/analytics';
import { AnalyticsPlatform, ModuleType, SegmentEventType } from 'lib/analytics/types';

import { PdfViewer } from '../components/PdfViewer/PdfViewer';
import { KEEP_YOUR_INFORMATION_SAFE_URL } from './constants';

const BACK_TO_REPORT_BUTTON_TEXT = {
  [ModuleType.HDR]: <FormattedMessage id="HDR_CTA_Back" />,
  [ModuleType.PGX]: <FormattedMessage id="PGX_CTA_Back" />,
};

const SEGMENT_EVENT_NAME = {
  [ModuleType.HDR]: SegmentEventType.HDR_REPORT_DOWNLOADED,
  [ModuleType.PGX]: SegmentEventType.PGX_REPORT_DOWNLOADED,
};

export const DownloadReport: React.FC = () => {
  const { hdrResultsAreOnHold, pgxResultsAreOnHold } = useResultStatuses();
  const { search } = useLocation();
  const { goBack } = useHistory();
  const { locale } = usePreferredLocale();

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const moduleType = query.get('moduleType') as ModuleType;
  const pdfUrl = decodeURI(query.get('pdfUrl') || '');
  const isS3PresignedLink = pdfUrl.includes('s3.amazonaws.com');
  // TODO(ALLOFUS-1848): Validate that the pdfUrl is part of an expected set.
  if (!pdfUrl || !moduleType || !(moduleType in BACK_TO_REPORT_BUTTON_TEXT)) {
    return null;
  }
  // If the report is on hold do not allow participants to download the report
  if (
    (moduleType === ModuleType.PGX && pgxResultsAreOnHold) ||
    (moduleType === ModuleType.HDR && hdrResultsAreOnHold)
  ) {
    return null;
  }
  return (
    <>
      <Container>
        <Box display="flex" justifyContent="space-between">
          <Box flex={1}>
            <ActionLink onClick={goBack} underline="none" color="black">
              <Box display="flex" alignItems="center" component="span">
                <ArrowBackIcon
                  sx={(theme) => ({
                    height: theme.spacing(3),
                    width: theme.spacing(3),
                  })}
                />
                <InlineSpacer variant="xSmall" />
                <Text component="span" variant="body1" sx={{ fontWeight: 500 }}>
                  {
                    BACK_TO_REPORT_BUTTON_TEXT[
                      moduleType as keyof typeof BACK_TO_REPORT_BUTTON_TEXT
                    ]
                  }
                </Text>
              </Box>
            </ActionLink>
            <Spacer variant="medium" />
            <Title variant="headline3">
              <FormattedMessage id="CTA_Download" />
            </Title>
            <Spacer variant="medium" />
            <Text paragraph>
              <FormattedMessage
                id="Download_Warning"
                values={{
                  link: (chunks: ReactNode) => (
                    <ExternalLink to={KEEP_YOUR_INFORMATION_SAFE_URL[locale]}>
                      {chunks}
                    </ExternalLink>
                  ),
                }}
              />
            </Text>
          </Box>
          <Box>
            <ExternalButtonLink
              to={pdfUrl}
              onClick={async () => {
                // We use whether the link is a s3 presigned link as a proxy for whether
                // we should write the event to Segment.
                // TODO: This doesn't feel like the best solution. Maybe we should pass all the
                // state of this page via props rather than the URL?
                if (isS3PresignedLink) {
                  await trackAnalyticsEvent(
                    {
                      eventType: SEGMENT_EVENT_NAME[moduleType as keyof typeof SEGMENT_EVENT_NAME],
                    },
                    AnalyticsPlatform.SEGMENT
                  );
                }
              }}
              download
            >
              <FormattedMessage id="HDR_Uninformative_Download_Header" />
            </ExternalButtonLink>
          </Box>
        </Box>
      </Container>
      {/* TODO(ALLOFUS-1848): Fix the background color of this component. */}
      <Container>
        <PdfViewer pdfUrl={pdfUrl} />
      </Container>
    </>
  );
};
