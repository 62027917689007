import { References } from 'components/common/Reports/References';

import { Trait } from '../../types';
import { getTraitResultsReferences } from './content';

interface Props {
  trait: Trait;
}

export const TraitResultsReferences: React.FC<Props> = (props) => {
  const { trait } = props;
  const references = getTraitResultsReferences(trait);
  return <References references={references} />;
};
