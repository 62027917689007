import { MenuItem, Select, Text, getMuiClassSelector, useTheme } from '@color/continuum';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useField } from 'formik';
import { FC } from 'react';

interface ICountrySelector {
  label: string;
  countryCodeName: string;
  error: boolean;
}
export const CountrySelector: FC<ICountrySelector> = (props) => {
  const { countryCodeName, error } = props;
  const [field] = useField(countryCodeName);
  const theme = useTheme();

  return (
    <Select
      disableUnderline
      defaultValue="1"
      IconComponent={() => <KeyboardArrowDownIcon color={error ? 'error' : 'action'} />}
      sx={{
        textAlign: 'right',
        minWidth: theme.spacing(10),
        height: '100%',
        paddingRight: 1.5,
        marginRight: '5px',
        [getMuiClassSelector('& .MuiOutlinedInput-notchedOutline')]: {
          display: 'none',
        },
      }}
      {...field}
    >
      <MenuItem value="1">
        <Text color={error ? theme.palette.error.text : 'primary'}>+1</Text>
      </MenuItem>
    </Select>
  );
};
