import { Text } from '@color/continuum';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { CircularImageType } from 'components/common/CircularImage/types';
import { GpTooltip } from 'components/common/GpTooltip/GpTooltip';
import { FinalPreResultsModuleFooter } from 'components/common/InformingLoop/InformingLoopFooters/FinalPreResultsModuleFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { trackAnalyticsEvent } from 'lib/analytics';
import { AnalyticsPlatform, EventType } from 'lib/analytics/types';

const messages = defineMessages({
  ancestryILModule3PageTitle: {
    description: 'Ancestry Informing Loop Page Title - Module 3',
    defaultMessage: 'Sensitive Information',
  },
  ancestryIlTooltipLabelExpectations: {
    description: 'Ancestry Informing Loop results expectations tooltip accessible label',
    defaultMessage: 'Genetic Ancestry Expectations Info',
  },
});

export const AncestryInformingLoopModule3: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const queryClient = useQueryClient();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.ancestryILModule3PageTitle)}
      module={{
        analyticsInformingLoopName: 'Ancestry Informing Loop',
        analyticsPageName: '3. Risks',
        image: CircularImageType.ANCESTRY_REPORT,
        title: intl.formatMessage({
          description: 'Ancestry informing loop page 3 title',
          defaultMessage: 'You may receive sensitive or unexpected information.',
        }),
        content: (
          <FormattedMessage
            description="Ancestry informing loop page 3 content"
            defaultMessage="<p>Information about where your ancestors came from may be different than you thought. {tooltip} Your genetic ancestry results will not tell you who you are related to. </p><p>The insights in your results may make you wonder if you are related to a family member in the way you thought. Keep in mind that DNA results are not always the same for all family members.</p><p>If you have questions, please reach out to Support. We can refer you to helpful resources.</p>"
            values={{
              tooltip: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.ancestryIlTooltipLabelExpectations)}
                  content={
                    <Text>
                      {intl.formatMessage({
                        description: 'Ancestry informing loop page 3 tooltip content',
                        defaultMessage:
                          'You may not see some places in your genetic ancestry results if only a small part of your DNA traces back to that region. This is because the information is not always accurate at that level.',
                      })}
                    </Text>
                  }
                />
              ),
            }}
          />
        ),
      }}
      footer={
        <FinalPreResultsModuleFooter
          onViewMyResultsClick={async () => {
            try {
              await trackAnalyticsEvent(
                { eventType: EventType.CHOSE_VIEW_AFTER_ANCESTRY_INFORMING_LOOP },
                AnalyticsPlatform.DEPRECATED_EVENT_LOG
              );
              // After the creation of the informing loop event, remove this query so that the backend is queried again
              queryClient.removeQueries({ queryKey: 'event_log' });
              history.push('/ancestry');
            } catch {
              // TODO(ALLOFUS-2452): Do something on error when we figure out the correct UX.
            }
          }}
        />
      }
    />
  );
};
