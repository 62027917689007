import { Locale } from '@color/lib';

export const US_STATES: Record<string, string> = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  FM: 'Federated States of Micronesia',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MH: 'Marshall Islands',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MP: 'North Mariana Islands',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  PW: 'Palau',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennesse',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const languagesList: Record<Locale, any> = {
  [Locale.ENGLISH]: {
    English: 'en',
    Spanish: 'es',
    Afrikaans: 'af',
    Akan: 'ak',
    Albanian: 'sq',
    Amharic: 'am',
    Armenian: 'hy',
    Arabic: 'ar',
    Azeri: 'az',
    Bambara: 'bm',
    Bashkir: 'ba',
    Basque: 'eu',
    Belarusian: 'be',
    Bengali: 'bn',
    Bosnian: 'bs',
    Bulgarian: 'bg',
    Burmese: 'my',
    Catalan: 'ca',
    Chamorro: 'ch',
    Chinese: 'zh',
    Croatian: 'hr',
    Czech: 'cs',
    Danish: 'da',
    Dutch: 'nl',
    Estonian: 'et',
    Ewe: 'ee',
    Farsi: 'fa',
    Finnish: 'fi',
    Flemish: 'nl',
    French: 'fr',
    Georgian: 'ka',
    German: 'de',
    Greek: 'el',
    Guarani: 'gn',
    Gujarati: 'gu',
    'Haitian Creole': 'ht',
    Hausa: 'ha',
    Hebrew: 'he',
    Hindi: 'hi',
    Hungarian: 'hu',
    Icelandic: 'is',
    Igbo: 'ig',
    Indonesian: 'id',
    Italian: 'it',
    Japanese: 'ja',
    Kannada: 'kn',
    Kazakh: 'kk',
    Khmer: 'km',
    Kikuyu: 'ki',
    Kinyarwanda: 'rw',
    Korean: 'ko',
    Kurdish: 'ku',
    Kyrgyz: 'ky',
    Lao: 'lo',
    Latvian: 'lv',
    Lingala: 'ln',
    Lithuanian: 'lt',
    Macedonian: 'mk',
    Malay: 'ms',
    Malayalam: 'ml',
    Marathi: 'mr',
    Marshallese: 'mh',
    Mongolian: 'mn',
    Norwegian: 'no',
    Pashto: 'ps',
    Polish: 'pl',
    Portuguese: 'pt',
    Punjabi: 'pa',
    Quechua: 'qu',
    Romanian: 'ro',
    Russian: 'ru',
    Samoan: 'sm',
    Sango: 'sg',
    Serbian: 'sr',
    Shona: 'sn',
    Sichuan: 'ii',
    Sinhalese: 'si',
    Slovak: 'sk',
    Slovene: 'sl',
    Somali: 'so',
    'Soranî (Kurdish)': 'ku',
    Swahili: 'sw',
    Swedish: 'sv',
    'Tagalog (Filipino)': 'tl',
    Tajik: 'tg',
    Tamil: 'ta',
    Telugu: 'te',
    Thai: 'th',
    Tibetan: 'bo',
    Tigrinya: 'ti',
    Tongan: 'to',
    Turkish: 'tr',
    Twi: 'tw',
    Ukrainian: 'uk',
    Urdu: 'ur',
    Uzbek: 'uz',
    Vietnamese: 'vi',
    Wolof: 'wo',
    Xhosa: 'xh',
    Yiddish: 'yi',
    Yoruba: 'yo',
    Zulu: 'zu',
  },
  [Locale.SPANISH]: {
    Inglés: 'en',
    Español: 'es',
    Afrikáans: 'af',
    Akan: 'ak',
    Albanés: 'sq',
    Amárico: 'am',
    Armenio: 'hy',
    Árabe: 'ar',
    Azerí: 'az',
    Bambara: 'bm',
    Baskir: 'ba',
    Euskera: 'eu',
    Bielorruso: 'be',
    Bengalí: 'bn',
    Bosnio: 'bs',
    Búlgaro: 'bg',
    Birmano: 'my',
    Catalán: 'ca',
    Chamorro: 'ch',
    Chino: 'zh',
    Croata: 'hr',
    Checo: 'cs',
    Danés: 'da',
    Holandés: 'nl',
    Estonio: 'et',
    Ewé: 'ee',
    Farsi: 'fa',
    Finés: 'fi',
    Flamenco: 'nl',
    Francés: 'fr',
    Georgiano: 'ka',
    Alemán: 'de',
    Griego: 'el',
    Guaraní: 'gn',
    Gujarati: 'gu',
    'Criollo haitiano': 'ht',
    Hausa: 'ha',
    Hebreo: 'he',
    Hindi: 'hi',
    Húngaro: 'hu',
    Islandés: 'is',
    Igbo: 'ig',
    'Bahasa indonesio': 'id',
    Italiano: 'it',
    Japonés: 'ja',
    Canarés: 'kn',
    Kazajo: 'kk',
    Jémer: 'km',
    Kikuyu: 'ki',
    Kinyaruanda: 'rw',
    Coreano: 'ko',
    Kurdo: 'ku',
    Kirguís: 'ky',
    Laosiano: 'lo',
    Letón: 'lv',
    Lingala: 'ln',
    Lituano: 'lt',
    Macedonio: 'mk',
    Malayo: 'ms',
    Malabar: 'ml',
    Maratí: 'mr',
    Marshalés: 'mh',
    Mongol: 'mn',
    Noruego: 'no',
    Pastún: 'ps',
    Polaco: 'pl',
    Portugués: 'pt',
    Panyabi: 'pa',
    Quechua: 'qu',
    Rumano: 'ro',
    Ruso: 'ru',
    Samoano: 'sm',
    Sango: 'sg',
    Serbio: 'sr',
    Shona: 'sn',
    Sichuanés: 'ii',
    Singalés: 'si',
    Eslovaco: 'sk',
    Esloveno: 'sl',
    Somalí: 'so',
    'Kurdo (sorani)': 'ku',
    Suajili: 'sw',
    Sueco: 'sv',
    Tagalo: 'tl',
    Tayiko: 'tg',
    Tamil: 'ta',
    Telugú: 'te',
    Tailandés: 'th',
    Tibetano: 'bo',
    Tigriña: 'ti',
    Tongano: 'to',
    Turco: 'tr',
    Twi: 'tw',
    Ucraniano: 'uk',
    Urdu: 'ur',
    Uzbeko: 'uz',
    Vietnamita: 'vi',
    Wólof: 'wo',
    Xhosa: 'xh',
    Ídish: 'yi',
    Yoruba: 'yo',
    Zulú: 'zu',
  },
} as Record<Locale, any>;
