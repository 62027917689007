import { Text } from '@color/continuum';
import { FormattedMessage, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { FirstModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/FirstModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

export const PgxPreResultsModule1: React.FC = () => {
  const intl = useIntl();
  const messages = {
    pageTitle: intl.formatMessage({ id: 'PGX_PreResults_1_Helmet_Title' }),
  };

  return (
    <InformingLoopModule
      helmetTitle={messages.pageTitle}
      module={{
        analyticsInformingLoopName: 'PGx Pre Results',
        analyticsPageName: '1. Start',
        image: CircularImageType.DRUGS_ENVELOPE,
        title: <FormattedMessage id="PGX_Pre_Results_Page_1__Title" />,
        content: (
          <>
            <Text paragraph>
              <FormattedMessage id="PGX_Pre_Results_Page_1__Paragraph_1" />
            </Text>
            <Text paragraph>
              <FormattedMessage id="PGX_Pre_Results_Page_1__Paragraph_2" />
            </Text>
          </>
        ),
      }}
      footer={<FirstModuleInformingLoopFooter />}
    />
  );
};
