export interface IAppointment {
  // Type is passed via URL
  type: string;
  // These fields are populated by ContactInfo.tsx
  language: string;
  phoneCountryCode: number;
  phoneNumber: string;
  location: string;
  timezone: string;
  // This field is populated by Schedule.tsx
  timestamp?: Date;
}

export interface IExistingAppointment {
  appointment: {
    language: string;
    phoneCountryCode: number;
    phoneNumber: string;
    location: string;
    timestamp: string;
    timezone: string;
  } | null;
}

// Timezone
export enum Timezones {
  // Canonical for "US/Pacific"
  'America/Los_Angeles' = 'Pacific',
  // Canonical for "US/Mountain"
  'America/Denver' = 'Mountain - Denver',
  // Canonical for "US/Central"
  'America/Chicago' = 'Central',
  // Canonical for "US/Eastern"
  'America/New_York' = 'Eastern',
  // Canonical for "US/Alaska"
  'America/Anchorage' = 'Alaska',
  // Canonical for "US/Arizona"
  'America/Phoenix' = 'Arizona',
  // Canonical for Atlantic Standard Time, including Puerto Rico and US Virgin Islands
  'America/Puerto_Rico' = 'Atlantic Standard',
  // Canonical for "US/Hawaii"
  'Pacific/Honolulu' = 'Hawaii',
  // Canonical for "US/Samoa"
  'Pacific/Pago_Pago' = 'US Samoa',
}
