import { Grid, InlineSpacer, Spacer, Text, Title, useMediaQuery, useTheme } from '@color/continuum';
import { ReactNode } from 'react';

import { ResultBannerIcon } from './ResultBannerIcon';
import { ResultBannerIconType } from './types';

export interface ResultBannerProps {
  icon: ResultBannerIconType;
  subtitle: ReactNode;
  title: ReactNode;
}

export const ResultBanner: React.FC<ResultBannerProps> = (props) => {
  const { icon, title, subtitle } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        background: theme.palette.secondary.hover,
        borderRadius: '104px 0 0 104px',
        marginLeft: '2.5vw',
        paddingX: 6,
        paddingY: 4,
        width: 'fill-available',
        [theme.breakpoints.down('sm')]: {
          marginLeft: 2,
        },
      }}
    >
      <Grid item>
        <ResultBannerIcon icon={icon} />
        <InlineSpacer variant="xLarge" />
      </Grid>
      <Grid item xs>
        <Text
          variant={isMobile ? 'body2' : 'body1'}
          sx={{
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
          }}
        >
          {subtitle}
        </Text>
        <Spacer variant="xSmall" />
        <Title variant={isMobile ? 'headline2' : 'headline1'}>{title}</Title>
      </Grid>
    </Grid>
  );
};
