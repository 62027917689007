import { ApiError } from '@color/lib';
import { useMutation, useQuery } from 'react-query';

import { api } from 'lib/api';

import { IAppointment } from '../types';
import { AvailabilityData } from './types';

export const useScheduleAppointmentMutation = (pid?: string) =>
  useMutation<Response, ApiError, IAppointment>(
    (json: IAppointment) => {
      // If scheduling on behalf of a participant, pass the PID. Falsy will auto-use the logged in user's PID
      return api('aou_schedule_appointment', {
        method: 'POST',
        json,
        searchParams: pid ? { pid } : {},
      });
    },
    {
      onError: () => {
        // TODO ALLOFUS-1975: display error to user noting appointment unavailable
        // and refresh display of available appointments.
      },
    }
  );

export const useAvailabilityData = (
  type: string,
  location: string,
  language: string,
  week: string
) =>
  useQuery<AvailabilityData>([
    'aou_list_appointment_availabilities',
    {
      searchParams: { type, location, language, week },
    },
  ]);
