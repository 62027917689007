import { config } from 'config';

import { EventProperties, NewEventPayload } from './types';

// We anonymize the IP address so that Segment events cannot easily be tied back to an end user
// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#anonymizing-ip
export const ANONYMOUS_IP_ADDRESS = '0.0.0.0';

export function initializeSegment() {
  window.analytics?.load(config.SEGMENT_WRITE_KEY);
}

export function identifySegmentUser(userId: string) {
  window.analytics?.identify(
    userId, // User-specific ID used to identify events in Segment
    {}, // Additional user traits
    {
      context: { ip: ANONYMOUS_IP_ADDRESS },
    }
  );
}

export function resetSegmentUser() {
  window.analytics?.reset();
}

export function logSegmentPageView(name: string, properties: EventProperties = {}) {
  window.analytics?.page(
    undefined, // category
    name,
    {
      ...properties,
      context: { ip: ANONYMOUS_IP_ADDRESS },
    }
  );
}

export function trackSegmentEvent(event: NewEventPayload) {
  window.analytics?.track(event.eventType, event.parameters, {
    context: { ip: ANONYMOUS_IP_ADDRESS },
  });
}
