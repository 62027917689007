export enum Trait {
  BITTERNESS = 'bitterness',
  CILANTRO = 'cilantro',
  EARWAX = 'earwax',
  LACTOSE = 'lactose',
}

export interface TraitOutcome {
  rsids: string[];
  genotypes: string[];
  outcome: string;
  hasViewedTrait: boolean;
}

export type TraitOutcomes = {
  [key in Trait]?: TraitOutcome;
} & {
  hasViewedTrait: boolean;
};

export type TraitOutcomeData = TraitOutcome | TraitOutcomes;
