import { Text, TwoButtonDialog } from '@color/continuum';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ModuleType } from 'lib/analytics/types';

import { useModuleTypeBasedOnCurrentPath } from '../utilities';

interface IContactInfoDialogProps {
  handleContactInfoDialogConfirm: () => void;
  setShowContactInfoDialog: (flag: boolean) => void;
}
export const ContactInfoDialog: React.FC<IContactInfoDialogProps> = (props) => {
  const { handleContactInfoDialogConfirm, setShowContactInfoDialog } = props;
  const history = useHistory();
  const moduleType = useModuleTypeBasedOnCurrentPath();

  return (
    <TwoButtonDialog
      title={<FormattedMessage id="GC_Scheduling_Edit__Title" />}
      primaryButtonText={<FormattedMessage id="Scheduling_Schedule__Button__Next" />}
      secondaryButtonText={<FormattedMessage id="Scheduling_Confirmation__Button__Exit" />}
      onPrimaryAction={() => handleContactInfoDialogConfirm()}
      onSecondaryAction={() => history.push(`/`)}
      onClose={() => setShowContactInfoDialog(false)}
      isFullScreenOnMobile={false}
    >
      <Text>
        {moduleType === ModuleType.PGX ? (
          <FormattedMessage id="GC_Scheduling_Edit__Body_PGx" />
        ) : (
          <FormattedMessage id="GC_Scheduling_Edit__Body_HDR" />
        )}
      </Text>
    </TwoButtonDialog>
  );
};
