import { Container, ListItem, Spacer, Text, Title, UnorderedList } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { AccordionIconType } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionIcon/types';
import { AccordionWithIcon } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionWithIcon';

import { PhenotypesTable } from './PhenotypesTable';

interface IMethodsAndLimitationsProps {
  cvlSite: string;
  genomeCenterSite: string;
}
export const MethodsAndLimitations: React.FC<IMethodsAndLimitationsProps> = (props) => {
  const { cvlSite, genomeCenterSite } = props;
  return (
    <Container>
      <AccordionWithIcon
        icon={AccordionIconType.MICROSCOPE}
        title={<FormattedMessage id="PGX__Methods_and_limitations_section_title" />}
      >
        <Container>
          <Text paragraph>
            <FormattedMessage id="PGX__Methods_and_limitations_paragraph_1" />
          </Text>
          <Spacer variant="medium" />
          <Title variant="headline3">
            <FormattedMessage id="PGX__methodology_subsection_title" />
          </Title>
          <Spacer variant="medium" />
          <Text paragraph>
            <FormattedMessage
              id="PGX__methodology_paragraph_1"
              values={{ cvlSite, genomeCenterSite }}
            />
          </Text>
          <Text paragraph>
            <FormattedMessage id="PGX__methodology_paragraph_2" />
          </Text>
          <Text paragraph>
            <FormattedMessage id="PGX__methodology_paragraph_3" />
          </Text>
          <Title variant="headline3">
            <FormattedMessage id="PGX__Genes_and_alleles_subsection_title" />
          </Title>
          <Spacer variant="medium" />
          <Text paragraph>
            <FormattedMessage id="PGX__Genes_and_alleles_paragraph_1" />
          </Text>
          <Spacer variant="medium" />
          <Title variant="headline3">
            <FormattedMessage id="PGX__Phenotypes_subsection_title" />
          </Title>
          <Spacer variant="medium" />
          <PhenotypesTable />
          <Spacer variant="medium" />
          <Title variant="headline3">
            <FormattedMessage id="PGX__Medications_subsection_title" />
          </Title>
          <Spacer variant="medium" />
          <Text paragraph>
            <FormattedMessage id="PGX__Medications_paragraph_1" />
          </Text>
          <Title variant="headline3">
            <FormattedMessage id="PGX__limitations_subsection_title" />
          </Title>
          <Spacer variant="medium" />
          <UnorderedList>
            <ListItem>
              <Text bold>
                <FormattedMessage id="PGX__limitations_bullet_1" />
              </Text>
            </ListItem>
            <ListItem>
              <Text bold>
                <FormattedMessage id="PGX__limitations_bullet_2" />
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                <FormattedMessage id="PGX__limitations_bullet_3" />
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                <FormattedMessage id="PGX__limitations_bullet_4" />
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                <FormattedMessage id="PGX__limitations_bullet_5" />
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                <FormattedMessage id="PGX__limitations_bullet_6" />
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                <FormattedMessage id="PGX__limitations_bullet_7" />
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                <FormattedMessage id="PGX__limitations_bullet_8" />
              </Text>
            </ListItem>
          </UnorderedList>
        </Container>
      </AccordionWithIcon>
    </Container>
  );
};
