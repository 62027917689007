import { ExternalLink, Text } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { GpTooltip } from 'components/common/GpTooltip/GpTooltip';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { config } from 'config';

import { HDR_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  hdrILModule6PageTitle: {
    description: 'HDR Informing Loop Page Title - Module 6',
    defaultMessage: 'Benefits',
  },
  hdrIlTooltipLabelGeneticCounselor: {
    description: 'HDR Informing Loop Genetic Counselor tooltip accessible label',
    defaultMessage: 'Genetic Counselor Info',
  },
});

export const HdrInformingLoopModule6: React.FC = () => {
  const intl = useIntl();
  const { locale } = usePreferredLocale();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.hdrILModule6PageTitle)}
      module={{
        analyticsInformingLoopName: HDR_INFORMING_LOOP_NAME,
        analyticsPageName: '6. Benefits',
        image: CircularImageType.STAR_DOCUMENT_RED,
        title: (
          <FormattedMessage
            description="HDR informing loop page 6 title"
            defaultMessage="What are the benefits of learning about my hereditary disease risk?"
          />
        ),
        content: (
          <>
            <Text paragraph>
              <FormattedMessage
                description="HDR informing loop page 6 content paragraph 1"
                defaultMessage="You could learn that there is something very important for your health in your DNA. Knowing this could help you work with your doctor or health care provider to prevent disease or to detect it early. In some cases, knowing this information can be lifesaving."
              />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="HDR informing loop page 6 content paragraph 2"
                defaultMessage="You could discover something that could help explain the history of a health condition among your blood relatives."
              />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="HDR informing loop page 6 content paragraph 3"
                defaultMessage="Having this information could help you talk with your family. Everyone’s DNA is different, but sharing your DNA results with your blood relatives can help them think about their own health. They can consider if they want to get tested themselves. This could end up helping them stay healthy."
              />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="HDR informing loop page 6 content paragraph 4"
                defaultMessage="You will be able to talk to a licensed <i>All of Us</i> genetic counselor about your test results. Talking to an <i>All of Us</i> genetic counselor is free. {tooltip}"
                values={{
                  tooltip: (
                    <GpTooltip
                      aria-label={intl.formatMessage(messages.hdrIlTooltipLabelGeneticCounselor)}
                      content={
                        <Text>
                          <FormattedMessage
                            description="HDR informing loop page 6 paragraph 4 tooltip"
                            defaultMessage="A genetic counselor is a genetics expert trained to help you understand your DNA results. They can also help you talk with your family about your results if you want them to."
                          />
                        </Text>
                      }
                    />
                  ),
                }}
              />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="HDR informing loop page 6 content paragraph 5"
                defaultMessage="To learn more about this type of DNA result and others, visit the {learningCenterLink}. Learning more may help you decide if you would like to see this information."
                values={{
                  learningCenterLink: (
                    <ExternalLink to={config.LEARNING_CENTER_GENES_AND_HEALTH_URL[locale].href}>
                      <FormattedMessage
                        description="Learning center link text"
                        defaultMessage="Learning Center"
                      />
                    </ExternalLink>
                  ),
                }}
              />
            </Text>
          </>
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
