import { Spacer, Text, Title } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { AccordionIconType } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionIcon/types';
import { AccordionWithIcon } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionWithIcon';

export const AdditionalInformation: React.FC = () => (
  <AccordionWithIcon
    title={<FormattedMessage id="HDR_Positive__Additional_Information_Section_Title" />}
    icon={AccordionIconType.INFO}
  >
    <Title variant="headline3">
      <FormattedMessage id="HDR_Positive__Understanding_This_Report_Subsection_Title" />
    </Title>
    <Spacer variant="small" />
    <Text paragraph>
      <FormattedMessage id="HDR_Positive__Understanding_This_Report_Paragraph_1" />
    </Text>
    <Text paragraph>
      <FormattedMessage id="HDR_Positive__Understanding_This_Report_Paragraph_2" />
    </Text>
    <Spacer variant="large" />
    <Title variant="headline3">
      <FormattedMessage id="HDR_Positive__How_Look_At_DNA_Subsection_Title" />
    </Title>
    <Spacer variant="small" />
    <Text paragraph>
      <FormattedMessage id="HDR_Positive__How_Look_At_DNA_Paragraph_1" />
    </Text>
    <Text paragraph>
      <FormattedMessage id="HDR_Positive__How_Look_At_DNA_Paragraph_2" />
    </Text>
  </AccordionWithIcon>
);
