import { Location, LocationState } from 'history';

import { HDR_PRE_RESULTS_SCREEN_PATH } from 'components/App/AppRoutes/constants';
import { LocationDictionary } from 'components/common/InformingLoop/types';
import { useLocationsForIdp } from 'hooks/useLocationsForIdp';

export const useHdrPreResultsLocationsByModule: () => LocationDictionary = () => {
  return useLocationsForIdp({
    MODULE_1: {
      pathname: `${HDR_PRE_RESULTS_SCREEN_PATH}/module-1`,
    },
    MODULE_2: {
      pathname: `${HDR_PRE_RESULTS_SCREEN_PATH}/module-2`,
    },
    MODULE_3: {
      pathname: `${HDR_PRE_RESULTS_SCREEN_PATH}/module-3`,
    },
    MODULE_4: {
      pathname: `${HDR_PRE_RESULTS_SCREEN_PATH}/module-4`,
    },
  });
};

export const useHdrPreResultsLocations: () => Location<LocationState>[] = () =>
  Object.values(useHdrPreResultsLocationsByModule());
