import {
  AccordionDetails,
  AccordionSummary,
  MuiAccordion,
  Text,
  Title,
  getMuiClassSelector,
} from '@color/continuum';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactNode } from 'react';

export interface ExpansionPanelProps {
  title: ReactNode | string;
  text: ReactNode | string;
}

export const ExpansionPanel: React.FC<ExpansionPanelProps> = (props: ExpansionPanelProps) => {
  const { title, text } = props;
  return (
    <MuiAccordion
      sx={{
        [getMuiClassSelector('& > .MuiCollapse-root, & > .MuiCollapse-hidden')]: {
          '@media print': {
            height: 'auto',
            visibility: 'visible',
          },
        },
        backgroundColor: (theme) => theme.palette.grey[100],
      }}
    >
      <AccordionSummary
        sx={{
          [getMuiClassSelector('& > .MuiAccordionSummary-content, & > .Mui-expanded')]: {
            margin: 0,
          },
          paddingX: 3,
          paddingY: 5,
        }}
        expandIcon={<ExpandMoreIcon color="primary" />}
      >
        <Title variant="headline2">{title}</Title>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingTop: 0,
          paddingBottom: 5,
          paddingX: 3,
        }}
      >
        <Text>{text}</Text>
      </AccordionDetails>
    </MuiAccordion>
  );
};
