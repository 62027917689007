import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  getMuiClassSelector,
} from '@color/continuum';
import { find } from 'lodash';
import { useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { DecisionModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { useResultDecisionsQuery } from 'lib/analytics/result-decisions';
import { ResultDecisionValue } from 'lib/analytics/result-decisions/types';
import { ModuleType } from 'lib/analytics/types';

import { PGX_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  pgxILDecisionPageTitle: {
    description: 'PGx Informing Loop Decision Page Title',
    defaultMessage: 'Decision',
  },
});

/**
 * This style should probably be incorporated into the theme, or extracted as a shared constant
 */
const RADIO_LABEL_SX = {
  alignItems: 'flex-start',
  [getMuiClassSelector('& .MuiFormControlLabel-label')]: {
    paddingTop: 1,
  },
};

export const PgxInformingLoopDecisionModule: React.FC = () => {
  const [decision, setDecision] = useState<ResultDecisionValue>();
  const [loading, isLoading] = useState(true);
  const intl = useIntl();
  const moduleType = ModuleType.PGX;
  useResultDecisionsQuery(
    { moduleType },
    {
      onSuccess: (data) => {
        const resultsDecision = find(data?.resultDecisions || [], {
          moduleType,
        });
        if (resultsDecision) {
          setDecision(resultsDecision.decisionValue);
        }
        isLoading(false);
      },
      onError: () => {
        isLoading(false);
      },
    }
  );
  if (loading) {
    return null;
  }
  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.pgxILDecisionPageTitle)}
      module={{
        analyticsInformingLoopName: PGX_INFORMING_LOOP_NAME,
        analyticsPageName: '7. Decision',
        image: CircularImageType.CHECK_MARK_DOCUMENT_RED,
        title: <FormattedMessage id="PGX_IL_Decision__Title" />,
        content: (
          <>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="informing-loop-decision"
                // The DOM API converts the InformingLoopDecision enum value to a string here, so we need to convert back.
                onChange={(_event, value) => setDecision(value as ResultDecisionValue)}
                defaultValue={decision}
              >
                <FormControlLabel
                  sx={RADIO_LABEL_SX}
                  value={ResultDecisionValue.YES}
                  control={<Radio data-testid="pgx-decision-option-1" size="small" />}
                  label={
                    <>
                      <Text bold>
                        <FormattedMessage id="PGX_IL_Decision__Yes_Decision_Label" />
                      </Text>
                      <Spacer variant="xSmall" />
                      <Text>
                        <FormattedMessage
                          description="PGX informing loop decision page yes decision description"
                          defaultMessage="You will be able to see your results once they are ready. Results may take up to a few months to generate. <i>All of Us</i> will contact you when your results are ready."
                        />
                      </Text>
                    </>
                  }
                />
                <Spacer variant="medium" />
                <FormControlLabel
                  sx={RADIO_LABEL_SX}
                  value={ResultDecisionValue.NO}
                  control={<Radio size="small" />}
                  label={
                    <>
                      <Text bold>
                        <FormattedMessage id="PGX_IL_Decision__No_Option_Label" />
                      </Text>
                      <Spacer variant="xSmall" />
                      <Text>
                        <FormattedMessage
                          description="PGX informing loop decision page no decision description"
                          defaultMessage="You can always change your decision later."
                        />
                      </Text>
                    </>
                  }
                />
                <Spacer variant="medium" />
                <FormControlLabel
                  sx={RADIO_LABEL_SX}
                  value={ResultDecisionValue.MAYBE_LATER}
                  control={<Radio size="small" />}
                  label={
                    <>
                      <Text bold>
                        <FormattedMessage
                          description="PGX informing loop decision page not sure decision label"
                          defaultMessage="I’m not sure right now."
                        />
                      </Text>
                      <Spacer variant="xSmall" />
                      <Text>
                        <FormattedMessage
                          description="PGX informing loop decision page not sure decision description"
                          defaultMessage="If you are not ready to decide, you can choose to get these results later."
                        />
                      </Text>
                    </>
                  }
                />
              </RadioGroup>
            </FormControl>
            <Spacer variant="medium" />
            <Text>
              <FormattedMessage
                description="PGX informing loop decision page bottom content"
                defaultMessage="You can always change this decision if you want to. This decision does not affect your ability to see other types of DNA results, like your risk for certain health conditions. <i>All of Us</i> will contact you when other results are available."
              />
            </Text>
          </>
        ),
      }}
      footer={<DecisionModuleInformingLoopFooter decision={decision} moduleType={moduleType} />}
    />
  );
};
