import { InternalLink, Spacer, Title } from '@color/continuum';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { DOWNLOAD_PDF_PAGE_PATH } from 'components/App/AppRoutes/constants';
import { ModuleType } from 'lib/analytics/types';

interface IMoreInformationSectionProps {
  moreInformationCards: { [tabKey: string]: ReactNode };
  infoSheetUrl: string;
  // Optional because this URL comes from a backend call which can fail.
  // In the failure case, we just don't display this link.
  reportPdfUrl?: string;
  moduleType: ModuleType;
  tabId: string;
}

export const MoreInformationSection: React.FC<IMoreInformationSectionProps> = (props) => {
  const { moreInformationCards, infoSheetUrl, moduleType, reportPdfUrl, tabId } = props;
  return (
    <>
      <Title variant="headline2">
        <FormattedMessage id="HDR_Uninformative_Header_QuickLinks" />
      </Title>
      <Spacer variant="medium" />
      {Object.entries(moreInformationCards).map(([otherTabId, moreInformationCard]) => {
        return (
          otherTabId !== tabId && (
            <>
              {moreInformationCard}
              <Spacer variant="medium" />
            </>
          )
        );
      })}
      <Spacer variant="medium" />
      <Title variant="headline4">
        <FormattedMessage id="HDR_Uninformative_Download_Header" />
      </Title>
      <Spacer variant="medium" />
      {reportPdfUrl && (
        <InternalLink
          to={`${DOWNLOAD_PDF_PAGE_PATH}?${new URLSearchParams({
            pdfUrl: reportPdfUrl,
            moduleType: moduleType.toString(),
          }).toString()}`}
        >
          <FormattedMessage id="HDR_Uninformative_Download_PDF" />
        </InternalLink>
      )}
      <Spacer variant="medium" />
      <InternalLink
        to={`${DOWNLOAD_PDF_PAGE_PATH}?${new URLSearchParams({
          pdfUrl: infoSheetUrl,
          moduleType: moduleType.toString(),
        }).toString()}`}
      >
        <FormattedMessage id="HDR_Uninformative_Info_Sheet" />
      </InternalLink>
    </>
  );
};
