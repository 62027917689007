import { MenuItem } from '@color/continuum';
import { FC } from 'react';

import { US_STATES } from '../constants';
import { FormikSelectField } from '../FormikSelectField/FormikSelectField';

const allLocationOptions = () => {
  return Object.keys(US_STATES).map((state) => (
    <MenuItem key={state} value={state}>
      {US_STATES[state]}
    </MenuItem>
  ));
};
export interface IFormikStateField {
  name: string;
  label: string;
  required?: boolean;
}

export const FormikStateField: FC<IFormikStateField> = (props) => {
  const { name, label, required = false } = props;
  return (
    <FormikSelectField name={name} label={label} required={required}>
      {allLocationOptions()}
    </FormikSelectField>
  );
};
