import { Container, Grid, Image, Spacer, Title, useMediaQuery, useTheme } from '@color/continuum';
import { useEffect } from 'react';

import { CircularImageType } from 'components/common/CircularImage/types';
import { getCircularImageSrc } from 'components/common/CircularImage/utils';
import { logAnalyticsPageView } from 'lib/analytics';

export interface InformingLoopLayoutProps {
  analyticsInformingLoopName?: string;
  analyticsPageName?: string;
  title: JSX.Element | string;
  content: JSX.Element;
  image: CircularImageType;
}

// TODO @rymarren: Reconsider the name of this component. 'InformingLoopLayout' sounds
// like a carousel based on the name.
export const InformingLoopLayout: React.FC<InformingLoopLayoutProps> = (
  props: InformingLoopLayoutProps
) => {
  const { analyticsInformingLoopName, analyticsPageName, title, content, image } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  /**
   * `useEffect` is often dangerous and unnecessary. This usage needs review by #ux-programs team.
   * See https://getcolor.atlassian.net/browse/UXTASK-1406
   */
  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    if (analyticsInformingLoopName && analyticsPageName) {
      logAnalyticsPageView(`${analyticsInformingLoopName} - ${analyticsPageName}`);
    }
  }, [analyticsInformingLoopName, analyticsPageName]);

  return (
    <Container>
      {!isMobile && (
        <>
          <Spacer variant="xxxLarge" />
          <Spacer variant="xxxLarge" />
        </>
      )}
      <Grid container spacing={7} justifyContent="center">
        <Grid container item xs={12} md={5} justifyContent="center">
          <Image
            src={getCircularImageSrc(image)}
            alt=""
            sx={{
              height: {
                xs: theme.spacing(20),
                md: theme.spacing(40),
              },
              width: {
                xs: theme.spacing(20),
                md: theme.spacing(40),
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Title variant="headline1" id="dialogTitle">
            {title}
          </Title>
          <Spacer variant="medium" />
          {content}
        </Grid>
      </Grid>
    </Container>
  );
};
