import { useToast } from '@color/continuum';

/**
 * This is a light wrapper around the @color/continuum `useToast` hook
 * which exposes the deprecated toasts as non deprecated. In GP
 * we use these deprecated toasts, and need NIH approval to change off of them.
 * This wrapper is to avoid confusion by allowing GP devs to add new toasts
 * without creating a new usage of deprecated code.
 * TODO(ALLOFUS-2388): Remove this when we move off deprecated toasts.
 */
export const useGpToast = () => {
  const { showDeprecatedErrorToast, showDeprecatedSuccessToast, showDeprecatedToast } = useToast();
  return {
    showErrorToast: showDeprecatedErrorToast,
    showSuccessToast: showDeprecatedSuccessToast,
    showToast: showDeprecatedToast,
  };
};
