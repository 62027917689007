import { Revision, RevisionReason } from '@color/aou-reports';
import { Accordion, Text } from '@color/continuum';
import { formatLongIsoDateString, usePreferredLocale } from '@color/lib';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { RevisionDescription } from '../RevisionHistoryModal/RevisionDescription';
import { RevisionHistoryModal } from '../RevisionHistoryModal/RevisionHistoryModal';

export interface IReReleaseBanner {
  revisions: Revision[];
  revision: number;
}
export const ReReleaseBanner: FC<IReReleaseBanner> = (props) => {
  const { revisions, revision } = props;
  const { locale } = usePreferredLocale();
  const multipeDowngradesAndUpgrades = revisions.filter(
    (revisionObj) =>
      revisionObj.revisionReason &&
      [RevisionReason.DOWNGRADE, RevisionReason.UPGRADE].includes(revisionObj.revisionReason)
  );
  return (
    <Accordion
      defaultExpanded
      title={
        <FormattedMessage
          id="Hdr_Report_Revision_Date"
          values={{
            date: formatLongIsoDateString(
              revisions.filter((revisionObj) => revisionObj.revision === revision)[0].reportDate,
              locale
            ),
          }}
        />
      }
      expandIcon={<ExpandMoreIcon />}
      sx={{
        backgroundColor: (theme) => theme.palette.grey[100],
      }}
    >
      <Text>
        <RevisionDescription
          revisionReason={revisions.filter((rev) => rev.revision === revision)[0].revisionReason!}
        />
      </Text>
      {multipeDowngradesAndUpgrades.length > 1 && (
        <RevisionHistoryModal revisions={multipeDowngradesAndUpgrades} />
      )}
    </Accordion>
  );
};
