import { Box, ExternalLink, Image, InlineSpacer, Text } from '@color/continuum';

export interface InformingLoopContactProps {
  icon: string;
  iconAlt: string;
  linkTo: string;
  linkText: string;
}

export const InformingLoopContact: React.FC<InformingLoopContactProps> = (
  props: InformingLoopContactProps
) => {
  const { icon, iconAlt, linkTo, linkText } = props;
  return (
    <Box display="flex" alignItems="center" py={1}>
      <Image src={icon} alt={iconAlt} sx={{ objectFit: 'none' }} />
      <InlineSpacer variant="xSmall" />
      <Text
        sx={(theme) => ({
          color: theme.palette.secondary.main,
          whiteSpace: 'nowrap',
        })}
      >
        <ExternalLink to={linkTo}>{linkText}</ExternalLink>
      </Text>
    </Box>
  );
};
