import { Spacer, Text } from '@color/continuum';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

import { PGX_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  pgxILModule3PageTitle: {
    description: 'PGx Informing Loop Page Title - Module 3',
    defaultMessage: 'Risks',
  },
});

export const PgxInformingLoopModule3: React.FC = () => {
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.pgxILModule3PageTitle)}
      module={{
        analyticsInformingLoopName: PGX_INFORMING_LOOP_NAME,
        analyticsPageName: '3. Risks',
        image: CircularImageType.EXCLAIMATION_POINT_DOCUMENT_RED,
        title: <FormattedMessage id="PGX_IL_3__Title" />,
        content: (
          <>
            <Text>
              <FormattedMessage
                description="PGX informing loop page 3 paragraph 1"
                defaultMessage="When you signed the Consent to Get DNA Results, you learned that there are different kinds of risks to learning about your DNA. Here are some that are important to remember for these results."
              />
            </Text>
            <Spacer variant="xSmall" />
            <ul>
              <li>
                <Text>
                  <FormattedMessage
                    description="PGX informing loop page 3 bullet 1"
                    defaultMessage="<b>If your doctor or health care provider has prescribed medicine for you, keep taking it as prescribed.</b> It can be dangerous to stop taking a medicine, or to change the dose or timing of it, without first talking to your doctor or health care provider."
                  />
                </Text>
              </li>
              <Spacer variant="medium" />
              <li>
                <Text>
                  <FormattedMessage
                    description="PGX informing loop page 3 bullet 2"
                    defaultMessage="Your results could show that a medicine you are taking may be affected by your DNA. Your doctor or health care provider may decide to confirm the result with a clinical test for you. This is because these are research results. Only clinical results can be used directly in medical care."
                  />
                </Text>
              </li>
              <Spacer variant="small" />
              <ul>
                <li>
                  <Text>
                    <FormattedMessage
                      description="PGX informing loop page 3 bullet 2.1"
                      defaultMessage="If your doctor or health care provider orders a clinical test, you or your insurance may be billed for it."
                    />
                  </Text>
                </li>
                <Spacer variant="xSmall" />
                <li>
                  <Text>
                    <FormattedMessage
                      description="PGX informing loop page 3 bullet 2.2"
                      defaultMessage="You can decide what care is right for you. Changing your care may cost more."
                    />
                  </Text>
                </li>
              </ul>
              <Spacer variant="medium" />
              <li>
                <Text>
                  <FormattedMessage
                    description="PGX informing loop page 3 bullet 3"
                    defaultMessage="You could get information you weren’t expecting in your results."
                  />
                </Text>
              </li>
              <Spacer variant="medium" />
              <li>
                <Text>
                  <FormattedMessage
                    description="PGX informing loop page 3 bullet 4"
                    defaultMessage="Your results may make you worried or confused."
                  />
                </Text>
              </li>
            </ul>
            <Spacer variant="xSmall" />
            <Text>
              <FormattedMessage
                description="PGX informing loop page 3 paragraph 2"
                defaultMessage="You can contact the <i>All of Us</i> Support Center at any time. We can help answer your questions. We can also help you find more resources."
              />
            </Text>
          </>
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
