import { HdrV1Gene } from '@color/aou-reports';
import { ReactNode } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { SUPPORTED_HTML_TAGS } from 'components/App/Providers/AppIntlProvider';

interface IOpenEmailWithFamilyLetterUrlProps {
  gene: HdrV1Gene;
}

/**
 * Overrides for the defaultRichTextElements defined in the IntlProvider which
 * must be specified for messages in the email body. These overrides simply remove the
 * HTML formatting so that we do not attempt to turn a react element to a string.
 */
const RICH_TEXT_ELEMENTS = Object.fromEntries(
  SUPPORTED_HTML_TAGS.map((tag: string) => [tag, (chunks: ReactNode[]) => chunks])
);

export const useFamilyLetterMailtoUrl = (props: IOpenEmailWithFamilyLetterUrlProps): URL => {
  const { gene } = props;
  const { formatMessage } = useIntl();
  const formatMessageWithStringRichTextElements = (descriptor: MessageDescriptor) =>
    formatMessage(descriptor, RICH_TEXT_ELEMENTS);
  const emailSubject = formatMessage({ id: 'Family_Letter__Email_Subject' });
  const emailBody = [
    formatMessage({ id: 'HDR_Family_Letter_Salutation' }),
    formatMessageWithStringRichTextElements({ id: 'HDR_Family_Letter_P1' }),
    formatMessage({ id: 'HDR_Family_Letter_P2' }, { gene: gene.toString(), ...RICH_TEXT_ELEMENTS }),
    formatMessageWithStringRichTextElements({ id: 'HDR_Family_Letter_Header2' }),
    formatMessageWithStringRichTextElements({ id: 'HDR_Family_Letter_P3' }),
  ].join('\n\n');

  return new URL(
    `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`
  );
};
