import { find } from 'lodash';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';

import { useParticipantId } from 'components/Appointments/utilities';
import { api } from 'lib/api';
import { ApiError } from 'lib/api/types';

import { ModuleType } from '../types';
import { ResultDecisionData, ResultDecisionPayload } from './types';

interface ResultDecisionQueryParams {
  moduleType?: ModuleType;
  pid?: string | null;
}

export function useResultDecisionsQuery(
  params?: ResultDecisionQueryParams,
  options?: UseQueryOptions<ResultDecisionData, ApiError>
) {
  return useQuery<ResultDecisionData, ApiError>(
    ['result_decisions', { searchParams: params }],
    options
  );
}

export function useCreateResultDecisionMutation() {
  const queryClient = useQueryClient();
  return useMutation<Response, ApiError, ResultDecisionPayload>(
    (json: ResultDecisionPayload) => api('result_decisions', { method: 'POST', json }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('result_decisions');
      },
    }
  );
}

const getResultsVisibleForModule = (
  resultDecisionsData: ResultDecisionData | undefined,
  moduleType: ModuleType
) => {
  if (resultDecisionsData === undefined) {
    return false;
  }
  const resultDecision = find(resultDecisionsData?.resultDecisions || [], {
    moduleType,
  });

  return resultDecision !== undefined ? resultDecision.resultVisible : false;
};

export const useResultsVisible = () => {
  const {
    data: resultDecisionsData,
    isLoading: resultDecisionsAreLoading,
  } = useResultDecisionsQuery({ pid: useParticipantId() });

  return {
    resultDecisionsAreLoading,
    pgxIsVisible: getResultsVisibleForModule(resultDecisionsData, ModuleType.PGX),
    hdrIsVisible: getResultsVisibleForModule(resultDecisionsData, ModuleType.HDR),
  };
};
