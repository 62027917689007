import { QueryClient } from 'react-query';

import { api } from './api';
import { ApiError, ApiRequestOptions, ApiResponse } from './types';

export type ApiQueryKey = [endpoint: string, options?: ApiRequestOptions];

interface IQueryFnParams {
  queryKey: readonly unknown[];
}

async function defaultQueryFn(params: IQueryFnParams) {
  const { queryKey } = params;
  const [endpoint, options] = queryKey as ApiQueryKey;
  const response: ApiResponse = await api(endpoint, options);
  return response.payload;
}

export const apiQueryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      refetchOnWindowFocus: false,
      retry: (failureCount: number, error) => {
        return (error as ApiError).status === 500 && failureCount < 3;
      },
      // For tests, set cacheTime to 0 so that we don't use a cached response from the previous test case
      cacheTime: process.env.NODE_ENV === 'test' ? 0 : undefined,
    },
  },
});
