export enum GpWaffleSwitch {
  HGM_REPORTS_DISABLED = 'gp-disable-hgm-reports',
  INCIDENT_BANNER = 'gp-enable-incident-banner',
  GC_APPOINTMENTS_DISABLED_FOR_NEGATIVE_RESULTS = 'gp-disable-gc-appointments-for-negative-results-2024-08',
  DISABLE_IL_LOOPS = 'gp-disable-il-loops-2024-08',
}

export interface Waffle {
  switchIsActive: (name: GpWaffleSwitch) => boolean;
}
