import { Question, SaveAnswerFunction } from './Question/Question';
import { IQuizItem, TQuizAnswer } from './types';

interface Props extends IQuizItem {
  onPrevious?: () => void;
  onNext: (answer?: TQuizAnswer) => void;
  answer: TQuizAnswer;
  saveQuizAnswer: SaveAnswerFunction;
  quizAnswerIsSaving: boolean;
  isLastQuizItem: boolean;
}

export const QuizItem: React.FC<Props> = (props) => {
  const { question, ...quizProps } = props;

  if (question) {
    return <Question question={question} {...quizProps} />;
  }
  return null;
};
