import { MessageDescriptor, defineMessages } from 'react-intl';

import { Trait } from '../../types';

const messages = defineMessages({
  bitternessYes: {
    description: 'bitter trait results banner text for yes outcome',
    defaultMessage: "you're likely to taste certain bitter compounds.",
  },
  bitternessNo: {
    description: 'bitter trait results banner text for no outcome',
    defaultMessage: "you're unlikely to taste certain bitter compounds.",
  },
  cilantroYes: {
    description: 'cilantro trait results banner text for yes outcome',
    defaultMessage: 'you have a slightly higher chance of liking cilantro.',
  },
  cilantroNo: {
    description: 'cilantro trait results banner text for no outcome',
    defaultMessage: 'you have a slightly higher chance of disliking cilantro.',
  },
  earwaxDry: {
    description: 'earwax trait results banner text for dry outcome',
    defaultMessage: "you're likely to have dry earwax.",
  },
  earwaxWet: {
    description: 'earwax trait results banner text for wet outcome',
    defaultMessage: "you're likely to have wet earwax.",
  },
  lactoseLikely: {
    description: 'lactose trait results banner text for likely outcome',
    defaultMessage: "you're likely to be lactose intolerant.",
  },
  lactoseUnlikely: {
    description: 'lactose trait results banner text for unlikely outcome',
    defaultMessage: "you're unlikely to be lactose intolerant.",
  },
});

type TraitResultOutcomeMessages = { [outcome: string]: MessageDescriptor };

export const TRAIT_RESULTS_OUTCOME_BANNER_CONTENT: Record<Trait, TraitResultOutcomeMessages> = {
  [Trait.BITTERNESS]: {
    yes: messages.bitternessYes,
    no: messages.bitternessNo,
  },
  [Trait.CILANTRO]: {
    yes: messages.cilantroYes,
    no: messages.cilantroNo,
  },
  [Trait.EARWAX]: {
    dry: messages.earwaxDry,
    wet: messages.earwaxWet,
  },
  [Trait.LACTOSE]: {
    likely: messages.lactoseLikely,
    unlikely: messages.lactoseUnlikely,
  },
};
