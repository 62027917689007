import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface IUseLocationControlledTabs {
  basePath: string;
  tabIds: string[];
  defaultTabId: string;
}
export const useLocationControlledTabs = (props: IUseLocationControlledTabs) => {
  const { basePath, tabIds, defaultTabId } = props;
  const { pathname, search } = useLocation();
  const { push } = useHistory();
  const tabId = pathname === basePath ? defaultTabId : pathname.split('/').pop();
  const setTab = useCallback(
    (nextTabId: string) => {
      if (tabIds.includes(nextTabId)) {
        push(`${basePath}/${nextTabId}${search}`);
      }
    },
    [basePath, tabIds, push, search]
  );
  return {
    tabId,
    setTab,
  };
};
