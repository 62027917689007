import { Spacer, Text, Title } from '@color/continuum';

import { IQuizQuestion, QuestionFormat, TQuizAnswer } from '../types';
import { RadioItemsForm } from './RadioItemsForm';
import { TextForm } from './TextForm';

export type SaveAnswerFunction = (
  questionId: string,
  answer: TQuizAnswer,
  answerChoiceIds?: number[],
  sessionId?: string
) => Promise<void>;

interface QuestionProps {
  question: IQuizQuestion;
  onPrevious?: () => void;
  onNext: (answer?: TQuizAnswer) => void;
  answer: TQuizAnswer;
  isLastQuizItem: boolean;
  saveQuizAnswer: SaveAnswerFunction;
  quizAnswerIsSaving: boolean;
}

export const Question: React.FC<QuestionProps> = (props) => {
  const {
    question: { id, title, description, format, isOptional },
    question,
    answer,
    onNext,
    onPrevious,
    isLastQuizItem,
    saveQuizAnswer,
    quizAnswerIsSaving,
  } = props;

  const saveAndProceed = async (
    newAnswer: TQuizAnswer,
    answerChoiceIds?: number[],
    sessionId?: string
  ) => {
    // When user skips question, serializer expects undefined rather than empty string
    await saveQuizAnswer(
      id,
      newAnswer === '' && isOptional ? undefined : newAnswer,
      answerChoiceIds,
      sessionId
    );
    onNext(newAnswer);
  };

  let FormComponent;
  switch (format) {
    case QuestionFormat.TEXT:
      FormComponent = TextForm;
      break;
    case QuestionFormat.SELECT:
      FormComponent = RadioItemsForm;
      break;
    default:
      throw new Error(`Unexpected question format type ${format}`);
  }

  return (
    <>
      <Title variant="headline2" component="h3">
        {title}
      </Title>
      <Spacer variant="small" />
      {description && <Text>{description}</Text>}
      <FormComponent
        key={id} // Force the component to re-mount on different questions. This fixes bugs where state is persisted accross questions.
        question={question}
        originalAnswer={answer}
        onSubmit={saveAndProceed}
        onPrevious={onPrevious}
        isLoading={quizAnswerIsSaving}
        isLastQuizItem={isLastQuizItem}
      />
    </>
  );
};
