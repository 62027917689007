import { Text } from '@color/continuum';
import { useEffect } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { FirstModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/FirstModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { useCreateInformingLoopStartedEventMutation } from 'lib/analytics/informing-loop-started/api';
import { ModuleType } from 'lib/analytics/types';

import { HDR_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  hdrILModule1PageTitle: {
    description: 'HDR Informing Loop Page Title - Module 1',
    defaultMessage: 'Getting Results',
  },
});

export const HdrInformingLoopModule1: React.FC = () => {
  const { mutateAsync: saveInformingLoopStarted } = useCreateInformingLoopStartedEventMutation();
  const intl = useIntl();

  /**
   * `useEffect` is often dangerous and unnecessary. This usage needs review by #ux-programs team.
   * See https://getcolor.atlassian.net/browse/UXTASK-1406
   */
  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    saveInformingLoopStarted({ moduleType: ModuleType.HDR });
  }, [saveInformingLoopStarted]);

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.hdrILModule1PageTitle)}
      module={{
        analyticsInformingLoopName: HDR_INFORMING_LOOP_NAME,
        analyticsPageName: '1. Start',
        image: CircularImageType.DNA_CLIPBOARD_RED,
        title: <FormattedMessage id="HDR_IL_1__Title" />,
        content: (
          <>
            <Text paragraph>
              <FormattedMessage id="HDR_IL_1__Paragraph_1" />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="HDR informing loop page 1 content paragraph 2"
                defaultMessage="At the end, you will be asked to decide if you want these results. You can decide “Yes,” “No,” or “I’m not sure right now.” If you decide “Yes,” results may take up to a few months to generate."
              />
            </Text>
          </>
        ),
      }}
      footer={<FirstModuleInformingLoopFooter />}
    />
  );
};
