import { Box, InlineSpacer, Text, useMediaQuery, useTheme } from '@color/continuum';
import { FormattedMessage, useIntl } from 'react-intl';

import emailIcon from 'assets/images/icons/email-icon-dark.svg';
import phoneIcon from 'assets/images/icons/phone-icon-dark.svg';
import ttyIcon from 'assets/images/icons/tty-icon-dark.svg';
import { FOOTER_EMAIL_ADDRESS, FOOTER_PHONE_NUMBER, FOOTER_TTY_NUMBER } from 'lib/constants';

import { InformingLoopContact } from './InformingLoopContact';

export const InformingLoopQuestionsSection: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const intl = useIntl();

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      flexWrap="wrap"
      justifyContent="center"
      px={isMobile ? 2 : 5}
      sx={{ marginBottom: theme.spacing(8) }}
    >
      <Box display="flex" alignItems="center" py={1}>
        <Text variant="subtitle1">
          <FormattedMessage
            defaultMessage="Questions?"
            description="Informing loop footer contacts text"
          />
        </Text>
      </Box>
      <InlineSpacer variant="large" />
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'flex-start' : 'center'}
        justifyContent="center"
      >
        <InformingLoopContact
          icon={emailIcon}
          iconAlt=""
          linkTo={`mailto:${FOOTER_EMAIL_ADDRESS}`}
          linkText={FOOTER_EMAIL_ADDRESS}
        />
        <InlineSpacer variant="large" />
        <InformingLoopContact
          icon={phoneIcon}
          iconAlt=""
          linkTo={`tel:${FOOTER_PHONE_NUMBER}`}
          linkText={FOOTER_PHONE_NUMBER}
        />
        <InlineSpacer variant="large" />
        <InformingLoopContact
          icon={ttyIcon}
          iconAlt=""
          linkTo={`tel:${FOOTER_TTY_NUMBER}`}
          linkText={intl.formatMessage({
            defaultMessage: 'TTY dial 711',
            description: 'Text of TTY link in informing loop footer',
          })}
        />
      </Box>
    </Box>
  );
};
