import { GpDialog, LocationFlowProvider } from '@color/continuum';

import { HdrInformingLoopRoutes } from './HdrInformingLoopRoutes';
import { useHdrInformingLoopLocations } from './locations';

export const HdrInformingLoop: React.FC = () => {
  return (
    <GpDialog open scroll="body" fullScreen aria-labelledby="dialogTitle">
      <LocationFlowProvider locations={useHdrInformingLoopLocations()}>
        <HdrInformingLoopRoutes />
      </LocationFlowProvider>
    </GpDialog>
  );
};
