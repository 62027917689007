import { Box, ScrollRestorationPoint } from '@color/continuum';
import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from './AppRoutes';
import { Footer } from './Footer';
import { Nav } from './Nav';
import { AppProviders } from './Providers/AppProviders';

// Color currently uses iframes to show reports to GCs in an iframe on Clinical. Auto-scrolling hides the header with
// patient/participant PHI, which is undesired and needs to be prevented.
const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AppProviders>
        {!isInIframe() && <ScrollRestorationPoint />}
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Nav />
          <Box
            flexGrow={1}
            sx={{
              // We use Material UI's Grid `spacing` prop in some components in GP.
              // Unfortunately, this can cause negative margin to extend beyond the <body>,
              // creating extra horizontal scroll in smaller screen sizes.
              // To fix this, we can add `overflow-x: hidden;` to the parent container.
              // https://v4.mui.com/components/grid/#negative-margin
              overflowX: 'hidden',
            }}
          >
            <main>
              <AppRoutes />
            </main>
          </Box>
          <Footer />
        </Box>
      </AppProviders>
    </BrowserRouter>
  );
};
