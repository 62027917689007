import {
  Button,
  Container,
  InlineSpacer,
  InternalButtonLink,
  Spacer,
  Text,
  Title,
} from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { add } from 'date-fns';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';

import { CancelConfirmationDialog } from 'components/Appointments/CancelConfirmationDialog';
import { UpdateAppointmentDialog } from 'components/Appointments/UpdateAppointmentDialog';
import { useParticipantId } from 'components/Appointments/utilities';
import { US_STATES, languagesList } from 'components/common/Forms/constants';
import { SimplePage } from 'components/common/SimplePage/SimplePage';
import { logSchedulingPageView } from 'lib/analytics/analytics';
import { ModuleType, SegmentPageType } from 'lib/analytics/types';
import { api } from 'lib/api';
import { GP_APPOINTMENT_DURATION } from 'lib/constants';

import { AddToCalendar } from './AddToCalendar';
import { AppointmentsBreadcrumbsBar } from './AppointmentsBreadcrumbsBar';
import { IAppointment, Timezones } from './types';

const formatPhoneNumber = (appointment: IAppointment) => {
  const pn = appointment.phoneNumber;
  return `(${pn.slice(0, 3)}) ${pn.slice(3, 6)}-${pn.slice(6, 10)}`;
};
interface Props {
  appointment: IAppointment;
  handleEditAppointment: () => void;
  setShowCancelConfirmation: (flag: boolean) => void;
}
export const Confirmation: React.FC<Props> = (props) => {
  const { appointment, handleEditAppointment, setShowCancelConfirmation } = props;

  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    logSchedulingPageView(appointment.type, SegmentPageType.GC_SCHEDULING_CONFIRMATION);
  }, [appointment]);

  const intl = useIntl();
  const messages = {
    title: intl.formatMessage({ id: 'Scheduling_Confirmation__Title' }),
    text: intl.formatMessage({ id: 'Scheduling_Confirmation__Text' }),
    languageTitle: intl.formatMessage({ id: 'Scheduling_Confirmation__Language__Title' }),
    phoneTitle: intl.formatMessage({ id: 'Scheduling_Confirmation__Phone__Title' }),
    phoneText: intl.formatMessage({ id: 'Scheduling_Confirmation__Phone__Text' }),
    locationTitle: intl.formatMessage({ id: 'Scheduling_Confirmation__Location__Title' }),
    pageTitleHdr: intl.formatMessage({ id: 'HDR_Confirmation_Helmet_Title' }),
    pageTitlePgx: intl.formatMessage({ id: 'PGX_Confirmation_Helmet_Title' }),
    breadcrumbText: intl.formatMessage({ id: 'Scheduling_Confirmation__Breadcrumb_Text' }),
    change: intl.formatMessage({ id: 'Scheduling_Confirmation__Button__Change' }),
    exit: intl.formatMessage({ id: 'Scheduling_Confirmation__Button__Exit' }),
    hdrDescription: intl.formatMessage({
      id: 'Scheduling_Confirmation__Appointment_Hdr_Description',
    }),
    pgxDescription: intl.formatMessage({
      id: 'Scheduling_Confirmation__Appointment_Pgx_Description',
    }),
  };

  const [showUpdateDialog, setShowUpdateDialog] = useState<boolean>(false);
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);

  const { locale } = usePreferredLocale();

  const getLanguageLongName = (language: string) => {
    return Object.keys(languagesList[locale]).find(
      (key) => languagesList[locale][key] === language
    );
  };

  const formatLocation = (location: string) => {
    if (location in US_STATES) {
      return US_STATES[location as keyof typeof US_STATES];
    }
    return location;
  };

  const formatDate = (date?: Date): string => {
    const day = new Intl.DateTimeFormat(locale, {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
      timeZone: appointment.timezone,
    }).format(date);
    const time = new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: appointment.timezone,
    })
      .formatToParts(date)
      .map(({ type, value }) => {
        switch (type) {
          case 'dayPeriod':
            return `${value.replace('AM', 'a.m.').replace('PM', 'p.m.')}`.toLowerCase();
          default:
            return value;
        }
      })
      .join('');
    return intl.formatMessage(
      { id: 'Scheduling_Confirmation__Appointment_Time' },
      { day, time, timezone: Timezones[appointment.timezone as keyof typeof Timezones] }
    );
  };

  const pid = useParticipantId();
  const cancelAppointment = useMutation(
    () => {
      // If scheduling on behalf of a participant, pass the PID. Falsy will auto-use the logged in user's PID
      const params = pid ? `?${new URLSearchParams({ pid })}` : '';
      const uri = `aou_cancel_existing_appointment${params.toString()}`;
      return api(uri, { method: 'DELETE', json: appointment });
    },
    {
      onSuccess: () => {
        setShowCancelDialog(false);
        setShowCancelConfirmation(true);
      },
      onError: () => {
        // TODO ALLOFUS-2057: display error to the user noting no appointments canceled
      },
    }
  );

  const handleUpdateDialogContinue = (value: string | null) => {
    if (value === 'edit') {
      handleEditAppointment();
    }
    if (value === 'cancel') {
      setShowCancelDialog(true);
    }
    setShowUpdateDialog(false);
  };

  const handleCancelDialogConfirm = () => {
    cancelAppointment.mutate();
  };

  const startDate = appointment.timestamp;
  const endDate = add(startDate!, GP_APPOINTMENT_DURATION);
  return (
    <SimplePage
      helmetTitle={
        appointment.type === ModuleType.PGX ? messages.pageTitlePgx : messages.pageTitleHdr
      }
    >
      <AppointmentsBreadcrumbsBar />
      <Spacer variant="small" />
      <Container>
        <Title>{messages.title}</Title>
        <Spacer variant="small" />
        <Text>
          {appointment.type === 'hdr' ? (
            <AddToCalendar
              appointmentName={intl.formatMessage({
                id: 'Scheduling_Confirmation__Appointment_Hdr_Name',
              })}
              description={`${intl.formatMessage({
                id: 'Scheduling_Confirmation__Appointment_Hdr_Description',
              })}\n\n${intl.formatMessage({ id: 'Scheduling_Confirmation__Phone__Text' })} ${
                appointment.phoneNumber
              }`}
              location={appointment.location}
              timezone={appointment.timezone}
              endDate={endDate}
              startDate={startDate!}
            />
          ) : (
            <AddToCalendar
              appointmentName={intl.formatMessage({
                id: 'Scheduling_Confirmation__Appointment_Pgx_Name',
              })}
              description={`${intl.formatMessage({
                id: 'Scheduling_Confirmation__Appointment_Pgx_Description',
              })}\n\n${intl.formatMessage({ id: 'Scheduling_Confirmation__Phone__Text' })} ${
                appointment.phoneNumber
              }`}
              location={appointment.location}
              timezone={appointment.timezone}
              endDate={endDate}
              startDate={startDate!}
            />
          )}
        </Text>
        <Spacer variant="xLarge" />
        <Title variant="headline3">{formatDate(appointment.timestamp)}</Title>
        <Text>
          {appointment.type === ModuleType.PGX ? messages.pgxDescription : messages.hdrDescription}
        </Text>
        <Spacer variant="large" />
        <Title variant="headline3">{messages.languageTitle}</Title>
        <Text>{getLanguageLongName(appointment.language)}</Text>
        <Spacer variant="large" />
        <Title variant="headline3">{messages.phoneTitle}</Title>
        <Text>{`${messages.phoneText} ${formatPhoneNumber(appointment)}`}</Text>
        <Spacer variant="large" />
        <Title variant="headline3">{messages.locationTitle}</Title>
        <Text>{formatLocation(appointment.location)}</Text>
        <Spacer variant="xxLarge" />
        <Button variant="color-secondary" onClick={() => setShowUpdateDialog(true)}>
          {messages.change}
        </Button>
        <InlineSpacer variant="medium" />
        <InternalButtonLink to="/">{messages.exit}</InternalButtonLink>
        {showUpdateDialog && (
          <UpdateAppointmentDialog
            handleUpdateDialogContinue={handleUpdateDialogContinue}
            setShowUpdateDialog={setShowUpdateDialog}
          />
        )}
        {showCancelDialog && (
          <CancelConfirmationDialog
            appointmentType={appointment.type}
            handleCancelDialogConfirm={handleCancelDialogConfirm}
            setShowCancelDialog={setShowCancelDialog}
          />
        )}
        <Spacer variant="xxLarge" />
      </Container>
    </SimplePage>
  );
};
