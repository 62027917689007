import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  TwoButtonDialog,
  getMuiClassSelector,
} from '@color/continuum';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { useHealthResultsQuery } from 'components/HealthRelatedResults/api';
import { ModuleType } from 'lib/analytics/types';

import { useModuleTypeBasedOnCurrentPath } from './utilities';

interface Props {
  handleUpdateDialogContinue: (value: string | null) => void;
  setShowUpdateDialog: (flag: boolean) => void;
}

const RADIO_LABEL_SX = {
  alignItems: 'flex-start',
  [getMuiClassSelector('& .MuiFormControlLabel-label')]: {
    paddingTop: 1,
  },
};
export const UpdateAppointmentDialog: React.FC<Props> = (props) => {
  const { handleUpdateDialogContinue, setShowUpdateDialog } = props;
  const [updateDialogValue, setUpdateDialogValue] = useState<string | null>(null);
  const intl = useIntl();
  const messages = {
    title: intl.formatMessage({ id: 'Scheduling_Update__Title' }),
    optionEdit: intl.formatMessage({ id: 'Scheduling_Update__Option__Edit' }),
    optionEditDescription: intl.formatMessage({
      id: 'Scheduling_Update__Option__Edit_Description',
    }),
    optionCancel: intl.formatMessage({ id: 'Scheduling_Update__Option__Cancel' }),
    optionCancelDescription: intl.formatMessage({
      id: 'Scheduling_Update__Option__Cancel_Description',
    }),
    optionCancelDescriptionHdrPositive: intl.formatMessage({
      id: 'Scheduling_Update__Option__Cancel_Description_Hdr_Positive',
    }),
    continue: intl.formatMessage({ id: 'Scheduling_Update__Button__Continue' }),
    exit: intl.formatMessage({ id: 'Scheduling_Update__Button__Exit' }),
  };
  const moduleType = useModuleTypeBasedOnCurrentPath();
  const { data: report, isLoading } = useHealthResultsQuery({
    moduleType: ModuleType.HDR,
  });
  if (isLoading) {
    return null;
  }
  const isHdrPositive =
    moduleType === ModuleType.PGX ? false : report?.components.variantDetails.length !== 0;
  return (
    <TwoButtonDialog
      title={messages.title}
      primaryButtonText={messages.continue}
      secondaryButtonText={messages.exit}
      onPrimaryAction={() => handleUpdateDialogContinue(updateDialogValue)}
      onSecondaryAction={() => setShowUpdateDialog(false)}
      onClose={() => setShowUpdateDialog(false)}
      isFullScreenOnMobile={false}
    >
      <FormControl component="fieldset">
        <RadioGroup
          // FIXME This aria label should be more user friendly.
          aria-label="which-information"
          value={updateDialogValue}
          onChange={(e) => setUpdateDialogValue(e.target.value)}
        >
          <FormControlLabel
            sx={RADIO_LABEL_SX}
            value="edit"
            control={<Radio size="small" />}
            label={
              <>
                <Text bold>{messages.optionEdit}</Text>
                <Spacer variant="xSmall" />
                <Text>{messages.optionEditDescription}</Text>
              </>
            }
          />
          <Spacer variant="medium" />
          <FormControlLabel
            sx={RADIO_LABEL_SX}
            value="cancel"
            control={<Radio size="small" />}
            label={
              <>
                <Text bold>{messages.optionCancel}</Text>
                <Spacer variant="xSmall" />
                <Text>
                  {isHdrPositive
                    ? messages.optionCancelDescriptionHdrPositive
                    : messages.optionCancelDescription}
                </Text>
              </>
            }
          />
        </RadioGroup>
      </FormControl>
    </TwoButtonDialog>
  );
};
