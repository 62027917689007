import { AncestryComposition, ParentPopulation, Subpopulation } from './types';

// Filters out all compositions and subcompositions with 0 percentage from `AncestryComposition` array.
export function getNonZeroCompositions(compositions: AncestryComposition[]): AncestryComposition[] {
  return compositions
    .filter((composition: AncestryComposition) => composition.percentage > 0)
    .map((composition: AncestryComposition) => ({
      ...composition,
      subcompositions: composition.subcompositions.filter(
        (subcomposition) => subcomposition.percentage > 0
      ),
    }));
}

// Return all `Subpopulation`s with non-zero percentage. Note: If a `ParentPopulation` has non-zero
// percentage and no subpopulation data, the `ParentPopulation` will be included in the result.
export function getSubpopulations(
  compositions: AncestryComposition[]
): (ParentPopulation | Subpopulation)[] {
  return getNonZeroCompositions(compositions)
    .map((composition) =>
      composition.subcompositions
        ? composition.subcompositions.map((subcomposition) => subcomposition.population)
        : composition.population
    )
    .flat();
}
