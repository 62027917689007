import {
  AccordionDetails,
  AccordionSummary,
  Box,
  IMuiTheme,
  InlineSpacer,
  InternalLink,
  MuiAccordion,
  Spacer,
  Text,
  getMuiClassSelector,
} from '@color/continuum';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export type PopulationBreakdownData = {
  title: string;
  percentage?: number;
  subpopulations?: {
    percentage?: number;
    examples: string | ReactNode;
    title: string | ReactNode;
  }[];
}[];

export interface PopulationBreakdownProps {
  /*
  When `isOverviewPageBreakdown` is true, several features are enabled:
    1. Expansion icons, and the ability for the accordions to expand and collapse
    2. Adds percentages
    3. Adds a final div with a link to the "All Tested Populations" page
  */
  isOverviewPageBreakdown?: boolean;
  data: PopulationBreakdownData;
}
const ACCORDION_DETAILS_SX = (theme: IMuiTheme) => ({
  padding: 3,
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: theme.palette.grey[400],
  backgroundColor: theme.palette.common.white,
});

export const PopulationBreakdown: React.FC<PopulationBreakdownProps> = (
  props: PopulationBreakdownProps
) => {
  const { isOverviewPageBreakdown = true, data } = props;
  return (
    <Box
      sx={{
        [getMuiClassSelector('& div:first-child > .MuiAccordionSummary-root')]: {
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        },
        [getMuiClassSelector('& .MuiAccordion-root.Mui-expanded')]: {
          margin: 0,
        },
      }}
    >
      {data.map((population, idx) => (
        <MuiAccordion
          defaultExpanded
          square
          sx={{
            [getMuiClassSelector('& .MuiAccordionSummary-expandIcon')]: {
              // Note: These have to be the same since the icon is rotated when expanded,
              // and uneven padding would make the icon appear to move
              paddingX: 2,
              paddingY: 0,
            },
          }}
        >
          <AccordionSummary
            sx={(theme) => ({
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              paddingX: 2,
              paddingY: 3,
              [getMuiClassSelector('& .MuiAccordionSummary-content, .Mui-expanded')]: {
                margin: 0,
              },
              ...(isOverviewPageBreakdown ? undefined : { pointerEvents: 'none' }),
            })}
            expandIcon={
              isOverviewPageBreakdown && (
                <ExpandMoreIcon
                  sx={{
                    color: (theme) => theme.palette.primary.contrastText,
                    padding: 0,
                  }}
                />
              )
            }
            id={`header-${idx}`}
            aria-controls={`content-${idx}`}
          >
            <Box display="flex" justifyContent="space-between" width="100%">
              <Text bold role="heading" aria-level={2}>
                {population.title}
              </Text>
              <InlineSpacer variant="small" />
              {isOverviewPageBreakdown && population.percentage && (
                <Text bold>{population.percentage}%</Text>
              )}
            </Box>
          </AccordionSummary>
          {population.subpopulations?.map((subpopulation) => (
            <AccordionDetails sx={ACCORDION_DETAILS_SX}>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box>
                  <Text bold color="primary" role="heading" aria-level={3}>
                    {subpopulation.title}
                  </Text>
                  <Spacer variant="xSmall" />
                  <Text>{subpopulation.examples}</Text>
                </Box>
                {isOverviewPageBreakdown && subpopulation.percentage && (
                  <Text bold color="primary">
                    {subpopulation.percentage}%
                  </Text>
                )}
              </Box>
            </AccordionDetails>
          ))}
        </MuiAccordion>
      ))}
      {isOverviewPageBreakdown && (
        <AccordionDetails sx={ACCORDION_DETAILS_SX}>
          <InternalLink to="/ancestry/populations">
            <Text>
              <FormattedMessage
                description="Population breakdown population page link text"
                defaultMessage="See Other Ancestry Groups Tested"
              />
            </Text>
          </InternalLink>
        </AccordionDetails>
      )}
    </Box>
  );
};
