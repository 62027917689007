import { useCookiesContext, usePreferredLocale } from '@color/lib';
import constate from 'constate';
import { useCallback } from 'react';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { HDR_REPORT_PATH, PGX_REPORT_PATH } from 'components/App/AppRoutes/constants';
import { config } from 'config';
import { useConfigForIdp } from 'hooks/useConfigForIdp';
import { identifyAnalyticsUser, resetAnalyticsUser } from 'lib/analytics';
import { api } from 'lib/api';
import { ApiError } from 'lib/api/types';
import { IDP_CE } from 'lib/constants';

import {
  AUTH_STRATEGIES,
  SSO_IDENTITY_PROVIDER,
  SSO_LOGIN_ENDPOINT,
  SSO_REFRESH_INTERVAL_IN_MILLISECONDS,
  SSO_REFRESH_SESSION_ENDPOINT,
} from './constants';
import { AuthStrategy, User } from './types';

const authStrategies: AuthStrategy[] =
  AUTH_STRATEGIES[config.ENVIRONMENT as keyof typeof AUTH_STRATEGIES];

function useAppAuth() {
  const loginRouteMatch = useRouteMatch('/login');
  const errorRouteMatch = useRouteMatch('*/error');
  const history = useHistory();
  const { pathname, search } = useLocation();
  const queryClient: QueryClient = useQueryClient();
  const { locale } = usePreferredLocale();
  const searchParams = new URLSearchParams(search);
  // Allow use of report access token authentication for GCs viewing reports
  const token = searchParams.get('token');
  const hdrTokenRouteMatch = pathname.includes(HDR_REPORT_PATH) && token;
  const pgxTokenRouteMatch = pathname.includes(PGX_REPORT_PATH) && token;
  // Allow specifying the identity provider with PTSC as the default if missing
  const idp = searchParams.get('idp') || SSO_IDENTITY_PROVIDER;
  const { SSO_LOGOUT_URL } = useConfigForIdp();
  const { cookies } = useCookiesContext();
  const ssoIdToken = cookies?.ssoIdToken || '';
  const redirectToLogin = useCallback(async () => {
    const nextUrl = pathname;

    // If SSO is allowed, it is the default auth option. In staging, going to any page other
    // than /login should redirect to the PTSC login page.
    if (authStrategies.includes(AuthStrategy.SSO)) {
      return window.location.assign(
        `${SSO_LOGIN_ENDPOINT}?idp=${idp}&lang=${locale}&next=${nextUrl}`
      );
    }
    // When SSO is not an allowed auth strategy (like in development), redirect to a login page
    // to enter Color credentials.
    return history.push(`/login?next=${nextUrl}`);
  }, [history, pathname, locale, idp]);
  const useLogout = useMutation<Response, ApiError>(() => api('sessions', { method: 'DELETE' }), {
    // We use onSettled instead of onSuccess because we want to redirect the user to logout even if the API
    // request to delete the session errors (for example, if the session has already expired).
    onSettled: () => {
      resetAnalyticsUser();
      if (authStrategies.includes(AuthStrategy.SSO)) {
        // CE needs the id token as well to log out
        if (idp === IDP_CE) {
          const logoutUrl = `${SSO_LOGOUT_URL.href}&id_token_hint=${ssoIdToken}`;
          window.location.assign(logoutUrl);
        } else {
          window.location.assign(SSO_LOGOUT_URL.href);
        }
      } else {
        // Clear the query cache.
        // If a new user logs in on the same device, they shouldn't see cached results from previous queries.
        queryClient.clear();
        // Redirect to login page for Color credentials without any `next` query param.
        history.push('/login');
      }
    },
  });
  const { mutate: logout } = useLogout;

  // When the user first navigates to any route within the AuthProvider, attempt to fetch
  // the active session to set isAuthenticated.
  // When the user returns to the tab after a period of inactivity, refetch the session to
  // check whether it is still active.
  const sessionResponse = useQuery('sessions', {
    enabled: !hdrTokenRouteMatch && !pgxTokenRouteMatch,
    refetchOnWindowFocus: 'always',
    onError: async (error: ApiError) => {
      const isOnLoginPage =
        Boolean(loginRouteMatch) && authStrategies.includes(AuthStrategy.PASSWORD);
      if (error.status === 401 && !isOnLoginPage && !errorRouteMatch) {
        await redirectToLogin();
      }
    },
  });
  const { isSuccess: isAuthenticated } = sessionResponse;

  // When the user first authenticates, fetch their information and identify them for analytics.
  const accountResponse = useQuery<User>('accounts', {
    enabled: isAuthenticated,
    onSuccess: (user: User) => {
      identifyAnalyticsUser(user);
    },
  });
  const { data: currentUser } = accountResponse;

  // If the user is authenticated and they are a proxy user, this heartbeat query keeps their PTSC session active.
  useQuery([SSO_REFRESH_SESSION_ENDPOINT], {
    enabled: isAuthenticated,
    refetchInterval: SSO_REFRESH_INTERVAL_IN_MILLISECONDS,
    refetchIntervalInBackground: false,
  });

  return {
    authStrategies,
    isAuthenticated,
    currentUser,
    logout,
  };
}

export const [AppAuthProvider, useAppAuthContext] = constate(useAppAuth);
