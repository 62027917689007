import { MessageDescriptor, defineMessages } from 'react-intl';

import { Trait } from 'components/TraitResults/types';

import { ReportIconType } from '../ReportIcon/types';

const messages = defineMessages({
  bitternessTitle: {
    description: 'Other results section bitter card title',
    defaultMessage: 'Bitter Taste Perception',
  },
  cilantroTitle: {
    description: 'Other results section cilantro card title',
    defaultMessage: 'Cilantro Preference',
  },
  earwaxTitle: {
    description: 'Other results section earwax card title',
    defaultMessage: 'Earwax Type',
  },
  lactoseTitle: {
    description: 'Other results section lactose card title',
    defaultMessage: 'Lactose Intolerance',
  },
});

export const OTHER_RESULTS_CARD_CONTENT: Record<
  Trait,
  { title: MessageDescriptor; icon: ReportIconType }
> = {
  [Trait.BITTERNESS]: {
    title: messages.bitternessTitle,
    icon: ReportIconType.BITTERNESS,
  },
  [Trait.CILANTRO]: {
    title: messages.cilantroTitle,
    icon: ReportIconType.CILANTRO,
  },
  [Trait.EARWAX]: {
    title: messages.earwaxTitle,
    icon: ReportIconType.EARWAX,
  },
  [Trait.LACTOSE]: {
    title: messages.lactoseTitle,
    icon: ReportIconType.LACTOSE,
  },
};
