import { Box, Container, Spacer, Title } from '@color/continuum';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { ProgressBar } from '../ProgressBar/ProgressBar';
import { useQuizSessionMutation, useSaveAnswerMutation } from './api';
import { GoBackButton } from './GoBackButton';
import { QuizItem } from './QuizItem';
import { useQuizState } from './state/useQuizState';
import { IFinishQuizScreenProps, IQuiz, TAnswerMap, TQuizAnswer } from './types';

const messages = defineMessages({
  finishScreenTitle: {
    description: 'Survey finish screen title',
    defaultMessage:
      'Thank you so much for your responses. <i>All of Us</i> will use your input to help make improvements to these reports.',
  },
});

export interface QuizProps {
  quiz: IQuiz;
  onFinishQuiz?: () => void;
  FinishScreen: React.FC<IFinishQuizScreenProps> | null;
  initialAnswers: TAnswerMap;
  sessionId?: string;
  setSessionId: (sessionId: string) => void;
}

export const Quiz: React.FC<QuizProps> = (props: QuizProps) => {
  const { quiz, initialAnswers, sessionId, setSessionId } = props;
  const intl = useIntl();
  const {
    quizItemIndex,
    hasPreviousQuizItem,
    goToPreviousQuizItem,
    saveAnswerLocallyAndGoToNextQuizItem,
    quizItem,
    answer,
    quizItemCount,
    isLastQuizItem,
  } = useQuizState({
    quiz,
    initialAnswers,
  });

  const { mutateAsync: saveQuizAnswer, isLoading: quizAnswerIsSaving } = useSaveAnswerMutation();
  const { mutateAsync: updateQuizSession } = useQuizSessionMutation();

  const saveAnswer = async (
    questionId: string,
    newAnswer: TQuizAnswer,
    answerChoiceIds?: number[]
  ) => {
    saveQuizAnswer({
      questionId,
      answer: newAnswer,
      quizName: quiz.name,
      answerChoices: answerChoiceIds,
      sessionId,
    }).then((response) => {
      const nextSessionId = response.payload?.sessionId;
      if (nextSessionId) {
        setSessionId(nextSessionId);
      }
      if (isLastQuizItem && sessionId) {
        updateQuizSession({
          sessionId,
          isCompleted: true,
        });
      }
    });
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        width: '100%',
      })}
    >
      <ProgressBar currentStep={quizItemIndex + 1} numSteps={quizItemCount} />
      <Spacer variant="large" />
      <Container
        sx={{
          paddingLeft: 2,
          paddingRight: 2,
          minHeight: 560,
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        {hasPreviousQuizItem && (
          <div>
            <GoBackButton onClick={goToPreviousQuizItem} />
          </div>
        )}
        <Spacer variant="medium" />
        <Title
          variant="headline4"
          component="h2"
          sx={(theme) => ({
            color: theme.palette.primary.main,
            fontWeight: 'normal',
          })}
        >
          <FormattedMessage
            description="Survey component title asking for feedback"
            defaultMessage="Would you like to give us some feedback?"
          />
        </Title>
        <Spacer variant="medium" />
        {quizItem ? (
          <QuizItem
            {...quizItem}
            saveQuizAnswer={saveAnswer}
            quizAnswerIsSaving={quizAnswerIsSaving}
            answer={answer}
            onNext={saveAnswerLocallyAndGoToNextQuizItem}
            isLastQuizItem={isLastQuizItem}
          />
        ) : (
          <Title variant="headline2" component="h3">
            {intl.formatMessage(messages.finishScreenTitle)}
          </Title>
        )}
      </Container>
      <Spacer variant="large" />
    </Box>
  );
};
