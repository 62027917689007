import { FormHelperText, InputAdornment, TextField, useTheme } from '@color/continuum';
import ReportProblemIcon from '@mui/icons-material/ReportProblemOutlined';
import { useField } from 'formik';

import { CountrySelector } from './CountrySelector';

export interface IFormikPhoneFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  phoneNumberName: string;
  countryCodeName: string;
}
export const FormikPhoneField: React.FC<IFormikPhoneFieldProps> = (props) => {
  const { countryCodeName, phoneNumberName, label, required } = props;
  const theme = useTheme();
  const [field, meta] = useField(phoneNumberName);
  const errorMessage = meta.touched ? meta.error : undefined;
  return (
    <>
      <TextField
        required={required}
        type="tel"
        label={label}
        placeholder={label}
        fullWidth
        sx={{
          label: { color: errorMessage ? `${theme.palette.error.text} !important` : null },
          fieldset: { borderColor: errorMessage ? `${theme.palette.error.text} !important` : null },
        }}
        InputProps={{
          endAdornment: errorMessage && (
            <InputAdornment position="end">
              <ReportProblemIcon color="error" data-testid="error-icon" />
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <CountrySelector
                label={label}
                countryCodeName={countryCodeName}
                error={Boolean(errorMessage)}
              />
            </InputAdornment>
          ),
          style: {
            height: theme.spacing(6),
            color: errorMessage && theme.palette.error.text,
          },
        }}
        {...field}
      />
      {errorMessage && <FormHelperText sx={{ color: 'error.text' }}>{errorMessage}</FormHelperText>}
    </>
  );
};
