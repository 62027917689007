import { UseQueryOptions, useQuery } from 'react-query';

import { ApiError } from 'lib/api/types';

import { TraitOutcomeData } from './types';

interface TraitOutcomeQueryParams {
  trait?: string;
}

export const useTraitOutcomeQuery = (
  params?: TraitOutcomeQueryParams,
  options?: UseQueryOptions<TraitOutcomeData, ApiError>
) => {
  return useQuery<TraitOutcomeData, ApiError>(
    ['trait_outcomes', { searchParams: params }],
    options
  );
};
