import { ExternalLink, Spacer, Text } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { GpTooltip } from 'components/common/GpTooltip/GpTooltip';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { PGX_GENES_LIST_URLS } from 'lib/constants';

import { PGX_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  pgxILModule6PageTitle: {
    description: 'PGx Informing Loop Page Title - Module 6',
    defaultMessage: 'Choices',
  },

  pgxIlTooltipLabelMedicinesGenesList: {
    description: 'PGX Informing Loop Medicines Genes List tooltip accessible label',
    defaultMessage: 'Medicines Genes List Info',
  },
});

export const PgxInformingLoopModule6: React.FC = () => {
  const intl = useIntl();
  const { locale } = usePreferredLocale();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.pgxILModule6PageTitle)}
      module={{
        analyticsInformingLoopName: PGX_INFORMING_LOOP_NAME,
        analyticsPageName: '6. Options',
        image: CircularImageType.RADIO_BUTTONS_RED,
        title: (
          <FormattedMessage
            description="PGX informing loop page 6 title"
            defaultMessage="What are my choices?"
          />
        ),
        content: (
          <>
            <Text paragraph>
              <FormattedMessage id="PGX_IL_6__Paragraph_1" />
            </Text>
            <Text>
              <FormattedMessage id="PGX_IL_6__Paragraph_2" />
            </Text>
            <Spacer variant="xSmall" />
            <ul>
              <li>
                <Text>
                  <FormattedMessage
                    description="PGX informing loop page 6 bullet 1"
                    defaultMessage="One of our specially trained scientists will look closely at some of the genes in your DNA related to how your body responds to some medicines. They will generate results for you based on what they find."
                  />
                </Text>
              </li>
              <Spacer variant="small" />
              <li>
                <Text>
                  <FormattedMessage
                    description="PGX informing loop page 6 bullet 2"
                    defaultMessage="The results will list the genes they looked at and the medicines that may be affected. {tooltip} They will point out if your DNA may affect how your body processes these medicines."
                    values={{
                      tooltip: (
                        <GpTooltip
                          aria-label={intl.formatMessage(
                            messages.pgxIlTooltipLabelMedicinesGenesList
                          )}
                          content={
                            <Text paragraph>
                              <FormattedMessage
                                id="PGX_IL_6__Bullet_2_Tooltip"
                                values={{
                                  genesListLink: (
                                    <ExternalLink to={PGX_GENES_LIST_URLS[locale]}>
                                      <FormattedMessage
                                        description="PGX informing loop page 6 bullet 2 tooltip genes list link"
                                        defaultMessage="here"
                                      />
                                    </ExternalLink>
                                  ),
                                }}
                              />
                            </Text>
                          }
                        />
                      ),
                    }}
                  />
                </Text>
              </li>
            </ul>
            <Spacer variant="xSmall" />
            <Text paragraph>
              <FormattedMessage id="PGX_IL_6__Paragraph_3" />
            </Text>
            <ul>
              <li>
                <Text>
                  <FormattedMessage
                    description="PGX informing loop page 6 bullet 3"
                    defaultMessage="No one at <i>All of Us</i> will look at your DNA in this way."
                  />
                </Text>
              </li>
            </ul>
            <Text paragraph>
              <FormattedMessage
                description="PGX informing loop page 6 paragraph 4"
                defaultMessage='If you say "<b>I’m not sure right now</b>":'
              />
            </Text>
            <ul>
              <li>
                <Text>
                  <FormattedMessage
                    description="PGX informing loop page 6 bullet 4"
                    defaultMessage="You can come back and make a decision later."
                  />
                </Text>
              </li>
              <Spacer variant="small" />
              <li>
                <Text>
                  <FormattedMessage
                    description="PGX informing loop page 6 bullet 5"
                    defaultMessage="We won’t generate your personalized results until you tell us you want us to."
                  />
                </Text>
              </li>
            </ul>
            <Spacer variant="xSmall" />
            <Text>
              <FormattedMessage
                description="PGX informing loop page 6 paragraph 5"
                defaultMessage="You will be able to decide separately if you want other types of DNA results, like your risk for certain health conditions. We will send you messages when new result types are available."
              />
            </Text>
          </>
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
