import { Box, Spacer, Text, Title } from '@color/continuum';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { useAncestryQuery } from 'components/AncestryResults/api';
import { useTraitOutcomeQuery } from 'components/TraitResults/api';
import { useResultDecisionsQuery } from 'lib/analytics/result-decisions';
import { ModuleType } from 'lib/analytics/types';

import { ResultCards } from './ResultCards';

export const AncestryAndTraitResults: React.FC = () => {
  const { data: ancestryData, isLoading: ancestryIsLoading } = useAncestryQuery();
  const { data: traitOutcomesData, isLoading: traitOutcomesAreLoading } = useTraitOutcomeQuery();
  const {
    data: resultDecisionsData,
    isLoading: resultDecisionsAreLoading,
  } = useResultDecisionsQuery({ moduleType: ModuleType.GEM });
  const resultDecision = isEmpty(resultDecisionsData?.resultDecisions)
    ? undefined
    : resultDecisionsData!.resultDecisions[0];

  if (ancestryIsLoading || traitOutcomesAreLoading || resultDecisionsAreLoading) {
    // TODO: Replace with spinner?
    return null;
  }

  const resultsAreVisible = Boolean(resultDecision?.resultVisible);

  const numberOfAncestryReports = ancestryData ? 1 : 0;
  const numberOfTraitReports = traitOutcomesData ? Object.keys(traitOutcomesData).length : 0;

  return (
    <>
      <Box display="flex" gap={1} justifyContent="space-between" alignItems="center">
        <Title variant="headline2">
          <FormattedMessage
            defaultMessage="Genetic ancestry and trait results"
            description="Title of traits and ancestry reports section on dashoard"
          />
        </Title>
        {resultsAreVisible && (
          <Text>
            <FormattedMessage
              defaultMessage="{reportNumber} reports"
              values={{ reportNumber: numberOfAncestryReports + numberOfTraitReports }}
            />
          </Text>
        )}
      </Box>
      <Spacer variant="large" />
      <ResultCards resultDecision={resultDecision} />
    </>
  );
};
