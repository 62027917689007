import {
  Box,
  Container,
  Grid,
  Spacer,
  Text,
  Title,
  useMediaQuery,
  useTheme,
} from '@color/continuum';
import { Fragment } from 'react';

import { Trait, TraitOutcome } from '../../types';
import { useTraitResultsSectionContent } from './content';

const IMAGE_BOX_SX = {
  alignSelf: 'center',
  display: 'flex',
  justifyContent: 'center',
  '& img': {
    maxWidth: '100%',
  },
};

interface Props {
  section: number;
  trait: Trait;
  traitOutcome: TraitOutcome;
}

export const TraitResultsSection: React.FC<Props> = (props) => {
  const { section, trait, traitOutcome } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const content = useTraitResultsSectionContent(trait, traitOutcome, section);
  const { title, columns, image } = content;

  return (
    <Container
      sx={{
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(5),
        },
      }}
    >
      <Box display="flex" flexDirection="column">
        <Title variant="headline2">{title}</Title>
        <Grid container spacing={isMobile ? 0 : 10}>
          {columns.map((column, columnIndex) => (
            <Grid item sm={12} md={6} key={columnIndex}>
              {column.map((subcontent, subcontentIndex) => (
                <Fragment key={subcontentIndex}>
                  <Spacer variant="xLarge" />
                  {subcontent.title && (
                    <>
                      <Title variant="headline3">{subcontent.title}</Title>
                      <Spacer variant="small" />
                    </>
                  )}
                  <Text paragraph={false}>{subcontent.content}</Text>
                  {subcontent.image && (
                    <>
                      <Spacer variant="medium" />
                      <Box sx={IMAGE_BOX_SX}>{subcontent.image}</Box>
                    </>
                  )}
                </Fragment>
              ))}
            </Grid>
          ))}
        </Grid>
        {image && (
          <>
            <Spacer variant="medium" />
            <Box sx={IMAGE_BOX_SX}>{image}</Box>
          </>
        )}
      </Box>
    </Container>
  );
};
