import { Box, IMuiTheme, IconButton, InlineSpacer } from '@color/continuum';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { defineMessages, useIntl } from 'react-intl';

export interface InfoCarouselButtonProps {
  onClick: () => void;
}

const ICON_CONTAINER_SX = (theme: IMuiTheme) => ({
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.secondary.main,
  borderRadius: '50%',
  height: '42px',
  width: '42px',
  padding: '2px',
  flex: '0 0 auto',
  fontSize: '36px',
  color: theme.palette.secondary.main,
});

const messages = defineMessages({
  previous: {
    defaultMessage: 'Previous',
    description: 'Accessible text for the Previous icon button',
  },
  next: {
    defaultMessage: 'Next',
    description: 'Accessible text for the Next icon button',
  },
});

export const InfoCarouselLeftButton: React.FC<InfoCarouselButtonProps> = (
  props: InfoCarouselButtonProps
) => {
  const { onClick } = props;
  const intl = useIntl();
  return (
    <>
      <InlineSpacer variant="xxxLarge" />
      <IconButton onClick={onClick} aria-label={intl.formatMessage(messages.previous)}>
        <Box sx={ICON_CONTAINER_SX}>
          <KeyboardArrowLeftIcon fontSize="inherit" />
        </Box>
      </IconButton>
    </>
  );
};

export const InfoCarouselRightButton: React.FC<InfoCarouselButtonProps> = (
  props: InfoCarouselButtonProps
) => {
  const { onClick } = props;
  const intl = useIntl();
  return (
    <>
      <IconButton onClick={onClick} aria-label={intl.formatMessage(messages.next)}>
        <Box sx={ICON_CONTAINER_SX}>
          <KeyboardArrowRightIcon fontSize="inherit" />
        </Box>
      </IconButton>
      <InlineSpacer variant="xxxLarge" />
    </>
  );
};
