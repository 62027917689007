import { HDR_REPORT_PATH } from 'components/App/AppRoutes/constants';

import { HdrUninformativeTabIds } from './types';

export const HDR_UNINFORMATIVE_LOCATION_CONTROLLED_TABS_PROPS = {
  basePath: HDR_REPORT_PATH,
  tabIds: [
    HdrUninformativeTabIds.YOUR_RESULTS,
    HdrUninformativeTabIds.HEALTHY_LIFESTYLES,
    HdrUninformativeTabIds.DISCUSS_RESULTS,
  ],
  defaultTabId: HdrUninformativeTabIds.YOUR_RESULTS,
};
