import { kebabCase } from 'lodash';
import requireContext from 'require-context.macro';

import { AccordionIconType } from './types';

const AccordionIcons = requireContext(
  '../../../../../assets/images/accordion-icons',
  true,
  /\.svg$/
);

export const getAccordionIconSrc = (icon: AccordionIconType): string => {
  return AccordionIcons(`./${kebabCase(icon)}.svg`).default;
};
