import { IPaginatedResponse } from '@color/continuum';
import { UseQueryOptions, useMutation, useQuery } from 'react-query';

import { api } from 'lib/api';
import { ApiError, ApiResponse } from 'lib/api/types';

import {
  IQuiz,
  IQuizResponse,
  IQuizSessionUpdateRequest,
  ISaveAnswerRequest,
  ISaveAnswerResponsePayload,
} from './types';

const QUIZ_API_ENDPOINT = 'quiz';
const QUESTION_RESPONSES_API_ENDPOINT = 'question_responses';
const QUIZ_UPDATE_SESSION_API_ENDPOINT = 'update_session';

export const useQuizQuery = (quizName: string, options?: UseQueryOptions<IQuiz, ApiError>) => {
  return useQuery<IQuiz, ApiError>(`${QUIZ_API_ENDPOINT}/${quizName}`, options);
};

export const useQuestionResponsesQuery = (quizName: string) => {
  return useQuery<IPaginatedResponse<IQuizResponse>, ApiError>([
    QUESTION_RESPONSES_API_ENDPOINT,
    {
      searchParams: { quizName, latest: true },
    },
  ]);
};

export const useSaveAnswerMutation = () => {
  return useMutation<ApiResponse<ISaveAnswerResponsePayload>, ApiError, ISaveAnswerRequest>(
    (json: ISaveAnswerRequest) => api(QUESTION_RESPONSES_API_ENDPOINT, { method: 'POST', json }),
    // TODO @rymarren Make a toast / snackbar component appear here.
    // eslint-disable-next-line unused-imports/no-unused-vars
    { onError: (error) => {} }
  );
};

export const useQuizSessionMutation = () => {
  return useMutation<ApiResponse, ApiError, IQuizSessionUpdateRequest>(
    (json: IQuizSessionUpdateRequest) =>
      api(QUIZ_UPDATE_SESSION_API_ENDPOINT, { method: 'POST', json }),
    // TODO @rymarren Make a toast / snackbar component appear here.
    // eslint-disable-next-line unused-imports/no-unused-vars
    { onError: (error) => {} }
  );
};
