import {
  HdrV1Gene,
  MULTIPLE_DISORDER_HDR_V1_GENES,
  MultipleDisorderHdrV1GeneType,
} from '@color/aou-reports';

import {
  MULTIPLE_DISORDER_SEX_AGNOSTIC_RISK_CONTENT,
  SINGLE_DISORDER_SEX_AGNOSTIC_RISK_CONTENT,
} from './constants';
import { SexAgnosticRiskContent } from './types';

const isMultipleDisorderHdrV1Gene = (gene: HdrV1Gene): gene is MultipleDisorderHdrV1GeneType =>
  MULTIPLE_DISORDER_HDR_V1_GENES.has(gene);

export const getSexAgnosticGeneSpecificRiskContent: (
  gene: HdrV1Gene,
  disorder: string
) => SexAgnosticRiskContent = (gene, disorder) =>
  isMultipleDisorderHdrV1Gene(gene)
    ? MULTIPLE_DISORDER_SEX_AGNOSTIC_RISK_CONTENT[gene][disorder]
    : SINGLE_DISORDER_SEX_AGNOSTIC_RISK_CONTENT[
        gene as keyof typeof SINGLE_DISORDER_SEX_AGNOSTIC_RISK_CONTENT
      ];
