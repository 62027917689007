import { HdrV1Gene } from '@color/aou-reports';
import {
  Container,
  Divider,
  ExternalButtonLink,
  ImageDivider,
  InternalButtonLink,
  InternalLink,
  Spacer,
  Text,
} from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
// TODO Import from CDS rather than MUI. In CDS-341 we turned on lint warnings on direct MUI imports. This line is disabled because GP CI sets the rule to error instead of warn.
// Swap this for a CDS import instead of a MUI import.
// eslint-disable-next-line no-restricted-imports
import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import DnaBanner from 'assets/images/dna-banner.svg';
import {
  DOWNLOAD_PDF_PAGE_PATH,
  HDR_APPOINTMENT_SCHEDULING_PATH,
  SHARE_RESULTS_WITH_PROVIDER_PATH,
} from 'components/App/AppRoutes/constants';
import { CircularImageType } from 'components/common/CircularImage/types';
import { useReportPdfUrl } from 'components/HealthRelatedResults/api';
import { ImageWithContentSection } from 'components/HealthRelatedResults/components/ImageWithContentSection/ImageWithContentSection';
import { config } from 'config';
import { trackSegmentEvent } from 'lib/analytics/segment';
import { ModuleType, SegmentEventType } from 'lib/analytics/types';
import { HDR_POSITIVE_INFO_SHEET_URLS } from 'lib/constants';

import { EmailRelativesButton, ShareResultsWithFamilyLink } from '../ShareResultsWithFamily';

interface INextStepsTabProps {
  gene: HdrV1Gene;
}

export const NextStepsTab: React.FC<INextStepsTabProps> = (props) => {
  const { gene } = props;
  const { locale } = usePreferredLocale();
  const reportPdfUrl = useReportPdfUrl({ moduleType: ModuleType.HDR });
  const location = useLocation();
  return (
    <>
      <Container>
        <Spacer variant="xxLarge" />
        <ImageWithContentSection
          title={<FormattedMessage id="HDR_Positive_Next_Steps__Clinical_Test_Header" />}
          description={
            <>
              <Text paragraph>
                <FormattedMessage id="HDR_Positive_Next_Steps__Clinical_Test_P1" />
              </Text>
              <ExternalButtonLink
                to={`${config.API_HOST_URL}api/v1/aou_clinical_confirmation`}
                onClick={() => {
                  trackSegmentEvent({
                    eventType: SegmentEventType.HDR_POSITIVE_CLINICAL_CONFIRMATION_CTA,
                  });
                }}
              >
                <FormattedMessage id="HDR_Positive_Next_Steps__Get_Started" />
              </ExternalButtonLink>
            </>
          }
          image={CircularImageType.CLINICAL_TEST}
          imageRight
        />
        <Spacer variant="xxxLarge" />
        <Divider aria-hidden="true" />
        <Spacer variant="xxxLarge" />
        <ImageWithContentSection
          title={<FormattedMessage id="HDR_Positive_Next_Steps__Share_With_Doctor_Header" />}
          description={
            <>
              <Text paragraph>
                <FormattedMessage id="HDR_Positive_Next_Steps__Share_With_Doctor_P1" />
              </Text>
              <Text paragraph>
                <FormattedMessage
                  id="HDR_Positive_Next_Steps__Share_With_Doctor_P2"
                  values={{
                    link: (chunks: ReactNode) => (
                      <InternalLink
                        // TODO: We should have a shared utility to generate these URLs.
                        to={`${DOWNLOAD_PDF_PAGE_PATH}?${new URLSearchParams({
                          pdfUrl: HDR_POSITIVE_INFO_SHEET_URLS[locale],
                          moduleType: ModuleType.HDR.toString(),
                        }).toString()}`}
                      >
                        {chunks}
                      </InternalLink>
                    ),
                  }}
                />
              </Text>
              <Grid container wrap="wrap" alignItems="center" gap={2}>
                <Grid item>
                  <InternalButtonLink
                    to={{
                      pathname: SHARE_RESULTS_WITH_PROVIDER_PATH,
                      state: { backgroundLocation: location },
                    }}
                  >
                    <FormattedMessage id="HDR_Positive_Next_Steps__Share_Results" />
                  </InternalButtonLink>
                </Grid>
                <Grid item>
                  {reportPdfUrl && (
                    <InternalLink
                      // TODO: We should have a shared utility to generate these URLs.
                      to={`${DOWNLOAD_PDF_PAGE_PATH}?${new URLSearchParams({
                        pdfUrl: reportPdfUrl,
                        moduleType: ModuleType.HDR.toString(),
                      }).toString()}`}
                    >
                      <FormattedMessage id="HDR_Positive_Next_Steps__Share_With_Doctor_P3" />
                    </InternalLink>
                  )}
                </Grid>
              </Grid>
            </>
          }
          image={CircularImageType.DOCTOR}
        />
        <Spacer variant="xxxLarge" />
      </Container>
      <ImageDivider imageSrc={DnaBanner} />
      <Container>
        <Spacer variant="xxxLarge" />
        <ImageWithContentSection
          title={<FormattedMessage id="HDR_Positive_Next_Steps__Talk_With_Family_Header" />}
          description={
            <>
              <Text paragraph>
                <FormattedMessage id="HDR_Positive_Next_Steps__Talk_With_Family_P1" />
              </Text>
              <Text paragraph>
                <FormattedMessage id="HDR_Positive_Next_Steps__Talk_With_Family_P2" />
              </Text>
              <Text paragraph>
                <FormattedMessage id="HDR_Positive_Next_Steps__Talk_With_Family_P3" />
              </Text>
              <Grid container wrap="wrap" alignItems="center" gap={2}>
                <Grid item>
                  <EmailRelativesButton gene={gene} />
                </Grid>
                <Grid item>
                  <ShareResultsWithFamilyLink gene={gene}>
                    <FormattedMessage id="HDR_Positive_Next_Steps__Talk_With_Family_P4" />
                  </ShareResultsWithFamilyLink>
                </Grid>
              </Grid>
              <Spacer variant="medium" />
              <Text variant="body2">
                <FormattedMessage id="HDR_Positive_Next_Steps__Talk_With_Family_P5" />
              </Text>
            </>
          }
          image={CircularImageType.GENES_FAMILY}
          imageRight
        />
        <Spacer variant="xxxLarge" />
        <Divider aria-hidden="true" />
        <Spacer variant="xxxLarge" />
        <ImageWithContentSection
          title={<FormattedMessage id="HDR_Positive_Next_Steps__Results_Questions_Header" />}
          description={
            <Text paragraph>
              <FormattedMessage
                id="HDR_Positive_Next_Steps__Results_Questions_P1"
                values={{
                  link: (chunks: ReactNode) => (
                    <InternalLink
                      to={HDR_APPOINTMENT_SCHEDULING_PATH}
                      onClick={() => {
                        trackSegmentEvent({
                          eventType: SegmentEventType.HDR_NEXT_STEPS_GC_SCHEDULING,
                        });
                      }}
                    >
                      {chunks}
                    </InternalLink>
                  ),
                }}
              />
            </Text>
          }
          image={CircularImageType.GENETIC_COUNSELOR}
        />
        <Spacer variant="xxxLarge" />
        <Divider aria-hidden="true" />
        <Spacer variant="xxxLarge" />
      </Container>
    </>
  );
};
