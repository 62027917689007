import { HdrV1Gene } from '@color/aou-reports';
import { ExternalButtonLink } from '@color/continuum';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { trackAnalyticsEvent } from 'lib/analytics';
import { AnalyticsPlatform, SegmentEventType } from 'lib/analytics/types';

import { useFamilyLetterMailtoUrl } from '../useFamilyLetterMailtoUrl';

interface IEmailRelativesButtonProps {
  gene: HdrV1Gene;
}

export const EmailRelativesButton: FC<IEmailRelativesButtonProps> = (props) => {
  const { gene } = props;
  const familyLetterMailtoUrl = useFamilyLetterMailtoUrl({ gene });
  return (
    <ExternalButtonLink
      to={familyLetterMailtoUrl.href}
      onClick={() => {
        trackAnalyticsEvent(
          { eventType: SegmentEventType.HDR_RESULTS_SHARED_WITH_FAMILY },
          AnalyticsPlatform.SEGMENT
        );
      }}
    >
      <FormattedMessage id="HDR_Positive_Next_Steps__Email_Relatives_Button" />
    </ExternalButtonLink>
  );
};
