import { HdrV1ReportPayload } from '@color/aou-reports';
import { AouDashboardCard, ReportIcon } from '@color/continuum';
import { find, groupBy } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import {
  HDR_APPOINTMENT_SCHEDULING_PATH,
  HDR_INFORMING_LOOP_PATH,
  HDR_PRE_RESULTS_SCREEN_PATH,
  PGX_INFORMING_LOOP_PATH,
  PGX_PRE_RESULTS_SCREEN_PATH,
} from 'components/App/AppRoutes/constants';
import { useExistingAppointment } from 'components/Appointments/Appointments';
import { usePreResultsScreenWasCompleted } from 'components/common/InformingLoop/PreResultsScreen/usePreResultsScreenWasCompleted';
import { ReportIconType } from 'components/common/Reports/ReportIcon/types';
import { getReportIconSrc } from 'components/common/Reports/ReportIcon/utils';
import { useHealthResultsQuery } from 'components/HealthRelatedResults/api';
import { useResultDecisionsQuery } from 'lib/analytics/result-decisions/api';
import { ModuleType } from 'lib/analytics/types';
import { areIlLoopsDisabled, isHgmReportsDisabledWaffleEnabled } from 'lib/waffle/util';

import { useAppointmentsQuery, useResultStatuses } from '../../api';
import { HEALTH_RELATED_RESULTS_PATHS } from '../../constants';
import { AppointmentsSummarySection } from './AppointmentsSummarySection';
import { useHealthRelatedResultsContent } from './content';

interface Props {
  moduleType: ModuleType.HDR | ModuleType.PGX;
}
export const HealthRelatedResultsCard: React.FC<Props> = (props: Props) => {
  const { moduleType } = props;
  const { data: hdrReport, isLoading: hdrReportLoading } = useHealthResultsQuery({
    moduleType: ModuleType.HDR,
  });
  const isHdrModuleType = moduleType === ModuleType.HDR;
  const location = useLocation();
  const {
    hdrResultsAreReady,
    pgxResultsAreReady,
    hdrResultsAreOnHold,
    pgxResultsAreOnHold,
    resultStatusesAreLoading,
    hdrIsGcBlocked,
  } = useResultStatuses();
  const {
    data: resultDecisionsData,
    isLoading: resultDecisionsAreLoading,
  } = useResultDecisionsQuery();
  const resultsDecision = find(resultDecisionsData?.resultDecisions || [], {
    moduleType,
  });
  const messages = useHealthRelatedResultsContent({
    moduleType,
    decisionTimestamp: resultsDecision?.decisionAt,
  });
  const cardIcon = isHdrModuleType
    ? ReportIconType.YOUR_RESULTS_HDR
    : ReportIconType.YOUR_RESULTS_PGX;
  const cardIconSrc = getReportIconSrc(cardIcon);
  const {
    preResultsScreenWasCompleted,
    isLoading: preResultsScreenStatusIsLoading,
  } = usePreResultsScreenWasCompleted({ moduleType });
  const {
    data: existingAppointment,
    isLoading: existingAppointmentIsLoading,
  } = useExistingAppointment(moduleType);
  const {
    data: previousAppointments,
    isLoading: previousAppointmentsAreLoading,
  } = useAppointmentsQuery({
    type: moduleType,
    previous: true,
    exclude: 'participant_cancelled',
  });
  const informingLoopPath = isHdrModuleType ? HDR_INFORMING_LOOP_PATH : PGX_INFORMING_LOOP_PATH;

  if (
    resultDecisionsAreLoading ||
    preResultsScreenStatusIsLoading ||
    resultStatusesAreLoading ||
    existingAppointmentIsLoading ||
    previousAppointmentsAreLoading ||
    hdrReportLoading
  ) {
    return null;
  }
  let numberOfGeneReports = 0;
  if (hdrReport) {
    const {
      components: { variantDetails },
    } = hdrReport as HdrV1ReportPayload;
    const geneToVariantDetails = groupBy(variantDetails, 'gene');
    const groupedVariantDetails = Object.values(geneToVariantDetails);
    numberOfGeneReports = groupedVariantDetails.length;
  }
  const hasExistingAppointment = existingAppointment && existingAppointment.appointment !== null;
  const resultsAreReady =
    (!isHdrModuleType && pgxResultsAreReady) || (isHdrModuleType && hdrResultsAreReady);
  const resultsAreOnHold =
    (!isHdrModuleType && pgxResultsAreOnHold) || (isHdrModuleType && hdrResultsAreOnHold);
  const participantCompletedInformingLoop = Boolean(resultsDecision);
  const participantConsentedToInformingLoop = resultsDecision?.resultVisible;
  const missedLastAppointment =
    previousAppointments?.results &&
    previousAppointments.results.length > 0 &&
    previousAppointments.results[0].missedAppointment;

  const disabledIlAccess = areIlLoopsDisabled();

  if (!participantCompletedInformingLoop) {
    // We show a card that links the participant to the Informing Loop.
    const buttonProps = disabledIlAccess
      ? {}
      : {
          buttonContent: messages.getStarted,
          buttonAriaLabel: messages.getStartedAccessibleLabelText[moduleType],
        };

    return (
      <AouDashboardCard
        icon={<ReportIcon iconSrc={cardIconSrc} />}
        title={messages.informingLoopCardTitle[moduleType]}
        subtitle={messages.informingLoopCardSubtitle[moduleType]}
        buttonLinkTo={{
          pathname: informingLoopPath,
          state: { backgroundLocation: location },
        }}
        {...buttonProps}
        buttonVariant="color-primary"
      />
    );
  }

  if (participantCompletedInformingLoop && !participantConsentedToInformingLoop) {
    // We show a card that tells the participant they have not consented to the Informing Loop,
    const buttonProps = disabledIlAccess
      ? {}
      : {
          buttonContent: messages.reviewOptions,
          buttonAriaLabel: messages.reviewOptionsAccessibleLabelText[moduleType],
        };
    // and have a link to review the Informing Loop to change their decision.

    return (
      <AouDashboardCard
        icon={<ReportIcon iconSrc={cardIconSrc} />}
        title={messages.choseNotToGetResultsTitle[moduleType]}
        subtitle={messages.choseNotToGetResultsSubtitle[moduleType]}
        buttonLinkTo={{
          pathname: informingLoopPath,
          state: { backgroundLocation: location },
        }}
        {...buttonProps}
        buttonVariant="color-secondary"
      />
    );
  }

  if (
    participantCompletedInformingLoop &&
    participantConsentedToInformingLoop &&
    resultsAreReady &&
    isHgmReportsDisabledWaffleEnabled()
  ) {
    return (
      <AouDashboardCard
        icon={<ReportIcon iconSrc={cardIconSrc} />}
        title={messages.resultsTitle[moduleType]}
        subtitle={<FormattedMessage id="GP_Report_Block" />}
      />
    );
  }

  if (
    participantCompletedInformingLoop &&
    participantConsentedToInformingLoop &&
    resultsAreReady &&
    resultsAreOnHold
  ) {
    // When a report is ready but on hold we show a card telling participants that their report
    // is currently unavailable and to check back later
    return (
      <AouDashboardCard
        icon={<ReportIcon iconSrc={cardIconSrc} />}
        title={messages.resultsTitle[moduleType]}
        subtitle={<FormattedMessage id="GP_Report_Block" />}
      />
    );
  }

  if (
    participantCompletedInformingLoop &&
    participantConsentedToInformingLoop &&
    !resultsAreReady
  ) {
    // We show a card telling the participant they have consented, and that they can expect results soon.
    // This is the end state until reports have launched, hence the waffle check.
    const buttonProps = disabledIlAccess
      ? {}
      : {
          buttonContent: messages.reviewOptions,
          buttonAriaLabel: messages.reviewOptionsAccessibleLabelText[moduleType],
        };
    return (
      <AouDashboardCard
        icon={<ReportIcon iconSrc={cardIconSrc} />}
        title={messages.resultsTitle[moduleType]}
        subtitle={messages.preparingResultsSubtitle[moduleType]}
        buttonLinkTo={{
          pathname: informingLoopPath,
          state: { backgroundLocation: location },
        }}
        {...buttonProps}
        buttonVariant="color-secondary"
      />
    );
  }

  if (
    participantCompletedInformingLoop &&
    participantConsentedToInformingLoop &&
    resultsAreReady &&
    isHdrModuleType &&
    hdrIsGcBlocked &&
    !hasExistingAppointment
  ) {
    // We show a card linking the participant to schedule a GC appointment
    return (
      <AouDashboardCard
        icon={<ReportIcon iconSrc={cardIconSrc} />}
        title={messages.resultsTitle[moduleType]}
        subtitle={messages.hdrGcBlockedCardSubtitle}
        buttonLinkTo={{
          pathname: HDR_APPOINTMENT_SCHEDULING_PATH,
        }}
        buttonContent={
          missedLastAppointment ? (
            <FormattedMessage id="Appt_Reschedule" />
          ) : (
            messages.hdrScheduleAppointmentButtonText
          )
        }
        buttonVariant="color-primary"
      >
        <AppointmentsSummarySection
          moduleType={moduleType}
          viewedResults={!!preResultsScreenWasCompleted}
          isPositive={isHdrModuleType && numberOfGeneReports > 0}
        />
      </AouDashboardCard>
    );
  }

  if (
    participantCompletedInformingLoop &&
    participantConsentedToInformingLoop &&
    resultsAreReady &&
    isHdrModuleType &&
    hdrIsGcBlocked &&
    hasExistingAppointment
  ) {
    // We show a card with no button since the participant has already scheduled their appointment
    return (
      <AouDashboardCard
        icon={<ReportIcon iconSrc={cardIconSrc} />}
        title={messages.resultsTitle[moduleType]}
        subtitle={messages.hdrGcBlockedCardSubtitle}
      >
        <AppointmentsSummarySection
          moduleType={moduleType}
          viewedResults={!!preResultsScreenWasCompleted}
          isPositive={isHdrModuleType && numberOfGeneReports > 0}
        />
      </AouDashboardCard>
    );
  }
  if (participantCompletedInformingLoop && participantConsentedToInformingLoop && resultsAreReady) {
    // We show a card linking the participant to the pre results screen if they haven't completed it yet,
    // or a link directly to the results if they have completed it.
    return (
      <AouDashboardCard
        icon={<ReportIcon iconSrc={cardIconSrc} />}
        title={messages.resultsTitle[moduleType]}
        subtitle={
          numberOfGeneReports > 1 ? (
            <FormattedMessage
              id="HDR_positive_multiple_dashboard"
              values={{ resultNumber: numberOfGeneReports }}
            />
          ) : (
            messages.resultsReadySubtitle[moduleType]
          )
        }
        buttonLinkTo={
          preResultsScreenWasCompleted
            ? HEALTH_RELATED_RESULTS_PATHS[moduleType]
            : {
                pathname: isHdrModuleType
                  ? HDR_PRE_RESULTS_SCREEN_PATH
                  : PGX_PRE_RESULTS_SCREEN_PATH,
                state: { backgroundLocation: location },
              }
        }
        buttonContent={messages.viewResults}
        buttonAriaLabel={messages.viewResultsAccessibleLabelText[moduleType]}
        buttonVariant={preResultsScreenWasCompleted ? 'color-secondary' : 'color-primary'}
      >
        <AppointmentsSummarySection
          moduleType={moduleType}
          viewedResults={!!preResultsScreenWasCompleted}
          isPositive={isHdrModuleType && numberOfGeneReports > 0}
        />
      </AouDashboardCard>
    );
  }
  return null;
};
