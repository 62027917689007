import { ExternalLink, Spacer, Text } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { GpTooltip } from 'components/common/GpTooltip/GpTooltip';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { HDR_GENES_LIST_URLS } from 'lib/constants';

import { HDR_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  hdrILModule8PageTitle: {
    description: 'HDR Informing Loop Page Title - Module 8',
    defaultMessage: 'Choices',
  },
  hdrIlTooltipLabelHealthRelatedGenesList: {
    description: 'HDR Informing Loop Health-Related Genes List tooltip accessible label',
    defaultMessage: 'Health-Related Genes List Info',
  },
});

export const HdrInformingLoopModule8: React.FC = () => {
  const intl = useIntl();
  const { locale } = usePreferredLocale();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.hdrILModule8PageTitle)}
      module={{
        analyticsInformingLoopName: HDR_INFORMING_LOOP_NAME,
        analyticsPageName: '8. Options',
        image: CircularImageType.RADIO_BUTTONS_RED,
        title: (
          <FormattedMessage
            description="HDR informing loop page 8 title"
            defaultMessage="What are my choices?"
          />
        ),
        content: (
          <>
            <Text paragraph>
              <FormattedMessage id="HDR_IL_8__Paragraph_1" />
            </Text>
            <Text paragraph>
              <FormattedMessage id="HDR_IL_8__Paragraph_2" />
              <ul>
                <li>
                  <FormattedMessage
                    description="HDR informing loop page 8 content paragraph 2 bullet 1"
                    defaultMessage="One of our specially trained scientists will look closely at some of the genes in your DNA related to serious health conditions, like certain kinds of cancer and heart disease. {tooltip} They will generate results for you based on what they find."
                    values={{
                      tooltip: (
                        <GpTooltip
                          aria-label={intl.formatMessage(
                            messages.hdrIlTooltipLabelHealthRelatedGenesList
                          )}
                          content={
                            <Text>
                              <FormattedMessage
                                id="HDR_IL_8__Paragraph_2_Bullet_1_Tooltip"
                                values={{
                                  genesListLink: (
                                    <ExternalLink to={HDR_GENES_LIST_URLS[locale]}>
                                      <FormattedMessage
                                        description="HDR informing loop page 8 paragraph 2 bullet 1 tooltip genes list link"
                                        defaultMessage="here"
                                      />
                                    </ExternalLink>
                                  ),
                                }}
                              />
                            </Text>
                          }
                        />
                      ),
                    }}
                  />
                </li>
              </ul>
            </Text>
            <Text paragraph>
              <FormattedMessage id="HDR_IL_8__Paragraph_3" />
              <ul>
                <li>
                  <FormattedMessage
                    description="HDR informing loop page 8 content paragraph 3 bullet 1"
                    defaultMessage="No one at <i>All of Us</i> will look at your DNA in this way."
                  />
                </li>
              </ul>
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="HDR informing loop page 8 content paragraph 4"
                defaultMessage="If you say “<b>I’m not sure right now</b>”:"
              />
              <ul>
                <li>
                  <FormattedMessage
                    description="HDR informing loop page 8 content paragraph 3 bullet 1"
                    defaultMessage="You can come back and decide later."
                  />
                </li>
                <Spacer variant="small" />
                <li>
                  <FormattedMessage
                    description="HDR informing loop page 8 content paragraph 3 bullet 1"
                    defaultMessage="We won’t generate your personalized results until you tell us you want us to."
                  />
                </li>
              </ul>
            </Text>
            <Spacer variant="xSmall" />
            <Text>
              <FormattedMessage id="HDR_IL_8__Paragraph_4" />
            </Text>
          </>
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
