import { CookiesProvider } from '@color/lib';
import { HelmetProvider } from 'react-helmet-async';

import { AppAuthProvider } from './AppAuthProvider';
import { AppIntlProvider } from './AppIntlProvider';
import { AppQueryClientProvider } from './AppQueryClientProvider';
import { AppThemeProvider } from './AppThemeProvider';

interface Props {
  children?: React.ReactNode;
}

export const AppProviders: React.FC<Props> = (props) => {
  const { children } = props;
  return (
    <HelmetProvider>
      <CookiesProvider>
        <AppThemeProvider>
          <AppQueryClientProvider>
            <AppAuthProvider>
              <AppIntlProvider>{children}</AppIntlProvider>
            </AppAuthProvider>
          </AppQueryClientProvider>
        </AppThemeProvider>
      </CookiesProvider>
    </HelmetProvider>
  );
};
