import { ITextProps, Text } from '@color/continuum';

export const FooterText: React.FC<ITextProps> = (props) => {
  return (
    <Text
      fontSize={{
        xs: 14,
        md: 16,
      }}
      lineHeight={{
        xs: 1,
        md: 1.5,
      }}
      {...props}
    />
  );
};
