import { Box, Container, Spacer, Text, Title } from '@color/continuum';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export interface ReferencesProps {
  references: ReactNode[];
}

export const References: React.FC<ReferencesProps> = (props: ReferencesProps) => {
  const { references } = props;
  return (
    <Container>
      <Spacer variant="xLarge" />
      <Spacer variant="xLarge" />
      <Title
        variant="headline3"
        sx={(theme) => ({
          // FIXME - I'd look at simply setting the `Title` color prop to `secondary`
          color: theme.palette.text.secondary,
        })}
      >
        <FormattedMessage description="References section title" defaultMessage="References" />
      </Title>
      <Box
        component="ol"
        sx={(theme) => ({
          color: theme.palette.text.secondary,
        })}
      >
        {references.map((reference, index) => (
          <li key={index}>
            <Text>{reference}</Text>
          </li>
        ))}
      </Box>
      <Spacer variant="xLarge" />
      <Spacer variant="xLarge" />
    </Container>
  );
};
