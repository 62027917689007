import { Box, Divider, IMuiTheme, Spacer } from '@color/continuum';

import allOfUsLogoSquare from 'assets/images/icons/allofus-logo-square.svg';
import nihLogoShort from 'assets/images/icons/nih-logo-short.svg';

import { FooterContactLinks } from './common/FooterContactLinks';
import { FooterContainer } from './common/FooterContainer';
import { FooterDisclaimerText } from './common/FooterDisclaimerText';
import { FooterFollowUsLinks } from './common/FooterFollowUsLinks';
import { FooterLearnMoreLinks } from './common/FooterLearnMoreLinks';
import { FooterPolicyLinks } from './common/FooterPolicyLinks';
import { FooterText } from './common/FooterText';
import { useFooterContent } from './content';

const FOOTER_SECTION_DIVIDER_SX = (theme: IMuiTheme) => ({
  backgroundColor: theme.palette.primary.contrastText,
});

export const MobileFooter = () => {
  const footerContent = useFooterContent();
  return (
    <FooterContainer>
      <Box
        sx={{
          padding: 2,
          paddingBottom: 1,
        }}
      >
        <FooterText>{footerContent.contactSectionTitle.toUpperCase()}</FooterText>
        <Spacer variant="medium" />
        <FooterContactLinks />

        <Spacer variant="medium" />
        <Divider aria-hidden="true" sx={FOOTER_SECTION_DIVIDER_SX} />
        <Spacer variant="medium" />

        <FooterText>{footerContent.learnMoreSectionTitle.toUpperCase()}</FooterText>
        <Spacer variant="medium" />
        <FooterLearnMoreLinks />

        <Spacer variant="medium" />
        <Divider aria-hidden="true" sx={FOOTER_SECTION_DIVIDER_SX} />
        <Spacer variant="medium" />

        <FooterText>{footerContent.followUsSectionTitle.toUpperCase()}</FooterText>
        <Spacer variant="medium" />
        <FooterFollowUsLinks />
        <Spacer variant="medium" />
        <Box display="flex" justifyContent="flex-start">
          <Box pr={4}>
            <img src={allOfUsLogoSquare} alt="All of Us logo" />
          </Box>
          <Box pt={3}>
            <img src={nihLogoShort} alt="NIH logo" />
          </Box>
        </Box>
        <Spacer variant="medium" />
        <FooterDisclaimerText>{footerContent.disclaimerText}</FooterDisclaimerText>
      </Box>
      <FooterPolicyLinks />
    </FooterContainer>
  );
};
