import { kebabCase } from 'lodash';
import requireContext from 'require-context.macro';

import { ReportIconType } from './types';

const ReportIcons = requireContext('../../../../assets/images/result-card-icons', true, /\.svg$/);

export const getReportIconSrc = (icon: ReportIconType): string => {
  return ReportIcons(`./${kebabCase(icon)}.svg`).default;
};
