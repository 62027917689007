import { Box, Container, Grid, IconButton, Spacer } from '@color/continuum';
import CloseIcon from '@mui/icons-material/Close';
import { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { LocationWithBackground } from 'components/common/types';
import { config } from 'config';
import { trackAnalyticsEvent } from 'lib/analytics';
import { AnalyticsPlatform } from 'lib/analytics/types';

import { FlowProgressBar } from '../ProgressBar/FlowProgressBar';
import { SimplePage } from '../SimplePage/SimplePage';
import {
  InformingLoopLayout,
  InformingLoopLayoutProps,
} from './InformingLoopLayout/InformingLoopLayout';
import { InformingLoopQuestionsSection } from './InformingLoopQuestionsSection/InformingLoopQuestionsSection';

const messages = defineMessages({
  exitButton: {
    defaultMessage: 'Exit Screen',
    description: 'Dialog exit button text for screen readers',
  },
});

export interface InformingLoopModuleProps {
  helmetTitle: string;
  module: InformingLoopLayoutProps;
  footer?: ReactNode;
}

// TODO @rymarren: Reconsider the name of this component as it is confusing, it doesn't convery that this
// is a full page with navigation buttons. Something like 'InformingLoopPage' could be better.
export const InformingLoopModule: React.FC<InformingLoopModuleProps> = (
  props: InformingLoopModuleProps
) => {
  const { helmetTitle, module, footer } = props;
  const { state } = useLocation<LocationWithBackground>();
  const history = useHistory();
  const intl = useIntl();

  return (
    <SimplePage helmetTitle={helmetTitle}>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          height: '100%',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            // We use Material UI's Grid `spacing` prop in some components in GP.
            // Unfortunately, this can cause negative margin to extend beyond the <body>,
            // creating extra horizontal scroll in smaller screen sizes.
            // To fix this, we can add `overflow-x: hidden;` to the parent container.
            // https://v4.mui.com/components/grid/#negative-margin
            height: '100%',
            overflowX: 'hidden',
          }}
        >
          <FlowProgressBar />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              overflowX: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid container wrap="wrap-reverse">
              <Grid item xs={12} md={11}>
                <InformingLoopLayout {...module} />
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={1}
                alignContent="flex-start"
                justifyContent="flex-end"
                paddingRight={2}
                paddingTop={2}
              >
                <IconButton
                  aria-label={intl.formatMessage(messages.exitButton)}
                  onClick={() => {
                    if (module.analyticsInformingLoopName) {
                      trackAnalyticsEvent(
                        { eventType: `${module.analyticsInformingLoopName} - Closed` },
                        AnalyticsPlatform.SEGMENT
                      );
                    }
                    if (state?.backgroundLocation) {
                      history.push(state.backgroundLocation);
                    } else if (document.referrer?.startsWith(config.PARTICIPANT_PORTAL_URL.href)) {
                      window.location.href = document.referrer;
                    } else {
                      history.push('/');
                    }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Box flexGrow={1}>
              <Spacer variant="xLarge" />
            </Box>
            {/** We only show the 'Questions?' section with contact information if there is also a footer on the Informing Loop module. */}
            {footer && <InformingLoopQuestionsSection />}
          </Box>
          {footer && (
            <>
              <Spacer variant="medium" />
              {footer}
            </>
          )}
        </Box>
      </Container>
    </SimplePage>
  );
};
