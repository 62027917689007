import { useState } from 'react';

import { useQuizQuery } from './api';
import { Quiz } from './Quiz';
import { IFinishQuizScreenProps } from './types';
import { usePreviousQuizSession } from './usePreviousQuizSession';

interface Props {
  quizName: string;
  // An optional finish screen.
  FinishScreen?: React.FC<IFinishQuizScreenProps> | null;
  alreadyCompletedCallback?: () => void;
}

export const QuizContainer: React.FC<Props> = (props) => {
  const { quizName } = props;
  const quizQuery = useQuizQuery(quizName);
  const {
    previousAnswers,
    answersAreLoading,
    sessionId: previousSessionId,
  } = usePreviousQuizSession(quizName);
  const [sessionId, setSessionId] = useState<string | undefined>(previousSessionId);
  if (
    quizQuery.isLoading ||
    quizQuery.isError ||
    quizQuery.data! === undefined ||
    answersAreLoading
  ) {
    return null;
  }
  const { FinishScreen } = props;
  const quiz = quizQuery.data!;
  return (
    <Quiz
      quiz={quiz}
      initialAnswers={previousAnswers}
      FinishScreen={FinishScreen ?? null}
      sessionId={sessionId}
      setSessionId={setSessionId}
    />
  );
};
