import { ExternalLink, InternalButtonLink, Spacer, Text } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { config } from 'config';

import { PGX_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  pgxILYesPageTitle: {
    description: 'PGx Informing Loop Decision Page Title - Yes',
    defaultMessage: 'Decision - Yes',
  },
});

export const PgxInformingLoopDecisionYes: React.FC = () => {
  const { locale } = usePreferredLocale();
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.pgxILYesPageTitle)}
      module={{
        analyticsInformingLoopName: PGX_INFORMING_LOOP_NAME,
        analyticsPageName: '8. Yes',
        image: CircularImageType.GENES_DRUGS,
        title: <FormattedMessage id="PGX_IL_Yes__Title" />,
        content: (
          <>
            <Text bold paragraph>
              <FormattedMessage id="PGX_IL_Yes__Paragraph_1" />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="PGX informing loop yes decision page paragraph 2"
                defaultMessage="These results may take up to a few months to generate. We will notify you when your results are ready. In the meantime, you can learn more about DNA and medicine in the {learningCenterLink}."
                values={{
                  learningCenterLink: (
                    <ExternalLink to={config.LEARNING_CENTER_GENES_AND_MEDICINE_URL[locale].href}>
                      <FormattedMessage
                        description="Learning center link text"
                        defaultMessage="Learning Center"
                      />
                    </ExternalLink>
                  ),
                }}
              />
              <Spacer variant="medium" />
              <InternalButtonLink to="/">
                <FormattedMessage
                  defaultMessage="Back to DNA Results"
                  description="PGX informing loop final page yes decision content back to results button"
                />
              </InternalButtonLink>
            </Text>
          </>
        ),
      }}
    />
  );
};
