import { Box, Button, Container, FormikRadioItemsField, Spacer } from '@color/continuum';
import { Formik, Form as FormikForm } from 'formik';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { FormikSubmitButton } from 'components/SubmitButton';

import { IQuizChoice } from '../types';
import { QuestionFormProps } from './types';

function getChoicesByTitle(answerChoices: IQuizChoice[]): Record<string, IQuizChoice> {
  return answerChoices.reduce((map, answerChoice) => {
    return { ...map, [answerChoice.title]: answerChoice };
  }, {});
}
export const RadioItemsForm: React.FC<QuestionFormProps> = (props) => {
  const {
    question: { choices, isOptional },
    isLoading,
    originalAnswer,
    onSubmit,
    isLastQuizItem,
  } = props;

  return (
    <Formik
      initialValues={{
        picked: originalAnswer || '',
      }}
      validationSchema={Yup.object({
        picked: Yup.string().required(),
      })}
      onSubmit={({ picked }) => {
        const choicesByTitle = getChoicesByTitle(choices);
        let answerChoiceIds = [];
        if (Array.isArray(picked)) {
          answerChoiceIds = picked.map((pickedAnswer) => choicesByTitle[pickedAnswer].id);
        } else {
          answerChoiceIds = [choicesByTitle[picked].id];
        }
        onSubmit(picked, answerChoiceIds);
      }}
      validateOnMount
    >
      {() => (
        // FIXME: Remove the above function. It is a Formik anti-pattern. Instead, move the form into a new file.
        <Box
          component={FormikForm}
          sx={{
            flexGrow: 1, // grows to fill the remaining height of the quiz container, allowing the buttons to stay at the bottom.
            display: 'flex',
            flexFlow: 'column',
          }}
        >
          <Container
            sx={{
              // grows to fill the remaining height of the form, which makes the buttons stay at bottom.
              flexGrow: 1,
            }}
          >
            <FormikRadioItemsField
              fieldName="picked"
              options={choices.map((choice: IQuizChoice) => choice.title)}
            />
          </Container>
          <Spacer variant="xSmall" />
          <Box display="flex" gap={2} justifyContent="flex-end">
            {isOptional && (
              <Button size="large" variant="color-secondary" onClick={() => onSubmit('')}>
                <FormattedMessage
                  description="Button text for skipping questions within GP survey component"
                  defaultMessage="Skip"
                />
              </Button>
            )}
            <FormikSubmitButton isLoading={isLoading}>
              {isLastQuizItem ? (
                <FormattedMessage
                  description="Button text for submitting answers within GP survey component"
                  defaultMessage="Submit"
                />
              ) : (
                <FormattedMessage
                  description="Button text for going to next question within GP survey component"
                  defaultMessage="Next"
                />
              )}
            </FormikSubmitButton>
          </Box>
        </Box>
      )}
    </Formik>
  );
};
