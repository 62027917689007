import { Container, Spacer, Text, Title } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { Trait } from '../../types';
import { TRAIT_RESULTS_HEADER_CONTENT } from './content';

interface Props {
  trait: Trait;
}

export const TraitResultsHeader: React.FC<Props> = (props) => {
  const { trait } = props;
  const { title, subtitle } = TRAIT_RESULTS_HEADER_CONTENT[trait];

  return (
    <Container
      sx={{
        textAlign: 'center',
      }}
    >
      <Title>
        <FormattedMessage {...title} />
      </Title>
      <Spacer variant="small" />
      <Text>
        <FormattedMessage {...subtitle} />
      </Text>
    </Container>
  );
};
