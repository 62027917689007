import { ListItem, Spacer, Text, Title, UnorderedList } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { AccordionIconType } from '../components/AccordionWithIcon/AccordionIcon/types';
import { AccordionWithIcon } from '../components/AccordionWithIcon/AccordionWithIcon';

interface IMethodAndLimitationsProps {
  genomeCenterSite: string;
  cvlSite: string;
}

export const MethodsAndLimitations: React.FC<IMethodAndLimitationsProps> = (props) => {
  const { genomeCenterSite, cvlSite } = props;
  return (
    <AccordionWithIcon
      title={<FormattedMessage id="HDR_Uninformative__Methods_and_Limitations_Section_Title" />}
      icon={AccordionIconType.MICROSCOPE}
    >
      <Text paragraph>
        <FormattedMessage id="HDR_Uninformative__Methods_And_Limitations_Paragraph_1" />
      </Text>
      <Spacer variant="large" />
      <Title variant="headline3">
        <FormattedMessage id="HDR_Uninformative__Methods_And_Limitations_Methodology_Subsection_Title" />
      </Title>
      <Spacer variant="small" />
      <Text paragraph>
        <FormattedMessage
          id="HDR_Uninformative__Methodology_Paragraph_1"
          values={{
            genomeCenterSite,
            cvlSite,
          }}
        />
      </Text>
      <Text paragraph>
        <FormattedMessage id="HDR_Uninformative__Methodology_Paragraph_2" />
      </Text>
      <Text paragraph>
        <FormattedMessage id="HDR_Uninformative__Methodology_Paragraph_3" />
      </Text>
      <Spacer variant="large" />
      <Title variant="headline3">
        <FormattedMessage id="HDR_Uninformative__Methods_And_Limitations_Genes_And_Transcripts_Subsection_Title" />
      </Title>
      <Spacer variant="small" />
      <Text paragraph>
        <FormattedMessage id="HDR_Uninformative__Genes_And_Transcripts_Paragraph_1" />
      </Text>
      <Spacer variant="large" />
      <Title variant="headline3">
        <FormattedMessage id="HDR_Uninformative__Methods_And_Limitations_Limitations_Subsection_Title" />
      </Title>
      <Spacer variant="small" />
      <UnorderedList>
        <ListItem>
          <Text bold>
            <FormattedMessage id="HDR_Uninformative__Limitations_Bullet_1" />
          </Text>
        </ListItem>
        <ListItem>
          <Text bold>
            <FormattedMessage id="HDR_Uninformative__Limitations_Bullet_2" />
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <FormattedMessage id="HDR_Uninformative__Limitations_Bullet_3" />
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <FormattedMessage id="HDR_Uninformative__Limitations_Bullet_4" />
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <FormattedMessage id="HDR_Uninformative__Limitations_Bullet_5" />
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <FormattedMessage id="HDR_Uninformative__Limitations_Bullet_6" />
          </Text>
        </ListItem>
      </UnorderedList>
    </AccordionWithIcon>
  );
};
