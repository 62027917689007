import { Box, Container, Grid, Spacer, Text, Title, useTheme } from '@color/continuum';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export interface DisclaimerSectionProps {
  sections: {
    header: ReactNode | string;
    text: ReactNode | string;
  }[];
}

export const DisclaimerSection = (props: DisclaimerSectionProps) => {
  const { sections } = props;
  const theme = useTheme();
  const textColor = theme.palette.primary.contrastText;
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Spacer variant="xxxLarge" />
      <Container>
        <Title
          variant="headline3"
          sx={{
            color: textColor,
          }}
        >
          <FormattedMessage
            description="Disclaimer section title"
            defaultMessage="Important information"
          />
        </Title>
        <Spacer variant="small" />
        <Grid container spacing={4}>
          {sections.map((section, index: number) => (
            <Grid key={index} item xs={12} sm>
              <Text bold sx={{ color: textColor }}>
                {section.header}
              </Text>
              <Spacer variant="small" />
              <Text sx={{ color: textColor }}>{section.text}</Text>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Spacer variant="xxxLarge" />
    </Box>
  );
};
