import {
  ActionLink,
  ExternalLink,
  MuiAppBar,
  Toolbar,
  getMuiClassSelector,
  useScrollTrigger,
} from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { useAppAuthContext } from 'components/App/Providers/AppAuthProvider';
import { useConfigForIdp } from 'hooks/useConfigForIdp';

import { Logo } from './Logo';

export const Nav: React.FC = () => {
  const { isAuthenticated, logout } = useAppAuthContext();
  const { PARTICIPANT_PORTAL_URL } = useConfigForIdp();
  const isScrolledDown = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <MuiAppBar
      elevation={isScrolledDown ? 1 : 0}
      sx={{
        [getMuiClassSelector('&.MuiPaper-elevation1')]: {
          boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.12)',
        },
      }}
    >
      <Toolbar
        sx={(theme) => ({
          alignItems: 'center',
          background: theme.palette.common.white,
          height: '64px',
          justifyContent: 'space-between',
          paddingX: {
            xs: 2,
            sm: 4,
          },
        })}
      >
        <ExternalLink to={PARTICIPANT_PORTAL_URL.href} linkShouldOpenInCurrentTab>
          <Logo />
        </ExternalLink>
        {isAuthenticated && (
          <ActionLink onClick={() => logout()}>
            <FormattedMessage description="Log out button text" defaultMessage="Log Out" />
          </ActionLink>
        )}
      </Toolbar>
    </MuiAppBar>
  );
};
