import { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { api } from 'lib/api';
import { ApiError } from 'lib/api/types';

import { EmailPasswordPayload } from './types';

export function usePasswordLogin() {
  const history = useHistory();
  const { search } = useLocation();
  const queryClient = useQueryClient();

  const nextUrl = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('next') || '/';
  }, [search]);

  return useMutation<Response, ApiError, EmailPasswordPayload>(
    (json: EmailPasswordPayload) => api('sessions', { method: 'POST', json }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sessions');
        history.push(nextUrl);
      },
    }
  );
}
