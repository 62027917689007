import { RevisionReason } from '@color/aou-reports';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface IRevisionDescription {
  revisionReason: RevisionReason;
}
export const RevisionDescription: FC<IRevisionDescription> = (props) => {
  const { revisionReason } = props;
  if (revisionReason === RevisionReason.DOWNGRADE) {
    return <FormattedMessage id="Hdr_Revision_Downgrade" />;
  }
  if (revisionReason === RevisionReason.UPGRADE) {
    return <FormattedMessage id="Hdr_Revision_Upgrade" />;
  }
  return null;
};
