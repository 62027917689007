import { Container, Spacer, Text, Title } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { AccordionIconType } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionIcon/types';
import { AccordionWithIcon } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionWithIcon';

export const CommonQuestions: React.FC = () => (
  <Container>
    <AccordionWithIcon
      icon={AccordionIconType.QUESTION_MARK}
      title={<FormattedMessage id="PGX__Common_questions_section_title" />}
    >
      <Container>
        <Title variant="headline3">
          <FormattedMessage id="PGX__How_Do_I_Know_If_This_Matters_Subsection_Title" />
        </Title>
        <Spacer variant="medium" />
        <Text paragraph>
          <FormattedMessage id="PGX__How_Do_I_Know_If_This_Matters_Paragraph_1" />
        </Text>
        <Spacer variant="medium" />
        <Title variant="headline3">
          <FormattedMessage id="PGX__How_Did_Look_At_DNA_Subsection_Title" />
        </Title>
        <Spacer variant="medium" />
        <Text paragraph>
          <FormattedMessage id="PGX__How_Did_Look_At_DNA_Paragraph_1" />
        </Text>
        <Text paragraph>
          <FormattedMessage id="PGX__How_Did_Look_At_DNA_Paragraph_2" />
        </Text>
        <Spacer variant="medium" />
        <Title variant="headline3">
          <FormattedMessage id="PGX__What_Was_Done_To_Get_Result_Subsection_Title" />
        </Title>
        <Spacer variant="medium" />
        <Text paragraph>
          <FormattedMessage id="PGX__What_Was_Done_To_Get_Result_Paragraph_1" />
        </Text>
        <Spacer variant="medium" />
        <Title variant="headline3">
          <FormattedMessage id="PGX__What_does_this_mean_for_my_family_subsection_title" />
        </Title>
        <Spacer variant="medium" />
        <Text paragraph>
          <FormattedMessage id="PGX__What_does_this_mean_for_my_family_paragraph_1" />
        </Text>
        <Spacer variant="medium" />
        <Title variant="headline3">
          <FormattedMessage id="PGX__Could_my_result_change_subsection_title" />
        </Title>
        <Spacer variant="medium" />
        <Text paragraph>
          <FormattedMessage id="PGX__Could_my_result_change_paragraph_1" />
        </Text>
      </Container>
    </AccordionWithIcon>
  </Container>
);
