import { useLocation } from 'react-router-dom';

import { PGX_URI_POSTFIX } from 'components/Dashboard/constants';
import { ModuleType } from 'lib/analytics/types';
import { TIME_ZONE_TO_COLOR_CANONICAL_TIME_ZONE } from 'lib/constants';

export const useParticipantId = (): string | null =>
  new URLSearchParams(useLocation().search.slice(1)).get('pid');

export const getCustomTimezoneOffset = (currentTime: Date, timezone: string) => {
  const preferred = currentTime.toLocaleString('en-GB', { timeZone: timezone });
  // Use the hour off of the preferred timezone locale string as a number
  const preferredHour = Number(preferred.split(' ')[1].split(':')[0]);
  // Use the hour off of the UTC standard locale string as a number
  const utc = currentTime.toLocaleString('en-GB', { timeZone: 'UTC' });
  const utcHour = Number(utc.split(' ')[1].split(':')[0]);

  // If not the same day, add 24 hours
  const add24 = utc.split('/')[0] !== preferred.split('/')[0];

  // Calculate how far off the given timezone is from UTC
  return (add24 ? 24 : 0) + utcHour - preferredHour;
};

/**
 * @deprecated
 * Use common time utilities instead, like from `date-fns` or `date-fns-tz`, or Color's
 * standard libs.
 */
export const deprecatedFormatTime = (
  hours: number,
  timezone: string,
  currentTime: Date = new Date()
) => {
  // Get the difference in timezoneOffset from original timezoneOffset
  const timezoneOffset = getCustomTimezoneOffset(currentTime, timezone);
  const hourAdjustment =
    getCustomTimezoneOffset(currentTime, 'America/Los_Angeles') - timezoneOffset;

  const timezoneAdjustedHour = hours + hourAdjustment;
  const postfix = timezoneAdjustedHour > 11 ? 'PM' : 'AM';
  const formattedHours =
    timezoneAdjustedHour > 12 ? timezoneAdjustedHour - 12 : timezoneAdjustedHour;
  return `${formattedHours} ${postfix}`;
};

/**
 * Convert a tz database timezone to the canonical one that Color uses.
 *
 * Note, some timezones such as "CST6CDT" are canonical; however, Color always
 * uses "America/Chicago" as the canonical entry to represent CT.
 *
 * Values used are all from the 2022e release of the tz database
 * https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 */
export const convertToCanonicalTimezone = (timezone: string) => {
  if (!(timezone in TIME_ZONE_TO_COLOR_CANONICAL_TIME_ZONE)) {
    return 'America/Los_Angeles';
  }
  return TIME_ZONE_TO_COLOR_CANONICAL_TIME_ZONE[
    timezone as keyof typeof TIME_ZONE_TO_COLOR_CANONICAL_TIME_ZONE
  ];
};

/**
 * Hook that gets the the current ModuleType that is being
 * currently scheduled for based on the URL.
 *
 * It might be better for the scheduling components to be more 'dumb'
 * and get things passed down rather than requiring them to inspect
 * the current location.
 */
export const useModuleTypeBasedOnCurrentPath = () => {
  const { pathname } = useLocation();
  const postfix = pathname.split('/').slice(-1)[0];
  return postfix === PGX_URI_POSTFIX ? ModuleType.PGX : ModuleType.HDR;
};
