import { Container, Spacer, Text, Title } from '@color/continuum';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { BreadcrumbsBar } from 'components/common/BreadcrumbsBar';
import { SimplePage } from 'components/common/SimplePage/SimplePage';
import { useResultStatuses } from 'components/Dashboard/api';
import { useResultDecisionsQuery } from 'lib/analytics/result-decisions';
import { ModuleType } from 'lib/analytics/types';

import { HideOptionsDisclaimerSection } from './HideOptionsDisclaimerSection';
import { OptionsSection } from './OptionsSection';

const messages = defineMessages({
  optionsPageTitle: {
    description: 'Options Page Title',
    defaultMessage: 'Options for your DNA results',
  },
  breadcrumbLink: {
    description: 'Breadcrumbs link to GP options',
    defaultMessage: 'Options for your DNA results',
  },
});

export const OptionsForResults: React.FC = () => {
  const { gemResultsAreReady, pgxResultsAreReady, hdrResultsAreReady } = useResultStatuses();
  const { data, isLoading } = useResultDecisionsQuery();
  const intl = useIntl();

  if (isLoading) {
    return null;
  }

  const resultDecisions = data?.resultDecisions || [];
  const gemResultDecision = resultDecisions.find(({ moduleType }) => moduleType === ModuleType.GEM);
  const hdrResultDecision = resultDecisions.find(({ moduleType }) => moduleType === ModuleType.HDR);
  const pgxResultDecision = resultDecisions.find(({ moduleType }) => moduleType === ModuleType.PGX);

  return (
    <SimplePage helmetTitle={intl.formatMessage(messages.optionsPageTitle)}>
      <BreadcrumbsBar>
        <Text>
          <FormattedMessage
            description="Breadcrumbs link to GP options"
            defaultMessage="Options for your DNA results"
          />
        </Text>
      </BreadcrumbsBar>
      <Spacer variant="small" />
      <Container>
        <Title>
          <FormattedMessage {...messages.optionsPageTitle} />
        </Title>
        <Spacer variant="small" />
        <Text>
          <FormattedMessage
            description="Text at top of options page"
            defaultMessage="You can choose to show or hide each of your DNA results."
          />
        </Text>
        {gemResultsAreReady && gemResultDecision && (
          <OptionsSection moduleType={ModuleType.GEM} resultDecision={gemResultDecision} />
        )}
        {hdrResultsAreReady && hdrResultDecision && (
          <OptionsSection moduleType={ModuleType.HDR} resultDecision={hdrResultDecision} />
        )}
        {pgxResultsAreReady && pgxResultDecision && (
          <OptionsSection moduleType={ModuleType.PGX} resultDecision={pgxResultDecision} />
        )}
        <HideOptionsDisclaimerSection />
      </Container>
    </SimplePage>
  );
};
