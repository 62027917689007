import { HDR_REPORT_PATH } from 'components/App/AppRoutes/constants';

import { HdrPositiveTabIds } from './types';

export const HDR_POSITIVE_LOCATION_CONTROLLED_TABS_PROPS = {
  basePath: HDR_REPORT_PATH,
  tabIds: [
    HdrPositiveTabIds.YOUR_RESULTS,
    HdrPositiveTabIds.RISK_WITH_GENE,
    HdrPositiveTabIds.NEXT_STEPS,
  ],
  defaultTabId: HdrPositiveTabIds.YOUR_RESULTS,
};
