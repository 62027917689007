import {
  HdrV1VariantDetails,
  REPORTABLE_VARIANT_CLASSIFICATION_NAME,
  ZYGOSITY_NAME,
} from '@color/aou-reports';
import { Spacer, Table, TableBody, TableCell, TableRow, Text, Title } from '@color/continuum';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export interface ITechnicalReportTableProps {
  variant: HdrV1VariantDetails;
  variantNumber: number;
  isMultiVariant: boolean;
}

export const TechnicalReportTable: FC<ITechnicalReportTableProps> = (props) => {
  const { variant, variantNumber, isMultiVariant } = props;
  const intl = useIntl();

  const variantCellTitle = variant.pHgvs ? `${variant.cHgvs} (${variant.pHgvs})` : variant.cHgvs;

  return (
    <>
      <Table
        columns={[
          {
            id: 'gene',
            name: intl.formatMessage({
              id: 'HDR_Positive__Technical_Report_Gene_Header',
            }),
            isSortable: false,
            widthRatio: 0.2,
          },
          {
            id: 'variant',
            name: isMultiVariant
              ? `${intl.formatMessage({
                  id: 'HDR_Positive__Technical_Report_Variant_Header',
                })} ${variantNumber + 1}`
              : intl.formatMessage({
                  id: 'HDR_Positive__Technical_Report_Variant_Header',
                }),
            isSortable: false,
            widthRatio: 0.5,
          },
          {
            id: 'classification',
            name: intl.formatMessage({
              id: 'HDR_Positive__Technical_Report_Classification_Header',
            }),
            isSortable: false,
            widthRatio: 0.3,
          },
        ]}
        isLoading={false}
        tableOptions={{ sortDirections: {}, setSortDirections: () => null }}
      >
        <TableBody>
          <TableRow>
            <TableCell>
              <Text bold>
                <em>{variant.gene}</em>
              </Text>
            </TableCell>
            <TableCell>
              <Text>{variantCellTitle}</Text>
              <Spacer variant="xSmall" />
              <Text variant="body2">
                <FormattedMessage
                  id="HDR_Positive__Technical_Report_Gene_Transcript"
                  values={{ geneTranscript: variant.transcript }}
                />
              </Text>
              <Spacer variant="xSmall" />
              <Text variant="body2">
                <FormattedMessage
                  id="HDR_Positive__Technical_Report_Genomic_Coordinates"
                  values={{ genomicCoordinates: variant.gHgvs }}
                />
              </Text>
              <Spacer variant="xSmall" />
              <Text variant="body2">
                <FormattedMessage
                  id="HDR_Positive__Technical_Report_Variant_Zygosity"
                  values={{ variantZygosity: ZYGOSITY_NAME[variant.zygosity] }}
                />
              </Text>
            </TableCell>
            <TableCell>
              <Text bold>{REPORTABLE_VARIANT_CLASSIFICATION_NAME[variant.classification]}</Text>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Spacer variant="large" />
      <Title variant="headline3">
        <FormattedMessage id="HDR_Positive__Technical_Report_Supporting_Evidence_Subsection_Title" />
      </Title>
      <Spacer variant="small" />
      <Text paragraph>{variant.classificationWriteUp}</Text>
      <Spacer variant="large" />
    </>
  );
};
