import { Spacer, Text, Title } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { useAppAuthContext } from 'components/App/Providers/AppAuthProvider';

export const AncestryResultsHeader: React.FC = () => {
  const { currentUser } = useAppAuthContext();
  return (
    <>
      <Title align="center">
        <FormattedMessage
          description="Ancestry results overview page title"
          defaultMessage="Genetic ancestry"
        />
      </Title>
      <Spacer variant="small" />
      <Text variant="body1" align="center">
        <FormattedMessage
          description="Ancestry results overview page subtitle"
          defaultMessage="{firstName}, learn what your genes say about your ancestry."
          values={{
            firstName: currentUser?.firstName || (
              <FormattedMessage
                description="Placeholder user name when data is not available in the ancestry results header"
                defaultMessage="User"
              />
            ),
          }}
        />
      </Text>
    </>
  );
};
