import { useState } from 'react';

import { usePasswordLogin } from './api';

// TODO: Use Formik and add styles
export const PasswordLoginForm: React.FC = () => {
  const { mutate: colorLogin, isLoading, error } = usePasswordLogin();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const onSubmit = async () => colorLogin({ email, password });

  if (isLoading) {
    return <div>logging in...</div>;
  }

  return (
    <form onSubmit={onSubmit}>
      {error && <div>{error.message}</div>}
      <input
        type="email"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event?.target.value)}
        value={email}
      />
      <input
        type="password"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event?.target.value)}
        value={password}
      />
      <button type="submit">Login</button>
    </form>
  );
};
