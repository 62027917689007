import { Text } from '@color/continuum';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { GpTooltip } from 'components/common/GpTooltip/GpTooltip';

import { AncestryPopulationsContent, ParentPopulation, Subpopulation } from './types';

const messages = defineMessages({
  //
  // AMERICAS - AMR
  //
  populationsAmrTitle: {
    description: 'Americas population details title',
    defaultMessage: 'The Americas',
  },
  populationsAmrCarouselItem0Body: {
    description: 'Americas population details carousel item 0 body',
    defaultMessage:
      'Type “O” is the most common blood type among Indigenous Peoples of the Americas. It is found in nearly 100% of these groups in Central and South America.<sup>2</sup>',
  },
  populationsAmrCarouselItem1Body: {
    description: 'Americas population details carousel item 1 body',
    defaultMessage:
      'In western South America, people have lived in the Andes Mountains for generations. They have adapted to living at extreme heights. Many have more genetic variants {tooltip} linked to stronger hearts than people living at lower altitudes.<sup>3</sup>',
  },
  carouselGeneticVariantsTooltipText: {
    description: 'Tooltip text explaining what a genetic variant is used in ancestry carousels',
    defaultMessage:
      'Genetic variants are differences in our genes that make us unique. Everyone has millions of variants in their DNA. They influence things like the way we look, the foods we like or dislike, and how our bodies work. Genetic variants are usually inherited from our parents. This is why we have so much in common with members of our family.',
  },
  carouselGeneticVariantsTooltipLabel: {
    description: 'Genetic Variants accessible tooltip label',
    defaultMessage: 'Genetic Variants Info',
  },
  populationsAmrCarouselItem2Body: {
    description: 'Americas population details carousel item 2 body',
    defaultMessage:
      'The Rarámuri are a group of people native to northern Mexico. They are known for their ability to run long distances. Many have genetic variants {tooltip} that are associated with being very athletic.<sup>4</sup>',
  },
  tooltipLabelAmrBeringLandBridge: {
    description: 'Americas population Bering Land Bridge accessible tooltip label',
    defaultMessage: 'Bering Land Bridge Info',
  },
  //
  // EUROPE - EUR
  //
  populationsEurTitle: {
    description: 'Europe population details title',
    defaultMessage: 'Europe',
  },
  populationsEurFactsItem0: {
    description: 'Europe population facts item 0',
    defaultMessage:
      'People with ancestors from central Asia, including present-day countries such as Afghanistan and Pakistan, may have patterns of DNA from this genetic ancestry group. This may be because of the silk and horse trade across North Europe to India. This trade route, called the Steppe Route, facilitated the migration and mixing of peoples across 6,200 miles.',
  },
  tooltipLabelEurAnatolia: {
    description: 'Europe population Anatolia accessible tooltip label',
    defaultMessage: 'Anatolia Info',
  },
  tooltipLabelEurEurasianSteppe: {
    description: 'Europe population Eurasian Steppe accessible tooltip label',
    defaultMessage: 'Eurasian Steppe Info',
  },
  //
  // SOUTHERN_AND_WESTERN_ASIA - SAS
  //
  populationsSasTitle: {
    description: 'Southern asia details title',
    defaultMessage: 'Southern Asia',
  },
  //
  // SUB_SAHARAN_AFRICA - AFR
  //
  populationsAfrTitle: {
    description: 'Sub saharan africa details title',
    defaultMessage: 'Sub-Saharan Africa',
  },
  //
  // OCEANIA
  //
  populationsOceaniaTitle: {
    description: 'Oceania details title',
    defaultMessage: 'Oceania',
  },
  tooltipLabelOceaniaPolynesia: {
    description: 'Oceania population Polynesia accessible tooltip label',
    defaultMessage: 'Polynesia Info',
  },
  tooltipLabelOceaniaMicronesia: {
    description: 'Oceania population Micronesia accessible tooltip label',
    defaultMessage: 'Micronesia Info',
  },
  tooltipLabelOceaniaRemote: {
    description: 'Oceania population Remote Oceania accessible tooltip label',
    defaultMessage: 'Remote Oceania Info',
  },
  //
  // MIDDLE_EAST_AND_NORTH_AFRICA
  //
  populationsMiddleEasternAndNorthAfricanTitle: {
    description: 'Middle eastern and north african title',
    defaultMessage: 'The Middle East and North Africa',
  },
  tooltipLabelMiddleEasternAndNorthAfricanCaucasus: {
    description: 'Middle eastern and north african population Caucasus accessible tooltip label',
    defaultMessage: 'Caucasus Info',
  },
  //
  // EAST_AND_NORTH_ASIA - EAS
  //
  populationsEasTitle: {
    description: 'East and north asia details title',
    defaultMessage: 'Eastern and Northern Asia',
  },
});

export function useAncestryPopulationsContent(): AncestryPopulationsContent {
  const intl = useIntl();
  return {
    populations: {
      [ParentPopulation.AMERICAS]: {
        title: intl.formatMessage(messages.populationsAmrTitle),
        descriptions: [
          <FormattedMessage
            key={0}
            description="Americas population details descriptions item 0"
            defaultMessage="<p>This group represents Indigenous Peoples from these areas:</p><ul><li>North America</li><li>Central America</li><li>South America</li></ul><p>Indigenous Peoples have lived in the Americas for at least 15,000–20,000 years. Some may have come by crossing the <b>Bering Land Bridge</b>. {tooltip} Others may have arrived by boat or come earlier by other routes.</p>"
            values={{
              tooltip: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.tooltipLabelAmrBeringLandBridge)}
                  content={
                    <Text>
                      <FormattedMessage
                        description="Americas population details descriptions item 0 tooltip"
                        defaultMessage="The last ice age was about 16,000 years ago. During this time, sea levels dropped and revealed land that used to be under water. One such strip of land connected what is now Siberia and Alaska. This land, known as the Bering Land Bridge, served as one path for people to migrate to the Americas."
                      />
                    </Text>
                  }
                />
              ),
            }}
          />,
        ],
        facts: [
          <FormattedMessage
            key={0}
            description="Americas population details facts item 0"
            defaultMessage="<p>People with ancestors from the places below may have patterns of DNA from the Americas:</p><ul><li>The Caribbean and Latin America</li><li>The United States and Canada</li><li>The Philippines</li></ul><p>The people in the Americas have diverse origin stories. DNA can help show how they moved around. For example, DNA studies show that people in North America split from those in Central and South America about 14,000 years ago.<sup>1</sup> Later, when people from the Americas moved to the Philippines during Spanish rule, they brought their DNA there too.</p>"
          />,
        ],
        carousel: [
          <FormattedMessage
            key={0}
            description="Americas population details carousel item 0 body"
            defaultMessage="Type “O” is the most common blood type among Indigenous Peoples of the Americas. It is found in nearly 100% of these groups in Central and South America.<sup>2</sup>"
          />,
          <FormattedMessage
            key={1}
            description="Americas population details carousel item 1 body"
            defaultMessage="In western South America, people have lived in the Andes Mountains for generations. They have adapted to living at extreme heights. Many have more genetic variants {tooltip} linked to stronger hearts than people living at lower altitudes.<sup>3</sup>"
            values={{
              tooltip: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.carouselGeneticVariantsTooltipLabel)}
                  content={
                    <Text>{intl.formatMessage(messages.carouselGeneticVariantsTooltipText)}</Text>
                  }
                />
              ),
            }}
          />,
          <FormattedMessage
            key={2}
            description="Americas population details carousel item 2 body"
            defaultMessage="The Rarámuri are a group of people native to northern Mexico. They are known for their ability to run long distances. Many have genetic variants {tooltip} that are associated with being very athletic.<sup>4</sup>"
            values={{
              tooltip: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.carouselGeneticVariantsTooltipLabel)}
                  content={
                    <Text>{intl.formatMessage(messages.carouselGeneticVariantsTooltipText)}</Text>
                  }
                />
              ),
            }}
          />,
        ],
      },
      [ParentPopulation.EUROPE]: {
        title: intl.formatMessage(messages.populationsEurTitle),
        descriptions: [
          <FormattedMessage
            key={0}
            description="Europe population details descriptions item 0"
            defaultMessage="<p>This genetic ancestry group represents people from these areas:</p><ul><li>Europe</li><li>Russia</li><li>The Mediterranean</li></ul><p>It also includes those of Ashkenazi Jewish ancestry. For many historical and cultural reasons, people who share this ethnicity also share patterns of DNA.</p><p>People in the European genetic ancestry group are a combination of at least three different populations that mixed sometime within the last 7,000 years:</p><ul><li>Hunter-gatherers who migrated from the Middle East</li><li>Early farmers who migrated from Anatolia {tooltip0}</li><li>Livestock herders who migrated from the Eurasian Steppe {tooltip1}</li></ul>"
            values={{
              tooltip0: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.tooltipLabelEurAnatolia)}
                  content={
                    <Text>
                      <FormattedMessage
                        description="Europe population details descriptions item 0 tooltip 0"
                        defaultMessage="Anatolia is a central plateau region in the most western part of Asia. It makes up the majority of present-day Turkey."
                      />
                    </Text>
                  }
                />
              ),
              tooltip1: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.tooltipLabelEurEurasianSteppe)}
                  content={
                    <Text>
                      <FormattedMessage
                        description="Europe population details descriptions item 0 tooltip 1"
                        defaultMessage="The Eurasian Steppe is a region of grassland plains that stretches from Eastern Europe through central Asia to China."
                      />
                    </Text>
                  }
                />
              ),
            }}
          />,
        ],
        facts: [
          <Text key={0}>
            <FormattedMessage
              description="Europe population facts item 0"
              defaultMessage="People with ancestors from central Asia, including present-day countries such as Afghanistan and Pakistan, may have patterns of DNA from this genetic ancestry group. This may be because of the silk and horse trade across North Europe to India. This trade route, called the Steppe Route, facilitated the migration and mixing of peoples across 6,200 miles."
            />
          </Text>,
        ],
        carousel: [
          <FormattedMessage
            key={0}
            description="Europe population carousel item 0 body"
            defaultMessage="Many ancient Europeans had dark skin because it protected them from the sun when they lived in the hot, sunny climate of Africa. Scientists think that modern Europeans developed lighter skin within the last 7,000 years in order to better absorb vitamin D from the sun once they migrated to the cloudier climates of what’s now Europe.<sup>8</sup>"
          />,
          <FormattedMessage
            key={1}
            description="Europe population carousel item 1 body"
            defaultMessage="Red hair occurs naturally in 1–2% of the human population. But it’s more common (2–6%) in people with northern or southwestern European genetic ancestry.<sup>9</sup>"
          />,
          <FormattedMessage
            key={2}
            description="Europe population carousel item 2 body"
            defaultMessage="Type “A” blood is commonly found in people from Scandinavia, whereas Type “O” blood is commonly found in people from West Europe.<sup>2</sup>"
          />,
        ],
      },
      [ParentPopulation.SOUTHERN_AND_WESTERN_ASIA]: {
        title: intl.formatMessage(messages.populationsSasTitle),
        descriptions: [
          <FormattedMessage
            key={0}
            description="Southern asia descriptions item 0"
            defaultMessage="<p>This genetic ancestry group represents people from these areas:</p><ul><li>The continent of Asia south of the Himalayas</li><li>India</li><li>Pakistan</li><li>The West Asian highlands</li><li>The Arabian Peninsula</li></ul>"
          />,
        ],
        facts: [
          <Text key={0}>
            <FormattedMessage
              description="Southern asia population facts item 0"
              defaultMessage="People with recent ancestors from the Middle East and Mediterranean may have patterns of DNA from this genetic ancestry group. This is likely because there was so much trade and migration between South Asia, West Asia, the Middle East, and the Mediterranean over the last 2,000 years. The Silk Road, founded as early as 5,000 BC, connected Asia, Europe, and the Middle East over nearly 4,000 miles."
            />
          </Text>,
        ],
        carousel: [
          <FormattedMessage
            key={0}
            description="Southern asia population carousel item 0"
            defaultMessage="Type “B” blood is relatively common in people from South Asia and is found in up to 25% of people from India.<sup>2</sup>"
          />,
          <FormattedMessage
            key={1}
            description="Southern asia population carousel item 1"
            defaultMessage="Lactose intolerance is three times more common in people with South Asian genetic ancestry than in other populations.<sup>17</sup>"
          />,

          <FormattedMessage
            key={2}
            description="Southern asia population carousel item 2"
            defaultMessage="A genetic variant {tooltip} that protects people from developing leprosy arose in South Asia around 50,000 years ago.<sup>18</sup>"
            values={{
              tooltip: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.carouselGeneticVariantsTooltipLabel)}
                  content={
                    <Text>{intl.formatMessage(messages.carouselGeneticVariantsTooltipText)}</Text>
                  }
                />
              ),
            }}
          />,
        ],
      },
      [ParentPopulation.SUB_SAHARAN_AFRICA]: {
        title: intl.formatMessage(messages.populationsAfrTitle),
        descriptions: [
          <FormattedMessage
            key={0}
            description="Sub saharan africa descriptions item 0"
            defaultMessage="<p>This genetic ancestry group represents a number of distinct groups of people from the continent of Africa, south of the Sahara Desert.</p><p>Present-day humans originated in sub-Saharan Africa around 250,000 years ago and migrated from Africa to Europe and Asia.</p>"
          />,
        ],
        facts: [
          <FormattedMessage
            key={0}
            description="Sub saharan africa facts item 0"
            defaultMessage="<p>People from Latin America and the United States may have patterns of DNA from this genetic ancestry group. This is because millions of Africans were forced to be slaves in the Americas between 1500 and 1865.</p><p>People with recent ancestors from North Africa may also have patterns of DNA from this genetic ancestry group. This is likely because of the gold and salt trade across the Sahara Desert from the 8th until the early 17th century.</p>"
          />,
        ],
        carousel: [
          <FormattedMessage
            key={0}
            description="Sub saharan aftica carousel item 0"
            defaultMessage="A genetic variant {tooltip} originated in this ancestry group that causes sickle cell disease but also provides some protection against malaria, a mosquito-borne disease that is widespread in Africa.<sup>19</sup>"
            values={{
              tooltip: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.carouselGeneticVariantsTooltipLabel)}
                  content={
                    <Text>{intl.formatMessage(messages.carouselGeneticVariantsTooltipText)}</Text>
                  }
                />
              ),
            }}
          />,
          <FormattedMessage
            key={1}
            description="Sub saharan aftica carousel item 1"
            defaultMessage="Genetic variants {tooltip} in the <i>TAS2R38</i> gene likely give some people with African genetic ancestry a better sense of bitter tastes.<sup>20</sup>"
            values={{
              tooltip: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.carouselGeneticVariantsTooltipLabel)}
                  content={
                    <Text>{intl.formatMessage(messages.carouselGeneticVariantsTooltipText)}</Text>
                  }
                />
              ),
            }}
          />,
          <FormattedMessage
            key={2}
            description="Sub saharan aftica carousel item 2"
            defaultMessage="People with African genetic ancestry have a lower risk of developing skin cancers such as melanoma because their skin produces more melanin, which blocks damaging UV rays from the sun.<sup>21</sup>"
          />,
        ],
      },
      [ParentPopulation.OCEANIA]: {
        title: intl.formatMessage(messages.populationsOceaniaTitle),
        descriptions: [
          <FormattedMessage
            key={0}
            description="Oceania population descriptions item 0"
            defaultMessage="<p>This genetic ancestry group represents people from these areas:</p><ul><li>Papua New Guinea</li><li>Fiji</li><li>Melanesia</li><li>Other islands in the Pacific Ocean</li></ul><p>Oceania includes current-day Australasia, Melanesia, Micronesia, and Polynesia.</p>"
          />,
        ],
        facts: [
          <FormattedMessage
            key={0}
            description="Oceania facts item 0"
            defaultMessage="<p>People with ancestors from Polynesia {tooltip0} or Micronesia {tooltip1} may have patterns of DNA from both Oceanic and southeast Asian genetic ancestry groups.</p><p>Available research indicates that many people from Remote Oceania {tooltip2} came from Southeast Asia, mixing with people in Papua New Guinea before settling throughout the Pacific.<sup>13</sup> But this may not describe the origin stories of all peoples with ancestry here.</p>"
            values={{
              tooltip0: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.tooltipLabelOceaniaPolynesia)}
                  content={
                    <Text>
                      <FormattedMessage
                        description="Oceania facts item 0 tooltip 0"
                        defaultMessage="Polynesia is in eastern Oceania. It’s bordered by New Zealand, Hawaii, and Easter Island."
                      />
                    </Text>
                  }
                />
              ),
              tooltip1: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.tooltipLabelOceaniaMicronesia)}
                  content={
                    <Text>
                      <FormattedMessage
                        description="Oceania facts item 0 tooltip 1"
                        defaultMessage="Micronesia is in northwestern Oceania. It’s bordered by Palau, the Northern Mariana Islands, Wake Island, and Kiribati."
                      />
                    </Text>
                  }
                />
              ),
              tooltip2: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.tooltipLabelOceaniaRemote)}
                  content={
                    <Text>
                      <FormattedMessage
                        description="Oceania facts item 0 tooltip 2"
                        defaultMessage="Scientists believe Remote Oceania was likely settled within the last 3,500 years. It’s possible that there were people settled here even earlier. The region includes Oceania east of the Solomon Islands and the open Pacific."
                      />
                    </Text>
                  }
                />
              ),
            }}
          />,
        ],
        carousel: [
          <FormattedMessage
            key={0}
            description="Oceania carousel item 0 body"
            defaultMessage="A genetic variant {tooltip} in the TYRP1 gene causes blonde hair in 5–10% of Solomon Islanders. This is different from the genetic variant that causes blonde hair in Europeans.<sup>14</sup>"
            values={{
              tooltip: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.carouselGeneticVariantsTooltipLabel)}
                  content={
                    <Text>{intl.formatMessage(messages.carouselGeneticVariantsTooltipText)}</Text>
                  }
                />
              ),
            }}
          />,
          <FormattedMessage
            key={1}
            description="Oceania carousel item 1 body"
            defaultMessage="Total color blindness (achromatopsia) is common on the island of Pingelap. The genetic variant {tooltip} that causes this disease became more common in the population after a typhoon swept through Pingelap in 1775 and reduced the number of inhabitants.<sup>15</sup>"
            values={{
              tooltip: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.carouselGeneticVariantsTooltipLabel)}
                  content={
                    <Text>{intl.formatMessage(messages.carouselGeneticVariantsTooltipText)}</Text>
                  }
                />
              ),
            }}
          />,
          <FormattedMessage
            key={2}
            description="Oceania carousel item 2 body"
            defaultMessage="People from Oceania share more DNA with ancient ancestors of modern-day humans called the Denisovans than any other genetic ancestry groups do.<sup>16</sup>"
          />,
        ],
      },
      [ParentPopulation.MIDDLE_EAST_AND_NORTH_AFRICA]: {
        title: intl.formatMessage(messages.populationsMiddleEasternAndNorthAfricanTitle),
        descriptions: [
          <FormattedMessage
            key={0}
            description="Middle eastern and north african descriptions item 0"
            defaultMessage="<p>This genetic ancestry group represents people from these areas:</p><ul><li>The Middle East</li><li>North Africa</li><li>Western Asia</li><li>The Caucasus {tooltip}</li></ul>"
            values={{
              tooltip: (
                <GpTooltip
                  aria-label={intl.formatMessage(
                    messages.tooltipLabelMiddleEasternAndNorthAfricanCaucasus
                  )}
                  content={
                    <Text>
                      <FormattedMessage
                        description="Middle eastern and north african descriptions item 0 tooltip"
                        defaultMessage="The Caucasus is a mountain region that separates eastern Europe and western Asia. It includes the present-day countries of Armenia, Azerbaijan, Georgia, and Russia."
                      />
                    </Text>
                  }
                />
              ),
            }}
          />,
        ],
        facts: [
          <Text key={0}>
            <FormattedMessage
              description="Middle eastern and north african facts item 0"
              defaultMessage="People with recent ancestors from Asia, Europe, and sub-Saharan Africa may have patterns of DNA from this genetic ancestry group. This is likely because of significant trade and migration through the region that continues to this day. The Silk Road and Incense Route connected the Middle East and North Africa to Europe and Asia. Trans-Saharan trade routes connected North Africa to sub-Saharan Africa."
            />
          </Text>,
        ],
        carousel: [
          <FormattedMessage
            key={0}
            description="Middle eastern and north african carousel item 0"
            defaultMessage="The ability to digest milk is encoded in our genes. It’s believed to have originated in the Middle East and spread across North Africa in response to cattle and camel domestication.<sup>10</sup>"
          />,
          <FormattedMessage
            key={1}
            description="Middle eastern and north african carousel item 1"
            defaultMessage="The most common blood type in this region is “O” positive and is found in 32%–47% of people, depending on the country.<sup>11</sup>"
          />,
          <FormattedMessage
            key={2}
            description="Middle eastern and north african carousel item 2"
            defaultMessage="Scientists have discovered that different groups of ancient farmers from the Middle East have very different DNA. This means farming was likely invented multiple times by different groups.<sup>12</sup>"
          />,
        ],
      },
      [ParentPopulation.EAST_AND_NORTH_ASIA]: {
        title: intl.formatMessage(messages.populationsEasTitle),
        descriptions: [
          <FormattedMessage
            key={0}
            description="East and north asia descriptions item 0"
            defaultMessage="<p>This group represents people from these areas:</p><ul><li>Eastern and northern Asia including <b>China</b>, <b>Japan</b>, <b>Mongolia</b>, and <b>Siberia</b></li><li>The mainland and islands of <b>Southeast Asia</b> in the South China Sea</li></ul>"
          />,
        ],
        facts: [
          <Text key={0}>
            <FormattedMessage
              description="East and north asia facts item 0"
              defaultMessage="People with ancestors from Russia and northeastern Europe may have patterns of DNA from this genetic ancestry group. This may be because of contact along the historic Siberian Route that connected Russia to Siberia and China in the early 18th and 19th centuries."
            />
          </Text>,
        ],
        carousel: [
          <FormattedMessage
            key={0}
            description="East and north asia carousel item 0"
            defaultMessage="Genetic variants {tooltip} in the <i>PDE10A</i> gene give the Bajau people of Southeast Asia, also known as the “Sea Nomads,” a greater red blood cell supply. This allows them to hold their breath for long periods of time while freediving for food.<sup>5</sup>"
            values={{
              tooltip: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.carouselGeneticVariantsTooltipLabel)}
                  content={
                    <Text>{intl.formatMessage(messages.carouselGeneticVariantsTooltipText)}</Text>
                  }
                />
              ),
            }}
          />,
          <FormattedMessage
            key={1}
            description="East and north asia carousel item 1"
            defaultMessage="Modern East Asians have very similar DNA to ancient East Asians. Scientists believe this means there haven’t been any large Asian migrations within the last 7,000 years.<sup>6</sup>"
          />,
          <FormattedMessage
            key={2}
            description="East and north asia carousel item 2"
            defaultMessage="Type 1 diabetes occurs less frequently in people with Asian genetic ancestry, which is likely due to genetic variants {tooltip} that regulate the immune system.<sup>7</sup>"
            values={{
              tooltip: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.carouselGeneticVariantsTooltipLabel)}
                  content={
                    <Text>{intl.formatMessage(messages.carouselGeneticVariantsTooltipText)}</Text>
                  }
                />
              ),
            }}
          />,
        ],
      },
    },
    subpopulations: {
      [Subpopulation.EAST_AFRICA]: {
        title: (
          <FormattedMessage
            description="Subpopulations east africa title"
            defaultMessage="Central and East Africa"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations east africa.examples"
            defaultMessage="Such as Kenya, the Congo Basin, and Angola"
          />
        ),
      },
      [Subpopulation.FINLAND]: {
        title: (
          <FormattedMessage description="Subpopulations finland title" defaultMessage="Finland" />
        ),
      },
      [Subpopulation.IBERIA]: {
        title: (
          <FormattedMessage
            description="Subpopulations iberia title"
            defaultMessage="West Europe and Iberia"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations .iberia.examples"
            defaultMessage="Such as Spain, Portugal, parts of France and Italy, and North Africa"
          />
        ),
      },
      [Subpopulation.TUSCANY]: {
        title: (
          <FormattedMessage
            description="Subpopulations tuscany title"
            defaultMessage="Southern Europe and the Middle East"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations tuscany examples"
            defaultMessage="Such as those of Ashkenazi Jewish descent, Italy, the Mediterranean, and North Africa"
          />
        ),
      },
      [Subpopulation.EAST_CHINA]: {
        title: (
          <FormattedMessage
            description="Subpopulations east china title"
            defaultMessage="East Asia"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations east china examples"
            defaultMessage="Such as China"
          />
        ),
      },
      [Subpopulation.SOUTH_CHINA_AND_SOUTHEAST_ASIA]: {
        title: (
          <FormattedMessage
            description="Subpopulations south china and southeast asia title"
            defaultMessage="Southeast Asia"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations south china and southeast asia examples"
            defaultMessage="Such as southeast China, Thailand, Vietnam, and Indonesia"
          />
        ),
      },
      [Subpopulation.EAST_INDIA_AND_SRILANKA]: {
        title: (
          <FormattedMessage
            description="Subpopulations east india and srilanka title"
            defaultMessage="South and East India"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations east india and srilanka examples"
            defaultMessage="Such as India, Sri Lanka, Bangladesh, and Nepal"
          />
        ),
      },
      [Subpopulation.NORTH_INDIA_AND_WEST_ASIA]: {
        title: (
          <FormattedMessage
            description="Subpopulations north india and west asia title"
            defaultMessage="West Asia and North India"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations north india and west asia examples"
            defaultMessage="Such as Pakistan, India, and parts of Iran"
          />
        ),
      },
      [Subpopulation.NORTHWEST_ASIA]: {
        title: (
          <FormattedMessage
            description="Subpopulations northwest asia title"
            defaultMessage="Northwest Asia"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations northwest asia examples"
            defaultMessage="Such as Kazakhstan, Mongolia, and parts of Russia"
          />
        ),
      },
      [Subpopulation.BALKANS_AND_MEDITERRANEAN]: {
        title: (
          <FormattedMessage
            description="Subpopulations balkans and mediterranean title"
            defaultMessage="The Balkans and the Mediterranean"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations balkans and mediterranean examples"
            defaultMessage="Such as the Balkans, Italy, and Greece"
          />
        ),
      },
      [Subpopulation.CENTRAL_EUROPE]: {
        title: (
          <FormattedMessage
            description="Subpopulations central europe title"
            defaultMessage="Central Europe"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations central europe examples"
            defaultMessage="Such as Germany, France, Poland, and Hungary"
          />
        ),
      },
      [Subpopulation.NORTH_ITALY]: {
        title: (
          <FormattedMessage
            description="Subpopulations north italy title"
            defaultMessage="North Italy"
          />
        ),
      },
      [Subpopulation.NORTH_EUROPE]: {
        title: (
          <FormattedMessage
            description="Subpopulations northv europe title"
            defaultMessage="Northern and Central Europe"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations north europe examples"
            defaultMessage="Such as Ireland, the United Kingdom, Germany, France, Scandinavia, and parts of eastern Europe"
          />
        ),
      },
      [Subpopulation.WEST_EUROPE]: {
        title: (
          <FormattedMessage
            description="Subpopulations west europe title"
            defaultMessage="Southwestern Europe"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations west europe examples"
            defaultMessage="Such as Spain, Portugal, and parts of France"
          />
        ),
      },
      [Subpopulation.WEST_ASIA]: {
        title: (
          <FormattedMessage
            description="Subpopulations west asia title"
            defaultMessage="Western Asia and the Caucasus"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations west asia examples"
            defaultMessage="Such as Turkey, Iran, Syria, Iraq, and the Caucasus"
          />
        ),
      },
      [Subpopulation.NORTH_AFRICA]: {
        title: (
          <FormattedMessage
            description="Subpopulations north africa title"
            defaultMessage="Northern Africa"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations north africa examples"
            defaultMessage="Such as Morocco, Algeria, and Egypt"
          />
        ),
      },
      [Subpopulation.BANGLADESH_AND_BENGAL]: {
        title: (
          <FormattedMessage
            description="Subpopulations bangladesh and bengal title"
            defaultMessage="Bangladesh and Bengal"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations .bangladesh_and_bengal.examples"
            defaultMessage="Such as Bangladesh and parts of India"
          />
        ),
      },
      [Subpopulation.SOUTH_AND_EAST_INDIA]: {
        title: (
          <FormattedMessage
            description="Subpopulations south and east india title"
            defaultMessage="South and East India"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations south and east india examples"
            defaultMessage="Such as India, Sri Lanka, and Nepal"
          />
        ),
      },
      [Subpopulation.BALOCHISTAN]: {
        title: (
          <FormattedMessage
            description="Subpopulations balochistan title"
            defaultMessage="Balochistan"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations balochistan examples"
            defaultMessage="Such as Pakistan, Afghanistan, and the Arabian Peninsula"
          />
        ),
      },
      [Subpopulation.CENTRAL_AND_EAST_AFRICA]: {
        title: (
          <FormattedMessage
            description="Subpopulations central and east africa title"
            defaultMessage="Central and East Africa"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations central and east africa examples"
            defaultMessage="Such as Kenya, Uganda, the Congo Basin, and Angola"
          />
        ),
      },
      [Subpopulation.HORN_OF_AFRICA]: {
        title: (
          <FormattedMessage
            description="Subpopulations horn of africa title"
            defaultMessage="Horn of Africa"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations horn of africa examples"
            defaultMessage="Such as Ethiopia, Somalia, Kenya, and Tanzania"
          />
        ),
      },
      [Subpopulation.JAPAN]: {
        title: (
          <FormattedMessage
            description="Subpopulations japan title"
            defaultMessage="Japan and Korea"
          />
        ),
      },
      [Subpopulation.SOUTHEAST_ASIA]: {
        title: (
          <FormattedMessage
            description="Subpopulations southeast asia title"
            defaultMessage="Southeast Asia"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations southeast asia examples"
            defaultMessage="Such as South China, Thailand, Vietnam, and Indonesia"
          />
        ),
      },
      [Subpopulation.NORTHEAST_ASIA]: {
        title: (
          <FormattedMessage
            description="Subpopulations northeast asia title"
            defaultMessage="Northeastern Asia"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations northeast asia examples"
            defaultMessage="Such as northeastern China, Mongolia, and Siberia"
          />
        ),
      },
      [Subpopulation.CENTRAL_EAST_ASIA]: {
        title: (
          <FormattedMessage
            description="Subpopulations central east asia title"
            defaultMessage="Central Eastern Asia"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations central east asia examples"
            defaultMessage="Such as China, Mongolia, and Korea"
          />
        ),
      },
      [Subpopulation.ASHKENAZI_JEWISH]: {
        title: (
          <FormattedMessage
            description="Subpopulations ashkenazi jewish title"
            defaultMessage="Ashkenazi Jewish {tooltip}"
            values={{
              tooltip: (
                <GpTooltip
                  aria-label=""
                  content={
                    <Text>
                      <FormattedMessage
                        description="Subpopulations ashkenazi jewish title tooltip"
                        defaultMessage="It may seem strange that Ashkenazi Jewish is shown here, even though it’s not a geographical area. That’s because Jewish people left the Mediterranean area due to religious persecution in the Middle Ages. They settled all across northern and eastern Europe."
                      />
                    </Text>
                  }
                />
              ),
            }}
          />
        ),
      },
      [Subpopulation.EAST_EUROPE]: {
        title: (
          <FormattedMessage
            description="Subpopulations east europe title"
            defaultMessage="Eastern Europe"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations east europe examples"
            defaultMessage="Such as Poland, Ukraine, and Russia"
          />
        ),
      },
      [Subpopulation.SOUTH_EUROPE]: {
        title: (
          <FormattedMessage
            description="Subpopulations .south_europe title"
            defaultMessage="Southern Europe and the Mediterranean"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations .south_europe.examples"
            defaultMessage="Such as the Balkans, Italy, and Greece"
          />
        ),
      },
      [Subpopulation.MIDDLE_EAST]: {
        title: (
          <FormattedMessage
            description="Subpopulations middle east title"
            defaultMessage="The Middle East"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations middle east examples"
            defaultMessage="Such as the Arabian Peninsula and Egypt"
          />
        ),
      },
      [Subpopulation.NORTH_INDIA]: {
        title: (
          <FormattedMessage
            description="Subpopulations north india title"
            defaultMessage="Northern India and Pakistan"
          />
        ),
      },
      [Subpopulation.SOUTH_INDIA]: {
        title: (
          <FormattedMessage
            description="Subpopulations south india title"
            defaultMessage="Southern and Eastern India"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations south indian examples"
            defaultMessage="Such as India, Sri Lanka, and Nepal"
          />
        ),
      },
      [Subpopulation.EAST_INDIA_BENGAL]: {
        title: (
          <FormattedMessage
            description="Subpopulations east india bengal title"
            defaultMessage="Bangladesh and Bengal"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations east india bengal examples"
            defaultMessage="Such as Bangladesh and parts of India"
          />
        ),
      },
      [Subpopulation.WEST_AFRICA]: {
        title: (
          <FormattedMessage
            description="Subpopulations west africa title"
            defaultMessage="West Africa"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations west africa examples"
            defaultMessage="Such as Nigeria, Senegal, and Ghana"
          />
        ),
      },
      [Subpopulation.CENTRAL_EAST_AFRICA]: {
        title: (
          <FormattedMessage
            description="Subpopulations central east africa title"
            defaultMessage="Central and East Africa"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations central east africa examples"
            defaultMessage="Such as Kenya, Uganda, the Congo Basin, and Angola"
          />
        ),
      },
      [Subpopulation.MELANESIA]: {
        title: (
          <FormattedMessage
            description="Subpopulations melanesia title"
            defaultMessage="Melanesia"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations melanesia examples"
            defaultMessage="Such as Papua New Guinea and the Pacific Islands"
          />
        ),
      },
      [Subpopulation.INDIGENOUS_AMERICAS]: {
        title: (
          <FormattedMessage
            description="Subpopulations indigenous americas title"
            defaultMessage="The Americas"
          />
        ),
        examples: (
          <FormattedMessage
            description="Subpopulations indigenous americas examples"
            defaultMessage="Such as North, Central, and South America"
          />
        ),
      },
    },
  };
}
