import { Box, BoxProps } from '@color/continuum';

export const FooterContainer: React.FC<BoxProps> = (props) => {
  const { children } = props;

  return (
    <Box
      boxShadow={3}
      component="footer"
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.main,
      })}
    >
      {children}
    </Box>
  );
};
