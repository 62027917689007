import {
  HdrV1VariantDetails,
  MULTIPLE_DISORDER_HDR_V1_GENES,
  getGeneSpecificContent,
} from '@color/aou-reports';
import {
  Container,
  Divider,
  ImageDivider,
  ListItem,
  Spacer,
  Text,
  Title,
  UnorderedList,
} from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import DNABanner from 'assets/images/dna-banner.svg';
import { CircularImageType } from 'components/common/CircularImage/types';
import { References } from 'components/common/Reports/References';
import { ImageWithContentSection } from 'components/HealthRelatedResults/components/ImageWithContentSection/ImageWithContentSection';

import { DoctorsUseDna } from '../RiskCharts/DoctorsUseDna';
import { getSexAgnosticGeneSpecificRiskContent } from '../RiskCharts/getSexAgnosticGeneSpecificRiskContent';
import HdrV1RiskCharts from '../RiskCharts/HdrV1RiskCharts.json';
import { REFERENCES } from '../RiskCharts/references';
import { RiskCharts } from '../RiskCharts/RiskCharts';

interface IRiskWithGeneProps {
  firstVariantDetails: HdrV1VariantDetails;
  isMultiVariant: boolean;
}
export const RiskWithGeneTab: React.FC<IRiskWithGeneProps> = (props) => {
  const { firstVariantDetails, isMultiVariant } = props;
  const { gene, reportedDisorder } = firstVariantDetails;
  const { participantDisorder } = getGeneSpecificContent(gene, reportedDisorder);
  const referencesKey = MULTIPLE_DISORDER_HDR_V1_GENES.has(gene)
    ? `${gene}_${reportedDisorder}`
    : gene;
  const hasRiskCharts = gene in HdrV1RiskCharts;
  const hasReferences = REFERENCES.some((ref) => ref.gene === referencesKey);
  return (
    <>
      <Container>
        <Spacer variant="xxLarge" />
        <ImageWithContentSection
          title={
            <Title variant="headline2">
              <FormattedMessage
                id="HDR_Positive_Risk__Result_Does_Not_Mean_Header"
                values={{ gene }}
              />
            </Title>
          }
          description={
            <>
              <UnorderedList>
                <ListItem>
                  <Text>
                    <FormattedMessage
                      id="HDR_Positive_Risk__Result_Does_Not_Mean_P1"
                      values={{ participantDisorder }}
                    />
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    <FormattedMessage
                      id="HDR_Positive_Risk__Result_Does_Not_Mean_P2"
                      values={{ participantDisorder }}
                    />
                  </Text>
                </ListItem>
              </UnorderedList>
              <Spacer variant="small" />
              <Text paragraph>
                <FormattedMessage
                  id="HDR_Positive_Risk__Result_Does_Not_Mean_P3"
                  values={{ participantDisorder }}
                />
              </Text>
              {isMultiVariant && (
                <Text paragraph>
                  <FormattedMessage id="HDR_Positive_Risk__Result_Does_Not_Mean_P4" />
                </Text>
              )}
            </>
          }
          image={CircularImageType.GENES}
          imageRight
        />
        <Spacer variant="xxxLarge" />
        <Divider aria-hidden="true" />
        <Spacer variant="xLarge" />
        {hasRiskCharts && <RiskCharts gene={gene} />}
        {!hasRiskCharts && (
          <>
            <Title variant="headline2">
              <FormattedMessage id="HDR_Positive_Risk__Screen_If_Female_P2" />
            </Title>
            <Spacer variant="medium" />
            {getSexAgnosticGeneSpecificRiskContent(gene, reportedDisorder).riskAll}
          </>
        )}
      </Container>
      <Spacer variant="xxLarge" />
      <ImageDivider imageSrc={DNABanner} />
      <Spacer variant="xxLarge" />
      <DoctorsUseDna hasRiskCharts={hasRiskCharts} variantDetails={firstVariantDetails} />
      {isMultiVariant && (
        <Container>
          <Text paragraph align="center">
            <FormattedMessage id="HDR_Positive_Risk__Additional_Next_Step_Multi_Variant" />
          </Text>
        </Container>
      )}
      {hasReferences && (
        <References references={REFERENCES.find((ref) => ref.gene === referencesKey)!.refrences} />
      )}
    </>
  );
};
