import { Spacer, Text, Title } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { AccordionIconType } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionIcon/types';
import { AccordionWithIcon } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionWithIcon';

export const CommonQuestions: React.FC = () => (
  <AccordionWithIcon
    icon={AccordionIconType.QUESTION_MARK}
    title={<FormattedMessage id="HDR_Uninformative__Common_Questions_Section_Title" />}
  >
    <Title variant="headline3">
      <FormattedMessage id="HDR_Uninformative__What_Was_Done_Subsection_Title" />
    </Title>
    <Spacer variant="medium" />
    <Text paragraph>
      <FormattedMessage id="HDR_Uninformative__What_Was_Done_Paragraph_1" />
    </Text>

    <Spacer variant="medium" />

    <Title variant="headline3">
      <FormattedMessage id="HDR_Uninformative__What_Means_For_Family_Subsection_Title" />
    </Title>
    <Spacer variant="medium" />
    <Text paragraph>
      <FormattedMessage id="HDR_Uninformative__What_Means_For_Family_Paragraph_1" />
    </Text>

    <Spacer variant="medium" />

    <Title variant="headline3">
      <FormattedMessage id="HDR_Uninformative__What_Was_The_Point_Subsection_Title" />
    </Title>
    <Spacer variant="medium" />
    <Text paragraph>
      <FormattedMessage id="HDR_Uninformative__What_Was_The_Point_Paragraph_1" />
    </Text>
    <Text paragraph>
      <FormattedMessage id="HDR_Uninformative__What_Was_The_Point_Paragraph_2" />
    </Text>
    <Text paragraph>
      <FormattedMessage id="HDR_Uninformative__What_Was_Point_Paragraph_3" />
    </Text>
    <Spacer variant="medium" />

    <Title variant="headline3">
      <FormattedMessage id="HDR_Uninformative__Could_Results_Change_Subsection_Title" />
    </Title>
    <Spacer variant="medium" />
    <Text paragraph>
      <FormattedMessage id="HDR_Uninformative__Could_Results_Change_Paragraph_1" />
    </Text>
  </AccordionWithIcon>
);
