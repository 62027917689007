import { Table, TableBody, TableCell, TableRow, Text } from '@color/continuum';
import { FormattedMessage, useIntl } from 'react-intl';

export const PhenotypesTable: React.FC = () => {
  const intl = useIntl();
  return (
    <Table
      columns={[
        {
          id: 'term',
          name: intl.formatMessage({
            id: 'PGX__Phenotypes_table_header_term',
          }),
          isSortable: false,
        },
        {
          id: 'definition',
          name: intl.formatMessage({
            id: 'PGX__Phenotypes_table_header_definition',
          }),
          isSortable: false,
        },
      ]}
      isLoading={false}
      tableOptions={{ sortDirections: {}, setSortDirections: () => null }}
    >
      <TableBody>
        <TableRow sx={{ background: 'white' }}>
          <TableCell>
            <Text bold>
              <FormattedMessage id="PGX__normal_term" />
            </Text>
          </TableCell>
          <TableCell>
            <Text>
              <FormattedMessage id="PGX__normal_term_definition" />
            </Text>
          </TableCell>
        </TableRow>
        <TableRow sx={{ background: 'white' }}>
          <TableCell>
            <Text bold>
              <FormattedMessage id="PGX__intermediate_term" />
            </Text>
          </TableCell>
          <TableCell>
            <Text>
              <FormattedMessage id="PGX__intermediate_term_definition" />
            </Text>
          </TableCell>
        </TableRow>
        <TableRow sx={{ background: 'white' }}>
          <TableCell>
            <Text bold>
              <FormattedMessage id="PGX__variable_term" />
            </Text>
          </TableCell>
          <TableCell>
            <Text>
              <FormattedMessage id="PGX__variable_term_definition" />
            </Text>
          </TableCell>
        </TableRow>
        <TableRow sx={{ background: 'white' }}>
          <TableCell>
            <Text bold>
              <FormattedMessage id="PGX__poor_term" />
            </Text>
          </TableCell>
          <TableCell>
            <Text>
              <FormattedMessage id="PGX__poor_term_definition" />
            </Text>
          </TableCell>
        </TableRow>
        <TableRow sx={{ background: 'white' }}>
          <TableCell>
            <Text bold>
              <FormattedMessage id="PGX__rapid_term" />
            </Text>
          </TableCell>
          <TableCell>
            <Text>
              <FormattedMessage id="PGX__rapid_term_definition" />
            </Text>
          </TableCell>
        </TableRow>
        <TableRow sx={{ background: 'white' }}>
          <TableCell>
            <Text bold>
              <FormattedMessage id="PGX__ultra_rapid_term" />
            </Text>
          </TableCell>
          <TableCell>
            <Text>
              <FormattedMessage id="PGX__ultra_rapid_term_definition" />
            </Text>
          </TableCell>
        </TableRow>
        <TableRow sx={{ background: 'white' }}>
          <TableCell>
            <Text bold>
              <FormattedMessage id="PGX__indeterminate_term" />
            </Text>
          </TableCell>
          <TableCell>
            <Text>
              <FormattedMessage id="PGX__indeterminate_term_definition" />
            </Text>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
