import { PgxV1Gene, Phenotype } from '@color/aou-reports';
import { Card, Grid, Spacer, Text, Title } from '@color/continuum';
// TODO Import from CDS rather than MUI. In CDS-341 we turned on lint warnings on direct MUI imports. This line is disabled because GP CI sets the rule to error instead of warn.
// Swap this for a CDS import instead of a MUI import.
// eslint-disable-next-line no-restricted-imports
import { Link } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { GENE_DESCRIPTIONS, PHENOTYPE_DESCRIPTIONS, PHENOTYPE_TERMS } from './constants';

export interface PgxGeneInfoCardProps {
  gene: PgxV1Gene;
  diplotype: string;
  phenotype: Phenotype;
  hasDnaMedicine?: boolean;
}
export const PgxGeneInfoCard: React.FC<PgxGeneInfoCardProps> = (props) => {
  const { gene, diplotype, phenotype, hasDnaMedicine = false } = props;
  return (
    <Card>
      <Grid container id={`pgx-info-card-${gene}`}>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            background: (theme) => theme.palette.secondary.hover,
            padding: 4,
          }}
        >
          <Text color="primary" bold>
            <FormattedMessage id="PGX__Gene_Label" />
          </Text>
          <Spacer variant="xSmall" />
          {hasDnaMedicine ? (
            <Link href={`#dna-medicine-${gene}`}>
              <Title variant="headline2" sx={{ color: (theme) => theme.palette.text.link }}>
                <em>{gene}</em>
              </Title>
            </Link>
          ) : (
            <Title variant="headline2" color="textPrimary">
              <em>{gene}</em>
            </Title>
          )}

          <Spacer variant="small" />
          <Text variant="body1">{GENE_DESCRIPTIONS[gene]}</Text>
        </Grid>
        <Grid item xs={12} sm={8} sx={{ padding: 4 }}>
          <Text color="primary" bold>
            <FormattedMessage id="PGX__Version_Label" />
          </Text>
          <Spacer variant="xSmall" />
          <Title variant="headline2" color="textPrimary">
            {diplotype}
          </Title>
          <Spacer variant="small" />
          <Text color="primary" bold>
            <FormattedMessage id="PGX__What_It_Means_Label" />
          </Text>
          <Spacer variant="xSmall" />
          <Title variant="headline2" color="textPrimary">
            {PHENOTYPE_TERMS[phenotype]}
          </Title>
          <Spacer variant="small" />
          <Text variant="body1">{PHENOTYPE_DESCRIPTIONS[phenotype]}</Text>
        </Grid>
      </Grid>
    </Card>
  );
};
