type EventProperty = string | number | boolean | Date;

export type EventProperties = { [key: string]: EventProperty };

/** Event Type enum for the standard Color EventLog model. */
export enum EventType {
  STARTED_ANCESTRY_INFORMING_LOOP = 'api:ancestry_introduction_started',
  CHOSE_VIEW_AFTER_ANCESTRY_INFORMING_LOOP = 'api:ancestry_introduction_user_chose_view',
  STARTED_HDR_PRE_RESULTS = 'api:hdr_pre_results_started',
  CHOSE_VIEW_AFTER_HDR_PRE_RESULTS = 'api:hdr_pre_results_user_chose_view',
  STARTED_PGX_PRE_RESULTS = 'api:pgx_pre_results_started',
  CHOSE_VIEW_AFTER_PGX_PRE_RESULTS = 'api:pgx_pre_results_user_chose_view',
}

export interface EventLog {
  eventType: EventType;
  parameters?: EventProperties;
  latestEventAt: Date;
}

export interface NewEventPayload {
  eventType: EventType | string;
  parameters?: EventProperties;
}

// This represents a platform where we can store an analytics event. When logging an event, we can send it to one or multiple platforms.
export enum AnalyticsPlatform {
  // Our backend stores analytics events in the EventLog data model.
  // We use it to store user events that are necessary for the app experience, e.g. whether they have completed the ancestry tutorial.
  DEPRECATED_EVENT_LOG,

  // Segment is a third-party analytics platform.
  // We use it to track user properties like device and geographic location, and user events like page views and button clicks.
  // These analytics give us more details about our users, but are not necessary for the app experience.
  // We may not be able to collect these analytics for users with ad-blockers.
  SEGMENT,
}

export enum ModuleType {
  GEM = 'gem',
  HDR = 'hdr',
  PGX = 'pgx',
}

export enum SegmentEventType {
  HDR_REPORT_DOWNLOADED = 'HDR Report Downloaded',
  PGX_REPORT_DOWNLOADED = 'PGx Report Downloaded',
  HDR_RESULTS_SHARED_WITH_DOCTOR = 'HDR Results Shared with Doctor',
  HDR_GC_NOTE_DOWNLOADED = 'HDR GC Note Downloaded',
  PGX_GC_NOTE_DOWNLOADED = 'PGx GC Note Downloaded',
  HDR_RESULTS_SHARED_WITH_FAMILY = 'HDR Results Shared with Family',
  HDR_POSITIVE_QUICK_LINK_YOUR_RESULTS = 'HDR+ Your Results Quick Link',
  HDR_POSITIVE_QUICK_LINK_RISK_WITH_GENE = 'HDR+ Risk with Gene Quick Link',
  HDR_POSITIVE_QUICK_LINK_NEXT_STEPS = 'HDR+ Next Steps Quick Link',
  HDR_POSITIVE_CLINICAL_CONFIRMATION_CTA = 'HDR+ Clinical Confirmation CTA',
  HDR_NEGATIVE_QUICK_LINK_YOUR_RESULTS = 'HDR- Your Results Quick Link',
  HDR_NEGATIVE_QUICK_LINK_HEALTHY_LIFESTYLES = 'HDR- Healthy Lifestyles Quick Link',
  HDR_NEGATIVE_QUICK_LINK_DISCUSS_RESULTS = 'HDR- Discuss Results Quick Link',
  PGX_QUICK_LINK_YOUR_RESULTS = 'PGx Your Results Quick Link',
  PGX_QUICK_LINK_BEYOND_DNA = 'PGx Beyond DNA Quick Link',
  PGX_QUICK_LINK_DISCUSS_RESULTS = 'PGx Discuss Results Quick Link',
  HDR_DASHBOARD_GC_SCHEDULING = 'HDR Dashbard GC Scheduling',
  PGX_DASHBOARD_GC_SCHEDULING = 'PGx Dashbard GC Scheduling',
  HDR_YOUR_RESULTS_GC_SCHEDULING = 'HDR Your Results GC Scheduling',
  HDR_NEXT_STEPS_GC_SCHEDULING = 'HDR Next Steps GC Scheduling',
  PGX_DISCUSS_RESULTS_GC_SCHEDULING = 'PGx Discuss Results GC Scheduling',
}

export enum SegmentPageType {
  GC_SCHEDULING_OVERVIEW = 'GC Scheduling Overview',
  GC_SCHEDULING_CONTACT_INFO = 'GC Scheduling Contact Info',
  GC_SCHEDULING_CALENDAR = 'GC Scheduling Calendar',
  GC_SCHEDULING_CONFIRMATION = 'GC Scheduling Confirmation',
}
