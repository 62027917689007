import { InternalButtonLink, Spacer, Text } from '@color/continuum';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

import { PGX_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  pgxILNoPageTitle: {
    description: 'PGx Informing Loop Decision Page Title - No',
    defaultMessage: 'Decision - No',
  },
});

export const PgxInformingLoopDecisionNo: React.FC = () => {
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.pgxILNoPageTitle)}
      module={{
        analyticsInformingLoopName: PGX_INFORMING_LOOP_NAME,
        analyticsPageName: '8. No',
        image: CircularImageType.GENES_DRUGS,
        title: <FormattedMessage id="PGX_IL_No__Title" />,
        content: (
          <>
            <Text bold paragraph>
              <FormattedMessage id="PGX_IL_No__Paragraph_1" />
            </Text>
            <Text paragraph>
              <FormattedMessage id="PGX_IL_No__Paragraph_2" />
              <Spacer variant="medium" />
              <InternalButtonLink to="/">
                <FormattedMessage
                  defaultMessage="Back to DNA Results"
                  description="PGX informing loop final page no decision content back to results button"
                />
              </InternalButtonLink>
            </Text>
          </>
        ),
      }}
    />
  );
};
