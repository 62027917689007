import { DisclaimerSection } from 'components/common/Reports/DisclaimerSection';

import { Trait } from '../../types';
import { useTraitResultsDisclaimerSectionContent } from './content';

interface Props {
  trait: Trait;
}

export const TraitResultsDisclaimer: React.FC<Props> = (props) => {
  const { trait } = props;
  const sections = useTraitResultsDisclaimerSectionContent(trait);
  return <DisclaimerSection sections={sections} />;
};
