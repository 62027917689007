import { Locale } from '@color/lib';

export const SUPPORT_CHAT_URLS: Record<Locale, string> = {
  [Locale.ENGLISH]:
    'https://home-c71.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=4ec1cdde-348d-4bd7-b734-e6b2d8bbe07a&bu=4598528',
  [Locale.SPANISH]:
    'https://home-c71.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=3cf50138-cfed-41ed-b386-aae7f80ef486&bu=4598528',
} as Record<Locale, string>;
export const FOOTER_FAQ_LINK = {
  [Locale.ENGLISH]: 'https://www.joinallofus.org/faq',
  [Locale.SPANISH]: 'https://go.joinallofus.org/es/faqs',
};
export const FOOTER_JOIN_ALL_OF_US_LINK = {
  [Locale.ENGLISH]: 'https://www.joinallofus.org/#',
  [Locale.SPANISH]: 'https://go.joinallofus.org/es',
};
export const FOOTER_RESEARCH_ALL_OF_US_LINK = 'https://www.researchallofus.org/';
export const FOOTER_FACEBOOK_LINK = 'https://www.facebook.com/AllofUsResearch/';
export const FOOTER_TWITTER_LINK = 'https://twitter.com/AllofUsResearch/';
export const FOOTER_INSTAGRAM_LINK = 'https://www.instagram.com/allofusresearch/';
export const FOOTER_YOUTUBE_LINK = 'https://www.youtube.com/channel/UCQId1TfpwPaYiDIGlxEhlkA/feed';
export const FOOTER_SUBSCRIBE_TO_NEWSLETTER_LINK =
  'https://www.joinallofus.org/subscribe-to-newsletters';
export const FOOTER_PRIVACY_POLICY_LINK = 'https://www.joinallofus.org/privacy-policy';
export const FOOTER_TERMS_LINK = 'https://www.joinallofus.org/terms-of-service';
export const FOOTER_SECURITY_POLICY_LINK =
  'https://allofus.nih.gov/protecting-data-and-privacy/precision-medicine-initiative-data-security-policy-principles-and-framework-overview';
export const FOOTER_FAQ_LINK_CE = {
  [Locale.ENGLISH]: 'https://go.joinallofus.org/faqs',
  [Locale.SPANISH]: 'https://go.joinallofus.org/es/faqs',
};
export const FOOTER_JOIN_ALL_OF_US_LINK_CE = {
  [Locale.ENGLISH]: 'http://go.joinallofus.org/',
  [Locale.SPANISH]: 'https://go.joinallofus.org/es',
};
