import { UseQueryOptions, useQuery } from 'react-query';

import { ApiError } from 'lib/api/types';

import { AncestryData } from './types';

interface AncestryQueryParams {
  logView?: boolean;
}

export const useAncestryQuery = (
  params?: AncestryQueryParams,
  options?: UseQueryOptions<AncestryData, ApiError>
) => {
  return useQuery<AncestryData, ApiError>(['ancestry', { searchParams: params }], options);
};
