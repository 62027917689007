import {
  Box,
  Button,
  Container,
  Grid,
  InlineSpacer,
  Spacer,
  Text,
  Title,
  useMediaQuery,
  useTheme,
} from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { AncestryTutorialImage } from './AncestryTutorialImage';
import { AncestryTutorialImageType } from './types';

export interface AncestryTutorialLayoutProps {
  title: string;
  content: string;
  image: AncestryTutorialImageType;
  hasPrevious: boolean;
  hasNext: boolean;
  onContinue: () => void;
  onBack: () => void;
  onStartOver: () => void;
}

export const AncestryTutorialLayout: React.FC<AncestryTutorialLayoutProps> = (
  props: AncestryTutorialLayoutProps
) => {
  const { title, content, image, hasPrevious, hasNext, onContinue, onBack, onStartOver } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Container maxWidth={false}>
      <Grid
        container
        sx={{
          paddingRight: {
            xs: 0,
            sm: 12,
          },
        }}
      >
        <Grid item xs={12} sm>
          <AncestryTutorialImage image={image} />
        </Grid>
        {!isMobile && <InlineSpacer variant="xxxLarge" />}
        {isMobile && (
          <Grid item xs={12}>
            <Spacer variant="xLarge" />
          </Grid>
        )}
        <Grid item xs={12} sm>
          <Title variant="headline3">{title}</Title>
          <Spacer variant="medium" />
          <Text>{content}</Text>
          <Spacer variant="xxLarge" />
          <Box display="flex" justifyContent="flex-end">
            {hasPrevious && (
              <>
                <Button variant="color-secondary" onClick={onBack}>
                  <FormattedMessage
                    defaultMessage="Back"
                    description="Ancestry tutorial back button text"
                  />
                </Button>
                <InlineSpacer variant="small" />
              </>
            )}
            {hasNext && (
              <Button onClick={onContinue}>
                <FormattedMessage
                  defaultMessage="Continue"
                  description="Ancestry tutorial continue button text"
                />
              </Button>
            )}
            {!hasNext && (
              <Button onClick={onStartOver}>
                <FormattedMessage
                  defaultMessage="Start Over"
                  description="Ancestry tutorial start over button text"
                />
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
      <Spacer variant="xxxLarge" />
    </Container>
  );
};
