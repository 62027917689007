import { ReportPayload, revisionsAreAvailable } from '@color/aou-reports';
import { Container, MoreInformationCard, Spacer, Text, Title } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';

import { BackButtonBar } from 'components/common/BackButtonBar';
import { QuizAndMoreInformationSection } from 'components/common/Reports/QuizAndMoreInformationSection';
import { ReportIconType } from 'components/common/Reports/ReportIcon/types';
import { getReportIconSrc } from 'components/common/Reports/ReportIcon/utils';
import { useReportPdfUrl } from 'components/HealthRelatedResults/api';
import { useLocationControlledTabs } from 'components/HealthRelatedResults/components/FullWidthTabsWithPanel';
import { MoreInformationSection } from 'components/HealthRelatedResults/components/MoreInformationSection/MoreInformationSection';
import { ReReleaseBanner } from 'components/HealthRelatedResults/components/ReReleaseBanner/ReReleaseBanner';
import { ResearchResultWarningBanner } from 'components/HealthRelatedResults/components/ResearchResultWarningBanner/ResearchResultWarningBanner';
import { ResultViewed } from 'components/HealthRelatedResults/components/ResultViewed/ResultViewed';
import { trackSegmentEvent } from 'lib/analytics/segment';
import { ModuleType, SegmentEventType } from 'lib/analytics/types';
import { HDR_UNINFORMATIVE_INFO_SHEET_URLS } from 'lib/constants';

import { FullWidthTabsWithPanel } from '../../components/FullWidthTabsWithPanel/FullWidthTabsWithPanel';
import { HDR_UNINFORMATIVE_LOCATION_CONTROLLED_TABS_PROPS } from './constants';
import { DiscussResultsTab } from './Tabs/DiscussResultsTab';
import { HealthyLifestylesTab } from './Tabs/HealthyLifestylesTab/HealthyLifestylesTab';
import { YourResultsTab } from './Tabs/YourResultsTab/YourResultsTab';
import { HdrUninformativeTabIds } from './types';

interface IHdrUninformativeReportProps {
  report: ReportPayload;
  token?: string;
}

export const HdrUninformativeReport: React.FC<IHdrUninformativeReportProps> = (props) => {
  const { locale } = usePreferredLocale();
  const { report, token } = props;
  const intl = useIntl();
  const messages = {
    pageTitle: intl.formatMessage({ id: 'HDR_Home_Helmet_Title' }),
  };
  const reportPdfUrl = useReportPdfUrl({ moduleType: ModuleType.HDR });
  const { tabId, setTab } = useLocationControlledTabs(
    HDR_UNINFORMATIVE_LOCATION_CONTROLLED_TABS_PROPS
  );

  if (!tabId) {
    return null;
  }

  return (
    <>
      <Helmet>
        {/* declarative intl syntax injects a span, which causes issues with Helmet title */}
        <title>{messages.pageTitle}</title>
      </Helmet>
      <Container>
        <ResearchResultWarningBanner>
          <Text>
            <FormattedMessage id="HDR_Uninformative__Research_Result_Warning" />
          </Text>
        </ResearchResultWarningBanner>
        {revisionsAreAvailable(report) && (
          <ReReleaseBanner
            revisions={report.reportInfo.revisions!}
            revision={report.reportInfo.revision!}
          />
        )}
      </Container>
      <BackButtonBar to="/" />
      <Spacer variant="large" />
      <Title align="center">
        <FormattedMessage id="HDR_Uninformative__Title" />
      </Title>
      <Spacer variant="xxLarge" />
      <FullWidthTabsWithPanel
        tabsConfig={{
          [HdrUninformativeTabIds.YOUR_RESULTS]: {
            label: <FormattedMessage id="HDR_Uninformative__Your_Results_Tab" />,
            component: <YourResultsTab report={report} />,
          },
          [HdrUninformativeTabIds.HEALTHY_LIFESTYLES]: {
            label: <FormattedMessage id="HDR_Uninformative__Healthy_Lifestyles_Tab" />,
            component: <HealthyLifestylesTab />,
          },
          [HdrUninformativeTabIds.DISCUSS_RESULTS]: {
            label: <FormattedMessage id="HDR_Uninformative__Discuss_Results_Tab" />,
            component: <DiscussResultsTab />,
          },
        }}
        tabId={tabId}
        setTab={setTab}
      />
      <Spacer variant="medium" />
      <Container>
        <QuizAndMoreInformationSection
          quizName="aou_survey_hdr_uninformative"
          moreInformationSection={
            <MoreInformationSection
              tabId={tabId}
              moduleType={ModuleType.HDR}
              moreInformationCards={{
                [HdrUninformativeTabIds.YOUR_RESULTS]: (
                  <MoreInformationCard
                    iconSrc={getReportIconSrc(ReportIconType.YOUR_RESULTS_HDR)}
                    title={<FormattedMessage id="HDR_Uninformative__Your_Results_Tab" />}
                    onClick={() => {
                      trackSegmentEvent({
                        eventType: SegmentEventType.HDR_NEGATIVE_QUICK_LINK_YOUR_RESULTS,
                      });
                      setTab(HdrUninformativeTabIds.YOUR_RESULTS);
                    }}
                  />
                ),
                [HdrUninformativeTabIds.HEALTHY_LIFESTYLES]: (
                  <MoreInformationCard
                    iconSrc={getReportIconSrc(ReportIconType.HEALTHY_LIFESTYLES)}
                    title={<FormattedMessage id="HDR_Uninformative__Healthy_Lifestyles_Tab" />}
                    onClick={() => {
                      trackSegmentEvent({
                        eventType: SegmentEventType.HDR_NEGATIVE_QUICK_LINK_HEALTHY_LIFESTYLES,
                      });
                      setTab(HdrUninformativeTabIds.HEALTHY_LIFESTYLES);
                    }}
                  />
                ),
                [HdrUninformativeTabIds.DISCUSS_RESULTS]: (
                  <MoreInformationCard
                    iconSrc={getReportIconSrc(ReportIconType.DISCUSS_RESULTS)}
                    title={<FormattedMessage id="HDR_Uninformative__Discuss_Results_Tab" />}
                    onClick={() => {
                      trackSegmentEvent({
                        eventType: SegmentEventType.HDR_NEGATIVE_QUICK_LINK_DISCUSS_RESULTS,
                      });
                      setTab(HdrUninformativeTabIds.DISCUSS_RESULTS);
                    }}
                  />
                ),
              }}
              infoSheetUrl={HDR_UNINFORMATIVE_INFO_SHEET_URLS[locale]}
              reportPdfUrl={reportPdfUrl}
            />
          }
        />
        <Spacer variant="medium" />
      </Container>
      <ResultViewed moduleType={ModuleType.HDR} token={token} />
    </>
  );
};
