import { GpWaffleSwitch } from './types';
import { waffle } from './waffle';

export const isHgmReportsDisabledWaffleEnabled = () => {
  return waffle.switchIsActive(GpWaffleSwitch.HGM_REPORTS_DISABLED);
};

/**
 * Returns true if the waffle is enabled.
 * This waffle enables the incident banner.
 * See https://getcolor.atlassian.net/browse/PC-1719
 */
export const isIncidentBannerWaffleEnabled = () => {
  return waffle.switchIsActive(GpWaffleSwitch.INCIDENT_BANNER);
};

export const isGcAppointmentsDisabledForNegativeResultsWaffleEnabled = () => {
  return waffle.switchIsActive(GpWaffleSwitch.GC_APPOINTMENTS_DISABLED_FOR_NEGATIVE_RESULTS);
};

export const areIlLoopsDisabled = () => {
  return waffle.switchIsActive(GpWaffleSwitch.DISABLE_IL_LOOPS);
};
