import { Environment, Locale } from '@color/lib';

import { IDP_CE } from 'lib/constants';

import { AppConfig } from './types';

const PARTICIPANT_PORTAL_BASE_URL = 'https://participant-stb.joinallofus.org';

// Note: This matches `all_of_us.ptsc.openid_connect.base_url` from `environment.yaml`
const OPENID_CONNECT_BASE_URL =
  'https://accounts-stb.joinallofus.org/auth/realms/stb_participant_realm/protocol/openid-connect/';

export const config: AppConfig = {
  API_HOST_URL: new URL('https://genomics-stb.joinallofus.org'),
  AGREEMENTS_URL: new URL('/agreements', PARTICIPANT_PORTAL_BASE_URL),
  ENVIRONMENT: Environment.STAGING,
  LEARNING_CENTER_URL: new URL('/dl/8ZuSCpy9', PARTICIPANT_PORTAL_BASE_URL),
  LEARNING_CENTER_GENES_AND_MEDICINE_URL: {
    [Locale.ENGLISH]: new URL('/dl/g3AfCgee', PARTICIPANT_PORTAL_BASE_URL),
    [Locale.SPANISH]: new URL('/dl/pI9h4Gk6', PARTICIPANT_PORTAL_BASE_URL),
  },
  LEARNING_CENTER_GENES_AND_HEALTH_URL: {
    [Locale.ENGLISH]: new URL('/dl/CVvcual4', PARTICIPANT_PORTAL_BASE_URL),
    [Locale.SPANISH]: new URL('/dl/Jk5Hdial', PARTICIPANT_PORTAL_BASE_URL),
  },
  LEARNING_CENTER_GENETIC_TESTING_URL: {
    [Locale.ENGLISH]: new URL('/dl/ywjeYF4R', PARTICIPANT_PORTAL_BASE_URL),
    [Locale.SPANISH]: new URL('/dl/vOPmOwWw', PARTICIPANT_PORTAL_BASE_URL),
  },
  LEARNING_CENTER_DNA_AND_GENOMICS_URL: {
    [Locale.ENGLISH]: new URL('/dl/8ZuSCpy9', PARTICIPANT_PORTAL_BASE_URL),
    [Locale.SPANISH]: new URL('/dl/6889IjDP', PARTICIPANT_PORTAL_BASE_URL),
  },
  MY_DATA_URL: new URL('/mydata', PARTICIPANT_PORTAL_BASE_URL),
  PARTICIPANT_PORTAL_URL: new URL(PARTICIPANT_PORTAL_BASE_URL),
  SEGMENT_WRITE_KEY: 'JRe3kaoy7vUwlhE4xRVBLpzb0E96Ps4D',
  // Note: *not* actually secret, also shared with all other (non-local) envs
  SENTRY_DSN: 'https://e985b5590759498f9b9f529805f20eba@o22410.ingest.sentry.io/6108917',
  SSO_LOGOUT_URL: new URL(
    `${OPENID_CONNECT_BASE_URL}logout?redirect_uri=${PARTICIPANT_PORTAL_BASE_URL}`
  ),
  SUPPORT_URL: new URL('/support', PARTICIPANT_PORTAL_BASE_URL),
};

export function getIdpOverrides(idp: string) {
  if (idp === IDP_CE) {
    const BASE_URL = 'https://platform.joinallofus.org';
    return {
      AGREEMENTS_URL: new URL('/dryrun/mydatahelps/p/s/Agreements', BASE_URL),
      LEARNING_CENTER_URL: new URL('/dryrun/mydatahelps/p/s/LC-DNA', BASE_URL),
      LEARNING_CENTER_DNA_AND_GENOMICS_URL: {
        [Locale.ENGLISH]: new URL('/dryrun/mydatahelps/p/s/LC-DNA', BASE_URL),
        [Locale.SPANISH]: new URL('/dryrun/mydatahelps/p/s/LC-DNA', BASE_URL),
      },
      MY_DATA_URL: new URL('/dryrun/mydatahelps/p/s/MyData', BASE_URL),
      PARTICIPANT_PORTAL_URL: new URL('/dryrun/mydatahelps/p/s/Dashboard', BASE_URL),
      SSO_LOGOUT_URL: new URL(
        `/dryrun/identityserver/connect/endsession?post_logout_redirect_uri=https://cdn.careevolution.com/allofus/dryrun.html`,
        BASE_URL
      ),
    };
  }
  return {};
}
