import { Banner } from '@color/continuum';
import { PropsWithChildren } from 'react';

export const ResearchResultWarningBanner: React.FC<PropsWithChildren<{}>> = (props) => {
  const { children } = props;
  return (
    <Banner severity="warning" variant="in-component">
      {children}
    </Banner>
  );
};
