import { HdrV1Gene, HdrV1VariantDetails, getGeneSpecificContent } from '@color/aou-reports';
import { Banner, InternalLink, Spacer, Text, Title } from '@color/continuum';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { HDR_APPOINTMENT_SCHEDULING_PATH } from 'components/App/AppRoutes/constants';
import { AccordionIconType } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionIcon/types';
import { AccordionWithIcon } from 'components/HealthRelatedResults/components/AccordionWithIcon/AccordionWithIcon';

import { TechnicalReportTable } from './TechnicalReportTable';

interface ITechnicalReportProps {
  multiVariantGeneDetails: HdrV1VariantDetails[];
  isMultiVariant: boolean;
}

export const TechnicalReport: React.FC<ITechnicalReportProps> = (props) => {
  const { multiVariantGeneDetails, isMultiVariant } = props;
  const { gene, reportedDisorder } = multiVariantGeneDetails[0];
  const { providerDescription, providerDisorder } = getGeneSpecificContent(gene, reportedDisorder);
  return (
    <AccordionWithIcon
      title={<FormattedMessage id="HDR_Positive__Technical_Report_Section_Title" />}
      icon={AccordionIconType.MEDICAL}
    >
      <Banner variant="in-component" severity="warning">
        <Text>
          <FormattedMessage id="HDR_Positive__Technical_Report_Warning_Banner_Text" />{' '}
          {isMultiVariant && (
            <FormattedMessage id="HDR_Positive__Technical_Report_Warning_Banner_Text_Two_Variants" />
          )}
        </Text>
      </Banner>
      <Spacer variant="large" />
      <Title variant="headline3">
        <FormattedMessage id="HDR_Positive__Technical_Report_Details_Subsection_Title" />
      </Title>
      <Spacer variant="small" />
      {multiVariantGeneDetails.map((variant, index) => (
        <TechnicalReportTable
          variant={variant}
          key={variant.cHgvs + variant.gHgvs}
          variantNumber={index}
          isMultiVariant={isMultiVariant}
        />
      ))}

      <Title variant="headline3">
        <FormattedMessage id="HDR_Positive__Technical_Report_About_This_Result_Subsection_Title" />
      </Title>
      <Spacer variant="small" />
      {gene === HdrV1Gene.TP53 ? (
        <>
          <Text paragraph>
            <FormattedMessage id="HDR_Positive__TP53_About_Result_P1" />
          </Text>
          <Text paragraph>
            <FormattedMessage id="HDR_Positive__TP53_About_Result_P2" />
          </Text>
          <Text paragraph>
            <FormattedMessage id="HDR_Positive__TP53_About_Result_P3" />
          </Text>
          <Text paragraph>
            <FormattedMessage id="HDR_Positive__Technical_Report_P3" />
          </Text>
        </>
      ) : (
        <>
          <Text paragraph>
            <FormattedMessage
              id="HDR_Positive__Technical_Report_P1"
              values={{ gene, providerDisorder }}
            />
          </Text>
          <Text paragraph>{providerDescription}</Text>
          <Text paragraph>
            <FormattedMessage id="HDR_Positive__Technical_Report_P3" />
          </Text>
        </>
      )}
      <Spacer variant="large" />
      <Title variant="headline3">
        <FormattedMessage id="HDR_Positive__Technical_Report_Questions_Subsection_Title" />
      </Title>
      <Spacer variant="small" />
      <Text paragraph>
        <FormattedMessage
          id="HDR_Positive__Technical_Report_Questions_Paragraph_1"
          values={{
            link: (chunks: ReactNode) => (
              <InternalLink to={HDR_APPOINTMENT_SCHEDULING_PATH}>{chunks}</InternalLink>
            ),
          }}
        />
      </Text>
      <Spacer variant="large" />
      <Title variant="headline3">
        <FormattedMessage id="HDR_Positive__Technical_Report_Test_Performed_Subsection_Title" />
      </Title>
      <Spacer variant="small" />
      <Text paragraph>
        <FormattedMessage id="HDR_Positive__Technical_Report_Test_Performed_Paragraph_1" />
      </Text>
    </AccordionWithIcon>
  );
};
