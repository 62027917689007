import { Image } from '@color/continuum';
import { kebabCase } from 'lodash';
import requireContext from 'require-context.macro';

import { PopulationDetailsImageType } from './types';

const PopulationDetailsImages = requireContext(
  '../../../../../../../assets/images/ancestry-population-images',
  true,
  /\.svg$/
);

export interface PopulationDetailsImageProps {
  image: PopulationDetailsImageType;
}

export const PopulationDetailsImage: React.FC<PopulationDetailsImageProps> = (props) => {
  const { image } = props;
  const imageSrc = PopulationDetailsImages(`./${kebabCase(image)}.svg`).default;
  return <Image sx={{ height: '100%', width: '100%' }} src={imageSrc} alt="" />;
};
