import { getValidLocale, usePreferredLocale } from '@color/lib';
import { useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

const SUPPORTED_LOCALES = ['en', 'es'];

// This is very similar to useQueryParamLocale that's used in home.
// In the future, we may want to standardize between apps and use a query param to switch between locales in GP as well.
// However, for now, we have to support the locale in the path param in order for the /aou_single_sign_on_callback endpoint
// to redirect correctly in both GEM and GP.
// Note: we should revisit this after the GEM > GP transition and see whether we want to use query param.
export function usePathParamLocale() {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { locale, setLocale } = usePreferredLocale();

  const localeMatch = useRouteMatch<{ locale: string }>(`/:locale(${SUPPORTED_LOCALES.join('|')})`);
  const pathParam = localeMatch?.params.locale;

  /**
   * `useEffect` is often dangerous and unnecessary. This usage needs review by #ux-programs team.
   * See https://getcolor.atlassian.net/browse/UXTASK-1406
   */
  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    if (pathParam) {
      // Important! clear the path param so that the user can choose a different locale.
      const updatedPathname = pathname.replace(`/${pathParam}`, '');
      history.replace(`${updatedPathname}${search}`);
      const validLocale = getValidLocale(pathParam);
      if (validLocale && locale !== validLocale) {
        setLocale(validLocale);
      }
    }
  }, [locale, pathParam, setLocale, pathname, search, history]);
  // Note: This effect is dependent on locale, which it sets within the hook. Make sure that setting the locale is guarded strictly
  // by conditions to prevent an infinite loop where the effect re-triggers itself.
  // Note: This effect is dependent on pathname, which changes very frequently. Make sure that any code within the hook is guarded
  // by conditions to ensure it does not run on every path change.
}
