import { IntlShape, defineMessages } from 'react-intl';

import { AncestryTutorialImageType } from './types';

const messages = defineMessages({
  ancestryTutorialItem1Title: {
    description: 'Ancestry tutorial item 1 title',
    defaultMessage: 'Your DNA carries a record of generations past.',
  },
  ancestryTutorialItem2Title: {
    description: 'Ancestry tutorial item 2 title',
    defaultMessage: 'We compare your DNA to reference populations.',
  },
  ancestryTutorialItem3Title: {
    description: 'Ancestry tutorial item 3 title',
    defaultMessage: 'Genetic ancestry improves with more reference populations.',
  },
  ancestryTutorialItem1Content: {
    description: 'Ancestry tutorial item 1 content',
    defaultMessage:
      'You get half of your DNA from your biological mother. The other half is from your biological father. Because of this, your DNA can tell you about your genetic ancestors.',
  },
  ancestryTutorialItem2Content: {
    description: 'Ancestry tutorial item 2 content',
    defaultMessage:
      '"Reference populations" are groups of people who are known to have lived in a specific area or have a certain background. We use publicly available DNA information from these groups and compare your DNA to theirs. This is how we determine your genetic ancestry.',
  },
  ancestryTutorialItem3Content: {
    description: 'Ancestry tutorial item 3 content',
    defaultMessage:
      'We look at places in your DNA and determine which reference population they likely came from. We then add up all of the places we looked at. Then we can tell you how much of your DNA comes from a certain area of the world. Your genetic ancestry result will become more specific over time. This is because we add more reference populations to compare your DNA against.',
  },
});

export const getAncestryTutorialContent = (
  intl: IntlShape
): {
  title: string;
  content: string;
  image: AncestryTutorialImageType;
}[] => [
  {
    image: AncestryTutorialImageType.FAMILY_TREE,
    title: intl.formatMessage(messages.ancestryTutorialItem1Title),
    content: intl.formatMessage(messages.ancestryTutorialItem1Content),
  },
  {
    image: AncestryTutorialImageType.MAP_COLORS,
    title: intl.formatMessage(messages.ancestryTutorialItem2Title),
    content: intl.formatMessage(messages.ancestryTutorialItem2Content),
  },
  {
    image: AncestryTutorialImageType.MAP_COLORS_DETAILED,
    title: intl.formatMessage(messages.ancestryTutorialItem3Title),
    content: intl.formatMessage(messages.ancestryTutorialItem3Content),
  },
];
