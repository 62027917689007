import { TimeSlot } from './types';

export const AVAILABLE_HOURS = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
export const NUMBER_OF_TIME_SLOTS_PER_DAY = 15;

// All of these are in 'America/Los_Angeles' time.
export const TIME_SLOTS_TIMEZONE = 'America/Los_Angeles';
// These are the possible appointment slots that an appointment can happen in for a day,
// all in the above timezone.
export const TIME_SLOTS: TimeSlot[] = [
  { hour: 6, minute: 0 },
  { hour: 6, minute: 45 },
  { hour: 7, minute: 30 },
  { hour: 8, minute: 15 },
  { hour: 9, minute: 0 },
  { hour: 9, minute: 45 },
  { hour: 10, minute: 30 },
  { hour: 11, minute: 15 },
  { hour: 12, minute: 30 },
  { hour: 13, minute: 15 },
  { hour: 14, minute: 0 },
  { hour: 14, minute: 45 },
  { hour: 15, minute: 30 },
  { hour: 16, minute: 15 },
];
