import { useState } from 'react';
import { useQuery } from 'react-query';

import { CancelConfirmation } from 'components/Appointments/CancelConfirmation';
import { Confirmation } from 'components/Appointments/Confirmation';
import { ContactInfo } from 'components/Appointments/ContactInfo/ContactInfo';
import { Overview } from 'components/Appointments/Overview';
import { Schedule } from 'components/Appointments/Schedule/Schedule';
import {
  convertToCanonicalTimezone,
  useModuleTypeBasedOnCurrentPath,
  useParticipantId,
} from 'components/Appointments/utilities';

import { IAppointment, IExistingAppointment } from './types';

export function useExistingAppointment(type: string) {
  return useQuery<IExistingAppointment>([
    'aou_check_for_existing_appointment',
    { searchParams: { type, pid: useParticipantId() } },
  ]);
}

export const Appointments: React.FC = () => {
  const [currentScheduleAppointment, setScheduleAppointment] = useState<boolean>(false);
  const toggle = () => {
    setScheduleAppointment(true);
  };
  const [currentContactInfo, setContactInfo] = useState<boolean>(false);
  const [currentSchedule, setSchedule] = useState<boolean>(false);
  const [hasPreviousAppointment, setPreviousAppointment] = useState<boolean>(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState<boolean>(false);
  const moduleType = useModuleTypeBasedOnCurrentPath();

  const [appointment, setAppointment] = useState<IAppointment>({
    type: moduleType,
    language: '',
    phoneCountryCode: 1,
    phoneNumber: '',
    location: '',
    timezone: convertToCanonicalTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone),
  });

  const { data, isLoading } = useExistingAppointment(appointment.type);

  // check for existing appointments
  if (!isLoading && data && data.appointment && appointment.language === '') {
    setAppointment({
      type: appointment.type,
      language: data.appointment.language,
      phoneNumber: data.appointment.phoneNumber,
      phoneCountryCode: data.appointment.phoneCountryCode,
      location: data.appointment.location,
      timestamp: new Date(data.appointment.timestamp),
      timezone: data.appointment.timezone,
    });
    // have the UI show the final confirmation page
    setContactInfo(true);
    setSchedule(true);
    setScheduleAppointment(true);
    setPreviousAppointment(true);
  }

  const handleEditAppointment = () => {
    setScheduleAppointment(true);
    setSchedule(false);
    setContactInfo(false);
  };

  const handleScheduleAppointment = () => {
    setSchedule(true);
    setPreviousAppointment(true);
  };
  if (!currentScheduleAppointment) {
    return <Overview appointment={appointment} handleScheduleAppointmentClick={toggle} />;
  }

  if (!currentContactInfo) {
    return (
      <ContactInfo
        handleContactInfoClick={() => setContactInfo(true)}
        handleCancelClick={() => setScheduleAppointment(false)}
        appointment={appointment}
        setAppointment={setAppointment}
        hasPreviousAppointment={hasPreviousAppointment}
      />
    );
  }
  if (!currentSchedule) {
    return (
      <Schedule
        handleScheduleClick={() => handleScheduleAppointment()}
        handlePreviousClick={() => setContactInfo(false)}
        appointment={appointment}
        setAppointment={setAppointment}
      />
    );
  }

  if (showCancelConfirmation) {
    return <CancelConfirmation />;
  }

  return (
    <Confirmation
      appointment={appointment}
      handleEditAppointment={handleEditAppointment}
      setShowCancelConfirmation={setShowCancelConfirmation}
    />
  );
};
