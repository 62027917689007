import { FormikForm, Spacer, Text, Title } from '@color/continuum';
import { useIntl } from 'react-intl';

import { FormikLanguageField } from 'components/common/Forms/FormikLanguageField/FormikLanguageField';
import { FormikPhoneField } from 'components/common/Forms/FormikPhoneField/FormikPhoneField';
import { FormikStateField } from 'components/common/Forms/FormikStateField/FormikStateField';

export const ContactInfoForm: React.FC = () => {
  const intl = useIntl();
  const messages = {
    languageTitle: intl.formatMessage({ id: 'Scheduling_Contact__Language__Title' }),
    languageText: intl.formatMessage({ id: 'Scheduling_Contact__Language__Text' }),
    languagePlaceholder: intl.formatMessage({ id: 'Scheduling_Contact__Language__Placeholder' }),
    phoneTitle: intl.formatMessage({ id: 'Scheduling_Contact__Phone__Title' }),
    phoneText: intl.formatMessage({ id: 'Scheduling_Contact__Phone__Text' }),
    phonePlaceholder: intl.formatMessage({ id: 'Scheduling_Contact__Phone__Placeholder' }),
    locationTitle: intl.formatMessage({ id: 'Scheduling_Contact__Location__Title' }),
    locationText: intl.formatMessage({ id: 'Scheduling_Contact__Location__Text' }),
    locationPlaceholder: intl.formatMessage({ id: 'Scheduling_Contact__Location__Placeholder' }),
  };
  return (
    <FormikForm>
      <Title variant="headline3">{messages.languageTitle}</Title>
      <Spacer variant="xSmall" />
      <Text>{messages.languageText}</Text>
      <Spacer variant="small" />
      <FormikLanguageField name="language" label={messages.languagePlaceholder} />
      <Spacer variant="xxLarge" />

      <Title variant="headline3">{messages.phoneTitle}</Title>
      <Spacer variant="xSmall" />
      <Text>{messages.phoneText}</Text>
      <Spacer variant="small" />
      <FormikPhoneField
        countryCodeName="phoneCountryCode"
        phoneNumberName="phoneNumber"
        required
        label={messages.phonePlaceholder}
      />
      <Spacer variant="xxLarge" />

      <Title variant="headline3">{messages.locationTitle}</Title>
      <Spacer variant="xSmall" />
      <Text>{messages.locationText}</Text>
      <Spacer variant="small" />
      <FormikStateField name="location" label={messages.locationPlaceholder} />
      <Spacer variant="xxLarge" />
    </FormikForm>
  );
};
