import { ActionLink, Container, Divider, Spacer, Text, Title } from '@color/continuum';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { ImageWithContentSection } from 'components/HealthRelatedResults/components/ImageWithContentSection/ImageWithContentSection';

interface IBeyondDnaTabProps {
  changeToDnaAndMedicine: () => void;
}

export const BeyondDnaTab: React.FC<IBeyondDnaTabProps> = (props) => {
  const { changeToDnaAndMedicine } = props;

  return (
    <Container>
      <Spacer variant="xxLarge" />
      <Title variant="headline2">
        <FormattedMessage id="PGX_Beyond_DNA__Genetic_Information_Header" />
      </Title>
      <Spacer variant="small" />
      <Text paragraph>
        <FormattedMessage
          id="PGX_Beyond_DNA__Genetic_Information_P1"
          values={{
            link: (chunks: ReactNode) => (
              <ActionLink onClick={changeToDnaAndMedicine}>
                <Text component="span">{chunks}</Text>
              </ActionLink>
            ),
          }}
        />
      </Text>
      <Text>
        <FormattedMessage id="PGX_Beyond_DNA__Genetic_Information_P2" />
      </Text>
      <Spacer variant="xxxLarge" />
      <Divider aria-hidden="true" />
      <Spacer variant="xxxLarge" />
      <ImageWithContentSection
        title={<FormattedMessage id="PGX_Beyond_DNA__Other_Medicines_Header" />}
        description={
          <>
            <FormattedMessage id="PGX_Beyond_DNA__Other_Medicines_P1" />
          </>
        }
        image={CircularImageType.PILL_BOTTLE}
      />
      <Spacer variant="xxxLarge" />
      <Divider aria-hidden="true" />
      <Spacer variant="xxxLarge" />
      <ImageWithContentSection
        title={<FormattedMessage id="PGX_Beyond_DNA__Health_History_Header" />}
        description={
          <>
            <Text paragraph>
              <FormattedMessage id="PGX_Beyond_DNA__Health_History_P1" />
            </Text>
            <Text paragraph>
              <FormattedMessage id="PGX_Beyond_DNA__Health_History_P2" />
            </Text>
          </>
        }
        image={CircularImageType.HEALTH_HISTORY}
      />
      <Spacer variant="xxxLarge" />
      <Divider aria-hidden="true" />
      <Spacer variant="xxxLarge" />
      <ImageWithContentSection
        title={<FormattedMessage id="PGX_Beyond_DNA__Lifestyle_Header" />}
        description={
          <Text paragraph>
            <FormattedMessage id="PGX_Beyond_DNA__Lifestyle_P1" />
          </Text>
        }
        image={CircularImageType.HEALTHY_LIFESTYLE}
      />
      <Spacer variant="xxxLarge" />
      <Divider aria-hidden="true" />
      <Spacer variant="xxxLarge" />
      <Spacer variant="large" />
      <Text textAlign="center" paragraph>
        <FormattedMessage id="PGX_Beyond_DNA__Remember_P1" />
      </Text>
      <Spacer variant="large" />
      <Spacer variant="xxxLarge" />
      <Divider aria-hidden="true" />
      <Spacer variant="xxxLarge" />
    </Container>
  );
};
