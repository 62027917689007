import { HdrV1Gene, HdrV1VariantDetails, getGeneSpecificContent } from '@color/aou-reports';
import {
  ActionLink,
  Container,
  Divider,
  ExternalButtonLink,
  Grid,
  IconBulletList,
  ImageDivider,
  InternalLink,
  ListItem,
  Spacer,
  Text,
  Title,
  UnorderedList,
} from '@color/continuum';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import DNABanner from 'assets/images/dna-banner.svg';
import {
  HDR_APPOINTMENT_SCHEDULING_PATH,
  SHARE_RESULTS_WITH_PROVIDER_PATH,
} from 'components/App/AppRoutes/constants';
import { CircularImageType } from 'components/common/CircularImage/types';
import { ResultBanner, ResultBannerIconType } from 'components/common/ResultBanner';
import { useLocationControlledTabs } from 'components/HealthRelatedResults/components/FullWidthTabsWithPanel';
import { ImageWithContentSection } from 'components/HealthRelatedResults/components/ImageWithContentSection/ImageWithContentSection';
import { config } from 'config';

import { HDR_POSITIVE_LOCATION_CONTROLLED_TABS_PROPS } from '../constants';
import { ShareResultsWithFamilyLink } from '../ShareResultsWithFamily';
import { HdrPositiveTabIds } from '../types';

interface IYourResultsProps {
  multiVariantGeneDetails: HdrV1VariantDetails[];
}

export const YourResults: React.FC<IYourResultsProps> = (props) => {
  const { multiVariantGeneDetails } = props;
  const { gene, reportedDisorder } = multiVariantGeneDetails[0];
  const { participantDisorder, participantDescription } = getGeneSpecificContent(
    gene,
    reportedDisorder
  );
  const location = useLocation();
  const { setTab } = useLocationControlledTabs(HDR_POSITIVE_LOCATION_CONTROLLED_TABS_PROPS);
  return (
    <>
      <Spacer variant="xxLarge" />
      <ResultBanner
        icon={ResultBannerIconType.GENES_CLIPBOARD}
        title={
          gene === HdrV1Gene.TP53 ? (
            <FormattedMessage id="HDR_Positive__TP53_Result_Banner_Title" />
          ) : (
            <FormattedMessage
              id="HDR_Positive__Result_Banner_Title"
              values={{ gene: <em>{gene}</em> }}
            />
          )
        }
        subtitle={<FormattedMessage id="HDR_Positive__Result_Banner_Subtitle" />}
      />
      <Spacer variant="xxLarge" />
      <Container>
        <Title variant="headline2">
          <FormattedMessage id="HDR_Positive__What_Does_This_Mean_Subsection_Title" />
        </Title>
        <Spacer variant="medium" />
        {gene === HdrV1Gene.TP53 ? (
          <>
            <Title variant="headline3">
              <FormattedMessage
                id="HDR_Positive__TP53_What_Does_This_Mean_Paragraph_1"
                values={{ participantDisorder }}
              />
            </Title>
            <Spacer variant="small" />

            <Text paragraph>
              <FormattedMessage
                id="HDR_Positive__What_Does_This_Mean_Not_Mean_Bullet_1"
                values={{ participantDisorder }}
              />{' '}
              <FormattedMessage
                id="HDR_Positive__What_Does_This_Mean_Not_Mean_Bullet_2"
                values={{ participantDisorder }}
              />{' '}
              <FormattedMessage id="HDR_Positive__TP53_What_Does_This_Mean_Not_Mean_Bullet_3" />
            </Text>

            <Text paragraph>
              <FormattedMessage id="HDR_Positive__TP53_What_Does_This_Mean_Not_Mean_Bullet_4" />{' '}
              <FormattedMessage id="HDR_Positive__TP53_What_Does_This_Mean_Not_Mean_Bullet_5" />{' '}
              <FormattedMessage id="HDR_Positive__TP53_What_Does_This_Mean_Not_Mean_Bullet_6" />{' '}
              <FormattedMessage id="HDR_Positive__TP53_What_Does_This_Mean_Not_Mean_Bullet_7" />
            </Text>
          </>
        ) : (
          <>
            <Title variant="headline3">
              <FormattedMessage
                id="HDR_Positive__What_Does_This_Mean_Paragraph_1"
                values={{ participantDisorder }}
              />
            </Title>
            <Spacer variant="small" />
            <Text paragraph>
              <FormattedMessage
                id="HDR_Positive__What_Does_This_Mean_Not_Mean_Bullet_1"
                values={{ participantDisorder }}
              />{' '}
              <FormattedMessage
                id="HDR_Positive__What_Does_This_Mean_Not_Mean_Bullet_2"
                values={{ participantDisorder }}
              />{' '}
              <FormattedMessage id="HDR_Positive__What_Does_This_Mean_Not_Mean_Bullet_3" />
            </Text>
          </>
        )}
        <Spacer variant="xLarge" />
        <Divider aria-hidden="true" />
        <Spacer variant="xLarge" />
        <ImageWithContentSection
          title={
            <Title variant="headline2">
              <FormattedMessage id="HDR_Positive__Important_Subsection_Title" />
            </Title>
          }
          description={
            <UnorderedList>
              <ListItem>
                <InternalLink
                  to={{
                    pathname: SHARE_RESULTS_WITH_PROVIDER_PATH,
                    state: { backgroundLocation: location },
                  }}
                >
                  <Text>
                    <FormattedMessage id="HDR_Positive__Important_Subsection_Bullet_1" />
                  </Text>
                </InternalLink>
              </ListItem>
              <ListItem>
                <Text>
                  <FormattedMessage id="HDR_Positive__Important_Subsection_Bullet_2" />
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <FormattedMessage id="HDR_Positive__Important_Subsection_Bullet_3" />
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <FormattedMessage id="HDR_Positive__Important_Subsection_Bullet_4" />
                </Text>
              </ListItem>
            </UnorderedList>
          }
          image={CircularImageType.EXCLAIMATION_POINT_DOCUMENT_RED}
          imageRight
        />
      </Container>
      <Spacer variant="large" />
      <ImageDivider imageSrc={DNABanner} />
      <Spacer variant="large" />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Title variant="headline2">
              <FormattedMessage id="HDR_Positive__The_Gene_Header" values={{ gene }} />
            </Title>
            <Spacer variant="small" />
            {gene === HdrV1Gene.TP53 ? (
              <>
                <Text paragraph>
                  <FormattedMessage id="HDR_Positive__The_TP53_Gene_P1" />
                </Text>
                <Text paragraph>
                  <FormattedMessage id="HDR_Positive__The_TP53_Gene_P2" />
                </Text>
                <Text paragraph>
                  <FormattedMessage id="HDR_Positive__The_TP53_Gene_P3" />
                </Text>
                <Text paragraph>
                  <FormattedMessage id="HDR_Positive__The_TP53_Gene_P4" />
                </Text>
              </>
            ) : (
              <Text paragraph>
                <FormattedMessage
                  id="HDR_Positive__The_Gene_P1"
                  values={{
                    gene,
                    link: (chunks: ReactNode) => (
                      <ActionLink onClick={() => setTab(HdrPositiveTabIds.RISK_WITH_GENE)}>
                        <Text component="span">{chunks}</Text>
                      </ActionLink>
                    ),
                  }}
                />{' '}
                {participantDescription}
              </Text>
            )}
            {multiVariantGeneDetails.length > 1 && (
              <Text paragraph>
                <FormattedMessage
                  id="HDR_Positive__The_Gene_P2"
                  values={{
                    link: (chunks: ReactNode) => (
                      <InternalLink to={SHARE_RESULTS_WITH_PROVIDER_PATH}>{chunks}</InternalLink>
                    ),
                  }}
                />
              </Text>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Title variant="headline2">
              <FormattedMessage id="HDR_Positive__What_Should_I_Do_Next_Subsection_Title" />
            </Title>
            <Spacer variant="small" />
            <IconBulletList
              listItems={[
                {
                  content: (
                    <FormattedMessage
                      id="HDR_Positive__What_Should_I_Do_Next_Bullet_1"
                      values={{
                        // TODO: We should not do this, it is very jank.
                        // This text has bold style on PDF, and a link on web.
                        // We should probably just use a separate key for the web and pdf.
                        strong: (chunks: ReactNode) => (
                          <InternalLink
                            to={{
                              pathname: SHARE_RESULTS_WITH_PROVIDER_PATH,
                              state: { backgroundLocation: location },
                            }}
                          >
                            {chunks}
                          </InternalLink>
                        ),
                      }}
                    />
                  ),
                  contentId: '1',
                },
                {
                  content: <FormattedMessage id="HDR_Positive__What_Should_I_Do_Next_Bullet_2" />,
                  contentId: '2',
                },
                {
                  content: (
                    <FormattedMessage
                      id="HDR_Positive__What_Should_I_Do_Next_Bullet_3"
                      values={{
                        link: (chunks: ReactNode) => (
                          <InternalLink to={HDR_APPOINTMENT_SCHEDULING_PATH}>{chunks}</InternalLink>
                        ),
                      }}
                    />
                  ),
                  contentId: '3',
                },
              ]}
            />
          </Grid>
        </Grid>
        <Spacer variant="xLarge" />
        <Divider aria-hidden="true" />
        <Spacer variant="xLarge" />
        <ImageWithContentSection
          title={
            <Title variant="headline2">
              <FormattedMessage id="HDR_Positive__Clinical_Confirmation_Subsection_Title" />
            </Title>
          }
          description={
            <>
              <Text paragraph>
                <FormattedMessage id="HDR_Positive_Next_Steps__Clinical_Test_P1" />
              </Text>
              <Spacer variant="small" />
              <ExternalButtonLink to={`${config.API_HOST_URL}api/v1/aou_clinical_confirmation`}>
                <FormattedMessage id="HDR_Positive__Clinical_Confirmation_Button_Text" />
              </ExternalButtonLink>
            </>
          }
          image={CircularImageType.CLINICAL_TEST}
          imageRight
        />
        <Spacer variant="xLarge" />
        <Divider aria-hidden="true" />
        <Spacer variant="xLarge" />
        <ImageWithContentSection
          title={
            <Title variant="headline2">
              <FormattedMessage id="HDR_Positive__Should_I_Share_This_Subsection_Title" />
            </Title>
          }
          description={
            gene === HdrV1Gene.TP53 ? (
              <>
                <Text paragraph>
                  <FormattedMessage id="HDR_Positive__TP53_Should_I_Share_P1" />
                </Text>
                <Text paragraph>
                  <FormattedMessage id="HDR_Positive__TP53_Should_I_Share_P2" />
                </Text>
                <Text paragraph>
                  <FormattedMessage id="HDR_Positive__TP53_Should_I_Share_P3" />
                </Text>
              </>
            ) : (
              <>
                <Text paragraph>
                  <FormattedMessage
                    id="HDR_Positive__Should_I_Share_Paragraph_1"
                    values={{
                      link: (chunks: ReactNode) => (
                        <ShareResultsWithFamilyLink gene={gene}>
                          {chunks}
                        </ShareResultsWithFamilyLink>
                      ),
                    }}
                  />
                </Text>
                <Text paragraph>
                  <FormattedMessage id="HDR_Positive__Should_I_Share_Paragraph_2" />
                </Text>
                <Text paragraph>
                  <FormattedMessage id="HDR_Positive__Should_I_Share_Paragraph_3" />
                </Text>
                <Text paragraph>
                  <FormattedMessage id="HDR_Positive__Should_I_Share_Paragraph_4" />
                </Text>
              </>
            )
          }
          image={CircularImageType.FAMILY}
          imageRight
        />
      </Container>
    </>
  );
};
