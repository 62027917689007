import { CVL_TO_NAME, GENOME_CENTER_TO_NAME, PgxV1ReportPayload } from '@color/aou-reports';
import { Spacer } from '@color/continuum';
import { RefObject } from 'react';

import { CommonQuestions } from './Sections/CommonQuestions';
import { MethodsAndLimitations } from './Sections/MethodsAndLimitations/MethodsAndLimitations';
import { NextSteps } from './Sections/NextSteps';
import { YourResults } from './Sections/YourResults/YourResults';

interface IYourResultsTab {
  report: PgxV1ReportPayload;
  dnaAndMedicineRef: RefObject<HTMLSpanElement>;
}
export const YourResultsTab: React.FC<IYourResultsTab> = (props) => {
  const {
    report: {
      reportInfo: { genomeCenter, clinicalValidationLab },
      components: { variantDetails },
    },
    dnaAndMedicineRef,
  } = props;
  return (
    <>
      <YourResults variantDetails={variantDetails} dnaAndMedicineRef={dnaAndMedicineRef} />
      <Spacer variant="medium" />
      <NextSteps />
      <Spacer variant="medium" />
      <CommonQuestions />
      <Spacer variant="medium" />
      <MethodsAndLimitations
        genomeCenterSite={GENOME_CENTER_TO_NAME[genomeCenter]}
        cvlSite={CVL_TO_NAME[clinicalValidationLab]}
      />
      <Spacer variant="medium" />
    </>
  );
};
