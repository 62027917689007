import { Text } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { BreadcrumbsBar } from 'components/common/BreadcrumbsBar';
import { ModuleType } from 'lib/analytics/types';

import { useModuleTypeBasedOnCurrentPath } from './utilities';

export const AppointmentsBreadcrumbsBar = () => {
  const moduleType = useModuleTypeBasedOnCurrentPath();
  return (
    <BreadcrumbsBar>
      <Text>
        {moduleType === ModuleType.HDR ? (
          <FormattedMessage id="Scheduling__HDR_Breadcrumb" />
        ) : (
          <FormattedMessage id="Scheduling__PGX_Breadcrumb" />
        )}
      </Text>
    </BreadcrumbsBar>
  );
};
