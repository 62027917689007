import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { LocationWithDecision } from 'components/common/InformingLoop/types';
import { ModuleType } from 'lib/analytics/types';

import { useUserWasExternallyLinkedToCompletedInformingLoop } from '../useUserWasExternallyLinkedToCompletedInformingLoop';
import {
  HdrInformingLoopDecisionMaybeLater,
  HdrInformingLoopDecisionModule,
  HdrInformingLoopDecisionNo,
  HdrInformingLoopDecisionYes,
  HdrInformingLoopModule1,
  HdrInformingLoopModule2,
  HdrInformingLoopModule3,
  HdrInformingLoopModule4,
  HdrInformingLoopModule5,
  HdrInformingLoopModule6,
  HdrInformingLoopModule7,
  HdrInformingLoopModule8,
} from './HdrInformingLoopModules';
import { useHdrInformingLoopLocationsByModule } from './locations';

export const HdrInformingLoopRoutes = () => {
  const { path } = useRouteMatch();
  const locations = useHdrInformingLoopLocationsByModule();
  const { state } = useLocation<LocationWithDecision>();
  const shouldRedirectToDashboard = useUserWasExternallyLinkedToCompletedInformingLoop({
    moduleType: ModuleType.HDR,
  });

  return (
    <Switch>
      {shouldRedirectToDashboard && <Redirect to="/" />}
      {/* Redirect to the first module */}
      <Redirect exact path={`${path}`} to={locations.MODULE_1} />
      <Route exact path={locations.MODULE_1.pathname}>
        <HdrInformingLoopModule1 />
      </Route>
      <Route exact path={locations.MODULE_2.pathname}>
        <HdrInformingLoopModule2 />
      </Route>
      <Route exact path={locations.MODULE_3.pathname}>
        <HdrInformingLoopModule3 />
      </Route>
      <Route exact path={locations.MODULE_4.pathname}>
        <HdrInformingLoopModule4 />
      </Route>
      <Route exact path={locations.MODULE_5.pathname}>
        <HdrInformingLoopModule5 />
      </Route>
      <Route exact path={locations.MODULE_6.pathname}>
        <HdrInformingLoopModule6 />
      </Route>
      <Route exact path={locations.MODULE_7.pathname}>
        <HdrInformingLoopModule7 />
      </Route>
      <Route exact path={locations.MODULE_8.pathname}>
        <HdrInformingLoopModule8 />
      </Route>
      <Route exact path={locations.DECISION.pathname}>
        <HdrInformingLoopDecisionModule />
      </Route>
      <Route exact path={locations.RESULT.pathname}>
        {state?.decision === 'yes' && <HdrInformingLoopDecisionYes />}
        {state?.decision === 'no' && <HdrInformingLoopDecisionNo />}
        {state?.decision === 'maybe-later' && <HdrInformingLoopDecisionMaybeLater />}
      </Route>
    </Switch>
  );
};
