import { Spacer, Text } from '@color/continuum';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { GpTooltip } from 'components/common/GpTooltip/GpTooltip';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

import { PGX_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  pgxILModule5PageTitle: {
    description: 'PGx Informing Loop Page Title - Module 5',
    defaultMessage: 'Limits',
  },
  pgxIlTooltipLabelCurrentDnaData: {
    description: 'PGX Informing Loop Current DNA Data tooltip accessible label',
    defaultMessage: 'Current DNA Data Info',
  },
});

export const PgxInformingLoopModule5: React.FC = () => {
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.pgxILModule5PageTitle)}
      module={{
        analyticsInformingLoopName: PGX_INFORMING_LOOP_NAME,
        analyticsPageName: '5. Limitations',
        image: CircularImageType.MAGNIFYING_GLASS_DOCUMENT_RED,
        title: <FormattedMessage id="PGX_IL_5__Title" />,
        content: (
          <ul>
            <li>
              <Text>
                <FormattedMessage
                  description="PGX informing loop page 5 bullet 1"
                  defaultMessage="<b>These results are research results.</b> That means that neither you nor your doctor or health care provider should use them to make any changes to your medicines. Your doctor will need to confirm these results with a separate clinical test if they want to use them in your care."
                />
              </Text>
            </li>
            <Spacer variant="medium" />
            <li>
              <Text>
                <FormattedMessage
                  description="PGX informing loop page 5 bullet 2"
                  defaultMessage="<b>Doctors and other health care providers don’t make decisions based only on DNA.</b> Some other important considerations can be age, weight, health, diet, and other medicines you are taking at the time."
                />
              </Text>
            </li>
            <Spacer variant="medium" />
            <li>
              <Text>
                <FormattedMessage
                  description="PGX informing loop page 5 bullet 3"
                  defaultMessage="<b>These results will be based on current scientific understanding.</b> There is a chance they could be wrong. As we learn more information, <i>All of Us</i> could look at more genes in the future or look at these genes again to provide new results. {tooltip}"
                  values={{
                    tooltip: (
                      <GpTooltip
                        aria-label={intl.formatMessage(messages.pgxIlTooltipLabelCurrentDnaData)}
                        content={
                          <Text paragraph>
                            <FormattedMessage
                              description="PGX informing loop page 3 bullet 3 tooltip"
                              defaultMessage="What we know about DNA and health comes from many scientific studies. Most of these studies were about people with European genetic ancestry. For people with different genetic ancestry, what we know about DNA and health may not be as complete. Some DNA changes may be found more often or only among people from specific ancestry groups. Scientists may not yet know about them if these groups were not included in previous studies."
                            />
                          </Text>
                        }
                      />
                    ),
                  }}
                />
              </Text>
            </li>
          </ul>
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
