import { Accordion, Box, InlineSpacer, Spacer, useMediaQuery, useTheme } from '@color/continuum';
import { PropsWithChildren, ReactNode } from 'react';

import { AccordionIconType } from './AccordionIcon/types';
import { getAccordionIconSrc } from './AccordionIcon/utils';

export interface IAccordionWithIconProps {
  icon: AccordionIconType;
  title: ReactNode;
}

export const AccordionWithIcon: React.FC<PropsWithChildren<IAccordionWithIconProps>> = (props) => {
  const { icon, title, children } = props;
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('md'));
  const imageSrc = getAccordionIconSrc(icon);
  if (!children) {
    return null;
  }
  return (
    <Accordion
      title={
        <>
          <Spacer variant="small" />
          <Box display="flex" alignItems="center">
            <img src={imageSrc} alt="" />
            <InlineSpacer variant="medium" />
            {title}
          </Box>
          <Spacer variant="small" />
        </>
      }
      titleComponent="h4"
      titleVariant="headline2"
    >
      <Box
        sx={{
          marginLeft: (theme) => (isSmallScreen ? theme.spacing(6) : theme.spacing(7)),
          marginRight: (theme) => (isSmallScreen ? theme.spacing(6) : theme.spacing(7)),
        }}
      >
        {children}
      </Box>
    </Accordion>
  );
};
