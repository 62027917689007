import { useEventLogsQuery } from 'lib/analytics/api';
import { EventType, ModuleType } from 'lib/analytics/types';

export interface IUsePreResultsScreenWasCompletedProps {
  moduleType: ModuleType;
}

export interface IUsePreResultsScreenWasCompletedResult {
  // If the results are still loading, this will be false.
  preResultsScreenWasCompleted: boolean;
  isLoading: boolean;
}

const MODULE_TYPE_TO_EVENT_TYPE: Record<ModuleType, EventType> = {
  [ModuleType.GEM]: EventType.CHOSE_VIEW_AFTER_ANCESTRY_INFORMING_LOOP,
  [ModuleType.HDR]: EventType.CHOSE_VIEW_AFTER_HDR_PRE_RESULTS,
  [ModuleType.PGX]: EventType.CHOSE_VIEW_AFTER_PGX_PRE_RESULTS,
};

/**
 * Hook that returns whether or not the pre-results screen was completed for a given module.
 *
 * This hook tells whether or not a pre-results screen was completed based on event logs
 * from the backend.
 */
export function usePreResultsScreenWasCompleted(
  props: IUsePreResultsScreenWasCompletedProps
): IUsePreResultsScreenWasCompletedResult {
  const { moduleType } = props;
  const { data: eventLogs, isLoading: eventLogsAreLoading } = useEventLogsQuery({
    eventType: MODULE_TYPE_TO_EVENT_TYPE[moduleType],
  });
  if (eventLogsAreLoading) {
    return { preResultsScreenWasCompleted: false, isLoading: true };
  }
  return {
    isLoading: false,
    preResultsScreenWasCompleted: Boolean(eventLogs && eventLogs.count > 0),
  };
}
