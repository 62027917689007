import {
  AouDashboardCard,
  Box,
  Container,
  InternalLink,
  ReportIcon,
  Spacer,
  Text,
  Title,
} from '@color/continuum';
import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';

import { BreadcrumbsBar } from 'components/common/BreadcrumbsBar';
import { ReportIconType } from 'components/common/Reports/ReportIcon/types';
import { getReportIconSrc } from 'components/common/Reports/ReportIcon/utils';
import { HEALTH_RELATED_RESULTS_PATHS } from 'components/Dashboard/constants';
import { ModuleType } from 'lib/analytics/types';

interface IPreScreenProps {
  geneInVariantDetails: string[];
  token?: string;
}
export const PreScreen: FC<IPreScreenProps> = (props) => {
  const { geneInVariantDetails, token } = props;
  const intl = useIntl();
  const messages = {
    pageTitle: intl.formatMessage({ id: 'PGx_Home_Helmet_Title' }),
    viewResultsAccessibleLabelText: intl.formatMessage({ id: 'ghBAkH' }),
  };
  return (
    <>
      <BreadcrumbsBar>
        <InternalLink to="/hdr">Hereditary Disease Risk Results</InternalLink>
      </BreadcrumbsBar>
      <Helmet>
        <title>{messages.pageTitle}</title>
      </Helmet>
      <Container>
        <Box display="flex" gap={1} justifyContent="space-between" alignItems="center">
          <Title variant="headline2">
            <FormattedMessage id="HDR_Positive__Title" />
          </Title>
          <Text>
            <FormattedMessage
              defaultMessage="{resultNumber} results"
              values={{ resultNumber: geneInVariantDetails.length }}
            />
          </Text>
        </Box>
        <Spacer variant="large" />
        {geneInVariantDetails.map((gene, index) => (
          <AouDashboardCard
            icon={<ReportIcon iconSrc={getReportIconSrc(ReportIconType.YOUR_RESULTS_HDR)} />}
            title={<FormattedMessage id="MwiFoK" />}
            subtitle={<FormattedMessage id="HDR_positive_multiple_card" values={{ gene }} />}
            buttonContent={<FormattedMessage id="HDR_CTA_ViewResults" />}
            buttonAriaLabel={messages.viewResultsAccessibleLabelText}
            buttonVariant="color-primary"
            buttonLinkTo={`${
              HEALTH_RELATED_RESULTS_PATHS[ModuleType.HDR]
            }?reportIndex=${index}&token=${token}`}
          />
        ))}
      </Container>
    </>
  );
};
