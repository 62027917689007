import { ExternalLink, InternalButtonLink, InternalLink, Spacer } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { useConfigForIdp } from 'hooks/useConfigForIdp';

const messages = defineMessages({
  finalModulePageTitleMaybe: {
    description: 'GEM informing loop final module page title - Not Sure decision',
    defaultMessage: 'Not Sure',
  },
  finalModuleTitle: {
    description: 'GEM informing loop final page title',
    defaultMessage:
      'Thanks for taking the time to consider the benefits and risks of receiving genetic ancestry and trait results.',
  },
});

export const GemInformingLoopDecisionMaybeLater: React.FC = () => {
  const intl = useIntl();
  const { locale } = usePreferredLocale();
  const { LEARNING_CENTER_DNA_AND_GENOMICS_URL } = useConfigForIdp();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.finalModulePageTitleMaybe)}
      module={{
        analyticsInformingLoopName: 'GEM Informing Loop',
        analyticsPageName: '8. Not Sure',
        image: CircularImageType.GENES_PEOPLE,
        title: intl.formatMessage(messages.finalModuleTitle),
        content: (
          <>
            <FormattedMessage
              description="GEM informing loop page 8 maybe later decision content"
              defaultMessage="<p><b>Your decision: I’m not sure right now.</b></p>
      <p>To learn more about how genetic science works, visit the {learningCenterLink}. Learning more may help you decide if you want to see this information.</p>
      <p>If you change your mind and would like to get your genetic ancestry and trait results, you can update your decision on your {optionsLink} page.</p>"
              values={{
                optionsLink: (
                  <InternalLink to="/options">
                    <FormattedMessage
                      description="Options page link name"
                      defaultMessage="DNA Results"
                    />
                  </InternalLink>
                ),
                learningCenterLink: (
                  <ExternalLink to={LEARNING_CENTER_DNA_AND_GENOMICS_URL[locale].href}>
                    <FormattedMessage
                      description="Learning center link text"
                      defaultMessage="Learning Center"
                    />
                  </ExternalLink>
                ),
              }}
            />
            <Spacer variant="medium" />
            <InternalButtonLink to="/">
              <FormattedMessage
                defaultMessage="Back to DNA Results"
                description="GEM informing loop final page maybe later decision content back to results button"
              />
            </InternalButtonLink>
          </>
        ),
      }}
    />
  );
};
