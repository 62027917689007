import {
  Button,
  Container,
  ExternalLink,
  Grid,
  InlineSpacer,
  Spacer,
  Text,
  Title,
  useMediaQuery,
  useTheme,
} from '@color/continuum';
import { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { OverviewImage } from 'components/Appointments/OverviewImage';
import { SimplePage } from 'components/common/SimplePage/SimplePage';
import { logSchedulingPageView } from 'lib/analytics/analytics';
import { ModuleType, SegmentPageType } from 'lib/analytics/types';

import { AppointmentsBreadcrumbsBar } from './AppointmentsBreadcrumbsBar';
import { IAppointment } from './types';

interface Props {
  appointment: IAppointment;
  handleScheduleAppointmentClick: () => void;
}

const OverviewImageSection: FC = () => {
  return (
    <Grid item xs={12} sm={5}>
      <OverviewImage />
    </Grid>
  );
};
interface OverviewContentSectionProps {
  appointment: IAppointment;
}
const OverviewContentSection: FC<OverviewContentSectionProps> = (props) => {
  const { appointment } = props;
  return (
    <Grid item xs={12} sm={7}>
      <Title>
        {appointment.type === ModuleType.PGX ? (
          <FormattedMessage id="Scheduling_Overview__Title_PGx" />
        ) : (
          <FormattedMessage id="Scheduling_Overview__Title_HDR" />
        )}
      </Title>
      <Spacer variant="xLarge" />
      <Text>
        <FormattedMessage id="Scheduling_Overview__Text1" />
        <Spacer variant="large" />
        <FormattedMessage id="Scheduling_Overview__Help_By__Title" />
        <ul>
          <li>
            <FormattedMessage id="Scheduling_Overview__Help_By__Bullet1" />
          </li>
          <Spacer variant="small" />
          <li>
            <FormattedMessage id="Scheduling_Overview__Help_By__Bullet2" />
          </li>
          <Spacer variant="small" />
          <li>
            <FormattedMessage id="Scheduling_Overview__Help_By__Bullet3" />
          </li>
        </ul>
        <Spacer variant="small" />
        <FormattedMessage id="Scheduling_Overview__Text2" />
        <Spacer variant="small" />
        <FormattedMessage id="Scheduling_Overview__Text3" />
        <Spacer variant="small" />
        <FormattedMessage
          id="Scheduling_Overview__Text4_v2"
          values={{
            phoneNumberLink1: <ExternalLink to="tel:8449622385">(844) 962-2385</ExternalLink>,
            phoneNumberLink2: <ExternalLink to="tel:8448422855">(844) 842-2855</ExternalLink>,
            emailLink: (
              <ExternalLink to="mailto:help@joinallofus.org">help@joinallofus.org</ExternalLink>
            ),
          }}
        />
      </Text>
      <Spacer variant="xxxLarge" />
    </Grid>
  );
};
export const Overview: React.FC<Props> = (props) => {
  const { appointment, handleScheduleAppointmentClick } = props;
  const history = useHistory();
  const location = useLocation();

  const handleBackButtonClick = () => {
    // if you have a location key that means you routed in-app
    if (location.key) {
      history.goBack();
    } else {
      history.push('/'); // send to dashboard
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // Lokalise-hosted content in English and Spanish
  const intl = useIntl();
  const messages = {
    back: intl.formatMessage({ id: 'Scheduling_Overview__Button__Back' }),
    next: intl.formatMessage({ id: 'Scheduling_Overview__Button__Next' }),
    pageTitleHdr: intl.formatMessage({ id: 'HDR_Scheduling_Helmet_Title' }),
    pageTitlePgx: intl.formatMessage({ id: 'PGX_Scheduling_Helmet_Title' }),
    breadcrumbText: intl.formatMessage({ id: 'Scheduling_Overview__Breadcrumb_Text' }),
  };

  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    logSchedulingPageView(appointment.type, SegmentPageType.GC_SCHEDULING_OVERVIEW);
  }, [appointment]);

  return (
    <SimplePage
      helmetTitle={
        appointment.type === ModuleType.PGX ? messages.pageTitlePgx : messages.pageTitleHdr
      }
    >
      <AppointmentsBreadcrumbsBar />
      <Spacer variant="small" />
      <Container>
        <Grid container spacing={2}>
          {isMobile ? (
            <>
              <OverviewImageSection />
              <OverviewContentSection appointment={appointment} />
            </>
          ) : (
            <>
              <OverviewContentSection appointment={appointment} />
              <OverviewImageSection />
            </>
          )}
        </Grid>
        <Button onClick={handleScheduleAppointmentClick}>{messages.next}</Button>
        <InlineSpacer variant="medium" />
        <Button variant="color-secondary" onClick={handleBackButtonClick}>
          {messages.back}
        </Button>
        <Spacer variant="xxxLarge" />
      </Container>
    </SimplePage>
  );
};
