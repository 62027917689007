import { FormControlLabel, IMuiTheme, Radio, useId } from '@color/continuum';
import { formatInTimeZone } from 'date-fns-tz';

export interface IAppointmentSlotRadioProps {
  onAppointmentSlotRadioChange?: () => void;
  selected: boolean;
  appointmentDate: Date;
  appointmentTimezone: string;
}

export const AppointmentSlotRadio: React.FC<IAppointmentSlotRadioProps> = (props) => {
  const { onAppointmentSlotRadioChange, appointmentDate, selected, appointmentTimezone } = props;
  const ariaLabel = formatInTimeZone(appointmentDate, appointmentTimezone, 'h:mm a yyyy-MM-dd');
  const label = formatInTimeZone(appointmentDate, appointmentTimezone, 'h:mm a');
  const appointmentSlotId = useId();
  return (
    <FormControlLabel
      id={appointmentSlotId}
      name="appointmentSlot"
      checked={selected}
      label={label}
      aria-label={ariaLabel}
      onChange={onAppointmentSlotRadioChange}
      control={<Radio icon={<></>} checkedIcon={<></>} />}
      sx={(theme: IMuiTheme) => ({
        backgroundColor: selected
          ? theme.palette.action.iconUnfocused
          : theme.palette.secondary[100],
        borderLeft: `${theme.spacing(1)} solid ${theme.palette.secondary.main}`,
        color: selected ? theme.palette.common.white : theme.palette.primary.main,
        height: theme.spacing(4),
        margin: 0,
        width: '100%',
        paddingLeft: 1,
        '& > .MuiRadio-root': {
          padding: 0,
        },
      })}
    />
  );
};
