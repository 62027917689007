import { FormattedMessage, useIntl } from 'react-intl';

import { ModuleType } from 'lib/analytics/types';
import { areIlLoopsDisabled } from 'lib/waffle/util';

interface Props {
  moduleType: ModuleType.HDR | ModuleType.PGX;
  decisionTimestamp?: string;
}
export const useHealthRelatedResultsContent = (props: Props) => {
  const { decisionTimestamp } = props;
  const intl = useIntl();
  const decisionDate = decisionTimestamp ? new Date(decisionTimestamp) : new Date();
  const showDisabledIlText = areIlLoopsDisabled();
  return {
    informingLoopCardTitle: {
      [ModuleType.HDR]:
        showDisabledIlText === false ? (
          <FormattedMessage
            description="HDR informing loop uncompleted card title"
            defaultMessage="Choose to get hereditary disease risk results"
          />
        ) : (
          <FormattedMessage
            description="HDR informing loop uncompleted card title - disabled"
            defaultMessage="Hereditary Disease Risk results are no longer available."
          />
        ),
      [ModuleType.PGX]:
        showDisabledIlText === false ? (
          <FormattedMessage id="PGX_Card__Review_IL_Title" />
        ) : (
          <FormattedMessage defaultMessage="Medicine and Your DNA results are no longer available." />
        ),
    },
    informingLoopCardSubtitle: {
      [ModuleType.HDR]:
        showDisabledIlText === false ? (
          <FormattedMessage id="HDR_Card__Review_IL_Subtitle" />
        ) : (
          <FormattedMessage defaultMessage="We are making changes to how we return information to participants. Stay tuned for more updates from <i>All of Us.</i>" />
        ),
      [ModuleType.PGX]:
        showDisabledIlText === false ? (
          <FormattedMessage id="PGX_Card__Review_IL_Subtitle" />
        ) : (
          <FormattedMessage defaultMessage="We are making changes to how we return information to participants. Stay tuned for more updates from <i>All of Us.</i>" />
        ),
    },
    choseNotToGetResultsTitle: {
      [ModuleType.HDR]:
        showDisabledIlText === false ? (
          <FormattedMessage
            description="HDR informing loop completed card no decision title"
            defaultMessage="You’ve chosen not to get hereditary disease risk results"
          />
        ) : (
          <FormattedMessage
            description="HDR informing loop completed card no decision title - disabled"
            defaultMessage="Hereditary Disease Risk results are no longer available."
          />
        ),
      [ModuleType.PGX]:
        showDisabledIlText === false ? (
          <FormattedMessage id="PGX_Card__Denied_Results_Title" />
        ) : (
          <FormattedMessage defaultMessage="Medicine and Your DNA results are no longer available." />
        ),
    },
    choseNotToGetResultsSubtitle: {
      [ModuleType.HDR]:
        showDisabledIlText === false ? (
          <FormattedMessage
            id="HDR_Card__Results_Denied_Subtitle"
            values={{
              date: intl.formatDate(decisionDate, {
                year: '2-digit',
                month: 'numeric',
                day: 'numeric',
              }),
            }}
          />
        ) : (
          <FormattedMessage defaultMessage="We are making changes to how we return information to participants. Stay tuned for more updates from <i>All of Us.</i>" />
        ),
      [ModuleType.PGX]:
        showDisabledIlText === false ? (
          <FormattedMessage
            id="PGX_Card__Results_Denied_Subtitle"
            values={{
              date: intl.formatDate(decisionDate, {
                year: '2-digit',
                month: 'numeric',
                day: 'numeric',
              }),
            }}
          />
        ) : (
          <FormattedMessage defaultMessage="We are making changes to how we return information to participants. Stay tuned for more updates from <i>All of Us.</i>" />
        ),
    },
    resultsTitle: {
      [ModuleType.HDR]: (
        <FormattedMessage
          description="HDR informing loop completed card yes decision title"
          defaultMessage="Hereditary disease risk"
        />
      ),
      [ModuleType.PGX]: <FormattedMessage id="PGX_Card__Results_Pending_Title" />,
    },
    preparingResultsSubtitle: {
      [ModuleType.HDR]: (
        <FormattedMessage
          description="HDR informing loop completed card yes decision subtitle"
          defaultMessage="We are preparing your results. We will notify you when they are ready. Results may take a few weeks to a few months."
        />
      ),
      [ModuleType.PGX]: (
        <FormattedMessage
          description="PGX informing loop completed card yes decision subtitle"
          defaultMessage="We are preparing your results. We will notify you when they are ready. Results may take a few weeks to a few months."
        />
      ),
    },
    resultsReadySubtitle: {
      [ModuleType.HDR]:
        showDisabledIlText === false ? (
          <FormattedMessage id="HDR_Card__Results_Ready_Subtitle" />
        ) : (
          <FormattedMessage
            description="HDR_Card__Results_Ready_Subtitle - disabled"
            defaultMessage="Your Hereditary Disease Risk results are ready."
          />
        ),
      [ModuleType.PGX]:
        showDisabledIlText === false ? (
          <FormattedMessage id="PGX_Card__Results_Ready_Subtitle" />
        ) : (
          <FormattedMessage
            description="PGX_Card__Results_Ready_Subtitle - disabled"
            defaultMessage="Medicine and Your DNA results are ready."
          />
        ),
    },
    reviewOptions: (
      <FormattedMessage
        description="HDR informing loop completed card review options button"
        defaultMessage="Review Options"
      />
    ),
    reviewOptionsAccessibleLabelText: {
      [ModuleType.HDR]: intl.formatMessage({
        description:
          'HDR informing loop completed card review options button accessible label text',
        defaultMessage: 'Review options for hereditary disease risk results',
      }),
      [ModuleType.PGX]: intl.formatMessage({
        description:
          'PGX informing loop completed card review options button accessible label text',
        defaultMessage: 'Review options for medicine and your DNA results',
      }),
    },
    getStarted: (
      <FormattedMessage
        description="HGM informing loop uncompleted card get started button"
        defaultMessage="Get Started"
      />
    ),
    getStartedAccessibleLabelText: {
      [ModuleType.HDR]: intl.formatMessage({
        description: 'HDR informing loop uncompleted card get started button accessible label text',
        defaultMessage: 'Get started with hereditary disease risk results',
      }),
      [ModuleType.PGX]: intl.formatMessage({
        description: 'PGX informing loop uncompleted card get started button accessible label text',
        defaultMessage: 'Get started with medicine and your DNA results',
      }),
    },
    viewResults: (
      <FormattedMessage
        description="HGM results ready view results button"
        defaultMessage="View Results"
      />
    ),
    viewResultsAccessibleLabelText: {
      [ModuleType.HDR]: intl.formatMessage({
        description: 'HDR results ready view results button accessible label text',
        defaultMessage: 'View hereditary disease risk results',
      }),
      [ModuleType.PGX]: intl.formatMessage({
        description: 'PGX results ready view results button accessible label text',
        defaultMessage: 'View medicine and your DNA results',
      }),
    },
    hdrGcBlockedCardSubtitle: <FormattedMessage id="HDR_Card__GC_Blocked_Subtitle" />,
    hdrScheduleAppointmentButtonText: (
      <FormattedMessage
        description="HDR schedule appointment"
        defaultMessage="Schedule Appointment"
      />
    ),
    hdrScheduleAppointmentButtonAccessibleLabelText: intl.formatMessage({
      description: 'HDR schedule appointment accessible label text',
      defaultMessage: 'Schedule appointment about hereditary disease risk results',
    }),
  };
};
