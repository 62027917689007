import { FormattedMessage } from 'react-intl';

import { useAppAuthContext } from 'components/App/Providers/AppAuthProvider';
import { ResultBanner, ResultBannerIconType } from 'components/common/ResultBanner';

import { Trait } from '../../types';
import { TRAIT_RESULTS_OUTCOME_BANNER_CONTENT } from './content';

interface Props {
  trait: Trait;
  outcome: string;
}

export const TraitResultsOutcomeBanner: React.FC<Props> = (props) => {
  const { trait, outcome } = props;
  const { currentUser } = useAppAuthContext();

  const outcomeContent = TRAIT_RESULTS_OUTCOME_BANNER_CONTENT[trait][outcome];
  if (!outcomeContent) {
    return null;
  }

  return (
    <ResultBanner
      icon={(trait as unknown) as ResultBannerIconType}
      subtitle={<FormattedMessage defaultMessage="Your result:" />}
      title={
        <>
          {currentUser?.firstName || (
            <FormattedMessage
              defaultMessage="User"
              description="Placeholder text in trait result outcome banner when first name of user is not available"
            />
          )}
          {', '}
          <FormattedMessage {...outcomeContent} />
        </>
      }
    />
  );
};
