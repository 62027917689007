import { IPaginatedResponse } from '@color/continuum';
import { UseQueryResult } from 'react-query';

import { ApiError } from 'lib/api/types';

import { useQuestionResponsesQuery } from './api';
import { IQuizResponse, TAnswerMap } from './types';

interface PreviousQuizSessionResult {
  previousAnswers: TAnswerMap;
  answersAreLoading: boolean;
  answerResponse: UseQueryResult<IPaginatedResponse<IQuizResponse>, ApiError>;
  sessionId?: string;
}

/**
 * Fetches the answers from a previous session of this quiz to let the user resume from where they left off
 * @param quizName The name of the quiz we're fetching
 */
export function usePreviousQuizSession(quizName: string): PreviousQuizSessionResult {
  const answerResponse = useQuestionResponsesQuery(quizName);
  const { data, isLoading } = answerResponse;

  // Gets the sessionId from the last quizResponse,
  // or underfined if there are no responses / the last quizResponse doesn't have a sessionId.
  const sessionId = data?.results?.slice(-1)[0]?.sessionId;
  const previousAnswers: TAnswerMap = {};
  data?.results?.forEach((quizResponse: IQuizResponse) => {
    previousAnswers[quizResponse.questionId] = quizResponse.answer;
  });
  return {
    answersAreLoading: isLoading,
    previousAnswers,
    answerResponse,
    sessionId,
  };
}
