import { Container, InternalButtonLink, Spacer, Text, Title } from '@color/continuum';
import { useIntl } from 'react-intl';

import { SimplePage } from 'components/common/SimplePage/SimplePage';

import { AppointmentsBreadcrumbsBar } from './AppointmentsBreadcrumbsBar';

export const CancelConfirmation: React.FC = () => {
  // Lokalise-hosted content in English and Spanish
  const intl = useIntl();
  const messages = {
    helmetTitle: intl.formatMessage({ id: 'Scheduling_Cancel__Page__Helmet_Title' }),
    title: intl.formatMessage({ id: 'Scheduling_Cancel__Page__Title' }),
    text: intl.formatMessage({ id: 'Scheduling_Cancel__Page__Text' }),
    exit: intl.formatMessage({ id: 'Scheduling_Cancel__Page__Exit' }),
  };

  return (
    <SimplePage helmetTitle={messages.helmetTitle}>
      <AppointmentsBreadcrumbsBar />
      <Spacer variant="small" />
      <Container>
        <Title>{messages.title}</Title>
        <Spacer variant="small" />
        <Text>{messages.text}</Text>
        <Spacer variant="medium" />
        <InternalButtonLink to="/">{messages.exit}</InternalButtonLink>
      </Container>
    </SimplePage>
  );
};
