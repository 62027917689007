import {
  ActionLink,
  Box,
  IMuiTheme,
  MuiTable,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  mergeSxProps,
} from '@color/continuum';
// TODO Import from CDS rather than MUI. In CDS-341 we turned on lint warnings on direct MUI imports. This line is disabled because GP CI sets the rule to error instead of warn.
// Swap this for a CDS import instead of a MUI import.
// eslint-disable-next-line no-restricted-imports
import { TableContainer } from '@mui/material';
import { addDays, eachDayOfInterval, isWeekend } from 'date-fns';
import { ReactNode, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IAppointment } from '../types';
import { deprecatedFormatTime } from '../utilities';
import { AppointmentSlotRadio } from './AppointmentSlotRadio';
import { AVAILABLE_HOURS, NUMBER_OF_TIME_SLOTS_PER_DAY, TIME_SLOTS } from './constants';
import { createDateFromTimeSlot } from './utils';

const headerCellStyles = (theme: IMuiTheme) => ({
  textAlign: 'center',
  minWidth: theme.spacing(7),
  minHeight: theme.spacing(3),
  padding: theme.spacing(1),
  border: `0px solid ${theme.palette.divider}`,
  borderTop: '1px',
  borderBottom: '2px',
  '&:first-child': {
    width: theme.spacing(8),
    borderLeft: '1px',
  },
  '&:last-child': {
    borderRight: '1px',
  },
});

interface IAvailabilityByWeekProps {
  setAppointment: (appointment: IAppointment) => void;
  appointment: IAppointment;
  startOfSelectedWeek: Date;
  appointmentAvailabilities: Date[];
  nextWeek: () => void;
  appointmentAvailabilitiesLoading: Boolean;
}

export const AvailabilityByWeek: React.FC<IAvailabilityByWeekProps> = (props) => {
  const {
    appointment,
    startOfSelectedWeek,
    appointmentAvailabilities,
    setAppointment,
    nextWeek,
    appointmentAvailabilitiesLoading,
  } = props;
  const intl = useIntl();
  const dayOfWeekLabels = {
    0: intl.formatMessage({ id: 'Scheduling_Schedule__Sun' }),
    1: intl.formatMessage({ id: 'Scheduling_Schedule__Mon' }),
    2: intl.formatMessage({ id: 'Scheduling_Schedule__Tues' }),
    3: intl.formatMessage({ id: 'Scheduling_Schedule__Wed' }),
    4: intl.formatMessage({ id: 'Scheduling_Schedule__Thurs' }),
    5: intl.formatMessage({ id: 'Scheduling_Schedule__Fri' }),
    6: intl.formatMessage({ id: 'Scheduling_Schedule__Sat' }),
  };

  const daysOfWeek = eachDayOfInterval({
    start: startOfSelectedWeek,
    end: addDays(startOfSelectedWeek, 6),
  });

  const appointmentAvailabilitiesSet = useMemo(
    () =>
      new Set<string>(
        appointmentAvailabilities.map((appointmentDate) => appointmentDate.toISOString())
      ),
    [appointmentAvailabilities]
  );
  return (
    <TableContainer component={Paper}>
      <MuiTable aria-label="Appointment Slots" sx={{ height: '440px' }}>
        <TableHead sx={{ background: (theme: IMuiTheme) => theme.palette.background.default }}>
          <TableRow>
            <TableCell sx={headerCellStyles} />
            {daysOfWeek.map((dayOfWeek) => (
              <TableCell
                key={dayOfWeek.getDay()}
                sx={mergeSxProps(headerCellStyles, {
                  color: (theme: IMuiTheme) =>
                    isWeekend(dayOfWeek) ? theme.palette.text.disabled : theme.palette.text.primary,
                })}
              >
                {dayOfWeekLabels[dayOfWeek.getDay() as keyof typeof dayOfWeekLabels]}{' '}
                {dayOfWeek.getDate()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              sx={(theme) => ({
                border: `1px solid ${theme.palette.divider}`,
                borderTop: '0px',
                borderBottom: '0px',
                fontWeight: 'normal',
                fontSize: theme.typography.body1.fontSize,
                p: 0,
              })}
              rowSpan={NUMBER_OF_TIME_SLOTS_PER_DAY}
            >
              <Box
                display="flex"
                height="100%"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
              >
                {AVAILABLE_HOURS.map((hour) => (
                  <Text>{deprecatedFormatTime(hour, appointment.timezone)}</Text>
                ))}
              </Box>
            </TableCell>
          </TableRow>
          {appointmentAvailabilitiesSet.size === 0 && !appointmentAvailabilitiesLoading ? (
            <TableCell
              colSpan={7}
              sx={{
                textAlign: 'center',
                verticalAlign: 'middle',
                display: 'table-cell',
                marginX: 'auto',
              }}
            >
              <FormattedMessage
                id="Scheduling_Blank_Calendar_Message"
                values={{
                  link: (chunks: ReactNode) => (
                    <ActionLink onClick={nextWeek} sx={{ paddingBottom: 0.5 }}>
                      {chunks}
                    </ActionLink>
                  ),
                }}
              />
            </TableCell>
          ) : (
            <>
              {TIME_SLOTS.map((timeSlot) => (
                <TableRow key={`${timeSlot.hour}:${timeSlot.minute}`}>
                  {daysOfWeek.map((dayOfWeek) => {
                    const appointmentDate = createDateFromTimeSlot({
                      timeSlot,
                      startOfDay: dayOfWeek,
                    });
                    const appointmentAvailable = appointmentAvailabilitiesSet.has(
                      appointmentDate.toISOString()
                    );

                    return (
                      <TableCell
                        key={dayOfWeek.toISOString()}
                        sx={(theme) => ({
                          textAlign: 'left',
                          border: `1px solid ${theme.palette.common.white}`,
                          borderRight: `1px solid ${theme.palette.divider}`,
                          p: 0,
                        })}
                      >
                        {appointmentAvailable ? (
                          <AppointmentSlotRadio
                            appointmentDate={appointmentDate}
                            appointmentTimezone={appointment.timezone}
                            onAppointmentSlotRadioChange={() =>
                              setAppointment({
                                ...appointment,
                                timestamp: appointmentDate,
                              })
                            }
                            selected={
                              appointmentDate.toISOString() ===
                              appointment?.timestamp?.toISOString()
                            }
                          />
                        ) : (
                          <Box sx={{ height: (theme: IMuiTheme) => theme.spacing(4) }} />
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};
