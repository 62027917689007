import { useEffect } from 'react';

import { useCreateHealthResultViewedEventMutation } from 'lib/analytics/health-result-viewed';
import { ModuleType } from 'lib/analytics/types';

export interface IResultsViewedProps {
  moduleType: ModuleType.HDR | ModuleType.PGX;
  token?: string;
}
export const ResultViewed: React.FC<IResultsViewedProps> = (props: IResultsViewedProps) => {
  const { moduleType, token } = props;
  const { mutate: saveHealthResultViewedEvent } = useCreateHealthResultViewedEventMutation(token);

  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    saveHealthResultViewedEvent({ moduleType });
  }, [saveHealthResultViewedEvent, moduleType]);

  return null;
};
