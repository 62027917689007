import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { LocationWithDecision } from 'components/common/InformingLoop/types';
import { ModuleType } from 'lib/analytics/types';

import { useUserWasExternallyLinkedToCompletedInformingLoop } from '../useUserWasExternallyLinkedToCompletedInformingLoop';
import {
  GemInformingLoopDecisionMaybeLater,
  GemInformingLoopDecisionModule,
  GemInformingLoopDecisionNo,
  GemInformingLoopDecisionYes,
  GemInformingLoopModule1,
  GemInformingLoopModule2,
  GemInformingLoopModule3,
  GemInformingLoopModule4,
  GemInformingLoopModule5,
  GemInformingLoopModule6,
} from './GemInformingLoopModules';
import { useGemInformingLoopLocationsByModule } from './locations';

export const GemInformingLoopRoutes = () => {
  const { path } = useRouteMatch();
  const locations = useGemInformingLoopLocationsByModule();
  const { state } = useLocation<LocationWithDecision>();
  const shouldRedirectToDashboard = useUserWasExternallyLinkedToCompletedInformingLoop({
    moduleType: ModuleType.GEM,
  });
  return (
    <Switch>
      {shouldRedirectToDashboard && <Redirect to="/" />}
      {/* Redirect to the first module */}
      <Redirect exact path={`${path}`} to={locations.MODULE_1} />
      <Route exact path={locations.MODULE_1.pathname}>
        <GemInformingLoopModule1 />
      </Route>
      <Route exact path={locations.MODULE_2.pathname}>
        <GemInformingLoopModule2 />
      </Route>
      <Route exact path={locations.MODULE_3.pathname}>
        <GemInformingLoopModule3 />
      </Route>
      <Route exact path={locations.MODULE_4.pathname}>
        <GemInformingLoopModule4 />
      </Route>
      <Route exact path={locations.MODULE_5.pathname}>
        <GemInformingLoopModule5 />
      </Route>
      <Route exact path={locations.MODULE_6.pathname}>
        <GemInformingLoopModule6 />
      </Route>
      <Route exact path={locations.DECISION.pathname}>
        <GemInformingLoopDecisionModule />
      </Route>
      <Route exact path={locations.RESULT.pathname}>
        {state?.decision === 'yes' && <GemInformingLoopDecisionYes />}
        {state?.decision === 'no' && <GemInformingLoopDecisionNo />}
        {state?.decision === 'maybe-later' && <GemInformingLoopDecisionMaybeLater />}
      </Route>
    </Switch>
  );
};
