import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

const messages = defineMessages({
  gemILModule4PageTitle: {
    description: 'GEM informing loop - module 4 page title',
    defaultMessage: 'Risks',
  },
});

export const GemInformingLoopModule4: React.FC = () => {
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.gemILModule4PageTitle)}
      module={{
        analyticsInformingLoopName: 'GEM Informing Loop',
        analyticsPageName: '4. Risks',
        image: CircularImageType.EXCLAIMATION_POINT_DOCUMENT_BLUE,
        title: (
          <FormattedMessage
            description="GEM informing loop page 4 title"
            defaultMessage="What are the risks of learning about my genetic ancestry and traits?"
          />
        ),
        content: (
          <FormattedMessage
            description="GEM informing loop page 4 content"
            defaultMessage="<p>When you signed the Consent to Get DNA Results, you learned that there are different kinds of risks to learning about your DNA. Here are some of the ones that are important to remember before deciding if you want to see your genetic ancestry and trait results.</p>
            <p>You could get information you weren’t expecting in your results. For example:</p>
            <ul>
                <li>Your results may suggest your ancestors did not come from where you thought they came from.</li>
                <li>Your genetic ancestry results will not tell you who you are related to. However, your results may make you wonder if you are related to a family member in the way you thought. Keep in mind that DNA results are not always the same for all family members.</li>
            </ul>
            <p>Getting information you didn’t expect may confuse or worry you. If you contact the <i>All of Us</i> Support Center, we can help answer your questions. We can also help you find more resources.</p>"
          />
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
