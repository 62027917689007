import { Spacer, Text } from '@color/continuum';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { GpTooltip } from 'components/common/GpTooltip/GpTooltip';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

import { HDR_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  hdrILModule7PageTitle: {
    description: 'HDR Informing Loop Page Title - Module 7',
    defaultMessage: 'Limits',
  },
  hdrIlTooltipLabelAncestryGroupsDna: {
    description: 'HDR Informing Loop Ancestry Groups DNA tooltip accessible label',
    defaultMessage: 'Ancestry Groups DNA Info',
  },
});

export const HdrInformingLoopModule7: React.FC = () => {
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.hdrILModule7PageTitle)}
      module={{
        analyticsInformingLoopName: HDR_INFORMING_LOOP_NAME,
        analyticsPageName: '7. Limitations',
        image: CircularImageType.MAGNIFYING_GLASS_DOCUMENT_RED,
        title: <FormattedMessage id="HDR_IL_7__Title" />,
        content: (
          <Text>
            <ul>
              <li>
                <FormattedMessage
                  description="HDR informing loop page 7 content bullet 1"
                  defaultMessage="<b>These are research results. You should not change your medical care</b> based on these results. These results would need to be confirmed with a clinical DNA test before your doctor or health care provider can use them in your care."
                />
              </li>
              <Spacer variant="medium" />
              <li>
                <FormattedMessage
                  description="HDR informing loop page 7 content bullet 2"
                  defaultMessage="<b>These results do not tell you whether you have or will get a disease.</b> Many factors can contribute to whether or not you develop a disease. Your DNA may contribute, but it is not the only factor."
                />
              </li>
              <Spacer variant="medium" />
              <li>
                <FormattedMessage
                  description="HDR informing loop page 7 content bullet 3"
                  defaultMessage="<b>There are a lot of genes that can affect disease risk and we won’t look at all of them.</b> There could even be something we couldn’t see or can’t understand in the genes that we did look at. Scientific understanding of DNA and diseases is always growing."
                />
              </li>
              <Spacer variant="medium" />
              <li>
                <FormattedMessage
                  description="HDR informing loop page 7 content bullet 4"
                  defaultMessage="<b>These results will be based on current scientific understanding.</b> There is a chance they could be wrong. As we learn more information, <i>All of Us</i> could look at more genes in the future or look at these genes again to provide new results. {tooltip}"
                  values={{
                    tooltip: (
                      <GpTooltip
                        aria-label={intl.formatMessage(messages.hdrIlTooltipLabelAncestryGroupsDna)}
                        content={
                          <Text>
                            <FormattedMessage
                              description="HDR informing loop page 7 bullet 4 tooltip"
                              defaultMessage="What we know about DNA and health comes from many scientific studies. Most of these studies were about people with European genetic ancestry. For people with different genetic ancestry, what we know about DNA and health may not be as complete. Some DNA changes may be found more often or only among people from specific ancestry groups. Scientists may not yet know about them if these groups were not included in previous studies."
                            />
                          </Text>
                        }
                      />
                    ),
                  }}
                />
              </li>
            </ul>
          </Text>
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
