import { Box, ExternalLink, Spacer, Text, Title } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { useConfigForIdp } from 'hooks/useConfigForIdp';

export const HideOptionsDisclaimerSection: React.FC = () => {
  const { AGREEMENTS_URL } = useConfigForIdp();
  return (
    <>
      <Spacer variant="xLarge" />
      <Title variant="headline3">
        <FormattedMessage
          description="Title for hiding options disclaimer section"
          defaultMessage="What happens when you hide results"
        />
      </Title>
      <Box
        component="ul"
        sx={{
          paddingInlineStart: 3,
          '& li': {
            marginX: 0,
            marginY: 2,
          },
        }}
      >
        <li>
          <Text>
            <FormattedMessage
              description="Hide options disclaimer bullet point 1"
              defaultMessage="You won't see those results in your list of DNA results any more."
            />
          </Text>
        </li>
        <li>
          <Text>
            <FormattedMessage
              description="Hide options disclaimer bullet point 2"
              defaultMessage="Your DNA can still be studied by researchers. To change this, visit the {agreementsLink} page to review and manage your consent documents."
              values={{
                agreementsLink: (
                  <ExternalLink to={AGREEMENTS_URL.href} linkShouldOpenInCurrentTab>
                    <FormattedMessage
                      description="Agreements page link text"
                      defaultMessage="Agreements"
                    />
                  </ExternalLink>
                ),
              }}
            />
          </Text>
        </li>
        <li>
          <Text>
            <FormattedMessage
              description="Hide options disclaimer bullet point 3"
              defaultMessage="If researchers learn new things about your DNA that would change your results, we will not let you know about these changes."
            />
          </Text>
        </li>
        <li>
          <Text>
            <FormattedMessage
              description="Hide options disclaimer bullet point 4"
              defaultMessage="You can always come back and choose to show your results again."
            />
          </Text>
        </li>
        <li>
          <Text>
            <FormattedMessage
              description="Hide options disclaimer bullet point 5"
              defaultMessage="You will still be able to talk to an <i>All of Us</i> genetic counselor, but they will not be able to see the results you’ve hidden. You can ask them general questions about DNA and genetic testing. Whether you hide them or not, they will not be able to see your genetic ancestry and trait results."
            />
          </Text>
        </li>
      </Box>
      <Spacer variant="xLarge" />
    </>
  );
};
