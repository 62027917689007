import { Text } from '@color/continuum';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { GpTooltip } from 'components/common/GpTooltip/GpTooltip';
import { FirstModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/FirstModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';

const messages = defineMessages({
  ancestryILModule1PageTitle: {
    description: 'Ancestry Informing Loop Page Title - Module 1',
    defaultMessage: 'Location',
  },
  ancestryIlTooltipLabelNonEurope: {
    description: 'Ancestry Informing Loop Non-Europe results tooltip accessible label',
    defaultMessage: 'Non-Europe Genetic Ancestry Info',
  },
});

export const AncestryInformingLoopModule1: React.FC = () => {
  const intl = useIntl();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.ancestryILModule1PageTitle)}
      module={{
        analyticsInformingLoopName: 'Ancestry Informing Loop',
        analyticsPageName: '1. Start',
        image: CircularImageType.ANCESTRY_MAP_POINTS,
        title: intl.formatMessage({
          description: 'Ancestry informing loop page 1 title',
          defaultMessage: 'Learn where your ancestors may have lived.',
        }),
        content: (
          <FormattedMessage
            description="Ancestry informing loop page 1 content"
            defaultMessage="<p>Genetic ancestry results are based on the information we have right now. This means your results will not tell you the origin of your ancestors for sure and they may change as we learn more.</p><p>People whose ancestors are not from Europe are more likely to have less precise results. {tooltip}</p><p>Also, genetic ancestry is not medical advice or meant to affect your health care.</p>"
            values={{
              tooltip: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.ancestryIlTooltipLabelNonEurope)}
                  content={
                    <Text>
                      {intl.formatMessage({
                        description: 'Ancestry informing loop page 1 tooltip content',
                        defaultMessage:
                          'To create your genetic ancestry results, we compare your DNA to publicly available DNA data sets. These data sets have more DNA information from people with European genetic ancestry. This means genetic ancestry results may be more precise for people with European genetic ancestry. We hope to improve the estimates as we collect more data from people with other genetic ancestries.',
                      })}
                    </Text>
                  }
                />
              ),
            }}
          />
        ),
      }}
      footer={<FirstModuleInformingLoopFooter />}
    />
  );
};
