import { ExternalLink, Grid, Spacer, Text } from '@color/continuum';
import { range } from 'lodash';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { GpTooltip } from 'components/common/GpTooltip/GpTooltip';
import { useConfigForIdp } from 'hooks/useConfigForIdp';

interface Props {
  gene: string;
  isNearGene?: boolean;
  rsids: string[];
  genotypes: string[];
}

const messages = defineMessages({
  traitResultsTooltipLabelDnaMarker: {
    description: 'Trait Genotype DNA Marker tooltip accessible label',
    defaultMessage: 'DNA Marker Info',
  },
  traitResultsTooltipLabelYourResult: {
    description: 'Trait Your Result tooltip accessible label',
    defaultMessage: 'Your Result Info',
  },
});

export const TraitGenotypeChart: React.FC<Props> = (props) => {
  const { gene, isNearGene = false, rsids, genotypes } = props;
  const intl = useIntl();
  const { LEARNING_CENTER_URL } = useConfigForIdp();

  return (
    <>
      <Grid
        container
        spacing={1}
        sx={(theme) => ({
          background: theme.palette.secondary[100],
          paddingX: {
            xs: 1,
            sm: 5,
          },
          paddingY: {
            xs: 5,
            sm: 6,
          },
        })}
      >
        <Grid container item spacing={2}>
          <Grid item xs={4}>
            <Text bold variant="body2">
              <FormattedMessage
                defaultMessage="DNA marker* {tooltip}"
                description="Trait genotype chart column header for DNA marker"
                values={{
                  tooltip: (
                    <GpTooltip
                      aria-label={intl.formatMessage(messages.traitResultsTooltipLabelDnaMarker)}
                      content={
                        <Text>
                          <FormattedMessage
                            description="Trait genotype chart DNA marker tooltip"
                            defaultMessage="An rs number is an identification number given to a SNP. SNPs, or single nucleotide polymorphisms, are places in DNA that commonly vary between individuals."
                          />
                        </Text>
                      }
                    />
                  ),
                }}
              />
            </Text>
          </Grid>
          <Grid item xs={4}>
            <Text bold variant="body2">
              <FormattedMessage
                defaultMessage="Gene"
                description="Trait genotype chart column header for gene name"
              />
            </Text>
          </Grid>
          <Grid item xs={4}>
            <Text bold variant="body2">
              <FormattedMessage
                defaultMessage="Your result* {tooltip}"
                description="Trait genotype chart column header for genotype"
                values={{
                  tooltip: (
                    <GpTooltip
                      aria-label={intl.formatMessage(messages.traitResultsTooltipLabelYourResult)}
                      content={
                        <Text>
                          <FormattedMessage
                            description="Trait genotype chart genotype tooltip"
                            defaultMessage="Your genetic code, or DNA, is made up of a four letter alphabet - A, T, C, G. The sequence of these letters (known as nucleotides) makes up your genes. Changing a single letter can sometimes alter how your body works."
                          />{' '}
                          <ExternalLink to={LEARNING_CENTER_URL.href} linkShouldOpenInCurrentTab>
                            <FormattedMessage
                              description="Trait genotype chart genotype tooltip learn more"
                              defaultMessage="Learn more about DNA."
                            />
                          </ExternalLink>
                        </Text>
                      }
                    />
                  ),
                }}
              />
            </Text>
          </Grid>
        </Grid>
        {range(rsids.length).map((rowIndex: number) => (
          <Grid container item spacing={2}>
            <Grid item xs={4}>
              <Text>{rsids[rowIndex]}</Text>
            </Grid>
            <Grid item xs={4}>
              <Text>
                {isNearGene && (
                  <>
                    <FormattedMessage
                      defaultMessage="Near"
                      description="Trait genotype chart gene location descriptor 'Near'"
                    />{' '}
                  </>
                )}
                <i>{gene}</i>
              </Text>
            </Grid>
            <Grid item xs={4}>
              <Text>{genotypes[rowIndex]}</Text>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Spacer variant="small" />
      <Text>
        <FormattedMessage
          defaultMessage="*Each of your parents provides you with a nucleotide at this position, but we don't know which parent gave you which nucleotide."
          description="Asterisk text under trait genotype chart"
        />
      </Text>
    </>
  );
};
