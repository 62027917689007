import { Button, useLocationFlowContext } from '@color/continuum';
import { FormattedMessage } from 'react-intl';

import { InformingLoopFooter } from '../InformingLoopFooter/InformingLoopFooter';

export const MiddleModuleInformingLoopFooter: React.FC = () => {
  const { goToPreviousLocation, goToNextLocation } = useLocationFlowContext();
  return (
    <InformingLoopFooter
      leftButton={
        <Button variant="color-secondary" onClick={() => goToPreviousLocation()}>
          <FormattedMessage
            defaultMessage="Previous"
            description="Informing loop footer previous button text"
          />
        </Button>
      }
      rightButton={
        <Button onClick={() => goToNextLocation()}>
          <FormattedMessage
            defaultMessage="Next"
            description="Informing loop footer next button text"
          />
        </Button>
      }
    />
  );
};
