export enum ResultBannerIconType {
  ANCESTRY = 'ancestry',
  BITTERNESS = 'bitterness',
  CILANTRO = 'cilantro',
  EARWAX = 'earwax',
  LACTOSE = 'lactose',
  DRUGS = 'drugs',
  DRUGS_CLIPBOARD = 'drugs-clipboard',
  GENES_CLIPBOARD = 'genes-clipboard',
}
