import { ExternalLink } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { useConfigForIdp } from 'hooks/useConfigForIdp';

const messages = defineMessages({
  gemILModule5PageTitle: {
    description: 'GEM informing loop - module 5 page title',
    defaultMessage: 'Benefits',
  },
});

export const GemInformingLoopModule5: React.FC = () => {
  const { locale } = usePreferredLocale();
  const intl = useIntl();
  const { LEARNING_CENTER_DNA_AND_GENOMICS_URL } = useConfigForIdp();

  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.gemILModule5PageTitle)}
      module={{
        analyticsInformingLoopName: 'GEM Informing Loop',
        analyticsPageName: '5. Benefits',
        image: CircularImageType.STAR_DOCUMENT_BLUE,
        title: (
          <FormattedMessage
            description="GEM informing loop page 5 title"
            defaultMessage="What are the benefits of learning about my genetic ancestry and traits?"
          />
        ),
        content: (
          <FormattedMessage
            description="GEM informing loop page 5 content"
            defaultMessage="<p>Genetic ancestry and trait results are meant to be educational and interesting. You may enjoy learning about what DNA can show you about your traits and about where your ancestors may be from. You may also enjoy sharing what you learn with your family.</p>
            <p>To learn more about DNA and how genetic science works, visit the {learningCenterLink}. Learning more may help you decide if you would like to see this information.</p>"
            values={{
              learningCenterLink: (
                <ExternalLink to={LEARNING_CENTER_DNA_AND_GENOMICS_URL[locale].href}>
                  <FormattedMessage
                    description="Learning center link text"
                    defaultMessage="Learning Center"
                  />
                </ExternalLink>
              ),
            }}
          />
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
