import { ActionLink, Box, IMuiTheme, MuiTable, Text } from '@color/continuum';
// TODO Import from CDS rather than MUI. In CDS-341 we turned on lint warnings on direct MUI imports. This line is disabled because GP CI sets the rule to error instead of warn.
// Swap this for a CDS import instead of a MUI import.
// eslint-disable-next-line no-restricted-imports
import { TableBody, TableCell, TableRow } from '@mui/material';
import { FC, ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { IAppointment } from '../types';
import { deprecatedFormatTime } from '../utilities';
import { AppointmentSlotRadio } from './AppointmentSlotRadio';
import { AVAILABLE_HOURS, NUMBER_OF_TIME_SLOTS_PER_DAY, TIME_SLOTS } from './constants';
import { TimeSlot } from './types';
import { createDateFromTimeSlot } from './utils';

interface Props {
  setAppointment: (appointment: IAppointment) => void;
  appointment: IAppointment;
  appointmentAvailabilities: Date[];
  startOfSelectedDay: Date;
  nextDay: () => void;
  appointmentAvailabilitiesLoading: Boolean;
}

export const AvailabilityByDay: FC<Props> = (props) => {
  const {
    appointment,
    setAppointment,
    appointmentAvailabilities,
    startOfSelectedDay,
    nextDay,
    appointmentAvailabilitiesLoading,
  } = props;
  const appointmentAvailabilitiesSet = useMemo(
    () =>
      new Set<string>(
        appointmentAvailabilities.map((appointmentDate) => appointmentDate.toISOString())
      ),
    [appointmentAvailabilities]
  );
  return (
    <MuiTable>
      <TableBody>
        <TableRow>
          <TableCell
            sx={(theme) => ({
              padding: 0,
              width: theme.spacing(10),
              borderRight: `1px solid ${theme.palette.divider}`,
            })}
            rowSpan={NUMBER_OF_TIME_SLOTS_PER_DAY}
          >
            {AVAILABLE_HOURS.map((hour) => (
              <Text color="primary" paragraph>
                {deprecatedFormatTime(hour, appointment.timezone)}
              </Text>
            ))}
          </TableCell>
        </TableRow>
        {appointmentAvailabilitiesSet.size === 0 && !appointmentAvailabilitiesLoading ? (
          <TableCell
            sx={{
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
          >
            <FormattedMessage
              id="Scheduling_Blank_Calendar_Message"
              values={{
                link: (chunks: ReactNode) => (
                  <ActionLink onClick={nextDay} sx={{ paddingBottom: 0.5 }}>
                    {chunks}
                  </ActionLink>
                ),
              }}
            />
          </TableCell>
        ) : (
          <>
            {TIME_SLOTS.map((timeSlot: TimeSlot) => {
              const appointmentDate = createDateFromTimeSlot({
                timeSlot,
                startOfDay: startOfSelectedDay,
              });
              const appointmentAvailable = appointmentAvailabilitiesSet.has(
                appointmentDate.toISOString()
              );
              return (
                <TableRow key={`${timeSlot.hour}:${timeSlot.minute}`}>
                  <TableCell
                    sx={(theme) => ({
                      textAlign: 'left',
                      border: `1px solid ${theme.palette.common.white}`,
                      borderRight: `1px solid ${theme.palette.divider}`,
                      p: 0,
                    })}
                  >
                    {appointmentAvailable ? (
                      <AppointmentSlotRadio
                        appointmentDate={appointmentDate}
                        appointmentTimezone={appointment.timezone}
                        onAppointmentSlotRadioChange={() =>
                          setAppointment({
                            ...appointment,
                            timestamp: appointmentDate,
                          })
                        }
                        selected={
                          appointmentDate.toISOString() === appointment?.timestamp?.toISOString()
                        }
                      />
                    ) : (
                      <Box sx={{ height: (theme: IMuiTheme) => theme.spacing(4) }} />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </>
        )}
      </TableBody>
    </MuiTable>
  );
};
