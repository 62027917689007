import { ExternalLink, Text } from '@color/continuum';
import { usePreferredLocale } from '@color/lib';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { CircularImageType } from 'components/common/CircularImage/types';
import { GpTooltip } from 'components/common/GpTooltip/GpTooltip';
import { MiddleModuleInformingLoopFooter } from 'components/common/InformingLoop/InformingLoopFooters/MiddleModuleInformingLoopFooter';
import { InformingLoopModule } from 'components/common/InformingLoop/InformingLoopModule';
import { HDR_GENES_LIST_URLS } from 'lib/constants';

import { HDR_INFORMING_LOOP_NAME } from './constants';

const messages = defineMessages({
  hdrILModule2PageTitle: {
    description: 'HDR Informing Loop Page Title - Module 2',
    defaultMessage: 'What You Can Learn',
  },
  hdrIlTooltipLabelHereditary: {
    description: 'HDR Informing Loop Hereditary tooltip accessible label',
    defaultMessage: 'Hereditary Info',
  },
  hdrIlTooltipLabelDiseases: {
    description: 'HDR Informing Loop Genetic Diseases tooltip accessible label',
    defaultMessage: 'Genetic Diseases Info',
  },
  hdrIlTooltipLabelGenesList: {
    description: 'HDR Informing Loop Genes List tooltip accessible label',
    defaultMessage: 'Genes List Info',
  },
});

export const HdrInformingLoopModule2: React.FC = () => {
  const intl = useIntl();
  const { locale } = usePreferredLocale();
  return (
    <InformingLoopModule
      helmetTitle={intl.formatMessage(messages.hdrILModule2PageTitle)}
      module={{
        analyticsInformingLoopName: HDR_INFORMING_LOOP_NAME,
        analyticsPageName: '2. About Results',
        image: CircularImageType.HEART_AND_RIBBON,
        title: (
          <FormattedMessage
            id="HDR_IL_2__Title"
            values={{
              tooltip: (
                <GpTooltip
                  aria-label={intl.formatMessage(messages.hdrIlTooltipLabelHereditary)}
                  content={
                    <Text>
                      <FormattedMessage
                        description="HDR informing loop page 2 title tooltip"
                        defaultMessage='"Hereditary” means it is passed to you in your DNA from one or both of your parents. "Disease risk” is how we refer to whether you have a higher chance of developing a health condition. Having a higher disease risk doesn’t mean you will definitely get that health condition. Your lifestyle and environment can also have an impact.'
                      />
                    </Text>
                  }
                />
              ),
            }}
          />
        ),
        content: (
          <>
            <Text paragraph>
              <FormattedMessage
                description="HDR informing loop page 2 content paragraph 1"
                defaultMessage="Your results will have information about your DNA and whether you may have a greater risk for developing some serious health conditions, such as certain kinds of cancer or heart disease."
              />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="HDR informing loop page 2 content paragraph 2"
                defaultMessage="About 2 out of 100 people will get a DNA result that shows that they have a higher risk of developing a disease. For example, a small number of people may find out they have a version of the <i>BRCA1</i> gene that increases their risk for some kinds of cancers, like breast cancer. {tooltip}"
                values={{
                  tooltip: (
                    <GpTooltip
                      aria-label={intl.formatMessage(messages.hdrIlTooltipLabelDiseases)}
                      content={
                        <Text>
                          <FormattedMessage
                            description="HDR informing loop page 2 paragraph 2 tooltip"
                            defaultMessage="Everyone has the same set of genes, but people can have slightly different versions of those genes. Sometimes, these differences can increase a person’s risk of developing certain diseases."
                          />
                        </Text>
                      }
                    />
                  ),
                }}
              />
            </Text>
            <Text paragraph>
              <FormattedMessage
                description="HDR informing loop page 2 content paragraph 3"
                defaultMessage="Most people (about 98 out of 100 people) will get a report that says <i>All of Us</i> did not find anything significant for their health in the DNA we looked at. {tooltip}"
                values={{
                  tooltip: (
                    <GpTooltip
                      aria-label={intl.formatMessage(messages.hdrIlTooltipLabelGenesList)}
                      content={
                        <Text>
                          <FormattedMessage
                            description="HDR informing loop page 2 paragraph 3 tooltip"
                            defaultMessage="There are a lot of genes that can affect your risk of disease. We won’t look at all of them. We will only look at some of the genes that are currently known to cause serious health conditions and have treatment or prevention options available. You can find the current list of genes we will look at {genesListLink}."
                            values={{
                              genesListLink: (
                                <ExternalLink to={HDR_GENES_LIST_URLS[locale]}>
                                  <FormattedMessage
                                    description="HDR informing loop page 2 paragraph 3 tooltip genes list link"
                                    defaultMessage="here"
                                  />
                                </ExternalLink>
                              ),
                            }}
                          />
                        </Text>
                      }
                    />
                  ),
                }}
              />
            </Text>
          </>
        ),
      }}
      footer={<MiddleModuleInformingLoopFooter />}
    />
  );
};
